import React, { useCallback, useContext, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

// STORE:
import { WastebinsStoreContext } from 'stores/wastebins';

// TYPES:
import { IGridTableSchema } from 'common/components/grid/types';
import { SearchWorkOrderType } from 'api/wastebins/types';

// COMPONENTS:
import GridTable from 'common/components/grid/GridTable';
import Toolbar from './Toolbar';
import DateCell from 'common/components/grid/DateCell';
import { useParams } from '@reach/router';
import CheckRowCell from './CheckRowCell';

const schema: IGridTableSchema<SearchWorkOrderType> = {
  header: {
    columns: [
      {
        id: 'checkboxCell',
        name: '',
      },
      {
        id: 'Id',
        name: <FormattedMessage id="Wastebins.tableHeaders.id" />,
      },
      {
        id: 'OrderDate',
        name: <FormattedMessage id="Wastebins.tableHeaders.orderDate" />,
      },
      {
        id: 'OrderType',
        name: <FormattedMessage id="Wastebins.tableHeaders.orderType" />,
      },
      {
        id: 'AgreementNumber',
        name: <FormattedMessage id="Wastebins.tableHeaders.agreementNr" />,
      },
      {
        id: 'CustomerName',
        name: <FormattedMessage id="Wastebins.tableHeaders.customerName" />,
      },
      {
        id: 'Address',
        name: <FormattedMessage id="Wastebins.tableHeaders.address" />,
      },
      {
        id: 'Municipality',
        name: <FormattedMessage id="Wastebins.tableHeaders.municipality" />,
      },
      {
        id: 'WasteBinId',
        name: <FormattedMessage id="Wastebins.tableHeaders.wasteBinId" />,
      },
      {
        id: 'WasteBinType',
        name: <FormattedMessage id="Wastebins.tableHeaders.binType" />,
      },
      {
        id: 'WasteBinWasteType',
        name: <FormattedMessage id="Wastebins.tableHeaders.wasteType" />,
      },
      {
        id: 'Comment',
        name: <FormattedMessage id="Wastebins.tableHeaders.comment" />,
      },
    ],
  },
  row: {
    id: 'Id',
    cells: [
      {
        customKey: 'checkboxCell',
        formatter: CheckRowCell,
      },
      {
        key: 'Id',
      },
      {
        key: 'OrderDate',
        formatter: DateCell,
      },
      {
        key: 'OrderType',
      },
      {
        key: 'AgreementNumber',
      },
      {
        key: 'CustomerName',
      },
      {
        key: 'Address',
      },
      {
        key: 'Municipality',
      },
      {
        key: 'WasteBinId',
      },
      {
        key: 'WasteBinType',
      },
      {
        key: 'WasteBinWasteType',
      },
      {
        key: 'Comment',
      },
    ],
  },
};

export enum SearchFilters {
  CustomerName = 'CustomerName',
  Address = 'Address',
  AgreementNumber = 'AgreementNumber',
  Barcode = 'Code128',
}

const Table = () => {
  const [search, setSearch] = useState('');

  const [selectedFilters, setSelectedFilters] = useState({
    wasteType: '',
    binType: '',
    dateFrom: '',
    dateTo: '',
  });

  const {
    searchWorkOrder,
    searchWorkOrderState,
    workOrderGroupData,
    clearSelectedWorkOrderToPrint,
  } = useContext(WastebinsStoreContext);

  const params = useParams();

  const getWorkOrderGroupData = useCallback(
    () =>
      searchWorkOrder({
        GroupId: params.workOrderGroupId,
        OrderDateFrom: selectedFilters.dateFrom,
        OrderDateTo: selectedFilters.dateTo,
        WasteBinType: selectedFilters.binType,
        WasteBinWasteType: selectedFilters.wasteType,
        AgreementNumber: parseInt(search),
      }),
    [params.workOrderGroupId, selectedFilters, search, searchWorkOrder]
  );

  const workOrderGroupTableData = workOrderGroupData?.Result.$values || [];

  useEffect(() => clearSelectedWorkOrderToPrint(), [
    clearSelectedWorkOrderToPrint,
  ]);

  return (
    <GridTable
      tableSchema={schema}
      data={workOrderGroupTableData}
      getData={getWorkOrderGroupData}
      loadingState={searchWorkOrderState}
      customToolbar={
        <Toolbar
          setSearch={setSearch}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
      }
    />
  );
};
export default observer(Table);
