import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import { Grid, Paper, Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

// COMPONENTS:
import UsersTable from './components/UsersTable';
import Header from 'common/components/Header';
import AddEditUserModal from '../User/components/AddEditUserModal';
import UserDetails from './components/UserDetails';

// STORE:
import { UsersStoreContext, IUsersStore } from 'stores/users';

// STYLES:
import { useStyles } from './index.styles';

// ICONS:
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';

const UsersList = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const { currentUser, setCurrentUser }: IUsersStore = useContext(
    UsersStoreContext
  );

  const classes = useStyles();

  const breadcrumbs = [
    {
      name: 'Users',
      path: '/users',
    },
  ];

  return (
    <div className={classes.container}>
      <Header
        breadcrumbs={breadcrumbs}
        title={<FormattedMessage id="Company.Users.users" />}
      >
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.addButton}
          onClick={() => {
            setCurrentUser(null);
            toggleModal();
          }}
        >
          <AddOutlinedIcon />
          <FormattedMessage id="Company.Users.addUser" />
        </Button>
      </Header>
      <Grid container className={classes.contentContainer}>
        <Grid item xs={12} md={6} className={classes.column}>
          <UsersTable />
        </Grid>

        <Grid item xs={12} md={6} className={classes.column}>
          <Paper className={classes.paper}>
            {!currentUser ? (
              <div className={classes.selectCustomerPlaceholder}>
                <PersonOutlineIcon className={classes.selectedIcon} />
                <FormattedMessage id="Company.Users.selectUserPlaceholder" />
              </div>
            ) : (
              <UserDetails user={currentUser} />
            )}
          </Paper>
        </Grid>
      </Grid>
      {isOpen && <AddEditUserModal isOpen={isOpen} toggleModal={toggleModal} />}
    </div>
  );
};

export default observer(UsersList);
