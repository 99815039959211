import { useFormikContext } from 'formik';
import React from 'react';

export const AutoSubmit = () => {
  const { values, submitForm } = useFormikContext();

  React.useEffect(() => {
    submitForm();
  }, [values, submitForm]);
  return null;
};
