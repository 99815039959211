import React, { useContext, useState, useEffect } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import { Grid, InputAdornment, IconButton } from '@material-ui/core';
import { observer } from 'mobx-react';

// COMPONENTS:
import InputField from 'common/components/formik/InputField';
import ButtonLoading from 'common/components/buttons/ButtonLoading';
import CustomModal from 'common/components/CustomModal';
import ModalRow from 'common/components/CustomModal/ModalRow';
import SelectField from 'common/components/formik/SelectField';
import Alert from 'common/components/Alert';

// ICONS:
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

// TYPES
import { EmployeeType } from 'api/employees/types';

// STORES
import { EmployeesStoreContext, IEmployeesStore } from 'stores/employees';
import * as Yup from 'yup';

interface IEmployeeModal {
  intl: IntlShape;
  currentEmployee?: EmployeeType;
  isOpen: boolean;
  toggleModal: () => void;
}

interface IFormValues {
  id: string;
  name: string;
  visibleName: string;
  role: any;
  password: string;
  address: string;
  postNr: number | null;
  email: string;
  phoneNumber: string;
  altPhoneNumber: string;
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  editButton: {
    height: 32,
    display: 'flex',
    justifyContent: 'flex-end',
    '& Svg': {
      width: 14,
    },
  },
  editIcon: {
    height: 14,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  row: {
    display: 'flex',
    borderBottom: `1px solid ${palette.action.disabledBackground}`,
    paddingBottom: spacing(2.5),
  },
  input: {
    padding: spacing(1, 3, 1, 0),
  },
  button: {
    marginTop: spacing(4),
  },
  section: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  additionalSection: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  anotherRow: {
    paddingTop: spacing(2),
  },
}));

const EmployeeModal: React.FC<IEmployeeModal> = ({
  intl,
  currentEmployee,
  isOpen,
  toggleModal,
}) => {
  const {
    addNewEmployee,
    editEmployee,
    getEmployeeById,
    getEmployees,
    saveEmployeeError,
    clearEmployeeError,
  }: IEmployeesStore = useContext(EmployeesStoreContext);

  const classes = useStyles();

  useEffect(() => {
    clearEmployeeError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearEmployeeError]);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const modalTitle = currentEmployee ? (
    <FormattedMessage
      id="Company.Employee.editModal.title"
      values={{
        b: (chunk: string) => <b>{chunk}</b>,
        value: `${currentEmployee?.BKey}, ${currentEmployee?.Navn}`,
      }}
    />
  ) : (
    <FormattedMessage id="Company.Employees.addEmployee" />
  );

  const roleOptions = [
    { value: '0', label: 'Admin' },
    { value: '1', label: 'Leder' },
    { value: '2', label: 'Standard' },
  ];

  const initialValues: IFormValues = {
    id: currentEmployee?.BKey || '',
    name: currentEmployee?.Navn || '',
    visibleName: currentEmployee?.SynligNavn || '',
    role:
      currentEmployee && currentEmployee?.RollePKey !== null
        ? roleOptions.find(
            option => option.value === currentEmployee?.RollePKey?.toString()
          )
        : '0',
    password: currentEmployee?.Passord || '',
    address: currentEmployee?.Adresse || '',
    postNr: currentEmployee?.PostNr || null,
    email: currentEmployee?.EPost || '',
    phoneNumber: currentEmployee?.TlfNr || '',
    altPhoneNumber: currentEmployee?.MobNr || '',
  };

  const handleAddEmployee = async (values: IFormValues, { setErrors }: any) => {
    const addingSuccess = await addNewEmployee(values);

    if (addingSuccess) {
      toggleModal();
      await getEmployees();
    } else {
      setErrors({ id: '' });
    }
  };

  const handleEditEmployee = async (values: IFormValues) => {
    await editEmployee(values);

    toggleModal();
    await getEmployeeById(currentEmployee!.BKey || '');
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('This field cannot be empty!')
      .max(50, 'Name can be max 50 characters long'),
    visibleName: Yup.string().max(50, 'Name can be max 50 characters long'),
    id: Yup.string()
      .required('This field cannot be empty!')
      .max(16, 'ID can be max 16 characters long'),
    password: Yup.string()
      .required('This field cannot be empty!')
      .max(16, 'Password can be max 16 characters long'),
    address: Yup.string().max(200, 'Address can be max 200 characters long'),
  });

  return (
    <CustomModal isOpen={isOpen} toggle={toggleModal} modalTitle={modalTitle}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={currentEmployee ? handleEditEmployee : handleAddEmployee}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ isSubmitting, values }) => {
          return (
            <>
              <Form noValidate>
                {saveEmployeeError && (
                  <Alert type="danger">{saveEmployeeError} </Alert>
                )}
                <>
                  <ModalRow rowTitle="General">
                    <Grid item xs={3} className={classes.input}>
                      <InputField
                        name="name"
                        placeholder={intl.formatMessage({
                          id: 'Global.form.placeholders.name',
                        })}
                        label={
                          <FormattedMessage id={`Global.form.labels.name`} />
                        }
                        size="small"
                        required
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.input}>
                      <InputField
                        name="visibleName"
                        placeholder={intl.formatMessage({
                          id: 'Global.form.placeholders.name',
                        })}
                        label={
                          <FormattedMessage
                            id={`Global.form.labels.visibleName`}
                          />
                        }
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.input}>
                      <SelectField
                        name="role"
                        options={roleOptions}
                        defaultValue={initialValues.role}
                        placeholder={intl.formatMessage({
                          id: 'Global.form.placeholders.role',
                        })}
                        label={
                          <FormattedMessage id={`Global.form.labels.role`} />
                        }
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.input}>
                      <InputField
                        name="id"
                        label={
                          <FormattedMessage id={`Global.form.labels.id`} />
                        }
                        placeholder={intl.formatMessage({
                          id: 'Global.form.placeholders.id',
                        })}
                        size="small"
                        required
                        disabled={currentEmployee !== undefined}
                      />
                    </Grid>
                  </ModalRow>

                  <ModalRow rowTitle="Employee password">
                    <Grid item xs={3} className={classes.input}>
                      <InputField
                        name="password"
                        value={values.password}
                        placeholder={intl.formatMessage({
                          id: 'Global.form.placeholders.password',
                        })}
                        label={
                          <FormattedMessage
                            id={`Global.form.labels.password`}
                          />
                        }
                        size="small"
                        required
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                          autoComplete: 'new-password',
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowPassword}
                                // onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </ModalRow>

                  <ModalRow rowTitle="Contact">
                    <Grid container className={classes.section}>
                      <Grid item xs={6} className={classes.input}>
                        <InputField
                          name="address"
                          placeholder={intl.formatMessage({
                            id: 'Global.form.placeholders.address',
                          })}
                          label={
                            <FormattedMessage
                              id={`Global.form.labels.address`}
                            />
                          }
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={3} className={classes.input}>
                        <InputField
                          name="postNr"
                          placeholder={intl.formatMessage({
                            id: 'Global.form.placeholders.postNr',
                          })}
                          label={
                            <FormattedMessage
                              id={`Global.form.labels.postNr`}
                            />
                          }
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={3} className={classes.input}>
                        <InputField
                          name="email"
                          placeholder={intl.formatMessage({
                            id: 'Global.form.placeholders.email',
                          })}
                          label={
                            <FormattedMessage id={`Global.form.labels.email`} />
                          }
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={3} className={classes.input}>
                        <InputField
                          name="phoneNumber"
                          placeholder={intl.formatMessage({
                            id: 'Global.form.placeholders.phoneNumber',
                          })}
                          label={
                            <FormattedMessage
                              id={`Global.form.labels.phoneNumber`}
                            />
                          }
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={3} className={classes.input}>
                        <InputField
                          name="altPhoneNumber"
                          placeholder={intl.formatMessage({
                            id: 'Global.form.placeholders.phoneNumber',
                          })}
                          label={
                            <FormattedMessage
                              id={`Global.form.labels.altPhoneNumber`}
                            />
                          }
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  </ModalRow>

                  <ButtonLoading
                    type="submit"
                    value={currentEmployee ? 'Global.save' : 'Global.create'}
                    state={isSubmitting}
                    disabled={isSubmitting}
                    className={classes.button}
                  />
                </>
              </Form>
            </>
          );
        }}
      </Formik>
    </CustomModal>
  );
};

export default injectIntl(observer(EmployeeModal));
