import React from 'react';
import {
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Divider,
} from '@material-ui/core';
import { injectIntl, IntlShape } from 'react-intl';

// ICONS:
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';

// COMPONENTS:
import SearchInput from 'common/components/SearchInput';
import FiltersForm from './FiltersForm';

// STYLES:
import { useStyles } from './index.styles';

// TYPES
interface IToolbar {
  intl: IntlShape;
}

const Toolbar: React.FC<IToolbar> = ({ intl }) => {
  const classes = useStyles();

  return (
    <div>
      <ExpansionPanel className={classes.expansion}>
        <div className={classes.row}>
          <SearchInput
            placeholder={intl.formatMessage({
              id: 'Company.Pricetables.searchPlaceholder',
            })}
            onChange={e => console.log(e.target.value)}
          />
          <ExpansionPanelSummary aria-controls="panel1a-content">
            <Button
              variant="outlined"
              color="primary"
              size="small"
              className={classes.filterButton}
            >
              <FilterListOutlinedIcon />
            </Button>
          </ExpansionPanelSummary>
        </div>
        <ExpansionPanelDetails className={classes.expansionDetails}>
          <FiltersForm />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <Divider className={classes.divider} />
    </div>
  );
};

export default injectIntl(Toolbar);
