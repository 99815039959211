import { createMuiTheme } from '@material-ui/core/styles';

import { palette } from './palette';
import { overrides } from './overrides';
import { constants } from './constants';
import { typography } from './typography';

export const mainThemeObj = {
  palette,
  overrides,
  constants,
  typography,
};

export const mainTheme = createMuiTheme(mainThemeObj);
