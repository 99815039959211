import React, { useContext } from 'react';
import { observer } from 'mobx-react';

// TYPES:
import { EmployeeType } from 'api/employees/types';
import { IGridTableSchema } from 'common/components/grid/types';

// COMPONENTS:
import GridTable from 'common/components/grid/GridTable';
import EmployeeRow from './EmployeeRow';
import Toolbar from './Toolbar';
import StatusCell from './StatusCell';
import RoleCell from './RoleCell';
import SortableStringHeader from './SortableStringHeader';
import SortableNumericHeader from './SortableNumericHeader';

// STORE:
import { EmployeesStoreContext, IEmployeesStore } from 'stores/employees';
import { FormattedMessage } from 'react-intl';

const schema: IGridTableSchema<EmployeeType> = {
  header: {
    columns: [
      {
        id: 'BKey',
        name: <FormattedMessage id="Company.Employees.tableHeaders.id" />,
        formatter: SortableStringHeader,
      },
      {
        id: 'Navn',
        name: <FormattedMessage id="Company.Employees.tableHeaders.name" />,
        formatter: SortableStringHeader,
      },
      {
        id: 'SynligNavn',
        name: <FormattedMessage id="Company.Employees.tableHeaders.visibleName" />,
        formatter: SortableStringHeader,
      },
      {
        id: 'RollePKey',
        name: <FormattedMessage id="Company.Employees.tableHeaders.role" />,
        formatter: SortableNumericHeader,
      },
      {
        id: 'DatoSluttet',
        name: <FormattedMessage id="Company.Employees.tableHeaders.status" />,
      },
    ],
  },
  row: {
    id: 'BKey',
    formatter: EmployeeRow,
    cells: [
      {
        key: 'BKey',
      },
      {
        key: 'Navn',
      },
      {
        key: 'SynligNavn',
      },
      {
        key: 'RollePKey',
        formatter: RoleCell,
      },
      {
        customKey: 'StatusCell',
        formatter: StatusCell,
      },
    ],
  },
};

const CustomersTable = () => {
  const {
    employees,
    getEmployees,
    getEmployeesState,
  }: IEmployeesStore = useContext(EmployeesStoreContext);

  return (
    <GridTable
      tableSchema={schema}
      data={employees}
      toolbar={Toolbar}
      loadingState={getEmployeesState}
      getData={getEmployees}
    />
  );
};

export default observer(CustomersTable);
