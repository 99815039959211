import React from 'react';
import TableauReport from 'react-tableau-report';

const MyTableauReport = () => {
  return (
    <TableauReport url="http://public.tableau.com/views/RegionalSampleWorkbook/Storms" />
  );
};

export default MyTableauReport;
