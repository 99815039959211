import React, { useState } from 'react';
import { InputBase, makeStyles, MenuItem, Select } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  select: {
    fontWeight: 'bold',
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
}));

interface IChartFilter {
  options: Array<{ label: string; value: string }>;
  initialValue?: string;
  onChangeCallback?: (option: string) => void;
}

const ChartFilter: React.FC<IChartFilter> = ({
  initialValue,
  options,
  onChangeCallback,
}) => {
  const [option, setOption] = useState(initialValue || options[0].value);

  const handleChange = (e: any) => {
    setOption(e.target.value);
    onChangeCallback && onChangeCallback(e.target.value);
  };

  const classes = useStyles();

  return (
    <Select
      classes={{ select: classes.select }}
      value={option}
      onChange={handleChange}
      input={<InputBase />}
      IconComponent={KeyboardArrowDown}
    >
      {options.map(({ label, value }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default ChartFilter;
