import React from 'react';
import Chart from 'react-apexcharts';
import { Paper } from '@material-ui/core';
import { TrendingUp } from '@material-ui/icons';

// STYLES:
import { useStyles } from './index.styles';
import ChartFilter from '../../../ChartFilter';

const Transactions = () => {
  const options = {
    chart: {
      type: 'area',
      sparkline: {
        enabled: true,
      },
    },
    colors: ['#ffd23f'],
    stroke: {
      curve: 'straight',
      width: 2,
    },
    yaxis: {
      min: 0,
    },
    title: {
      text: '$ 12,345.6',
      style: {
        fontSize: '24px',
      },
    },
  };

  const series = [
    {
      data: [
        13,
        25,
        20,
        30,
        41,
        23,
        51,
        7,
        20,
        19,
        35,
        14,
        13,
        25,
        20,
        30,
        41,
        23,
        51,
        7,
        20,
        19,
        35,
        14,
      ],
    },
  ];

  const classes = useStyles();

  const filterOptions = [
    {
      value: 'monthly',
      label: 'Monthly',
    },
    {
      value: 'weekly',
      label: 'Weekly',
    },
    {
      value: 'daily',
      label: 'Daily',
    },
  ];

  return (
    <Paper className={classes.paper}>
      <div className={classes.toolbarContainer}>
        <div className={classes.title}>
          <TrendingUp color="primary" />
          <span className={classes.titleText}>Transactions</span>
        </div>
        <ChartFilter
          options={filterOptions}
          onChangeCallback={o => console.log(o)}
        />
      </div>
      <Chart options={options} series={series} type="area" />
    </Paper>
  );
};

export default Transactions;
