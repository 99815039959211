import React, { useContext } from 'react';
import { observer } from 'mobx-react';

// TYPES:
import { ClientType } from 'api/clients/types';

// STORES:
import { ClientsStoreContext, IClientsStore } from 'stores/clients';

interface IClientGroupFormatter {
  currentItem: ClientType;
}

const ClientGroupFormatter: React.FC<IClientGroupFormatter> = ({
  currentItem,
}) => {
  const { allClientGroups }: IClientsStore = useContext(ClientsStoreContext);

  const clientGroup = allClientGroups?.find(
    group => group.PKey === currentItem.ClientGroupPKey
  );

  return <>{clientGroup ? clientGroup.Name : ''}</>;
};

export default observer(ClientGroupFormatter);
