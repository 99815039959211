import React from 'react';
import { Redirect, RouteComponentProps } from '@reach/router';
import { observer } from 'mobx-react';

// HELPERS:
import { getRefreshToken } from 'common/helpers/auth';

// LAYOUT:
import PrivateLayout from 'common/components/layouts/PrivateLayout';

interface IPrivateRoute extends RouteComponentProps {
  component: React.FC;
  layout?: React.FC;
}

const PrivateRoute: React.FC<IPrivateRoute> = ({
  component: Component,
  layout: Layout = PrivateLayout,
  ...rest
}) => {

  if (getRefreshToken()) {
    return (
      <Layout>
        <Component {...rest} />
      </Layout>
    );
  } else {
    return <Redirect to="/login" noThrow />;
  }
};

export default observer(PrivateRoute);
