import React, { useRef } from 'react';
import {
  Grid,
  makeStyles,
  Typography,
  IconButton,
  Button
} from '@material-ui/core';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
// import { useQuery } from 'react-query';

// ICONS:
import CloseIcon from '@material-ui/icons/Close';

// STORES:
import cardPreview from 'assets/images/card-preview.png';
import { Form, Formik } from 'formik';

// COMPONENTS:
// import { AutoSubmit } from 'common/components/formik/AutoSubmitForm';
import InputField from 'common/components/formik/InputField';
// import SelectField from 'common/components/formik/SelectField';

// // API:
// import { getApartmentNumbers } from 'api/customers';

// TYPES:
import { CustomerType, RegisterEnheterType } from 'api/customers/types';
import { useReactToPrint } from 'react-to-print';
import ButtonLoading from '../../../../../../../../../common/components/buttons/ButtonLoading';

interface IPrintCardApartments {
  toggle: () => void;
  intl: IntlShape;
  customer: CustomerType;
  cardNumber: string;
  apartment: RegisterEnheterType | undefined;
  nextStep: () => void;
  setExpirationDate: (value: string) => void;
}

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  stepContainer: {
    height: '100%',
    padding: spacing(4, 3),
    position: 'relative',
  },
  stepInfo: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'baseline',
    paddingTop: spacing(6),
  },
  formLabel: {
    padding: spacing(4, 0, 1, 0),
  },
  title: {
    color: palette.secondary.main,
  },
  submitButton: {
    marginTop: spacing(14),
  },
  inputRoot: {
    fontSize: 13,
    border: `1px solid ${palette.secondary.light}`,
    borderRadius: '4px',
    transition:
      'border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
  },
  inputInput: {
    padding: '8px',
  },
  inputError: {
    borderColor: palette.error.main,
    '&.Mui-focused': {
      borderColor: palette.error.main,
    },
  },
  inputFocused: {
    borderColor: palette.primary.main,
    borderWidth: 1,
  },
  inputDisabled: {
    backgroundColor: palette.action.disabledBackground,
    borderColor: palette.action.disabledBackground,
    color: palette.action.disabled,
  },
  commentField: {
    paddingBottom: spacing(7),
    width: '321px !important',
  },
  infoIcon: {
    paddingRight: spacing(1),
  },
  cardPreviewContainer: {
    boxShadow: '0px 0px 16px 0px rgba(0,0,0,0.15)',
    marginBottom: spacing(3),
    background: `url(${cardPreview})`,
    width: '321px',
    height: '204px',
  },
  cardPreview: {
    // boxShadow: '0px 0px 16px 0px rgba(0,0,0,0.15)',
    borderRadius: 4.8,
    width: '321px',
    height: '204px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // background: `url(${cardPreview})`,
  },
  buttons: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: spacing(3, 20, 0),
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
  },
  closeIcon: {
    padding: 0,
    '& Svg': {
      color: palette.secondary.main,
    },
  },
  customFieldsArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // maxHeight: 75,
    padding: '19px 19px 0 23px',
  },
  cardText: {
    fontSize: '10px',
    fontWeight: typography.fontWeightMedium,
  },
  scannedCardNumber: {
    paddingBottom: spacing(2),
  },
  uidCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  rowHeader: {
    fontWeight: typography.fontWeightMedium,
    color: palette.secondary.light
  },
  date: {
    margin: spacing(0, 12),
  },
  displayField: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
    paddingTop: spacing(3),
  },
}));

const PrintCardApartments: React.FC<IPrintCardApartments> = ({ apartment, toggle, customer, cardNumber, nextStep, setExpirationDate }) => {
  const classes = useStyles();

  // const { scannedCardNumber, addCustomerCard }: ICustomersStore = useContext(
  //   CustomersStoreContext
  // );

  // const addCard = async (values: { expirationDate: string }) => {
  //   const cardData = {
  //     expirationDate: values.expirationDate,
  //     cardNumber: cardNumber,
  //     scannedCardNumber: scannedCardNumber,
  //   };
  //
  //   const apartmentCardData = {
  //     expirationDate: values.expirationDate,
  //     cardNumber: cardNumber,
  //     scannedCardNumber: scannedCardNumber,
  //     registerEnhetId: apartment!.RegisterEnhetId,
  //   }
  //   await addCustomerCard(apartment ? apartmentCardData : cardData);
  //   toggle();
  //   getCards();
  // };

  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleSubmit = (values: { expirationDate: string }) => {
    setExpirationDate(values.expirationDate);
    nextStep();
  };

  return (
    <Grid className={classes.stepContainer}>
      <div className={classes.modalHeader}>
        <Typography variant="h3" className={classes.title}>
          {apartment ? 'Preview (Apartment)' : 'Preview (Apartment block)'}
        </Typography>
        <IconButton className={classes.closeIcon} onClick={toggle}>
          <CloseIcon />
        </IconButton>
      </div>
      <Grid item xs={12} className={classes.stepInfo}>
        <div className={classes.cardPreviewContainer}>
          <div className={classes.cardPreview} ref={componentRef}>
            <div className={classes.customFieldsArea}>
              <Typography className={classes.cardText}>Gateadresse: {customer.AlternativtNavn}</Typography>
              <Typography className={classes.cardText}>Avtalenr: {customer.BKey}</Typography>
              {cardNumber && <Typography className={classes.cardText}>Kortnr: {cardNumber}</Typography>}
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', width: '100%' }}>
          <Grid item style={{ marginTop: 4, width: '100%' }}>
            <Formik
              initialValues={{ expirationDate: '' }}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => {
                return (
                  <Form>
                    <Grid container style={{ padding: '0 40px' }}>
                      {/*<Grid item xs={6} className={classes.uidCard}>*/}
                      {/*  <Typography variant="body2" className={classes.rowHeader}>*/}
                      {/*    UID from scanned card*/}
                      {/*  </Typography>*/}
                      {/*  <Typography variant="body2">*/}
                      {/*    {scannedCardNumber}*/}
                      {/*  </Typography>*/}
                      {/*</Grid>*/}
                      <Grid item xs={12} className={classes.date}>
                        <InputField
                          name="expirationDate"
                          label={
                            <FormattedMessage id="Global.form.labels.expirationDate" />
                          }
                          type="date"
                        />
                      </Grid>

                      {apartment && (
                        <>
                          <Grid item xs={4} className={classes.displayField}>
                            <Typography variant="body2" className={classes.rowHeader}>
                              Apartmentnr.
                            </Typography>
                            <Typography variant="body2">
                              {apartment?.BoligNummer || '-'}
                            </Typography>
                          </Grid>
                          <Grid item xs={4} className={classes.displayField}>
                            <Typography variant="body2" className={classes.rowHeader}>
                              Housenr.
                            </Typography>
                            <Typography variant="body2">
                              {apartment?.HusNummer || '-'}
                            </Typography>
                          </Grid>
                          <Grid item xs={4} className={classes.displayField}>
                            <Typography variant="body2" className={classes.rowHeader}>
                              Letter
                            </Typography>
                            <Typography variant="body2">
                              {apartment?.Bokstav || '-'}
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <div className={classes.buttons}>
                      <Button variant="outlined" color="primary" onClick={handlePrint}>Print</Button>
                      <ButtonLoading
                        state={isSubmitting}
                        disabled={isSubmitting}
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        next
                      </ButtonLoading>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default injectIntl(PrintCardApartments);
