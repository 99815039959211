import React, { useContext, useState } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import { Grid, IconButton, InputAdornment } from '@material-ui/core';
import { observer } from 'mobx-react';
import * as Yup from 'yup';

// COMPONENTS:
import InputField from 'common/components/formik/InputField';
import ButtonLoading from 'common/components/buttons/ButtonLoading';
import CustomModal from 'common/components/CustomModal';
import ModalRow from 'common/components/CustomModal/ModalRow';
import SelectField from 'common/components/formik/SelectField';

// TYPES:
import { UserType } from 'api/users/types';

// API:
import { addUser as addUserAPI, editUser as editUserAPI } from 'api/users';
import { getEmployees } from 'api/employees';

// STORES:
import { IUsersStore, UsersStoreContext } from 'stores/users';

// QUERIES:
import { useMutation, useQuery } from 'react-query';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

interface IAddEditUserModal {
  intl: IntlShape;
  currentUser?: UserType;
  isOpen: boolean;
  toggleModal: () => void;
}

interface IFormValues {
  name: string;
  username: string;
  password: string;
  active: any;
  phone: string;
  email: string;
  employee: any;
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  editButton: {
    height: 32,
    display: 'flex',
    justifyContent: 'flex-end',
    '& Svg': {
      width: 14,
    },
  },
  editIcon: {
    height: 14,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  row: {
    display: 'flex',
    borderBottom: `1px solid ${palette.action.disabledBackground}`,
    paddingBottom: spacing(2.5),
  },
  input: {
    padding: spacing(1, 3, 1, 0),
  },
  sectionEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  sectionStart: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  additionalSection: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  anotherRow: {
    paddingTop: spacing(2),
  },
  additionalSectionButton: {
    '& Svg': {
      width: 16,
      marginRight: spacing(0.5),
    },
  },
  removeSectionButton: {
    color: palette.error.main,
    '& Svg': {
      width: 16,
      marginRight: spacing(0.5),
      color: palette.error.main,
    },
  },
}));

const AddEditUserModal: React.FC<IAddEditUserModal> = ({
  intl,
  currentUser,
  isOpen,
  toggleModal,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const { getUsers }: IUsersStore = useContext(UsersStoreContext);

  const { mutate: addUser } = useMutation(
    async (values: any) => {
      await addUserAPI(values);
    },
    {
      onSuccess: () => toggleModal(),
      onSettled: () => getUsers(),
    }
  );

  const { mutate: editUser } = useMutation(async (values: any) => await editUserAPI(values), {
    onSuccess: () => toggleModal(),
  });

  const { data: allEmployees, status: allEmployeesStatus, isSuccess: allEmployeesIsSuccess } = useQuery('allEmployes', async () => await getEmployees())

  const classes = useStyles();

  const modalTitle = currentUser ? (
    <FormattedMessage id="Company.Users.editUser" />
  ) : (
    <FormattedMessage id="Company.Users.addUser" />
  );

  const handleAddUser = async (values: IFormValues) => {
    const newUserValues = {
      ...values,
      active: Boolean(values.active),
    };
    await addUser(newUserValues);
  };

  const handleEditUser = async (values: IFormValues) => {
    await editUser({
      PKey: currentUser!.PKey,
      Password: values.password,
      Username: values.username,
      Name: values.name,
      Email: values.email,
      Phone: values.phone,
      Active: Boolean(values.active),
      EmployeeBKey: values.employee,
      EmployeeName: allEmployees?.data.find((employee: any) => employee.BKey === values.employee).Navn,
      EmployeeBnr: allEmployees?.data.find((employee: any) => employee.BKey === values.employee).Bnr,
    });
  };

  const statusOptions = [
    {
      value: 'true',
      label: 'Active',
    },
    {
      value: 'false',
      label: 'Deactivated',
    },
  ];

  const employeeOptions = allEmployeesIsSuccess ? allEmployees?.data?.map((option: any) => ({
    value: option.BKey.toString() || '',
    label: option.Navn || '',
  })) : [];


  const validationSchema = Yup.object().shape({
    name: Yup.string().required('This field cannot be empty!'),
    username: Yup.string().required('This field cannot be empty!'),
  });

  const initialValues: IFormValues = {
    username: currentUser?.Username || '',
    name: currentUser?.Name || '',
    password: currentUser?.Password || '',
    email: currentUser?.Email || '',
    phone: currentUser?.Phone || '',
    active:
      statusOptions.find(
        option => JSON.parse(option.value) === currentUser?.Active
      ) || '',
    employee:
      employeeOptions.find(
        (option: any) => option.value === currentUser?.EmployeeBKey
      ) || '',
  };

  return (
    <CustomModal isOpen={isOpen} toggle={toggleModal} modalTitle={modalTitle}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={currentUser ? handleEditUser : handleAddUser}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ isSubmitting }) => {
          return (
            <>
              <Form noValidate>
                <>
                  <ModalRow rowTitle="General">
                    <Grid container>
                      <Grid item xs={4} className={classes.input}>
                        <InputField
                          name="username"
                          placeholder={intl.formatMessage({
                            id: `Global.form.placeholders.username`,
                          })}
                          label={
                            <FormattedMessage id="Global.form.labels.username" />
                          }
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={4} className={classes.input}>
                        <InputField
                          name="name"
                          placeholder={intl.formatMessage({
                            id: `Global.form.placeholders.name`,
                          })}
                          label={
                            <FormattedMessage id={`Global.form.labels.name`} />
                          }
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={4} className={classes.input}>
                        <InputField
                          name="password"
                          placeholder={intl.formatMessage({
                            id: `Global.form.placeholders.password`,
                          })}
                          label={
                            <FormattedMessage id='Global.form.labels.password' />
                          }
                          size="small"
                          type={showPassword ? 'text' : 'password'}
                          InputProps={{
                            autoComplete: 'new-password',
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setShowPassword(!showPassword)}
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={4} className={classes.input}>
                        <InputField
                          name="email"
                          placeholder={intl.formatMessage({
                            id: `Global.form.placeholders.email`,
                          })}
                          label={
                            <FormattedMessage id={`Global.form.labels.email`} />
                          }
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={4} className={classes.input}>
                        <InputField
                          name="phone"
                          placeholder={intl.formatMessage({
                            id: `Global.form.placeholders.phoneNumber`,
                          })}
                          label={
                            <FormattedMessage
                              id={`Global.form.labels.phoneNumber`}
                            />
                          }
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={4} className={classes.input}>
                        <SelectField
                          name="employee"
                          state={allEmployeesStatus}
                          options={employeeOptions}
                          defaultValue={initialValues.employee}
                          placeholder={intl.formatMessage({
                            id: `Global.form.placeholders.employeeName`,
                          })}
                          label={
                            <FormattedMessage
                              id={`Global.form.labels.employeeName`}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={4} className={classes.input}>
                        <SelectField
                          name="active"
                          options={statusOptions}
                          defaultValue={initialValues.active}
                          placeholder={intl.formatMessage({
                            id: `Global.form.placeholders.status`,
                          })}
                          label={
                            <FormattedMessage
                              id={`Global.form.labels.status`}
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  </ModalRow>

                  <ButtonLoading
                    type="submit"
                    value={currentUser ? 'Global.save' : 'Global.create'}
                    state={isSubmitting}
                    disabled={isSubmitting}
                  />
                </>
              </Form>
            </>
          );
        }}
      </Formik>
    </CustomModal>
  );
};

export default injectIntl(observer(AddEditUserModal));

