import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ spacing }) => ({
  container: {
    padding: spacing(2),
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  contentContainer: {
    flexGrow: 1,
    minHeight: 0,
  },
  column: {
    padding: spacing(1),
    height: '100%',
  },
  employeesDetailsPaper: {
    height: '100%',
    overflow: 'auto',
  },
  addButton: {
    display: 'flex',
    '& Svg': {
      width: 18,
    },
  },
}));
