import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// ICONS:
import EditIcon from '@material-ui/icons/Edit';

// TYPES:
import { CustomerType } from 'api/customers/types';

// COMPONENTS:
import CustomerModal from '../../Modal';

// STORES:
import { CustomersStoreContext, ICustomersStore } from 'stores/customers';

const useStyles = makeStyles(({ spacing, palette }) => ({
  editButton: {
    height: 32,
    display: 'flex',
    justifyContent: 'flex-end',
    '& Svg': {
      width: 14,
    },
  },
  editIcon: {
    height: 14,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  row: {
    display: 'flex',
    borderBottom: `1px solid ${palette.action.disabledBackground}`,
    paddingBottom: spacing(2.5),
  },
  sectionHeader: {
    paddingBottom: spacing(1),
  },
  input: {
    paddingRight: spacing(3),
  },
  button: {
    marginTop: spacing(4),
  },
  section: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

interface ICustomerOverviewToolbar {
  currentCustomer: CustomerType;
}

const CustomerOverviewToolbar: React.FC<ICustomerOverviewToolbar> = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => setIsOpen(prevState => !prevState);

  const classes = useStyles();

  const { currentCustomer }: ICustomersStore = useContext(CustomersStoreContext);

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={10}>
          <Typography variant="h4" component="h4">
            <FormattedMessage id="Company.Client.information" />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2} className={classes.editButton}>
          <Button variant="outlined" color="secondary" onClick={toggleModal}>
            <EditIcon classes={{ root: classes.editIcon }} />
          </Button>
        </Grid>
      </Grid>
      {isOpen && (
        <CustomerModal
          currentCustomer={currentCustomer!}
          isOpen={isOpen}
          toggleModal={toggleModal}
        />
      )}
    </>
  );
};

export default CustomerOverviewToolbar;
