import React, { ChangeEvent, KeyboardEvent, FormEvent } from 'react';
import { InputBase, Paper } from '@material-ui/core';

// ICONS
import SearchIcon from '@material-ui/icons/Search';

// STYLES
import { useStyles } from './index.styles';

// TYPES
interface ISearchInput {
  placeholder: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onSubmit?: (e: FormEvent) => void;
}

const SearchInput: React.FC<ISearchInput> = ({
  placeholder,
  value,
  onChange,
  onClick,
  onKeyPress,
  onSubmit,
}) => {
  const classes = useStyles();

  const handleKeyPress = onKeyPress
    ? onKeyPress
    : (e: KeyboardEvent<HTMLInputElement>) => {
        e.key === 'Enter' && e.preventDefault();
      };
  return (
    <Paper className={classes.paper}>
      <form onSubmit={onSubmit} className={classes.form}>
        <InputBase
          name="searchTerm"
          value={value}
          className={classes.input}
          placeholder={placeholder}
          onChange={onChange}
          onClick={onClick}
          onKeyPress={handleKeyPress}
        />
        <div className={classes.searchButton}>
          <SearchIcon />
        </div>
      </form>
    </Paper>
  );
};

export default SearchInput;
