import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  header: {
    padding: spacing(3),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'end',
  },
  heading: {
    fontWeight: typography.fontWeightBold,
    borderBottom: `2px solid ${palette.primary.contrastText}`,
  },
  addButton: {
    display: 'flex',
    '& Svg': {
      width: 18,
    },
  },
  addIcon: {
    paddingRight: '3px',
  },
  modal: {
    backgroundColor: palette.primary.main,
  },
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  filtersRow: {
    paddingTop: spacing(2),
    display: 'flex',
    width: '100%',
  },
  search: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  select: {
    zIndex: 3,
  },
  editIcon: {
    height: 14,
  },
  accent: {
    color: palette.secondary.light,
  },
  dateFrom: {
    padding: spacing(0, 2),
  },
}));
