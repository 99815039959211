import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';

// COMPONENTS
import TableauChart from './TableauChart';
import TableauChart4 from './TableauChart4';

// STORES
import { ITableauStore, TableauStoreContext } from 'stores/tableau';

const TableauCharts = () => {
  const { getAccessToTableau, ticket }: ITableauStore = useContext(
    TableauStoreContext
  );

  useEffect(() => {
    getAccessToTableau();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ padding: 20 }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          background: '#fff',
          transform: 'scale(0.8)',
        }}
      >
        {ticket && (
          <div>
            <TableauChart ticket={ticket} />
          </div>
        )}
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          background: '#fff',
          transform: 'scale(0.8)',
          marginTop: '-20px'
        }}
      >

        {ticket && (
          <div style={{ marginLeft: 20 }}>
            <TableauChart4 ticket={ticket} />
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(TableauCharts);
