import React, { ReactNode } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade } from '@material-ui/core';

export interface ICustomModal {
  isOpen: boolean;
  toggleModal: (e?: any) => void;
  children: ReactNode;
  title?: string | ReactNode;
  className?: string;
}

const useStyles = makeStyles(({ spacing, palette, shape }) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 200
    },
    closeIcon: {
      padding: 0,
      '& Svg': {
        color: palette.secondary.main,
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: spacing(2),
    },
    paper: {
      margin: spacing(8, 0),
      backgroundColor: palette.background.paper,
      borderRadius: shape.borderRadius,
      '&:focus': {
        outline: 'none',
      },
    },
  })
);

const CustomModal: React.FC<ICustomModal> = ({
  isOpen,
  toggleModal,
  children,
  className,
}) => {
  const classes = useStyles();
  return (
    <>
      <Modal
        className={classes.modal}
        open={isOpen}
        onClose={toggleModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={`${classes.paper} ${className}`}>
            <div>{children}</div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default CustomModal;
