import React, { useContext, useState } from 'react';
import { makeStyles, TableCell, TableSortLabel } from '@material-ui/core';
import { observer } from 'mobx-react';

// TYPES:
import { IColumn, SortingOrder } from 'common/components/grid/types';
import { CustomerType } from 'api/customers/types';

// STORE:
import { CustomersStoreContext, ICustomersStore } from 'stores/customers';

// ICONS
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

interface ISortableHeader {
  children: React.ReactNode;
  column: IColumn<CustomerType>;
}

const useStyles = makeStyles(({ spacing, palette, constants }) => ({
  header: {
    background: palette.common.white,
    borderBottom: 'none',
    padding: constants.bigTableCellPadding,
    color: palette.secondary.light,
  },
}));

const SortableStringHeader: React.FC<ISortableHeader> = ({
  children,
  column,
}) => {
  const classes = useStyles();
  const [order, setOrder] = useState<SortingOrder>('asc');
  const { sortBy, sortCustomersStringBy }: ICustomersStore = useContext(
    CustomersStoreContext
  );

  const handleClick = () => {
    if (sortBy === column.id) {
      if (order === 'asc') {
        sortCustomersStringBy(column.id, 'desc');
        setOrder('desc');
      } else {
        sortCustomersStringBy(column.id, 'asc');
        setOrder('asc');
      }
    } else {
      sortCustomersStringBy(column.id, 'asc');
      setOrder('asc');
    }
  };

  return (
    <TableCell classes={{ stickyHeader: classes.header }}>
      <TableSortLabel
        active={sortBy === column.id}
        direction={sortBy === column.id ? order : 'asc'}
        onClick={handleClick}
        IconComponent={KeyboardArrowDownIcon}
      >
        {children}
      </TableSortLabel>
    </TableCell>
  );
};

export default observer(SortableStringHeader);
