import React, { useCallback, useContext, useState } from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';

// COMPONENTS:
import Toolbar from './components/Toolbar';
import GridTable from 'common/components/grid/GridTable';
import RemoveUserWareCell from './components/RemoveUserWareCell';

// STORE:
import { IUsersStore, UsersStoreContext } from 'stores/users';

// TYPES
import { UserWaresType } from 'api/users/types';
import { IGridTableSchema } from 'common/components/grid/types';

// HELPERS:
import useLocalSearch from 'common/helpers/useLocalSearch';

const useStyles = makeStyles(({ spacing }) => ({
  transactionContainer: {
    overflow: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    paddingTop: spacing(2),
    height: '100%',
  },
  gridTableWrapper: {
    overflow: 'unset',
    flexGrow: 1,
  },
}));

export enum TransactionType {
  transaction = 'transaction',
  quotaTransaction = 'quotaTransaction',
}

const customersDataSchema: IGridTableSchema<UserWaresType> = {
  wrapper: ({ children, ...rest }: any) => <div {...rest}>{children}</div>,
  header: {
    columns: [
      {
        id: 'BKey',
        name: 'Ware ID',
      },
      {
        id: 'Navn',
        name: 'Name',
      },
      {
        id: 'Status',
        name: 'Status',
      },
    ],
  },
  row: {
    id: 'BKey',
    cells: [
      {
        key: 'BKey',
      },
      {
        key: 'Navn',
      },
      {
        key: 'Status',
      },
      {
        customKey: 'removeWare',
        formatter: RemoveUserWareCell
      }
    ],
  },
};

const UserWares = () => {
  const classes = useStyles();

  const {
    currentUser,
    getCurrentUserWares: getCurrentUserWaresAPI,
    currentUserWares,
    getCurrentUserWaresState,
  }: IUsersStore = useContext(UsersStoreContext);

  const getCurrentUserWares = useCallback(
    () => getCurrentUserWaresAPI(currentUser!.PKey),
    [currentUser, getCurrentUserWaresAPI]
  );

  const [search, setSearch] = useState('');
  const { data: waresUserData } = useLocalSearch(
    search,
    ['Navn'],
    currentUserWares
  );

  return (
    <div className={classes.container}>
      <Paper className={classes.transactionContainer}>
        {currentUser && (
          <>
            <Toolbar search={search} setSearch={setSearch} userId={currentUser!.PKey} />

            <GridTable
              className={classes.gridTableWrapper}
              tableSchema={customersDataSchema}
              data={waresUserData}
              loadingState={getCurrentUserWaresState}
              getData={getCurrentUserWares}
            />
          </>
        )}
      </Paper>
    </div>
  );
};

export default observer(UserWares);
