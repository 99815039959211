import { PricetableType } from 'api/pricetables/types';

export interface IPricetableInformationsSectionSchema {
  label: string;
  value: keyof PricetableType;
}

export const generalSectionSchema: IPricetableInformationsSectionSchema[] = [
  {
    label: 'id',
    value: 'PKey',
  },
  {
    label: 'name',
    value: 'Navn',
  },
  {
    label: 'status',
    value: 'Status',
  },
  {
    label: 'price',
    value: 'MinstePris',
  },
  {
    label: 'details',
    value: 'navPrisGruppeTabellDetaljer',
  },
];
