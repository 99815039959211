import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing, palette }) => ({
  row: {
    display: 'flex',
    borderBottom: `1px solid ${palette.action.disabledBackground}`,
    paddingBottom: spacing(2.5),
    '&:last-of-type': {
      borderBottom: 'none',
    },
  },
  sectionHeader: {
    padding: spacing(2, 0, 1),
  },
}));

interface IModalRow {
  rowTitle: string;
  children: React.ReactNode;
}
const ModalRow: React.FC<IModalRow> = ({ rowTitle, children }) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="subtitle1" className={classes.sectionHeader}>
          {rowTitle}
        </Typography>
      </Grid>
      <div className={classes.row}>{children}</div>
    </>
  );
};

export default ModalRow;
