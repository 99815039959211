import React, { useContext } from 'react';
import { Form, Formik } from 'formik';
import { Button, Grid } from '@material-ui/core';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { observer } from 'mobx-react';

// COMPONENTS:
import InputField from 'common/components/formik/InputField';

// STYLES:
import { useStyles } from './index.styles';

// STORES:
import {
  ClientGroupsStoreContext,
  IClientGroupsStore,
} from 'stores/clientGroups';

interface IFormValues {
  idFrom: string;
  idTo: string;
}

interface IFiltersForm {
  searchValue: string;
  intl: IntlShape;
}

const FiltersForm: React.FC<IFiltersForm> = ({ searchValue, intl }) => {
  const {
    getAllClientGroups,
    changeFilters,
    filters,
  }: IClientGroupsStore = useContext(ClientGroupsStoreContext);

  const classes = useStyles();

  const initialValues: IFormValues = {
    idFrom: filters?.idFrom || '',
    idTo: filters?.idTo || '',
  };

  const handleSubmit = async (values: IFormValues) => {
    changeFilters({ ...values, searchName: searchValue });
    await getAllClientGroups();
  };

  const handleReset = () => {
    changeFilters({ idFrom: '', idTo: '', searchName: searchValue });
    getAllClientGroups();
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onReset={handleReset}
      >
        {() => {
          return (
            <Form>
              <Grid container spacing={4} className={classes.root}>
                <Grid item xs={12} sm={4}>
                  <InputField
                    name="idFrom"
                    placeholder={intl.formatMessage({
                      id: 'Global.form.placeholders.idFrom',
                    })}
                    label={<FormattedMessage id="Global.form.labels.idFrom" />}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <InputField
                    name="idTo"
                    placeholder={intl.formatMessage({
                      id: 'Global.form.placeholders.idTo',
                    })}
                    label={<FormattedMessage id="Global.form.labels.idTo" />}
                  />
                </Grid>
              </Grid>
              <Grid container justify="space-between" spacing={4}>
                <Grid item>
                  <Button
                    variant="text"
                    type="reset"
                    className={classes.clearButton}
                  >
                    <FormattedMessage id="Global.clearFilters" />
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    <FormattedMessage id="Global.apply" />
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default observer(injectIntl(FiltersForm));
