import React, { Component } from 'react';
import 'tableau-api';

class TableauChart extends Component {
  componentDidMount() {
    this.initViz();
  }

  initViz() {
    const vizUrl = `https://tableau.vspdemo.no/trusted/${this.props.ticket}/views/Test-db-Int-01/Demo-Db-1?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=y#19`;
    const vizContainer = this.vizContainer;
    this.viz = new window.tableau.Viz(vizContainer, vizUrl);
  }

  render() {
    return (
      <>
        <div
          ref={div => {
            this.vizContainer = div;
          }}
        />
      </>
    );
  }
}

export default TableauChart;
