import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import CSVReader from 'react-csv-reader';
import { v4 as uuidv4 } from 'uuid';

// COMPONENTS:
import Header from 'common/components/Header';
import CustomModal from 'common/components/Modal';
import Table from './Table';

// ICONS:
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { navigate } from '@reach/router';
import { WastebinsStoreContext } from '../../../stores/wastebins';

const useStyles = makeStyles(({ spacing, typography, palette }) => ({
  container: {
    padding: spacing(2),
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  contentContainer: {
    flexGrow: 1,
    minHeight: 0,
  },
  column: {
    padding: spacing(1),
    height: '100%',
  },
  waresPaper: {
    height: '100%',
    overflow: 'auto',
  },
  button: {
    marginLeft: spacing(2),
    display: 'flex',
    '& Svg': {
      width: 18,
    },
  },
  icon: {
    marginRight: spacing(0.5),
  },
  buttonText: {
    textTransform: 'uppercase',
    fontWeight: typography.fontWeightMedium,
  },
  modalContainer: {
    width: 496,
    height: '100%',
    padding: spacing(5, 8),
  },
  title: {
    paddingBottom: spacing(2),
  },
  error: {
    color: palette.error.main,
    paddingTop: spacing(2),
  },
}));

const WorkOrders = () => {
  const classes = useStyles();

  const { setImportedWorkOrders, setSelectedWorkOrderToImport } = useContext(
    WastebinsStoreContext
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFileInvalid, setIsFileInvalid] = useState(false);

  const openWorkOrderModal = (e: any) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const correctTableColumnsNames = [
    'Kommentar',
    'Avtalenr',
    'Regningsmottaker',
    'Adresse',
    'Postnr',
    'Poststed',
    'Bestillingstype',
    'BeholderId',
    'Beholdertype',
  ];

  return (
    <>
      <div className={classes.container}>
        <Header title={<FormattedMessage id="Wastebins.workOrders" />}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
            onClick={openWorkOrderModal}
          >
            <ArrowUpwardIcon classes={{ root: classes.icon }} />
            <Typography variant="caption" className={classes.buttonText}>
              <FormattedMessage id="Wastebins.importCSV" />
            </Typography>
          </Button>
        </Header>
        <Grid container className={classes.contentContainer}>
          <Grid item xs={12} className={classes.column}>
            <Paper className={classes.waresPaper}>
              <Table />
            </Paper>
          </Grid>
        </Grid>
      </div>
      {/* WORK ORDER MODAL */}
      <CustomModal
        isOpen={isModalOpen}
        toggleModal={(e: any) => {
          e.stopPropagation();
          setIsModalOpen(false);
          setIsFileInvalid(false);
        }}
      >
        <div className={classes.modalContainer}>
          <Typography variant="h4" className={classes.title}>
            <FormattedMessage id="Wastebins.importCSV" />
          </Typography>
          <CSVReader
            onFileLoaded={data => {
              const tableColumnsNames = data[0];

              const checker = (arr: any, target: any) =>
                target.every((element: any) => arr.includes(element));

              const isArrayCorrect = checker(
                tableColumnsNames,
                correctTableColumnsNames
              );

              if (!isArrayCorrect) {
                setIsFileInvalid(true);
              } else {
                let preparedData: any[] = [];
                data.forEach((column: any, i: number) => {
                  column.forEach((row: any, j: number) => {
                    preparedData[i] = {
                      ...preparedData[i],
                      [tableColumnsNames[j]]: column[j],
                      Id: uuidv4(),
                    };
                  });
                });

                preparedData.pop();
                const filteredPreparedData = preparedData.filter(element =>
                  element['Beholdertype'].includes('-')
                );

                const parsedDataForImporting = filteredPreparedData.map(
                  (element: any) => ({
                    Id: element.Id,
                    OrderType: element.Bestillingstype,
                    AgreementNumber: element.Avtalenr,
                    WasteBinId: element.BeholderId,
                    Comment: element.Kommentar,
                    WasteBinType: element.Beholdertype.split('-')[0].trim(),
                    WasteBinWasteType: element.Beholdertype.split('-')[1],
                  })
                );

                setImportedWorkOrders(filteredPreparedData);
                setSelectedWorkOrderToImport(parsedDataForImporting);
                navigate('/import-work-orders');
              }
            }}
          />

          {isFileInvalid && (
            <div className={classes.error}>
              <Typography variant="body2">
                <FormattedMessage id="Wastebins.invalidCSVErrorMessage" />
              </Typography>
              <ul>
                {correctTableColumnsNames.map(name => (
                  <li key={name}>{name}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </CustomModal>
    </>
  );
};

export default WorkOrders;
