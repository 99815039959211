import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';

const ClientGroupOverviewToolbar = () => {
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={10}>
          <Typography variant="h4" component="h4">
            <FormattedMessage id="Company.Client.information" />
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ClientGroupOverviewToolbar;
