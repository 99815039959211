import React, { useContext } from 'react';
import { makeStyles, TableCell, IconButton } from '@material-ui/core';
import { observer } from 'mobx-react';

// COMPONENTS
import ManualQuotaForApartmentModal from './ManualQuotaForApartmentModal';

// ICONS
import EditIcon from '@material-ui/icons/Edit';

// HELPERS
import { useModal } from 'common/helpers/useModal';

// TYPES
import { ICell, IGridTableSchema } from 'common/components/grid/types';
import { CustomerQuotasType } from 'api/customers/types';
import {
  CustomersStoreContext,
  ICustomersStore,
} from '../../../../../../../stores/customers';

interface IEditApartmentQuotaCell<T> {
  row: IGridTableSchema<T>['row'];
  cell: ICell<T>;
  rowData: CustomerQuotasType;
}

const useStyles = makeStyles({
  cell: {
    padding: 2,
    fontWeight: 'bold',
    borderRadius: '100%',
    transition: '.4s background ease-in-out',
    '&:hover': {
      background: '#cee0d0',
    },
  },
  menu: {
    marginLeft: 40,
    marginTop: 82,
  },
  editIcon: {
    height: 18,
    width: 18,
  },
});

const EditApartmentQuotaCell = <T extends object>({
  row,
  cell,
  rowData,
}: IEditApartmentQuotaCell<T>) => {
  const classes = useStyles();
  const { clearCurrentQuota, currentApartment }: ICustomersStore = useContext(
    CustomersStoreContext
  );

  const customToggleModal = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const { toggleModal, RenderModal } = useModal(e => customToggleModal(e));

  return (
    <>
      <TableCell key={`${row.id}-${cell.key}-edit-cell`}>
        <IconButton
          className={classes.cell}
          onClick={e => {
            e.stopPropagation();
            toggleModal();
          }}
        >
          <EditIcon classes={{ root: classes.editIcon }} />
        </IconButton>
      </TableCell>
      <RenderModal>
        <ManualQuotaForApartmentModal
          toggleModal={() => {
            toggleModal();
            clearCurrentQuota();
          }}
          defaultQuotaTypeValue={rowData.QuotaPKey}
          apartmentId={currentApartment.RegisterEnhetId}
        />
      </RenderModal>
    </>
  );
};

export default observer(EditApartmentQuotaCell);
