import React from 'react';
import { makeStyles, Typography, Grid, Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

// COMPONENTS:
import ManualQuotaModal from './ManualQuotaModal';

// ICONS:
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';

// HELPERS:
import { useModal } from 'common/helpers/useModal';

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  header: {
    padding: spacing(3),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'end',
  },
  heading: {
    fontWeight: typography.fontWeightBold,
  },
  addButton: {
    display: 'flex',
    '& Svg': {
      width: 18,
    },
  },
  addIcon: {
    paddingRight: '3px',
  },
  modal: {
    backgroundColor: palette.primary.main,
  },
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  filtersRow: {
    paddingTop: spacing(2),
    display: 'flex',
    width: '100%',
  },
  search: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingLeft: spacing(2),
  },
  select: {
    zIndex: 3,
  },
  editIcon: {
    height: 14,
  },
}));

const Toolbar: React.FC = (
) => {
  const classes = useStyles();

  const { toggleModal, RenderModal } = useModal();

  return (
    <>
      <Grid container className={classes.header}>
        <Grid item xs={8}>
          <Typography variant="h5" className={classes.heading}>
            <FormattedMessage id="Customer.Quotas.heading" />
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.right}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.addButton}
            onClick={toggleModal}
          >
            <AddOutlinedIcon className={classes.addIcon} />
            <FormattedMessage id="Customer.Quotas.manualQuota" />
          </Button>
        </Grid>
      </Grid>

      <RenderModal>
        <ManualQuotaModal toggleModal={toggleModal}/>
      </RenderModal>
    </>
  );
};

export default Toolbar;
