import { ComponentType } from 'react';

// TYPES:
import { ClientType } from 'api/clients/types';

// COMPONENTS:
import OverrideFormatter from './OverrideFormatter';
import ClientGroupFormatter from './ClientGroupFormatter';
import StatusFormatter from '../StatusFormatter';

export interface IClientInformationsSectionSchema {
  label: string;
  value?: keyof ClientType;
  formatter?: ComponentType<any>;
}

export const generalSectionSchema: IClientInformationsSectionSchema[] = [
  {
    label: 'status',
    formatter: StatusFormatter,
  },
  {
    label: 'id',
    value: 'PKey',
  },
  {
    label: 'name',
    value: 'Name',
  },
  {
    label: 'clientGroup',
    formatter: ClientGroupFormatter,
  },
  {
    label: 'password',
    value: 'Password',
  },
];

export const addres1SectionSchema: IClientInformationsSectionSchema[] = [
  {
    label: 'address',
    value: 'Address1',
  },
  {
    label: 'postalCode',
    value: 'PostalCode',
  },
  {
    label: 'orgNumber',
    value: 'OrgNo',
  },
];

export const addres2SectionSchema: IClientInformationsSectionSchema[] = [
  {
    label: 'address',
    value: 'Address2',
  },
];

export const accountingSectionSchema: IClientInformationsSectionSchema[] = [
  {
    label: 'r3',
    value: 'R3',
  },
  {
    label: 'discount',
    value: 'Name',
  },
];

export const vippsSectionSchema: IClientInformationsSectionSchema[] = [
  {
    label: 'override',
    formatter: OverrideFormatter,
  },
  {
    label: 'clientId',
    value: 'VippsClientId',
  },
  {
    label: 'clientSecret',
    value: 'VippsClientSecret',
  },
  {
    label: 'subscriptionKey',
    value: 'VippsClientSubKey',
  },

  {
    label: 'merchantId',
    value: 'VippsMerchantId',
  },
];
