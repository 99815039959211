import React, { useContext } from 'react';
import { observer } from 'mobx-react';

// TYPES:
import { ClientGroupType } from 'api/clientGroups/types';
import { IGridTableSchema } from 'common/components/grid/types';

// COMPONENTS:
import GridTable from 'common/components/grid/GridTable';
import Toolbar from './Toolbar';
import ClientGroupsRow from './ClientGroupsRow';
import SortableStringHeader from './SortableStringHeader';
import SortableNumericHeader from './SortableNumericHeader';

// STORE:
import {
  ClientGroupsStoreContext,
  IClientGroupsStore,
} from 'stores/clientGroups';
import { FormattedMessage } from 'react-intl';

const schema: IGridTableSchema<ClientGroupType> = {
  header: {
    columns: [
      {
        id: 'PKey',
        name: <FormattedMessage id="Company.ClientGroups.tableHeaders.id" />,
        formatter: SortableNumericHeader,
      },
      {
        id: 'Name',
        name: <FormattedMessage id="Company.ClientGroups.tableHeaders.name" />,
        formatter: SortableStringHeader,
      },
    ],
  },
  row: {
    id: 'PKey',
    formatter: ClientGroupsRow,
    cells: [
      {
        key: 'PKey',
      },
      {
        key: 'Name',
      },
    ],
  },
};

const IClientGroupsTable = () => {
  const {
    getAllClientGroupsState,
    allClientGroups,
    getAllClientGroups,
  }: IClientGroupsStore = useContext(ClientGroupsStoreContext);

  return (
    <GridTable
      tableSchema={schema}
      data={allClientGroups}
      toolbar={Toolbar}
      loadingState={getAllClientGroupsState}
      getData={getAllClientGroups}
    />
  );
};

export default observer(IClientGroupsTable);
