import React, { useState } from 'react';

// COMPONENTS
import CustomModal from 'common/components/Modal';

interface IRenderModal {
  children: React.ReactNode;
  title?: string;
  className?: string;
}

function callAll(...functions: any) {
  return (...args: any) => {
    functions.forEach((fn: any) => {
      fn && fn(...args)
    })
  }
}

export const useModal = (customToggleModal?: (...args: any) => void) => {
  const [isVisible, setIsVisible] = useState(false);
  const toggleModal = () => setIsVisible(!isVisible);

  const RenderModal = ({ children, title, className }: IRenderModal) => (
    <>
      {isVisible && (
        <CustomModal
          isOpen={isVisible}
          toggleModal={callAll(toggleModal, customToggleModal)}
          title={title}
          className={className}
          children={children}
        />
      )}
    </>
  );

  return {
    toggleModal,
    RenderModal,
    isVisible
  };
};
