import React from 'react';
import { TableBody } from '@material-ui/core';

// TYPES:
import { IGridTable, IGridTableSchema } from './types';

// COMPONENTS:
import DefaultRow from './DefaultRow';
import DefaultCell from './DefaultCell';

// HELPERS:
import { getNestedValue } from '../../helpers/getNestedValue';

interface IGridRows<T> {
  data: IGridTable<T>['data'];
  row: IGridTableSchema<T>['row'];
}

const GridRows = <T extends { [key: string]: any }>({
  data,
  row,
}: IGridRows<T>) => {
  const Row = row.formatter || DefaultRow;

  return (
    <TableBody>
      {data.map((rowData, index) => (
        <Row key={`${index}-${rowData[row.id]}`} row={row} rowData={rowData}>
          {row.cells.map(cell => {
            const Cell = cell.formatter || DefaultCell;

            return (
              <Cell
                key={`${row.id}-${cell.customKey || cell.key}`}
                row={row}
                cell={cell}
                rowData={rowData}
                {...cell.formatterProps}
              >
                {cell.key && getNestedValue(cell.key, rowData)}
              </Cell>
            );
          })}
        </Row>
      ))}
    </TableBody>
  );
};

export default GridRows;
