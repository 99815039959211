import React, { FC } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

// COMPONENTS:
import SearchInput from 'common/components/SearchInput';

interface IUsersListToolbar {
  intl: IntlShape;
  search: string;
  setSearch: (value: any) => void;
}

const useStyles = makeStyles(({ spacing, typography, palette }) => ({
  header: {
    padding: spacing(3,3,0,3),
    display: 'flex',
    alignItems: 'end',
  },
  heading: {
    fontWeight: typography.fontWeightBold,
    marginBottom: spacing(2),
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(0, 8, 2, 0),
  },
}));

const UsersListToolbar: FC<IUsersListToolbar> = ({ intl, search, setSearch }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.header}>
      <Grid item xs={8}>
        <Typography variant="h4" className={classes.heading}>
          <FormattedMessage id="Company.Users.users" />
        </Typography>
      </Grid>
      <Grid item xs={8} className={classes.search}>
        <SearchInput
          placeholder={intl.formatMessage({
            id: 'Company.Users.searchUsers',
          })}
          onChange={e => setSearch(e.target.value)}
          value={search}
        />
      </Grid>
    </Grid>
  )
}


export default injectIntl(UsersListToolbar);
