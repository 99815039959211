import React from 'react';
import { Field } from 'formik';
import SelectInput, { ISelectInput } from 'common/components/forms/SelectInput';
import { Props as SelectProps } from 'react-select';

const SelectField: React.FC<ISelectInput & SelectProps> = ({
  options,
  ...props
}) => {
  return <Field component={SelectInput} options={options} {...props} />;
};

export default SelectField;
