import React, { useContext } from 'react';
import { observer } from 'mobx-react';

// TYPES:
import { PricetableType } from 'api/pricetables/types';
import { IGridTableSchema } from 'common/components/grid/types';

// COMPONENTS:
import GridTable from 'common/components/grid/GridTable';
import Toolbar from './Toolbar';
import SortableStringHeader from '../SortableStringHeader';
import SortableNumericHeader from '../SortableNumericHeader';

// STORE:
import { PricetablesStoreContext, IPricetablesStore } from 'stores/pricetables';

// FORMATTERS:
import PricetableRow from './PricetableRow';
import { FormattedMessage } from 'react-intl';

const schema: IGridTableSchema<PricetableType> = {
  header: {
    columns: [
      {
        id: 'PKey',
        name: <FormattedMessage id="Company.Pricetables.tableHeaders.id" />,
        formatter: SortableNumericHeader
      },
      {
        id: 'Navn',
        name: <FormattedMessage id="Company.Pricetables.tableHeaders.name" />,
        formatter: SortableStringHeader
      },
      {
        id: 'navPrisGruppeTabellDetaljer',
        name: <FormattedMessage id="Company.Pricetables.tableHeaders.details" />,
        formatter: SortableStringHeader
      },
    ],
  },
  row: {
    id: 'PKey',
    formatter: PricetableRow,
    cells: [
      {
        key: 'PKey',
      },
      {
        key: 'Navn',
      },
      {
        key: 'navPrisGruppeTabellDetaljer',
      },
    ],
  },
};

const PricetablesTable = () => {
  const {
    pricetables,
    getPricetables,
    getPricetablesState,
  }: IPricetablesStore = useContext(PricetablesStoreContext);

  return (
    <GridTable
      tableSchema={schema}
      data={pricetables}
      toolbar={Toolbar}
      loadingState={getPricetablesState}
      getData={getPricetables}
    />
  );
};

export default observer(PricetablesTable);
