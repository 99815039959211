import React, { useContext } from 'react';
import { observer } from 'mobx-react';

// TYPES
import { CustomerType } from 'api/customers/types';

// STORES
import { CustomersStoreContext, ICustomersStore } from 'stores/customers';

interface ICustomerTypeFormatter {
  currentItem: CustomerType;
}

const CustomerTypeFormatter: React.FC<ICustomerTypeFormatter> = ({
  currentItem,
}) => {
  const { availableCustomersTypes }: ICustomersStore = useContext(
    CustomersStoreContext
  );

  const customerType = availableCustomersTypes?.find(
    type => type.PKey === currentItem?.PrisGruppeTabellPKey
  );

  return (
    <>{currentItem.PrisGruppeTabellPKey !== null ? customerType?.Navn : ''}</>
  );
};

export default observer(CustomerTypeFormatter);
