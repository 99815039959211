import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
// COMPONENTS
import Breadcrumbs from './Breadcrumbs';

const useStyles = makeStyles(({ spacing }) => ({
  header: {
    padding: spacing(1, 1.5, 2, 1.5),
  },
  children: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

interface IHeader {
  breadcrumbs?: Array<{ name: string | React.ReactNode; path: string }>;
  title?: React.ReactElement | string | null;
  children?: React.ReactNode;
  className?: string;
}

export const Header: React.FC<IHeader> = ({
  children,
  breadcrumbs,
  className,
  title,
}) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.header}>
      <Grid item xs={12} sm={4} className={className}>
        <Typography variant="h3" component="h3" style={{ minHeight: 33, whiteSpace: 'nowrap' }}>
          {title}
        </Typography>
        {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
      </Grid>
      <Grid item xs={12} sm={8} className={classes.children}>
        {children}
      </Grid>
    </Grid>
  );
};

export default Header;
