import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing, palette, typography }) => ({
  root: {
    marginTop: '-50px',
  },
  container: {
    flexGrow: 1,
    minHeight: 0,
  },
  flexContainer: {
    borderBottom: '1px solid #d3d3d3',
    justifyContent: 'flex-end',
  },
  customerInfoContainer: {
    padding: spacing(2, 3),
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  gridContainer: {
    height: '100%',
  },
  customersTableColumn: {
    padding: spacing(2),
    height: 300,
  },
  customersDetailsColumn: {
    padding: spacing(2),
    height: '100%',
  },
  customersDetailsPaper: {
    height: '100%',
    overflow: 'scroll',
  },
  rowHeader: {
    fontWeight: typography.fontWeightBold,
    color: palette.secondary.light,
  },
  header: {
    paddingTop: spacing(2),
  },
  customerButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  tabPanelClassName: {
    height: '80vh',
  },
}));
