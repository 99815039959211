import React, { useContext } from 'react';
import { TableCell } from '@material-ui/core';

// COMPONENTS
import { ICell, IGridTableSchema } from 'common/components/grid/types';

// STORES
import { ClientsStoreContext, IClientsStore } from 'stores/clients';

interface IStatusCell<T> {
  row: IGridTableSchema<T>['row'];
  cell: ICell<T>;
  rowData: any;
}

const ClientGroupCell = <T extends object>({
  rowData,
  row,
  cell,
}: IStatusCell<T>) => {
  const { allClientGroups }: IClientsStore = useContext(ClientsStoreContext);

  const clientGroup = allClientGroups?.find(
    group => group.PKey === rowData.ClientGroupPKey
  );

  return (
    <TableCell key={`${row.id}-${cell.key}`}>
      {clientGroup ? clientGroup.Name : ''}
    </TableCell>
  );
};

export default ClientGroupCell;
