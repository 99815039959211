import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Paper } from '@material-ui/core';

// COMPONENTS:
import Header from 'common/components/Header';
import Table from './Table';
import Preview from './Preview';
import WareModal from './Modal';

// ICONS:
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    padding: spacing(2),
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  contentContainer: {
    flexGrow: 1,
    minHeight: 0,
  },
  column: {
    padding: spacing(1),
    height: '100%',
  },
  waresPaper: {
    height: '100%',
    overflow: 'auto',
  },
  addButton: {
    display: 'flex',
    '& Svg': {
      width: 18,
    },
  },
}));

const Wares = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const classes = useStyles();

  const breadcrumbs = [
    {
      name: <FormattedMessage id="Accounting.Wares.header" />,
      path: '/wares',
    },
  ];

  return (
    <div className={classes.container}>
      <Header
        breadcrumbs={breadcrumbs}
        title={<FormattedMessage id="Accounting.Wares.header" />}
      >
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.addButton}
          onClick={toggleModal}
        >
          <AddOutlinedIcon />
          <FormattedMessage id="Accounting.Wares.addWare" />
        </Button>
      </Header>
      <Grid container className={classes.contentContainer}>
        <Grid item xs={12} md={6} className={classes.column}>
          <Paper className={classes.waresPaper}>
            <Table />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} className={classes.column}>
          <Paper className={classes.waresPaper}>
            <Preview />
          </Paper>
        </Grid>
      </Grid>
      {isOpen && <WareModal isOpen={isOpen} toggleModal={toggleModal} />}
    </div>
  );
};

export default Wares;
