import { useDebounce } from './useDebounce';

type Options = {
  interval: number;
};

function getValues(obj: any, key: any) {
  let objects: any[] = [];
  for (const i in obj) {
    if (!obj.hasOwnProperty(i)) continue;
    if (typeof obj[i] == 'object') {
      objects = objects.concat(getValues(obj[i], key));
    } else if (i === key) {
      objects.push(obj[i]);
    }
  }
  return objects;
}

function search(string: string, filters: Array<string>, arr: any[]) {
  if (filters.length > 0) {
    return arr.filter(item =>
      filters.some(filter =>
        getValues(item, filter)
          ?.toString()
          .toLowerCase()
          .includes(string.toLowerCase())
      )
    );
  } else {
    return arr;
  }
}

const useLocalSearch = (
  string: string,
  filters: Array<string>,
  arr: any[],
  options: Options = { interval: 500 }
) => {
  const debouncedString = useDebounce(string, options.interval);
  return { data: search(debouncedString, filters, arr), originalData: arr };
};

export default useLocalSearch;
