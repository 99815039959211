import React, { useContext, FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

// STYLES:
import { useStyles } from './index.styles';

// STORES:
import { CustomersStoreContext, ICustomersStore } from 'stores/customers';

// TYPES:
import { IGridTableSchema } from 'common/components/grid/types';
import { CustomerCards, CustomerTransactionType } from 'api/customers/types';

// COMPONENTS:
import DateCell from 'common/components/grid/DateCell';
import GridTable from 'common/components/grid/GridTable';
import CustomToolbar from './CustomToolbar';
import ApartmentDetailsOverview from './ApartmentDetailsOverview';
import ApartmentCardsDropdownCell from './ApartmentCardsDropdownCell';
import EditApartmentQuotaCell from './EditApartmentQuotaCell';

interface ApartmentDetailsProps {
  apartment: any;
  setValue: any;
}

const quotaSummarySchema: any = {
  wrapper: ({ children, ...rest }: any) => <div {...rest}>{children}</div>,
  header: {
    columns: [
      {
        id: 'QuotaName',
        name: 'Name',
      },
      {
        id: 'Quota',
        name: 'StartQuantity',
      },
      {
        id: 'Quantity',
        name: 'Rest',
      },
      {
        id: 'unit',
        name: 'Unit',
      },
    ],
  },
  row: {
    id: 'QuotaPKey',
    // formatter: QuotasRow,
    cells: [
      {
        key: 'QuotaName',
      },
      {
        key: 'StartQuantity',
      },
      {
        key: 'Quantity',
      },
      {
        key: 'unit.Unit',
        // formatter: UnitsCell,
      },
      {
        customKey: 'editCell',
        formatter: EditApartmentQuotaCell,
      },
    ],
  },
};

const cardsSchema: IGridTableSchema<CustomerCards> = {
  wrapper: ({ children, ...rest }: any) => <div {...rest}>{children}</div>,
  header: {
    columns: [
      {
        id: 'Kundekortnummer',
        name: <FormattedMessage id="Agreement.Cards.tableHeaders.cardNumber" />,
      },
      {
        id: 'DatoOpprettet',
        name: <FormattedMessage id="Agreement.Cards.tableHeaders.created" />,
      },
      {
        id: 'Tekst',
        name: <FormattedMessage id="Agreement.Cards.tableHeaders.status" />,
      },
      {
        id: 'CardStatus',
        name: <FormattedMessage id="Agreement.Cards.tableHeaders.comment" />,
      },
    ],
  },
  row: {
    id: 'Kundekortnummer',
    cells: [
      {
        key: 'Kundekortnummer',
      },
      {
        key: 'DatoOpprettet',
        formatter: DateCell,
      },
      {
        key: 'CardStatus',
      },
      {
        key: 'Tekst',
      },
      {
        customKey: 'dropdownCell',
        formatter: ApartmentCardsDropdownCell,
        formatterProps: {
          options: [
            { name: 'Customer.Cards.dropdown.option1' },
            { name: 'Customer.Cards.dropdown.option2' },
          ],
        },
      },
    ],
  },
};

const transactionsDataSchema: IGridTableSchema<CustomerTransactionType> = {
  wrapper: ({ children, ...rest }) => <div {...rest}>{children}</div>,
  header: {
    columns: [
      {
        id: 'Date',
        name: 'Date',
      },
      {
        id: 'SaleNr',
        name: 'Bilagsnr.',
      },
      {
        id: 'QuotaType',
        name: 'Quota type',
      },
      {
        id: 'ItemName',
        name: 'Ware name',
      },
      {
        id: 'Quantity',
        name: 'Amount',
      },
      {
        id: 'UnitCount',
        name: 'Quota amount',
      },
    ],
  },
  row: {
    id: 'SaleNr',
    cells: [
      {
        key: 'Date',
        formatter: DateCell,
      },
      {
        key: 'SaleNr',
      },
      {
        key: 'QuotaType',
      },
      {
        key: 'ItemName',
      },
      {
        key: 'Quantity',
      },
      {
        key: 'UnitCount',
      },
    ],
  },
};

const ApartmentDetails: FC<ApartmentDetailsProps> = ({
  apartment,
  setValue,
}) => {
  const classes = useStyles();

  const {
    getQuotaSummary,
    quotaSummary,
    getQuotaSummaryState,
    getCustomerCards,
    getCustomerCardsState,
    customerCards,
    currentCustomer,
    getCustomerTransactions,
    customer,
    getCustomerTransactionsState,
    customerTransactions,
  }: ICustomersStore = useContext(CustomersStoreContext);

  const getQuotas = useCallback(
    () => getQuotaSummary(currentCustomer!, apartment.RegisterEnhetId),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apartment, getQuotaSummary]
  );

  const getCards = useCallback(
    () =>
      getCustomerCards({
        id: currentCustomer!.BKey,
        registerEnhetId: apartment.RegisterEnhetId,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apartment, getCustomerCards]
  );

  const getLastTransactions = useCallback(
    () =>
      getCustomerTransactions({
        customerBkey: customer.BKey,
        registerEnhetID: apartment.RegisterEnhetId,
      }),
    [apartment, customer, getCustomerTransactions]
  );

  return (
    <div>
      <ApartmentDetailsOverview apartment={apartment} />
      <GridTable
        className={classes.gridTableWrapper}
        tableSchema={quotaSummarySchema}
        data={quotaSummary}
        loadingState={getQuotaSummaryState}
        getData={getQuotas}
        customToolbar={
          <CustomToolbar
            setValue={setValue}
            titleTranslationId="Customer.quotas"
          />
        }
      />
      <GridTable
        className={classes.gridTableWrapper}
        tableSchema={cardsSchema}
        data={customerCards}
        loadingState={getCustomerCardsState}
        getData={getCards}
        customToolbar={
          <CustomToolbar
            isCard
            isApartmentNumberEmpty={apartment.BoligNummer === ''}
            titleTranslationId="Customer.cards"
            navigateToHash="#cards"
            setValue={setValue}
            getCards={getCards}
          />
        }
      />

      <GridTable
        className={classes.gridTableWrapper}
        tableSchema={transactionsDataSchema}
        data={customerTransactions}
        loadingState={getCustomerTransactionsState}
        getData={getLastTransactions}
        hasScrollbar={false}
        customToolbar={
          <CustomToolbar
            titleTranslationId="Customers.List.lastTransactions"
            navigateToHash="#transactions"
          />
        }
      />
    </div>
  );
};

export default observer(ApartmentDetails);
