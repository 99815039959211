import React, { FC, useContext } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react';
import { useMutation, useQuery } from 'react-query';

// COMPONENTS:
import ButtonLoading from 'common/components/buttons/ButtonLoading';
import SelectField from 'common/components/formik/SelectField';

// STORES:
import { IUsersStore, UsersStoreContext } from 'stores/users';

import {
  addCustomerForTheUser as addCustomerForTheUserAPI,
  getAllCompanyCustomers,
} from 'api/users';

const useStyles = makeStyles(({ spacing, typography }) => ({
  modalContainer: {
    width: 496,
    height: '100%',
    padding: spacing(5, 8),
  },
  title: {
    paddingBottom: spacing(2),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
}));

interface IAddCustomerUserModal {
  userId: string;
  toggleModal: () => void;
}

const AddCustomerUserModal: FC<IAddCustomerUserModal> = ({
  userId,
  toggleModal,
}) => {
  const classes = useStyles();

  const { getCurrentUserCustomers, currentUserCustomers }: IUsersStore = useContext(
    UsersStoreContext
  );

  const { data: allCompanyCustomers, status: allCompanyCustomersStatus, isSuccess } = useQuery('allCompanyCustomers', async () => await getAllCompanyCustomers())

  const customersOptions =  isSuccess ? allCompanyCustomers?.data?.map((option: any) => ({
    value: option.BKey.toString() || '',
    label: option.Navn || '',
    isDisabled: currentUserCustomers.some((customer: any) => customer.BKey === option.BKey)
  })) : [];

  const { mutate: addCustomerForTheUser } = useMutation(
    async (values: any) => await addCustomerForTheUserAPI(values),
    {
      onSuccess: () => toggleModal(),
      onSettled: async () => await getCurrentUserCustomers(userId),
    }
  );

  const handleSubmit = (values: any) => {
    addCustomerForTheUser({
      UserPkey: userId,
      KundeBKey: values.customer,
    });
  };

  return (
    <div className={classes.modalContainer}>
      <Typography variant="h4" className={classes.title}>
        <FormattedMessage id="Company.User.addCustomer" />
      </Typography>
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        {({ isSubmitting }) => {
          return (
            <Form className={classes.form}>
              <Grid container>
                <Grid item xs={12} style={{ margin: '16px 0' }}>
                  <SelectField
                    state={allCompanyCustomersStatus}
                    name="customer"
                    options={customersOptions}
                    isClearable
                    placeholder={<FormattedMessage id="Customer.Cards.all" />}
                    label={<FormattedMessage id="Global.form.labels.status" />}
                  />
                </Grid>
              </Grid>
              <ButtonLoading
                type="submit"
                value="Global.addButton"
                state={isSubmitting}
                disabled={isSubmitting}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default observer(AddCustomerUserModal);
