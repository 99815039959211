import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Modal, Grid, Backdrop, Fade, Typography } from '@material-ui/core';

const useStyles = makeStyles(({ palette, shadows, spacing }) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalContent: {
      overflow: 'scroll',
      height: '100%',
    },
    paper: {
      position: 'fixed',
      right: 0,
      width: '80%',
      height: '100vh',
      backgroundColor: palette.background.paper,
      border: '2px solid #000',
      boxShadow: shadows[5],
      padding: spacing(3),
    },
    closeIcon: {
      padding: 0,
      '& Svg': {
        color: palette.secondary.main,
      },
    },
    header: {
      paddingBottom: spacing(1),
    },
  })
);

interface ICustomModal {
  isOpen: boolean;
  toggle: (e?: any) => void;
  children: React.ReactNode;
  modalTitle: React.ReactNode;
}

const CustomModal: React.FC<ICustomModal> = ({
  isOpen,
  toggle,
  children,
  modalTitle,
}) => {
  const classes = useStyles();

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      onClose={toggle}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableEnforceFocus={true}
      disableAutoFocus={true}
    >
      <Fade in={isOpen}>
        <div className={classes.paper}>
          <Grid container className={classes.modalContent}>
            <Grid item sm={10}>
              <Typography variant="h4" className={classes.header}>
                {modalTitle}
              </Typography>
              {children}
            </Grid>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};

export default CustomModal;
