import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { injectIntl, IntlShape } from 'react-intl';
import { Form, Formik } from 'formik';

// COMPONENTS:
import SearchInput from 'common/components/SearchInput';
import InputField from 'common/components/formik/InputField';
import { AutoSubmit } from 'common/components/formik/AutoSubmitForm';

// STYLES
import { useStyles } from './index.styles';

interface IFormValues {
  dateFrom: string;
  dateTo: string;
}

interface IToolbar {
  intl: IntlShape;
  search: string;
  filters: any;
  setSearch: (value: any) => void;
  setFilters: (values: any) => void;
}

const Toolbar: React.FC<IToolbar> = ({ intl, search, setSearch, filters, setFilters }) => {
  // const {
  //   currentCustomerQuota,
  //   getQuotasTransactions,
  // }: ICustomersStore = useContext(CustomersStoreContext);

  const classes = useStyles();

  const handleSubmit = async (values: IFormValues) => {
    setFilters({...filters, ...values});
    // await getQuotasTransactions(values);
  };

  const initialValues: IFormValues = {
    dateFrom: '',
    dateTo: '',
  };

  return (
    <Grid container className={classes.header}>
      <Grid item xs={12}>
        <Typography variant="subtitle1" className={classes.heading}>
          <FormattedMessage
            id="Customer.Cards.cardTransactions" />
        </Typography>
      </Grid>

      <div className={classes.filtersRow}>
        <Grid item xs={6} className={classes.search}>
          <SearchInput
            placeholder={intl.formatMessage({
              id: 'Customer.Cards.cardTransactionsSearch',
            })}
            onChange={e => setSearch(e.target.value)}
            value={search}
          />
        </Grid>

        <Grid item xs={6}>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {() => {
              return (
                <Form>
                  <AutoSubmit />
                  <Grid container justify="space-around">
                    <Grid item xs={6} className={classes.dateFrom}>
                      <InputField
                        name="dateFrom"
                        label={
                          <FormattedMessage id="Global.form.labels.dateFrom" />
                        }
                        type="date"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputField
                        name="dateTo"
                        label={
                          <FormattedMessage id="Global.form.labels.dateTo" />
                        }
                        type="date"
                      />
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </div>
    </Grid>
  );
};

export default injectIntl(Toolbar);
