import React from 'react';
import { makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

// COMPONENTS:
import { Button } from '@material-ui/core';

export const useStyles = makeStyles(({ spacing, palette }) => ({
  footer: {
    minHeight: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Footer = ({
  page,
  setPage,
  isVisible,
}: {
  page: number;
  setPage: (value: number) => void;
  isVisible: boolean;
}) => {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      {isVisible && (
        <Button
          onClick={() => setPage(page + 1)}
          variant="outlined"
          color="primary"
        >
          <FormattedMessage id="Wastebins.loadMoreWastebins" />
        </Button>
      )}
    </div>
  );
};

export default Footer;
