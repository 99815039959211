import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Paper, Typography } from '@material-ui/core';

// COMPONENTS:
import Header from 'common/components/Header';
import Table from './Table';

// ICONS:
import { useParams } from '@reach/router';
import { WastebinsStoreContext } from '../../../stores/wastebins';
import PrintIcon from '@material-ui/icons/Print';
import { observer } from 'mobx-react';

const useStyles = makeStyles(({ spacing, typography }) => ({
  container: {
    padding: spacing(2),
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  contentContainer: {
    flexGrow: 1,
    minHeight: 0,
  },
  column: {
    padding: spacing(1),
    height: '100%',
  },
  waresPaper: {
    height: '100%',
    overflow: 'auto',
  },
  button: {
    marginLeft: spacing(2),
    display: 'flex',
    '& Svg': {
      width: 18,
    },
  },
  icon: {
    marginRight: spacing(0.5),
  },
  buttonText: {
    textTransform: 'uppercase',
    fontWeight: typography.fontWeightMedium,
  },
  modalContainer: {
    width: 496,
    height: '100%',
    padding: spacing(5, 8),
  },
  title: {
    paddingBottom: spacing(2),
  },
}));

const WorkOrder = () => {
  const classes = useStyles();

  const breadcrumbs = [
    {
      name: <FormattedMessage id="Wastebins.workOrders" />,
      path: '/work-orders',
    },
    {
      name: <FormattedMessage id="Wastebins.workOrder" />,
      path: '/',
      isActive: true,
    },
  ];

  const params = useParams();

  const { printWorkOrder, selectedWorkOrdersToPrint } = useContext(
    WastebinsStoreContext
  );

  const handlePrintButton = async () => {
    var tostring = selectedWorkOrdersToPrint.toString();
    var input = JSON.parse('[' + tostring + ']');
    return printWorkOrder({ workOrderIds: input });
  };

  const handlePrintAllButton = async () =>
    await printWorkOrder({ workOrderGroupId: params.workOrderGroupId });

  return (
    <>
      <div className={classes.container}>
        <Header
          breadcrumbs={breadcrumbs}
          title={<FormattedMessage id="Wastebins.workOrder" />}
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
            onClick={handlePrintButton}
            disabled={!selectedWorkOrdersToPrint.length}
          >
            <PrintIcon classes={{ root: classes.icon }} />
            <Typography variant="caption" className={classes.buttonText}>
              <FormattedMessage id="Wastebins.print" />
            </Typography>
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
            onClick={handlePrintAllButton}
          >
            <PrintIcon classes={{ root: classes.icon }} />
            <Typography variant="caption" className={classes.buttonText}>
              <FormattedMessage id="Wastebins.printAll" />
            </Typography>
          </Button>
        </Header>
        <Grid container className={classes.contentContainer}>
          <Grid item xs={12} className={classes.column}>
            <Paper className={classes.waresPaper}>
              <Table />
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default observer(WorkOrder);
