import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

// TYPES
import { ClientType } from 'api/clients/types';

interface IOverrideFormatter {
  currentItem: ClientType;
}

const useStyles = makeStyles(({ palette }) => ({
  active: {
    color: palette.primary.main,
  },
  deactivated: {
    color: palette.error.main,
  },
}));

const OverrideFormatter: React.FC<IOverrideFormatter> = ({ currentItem }) => {
  const classes = useStyles();

  return (
    <>
      {!currentItem.VippsOverride ? (
        <Typography className={classes.deactivated}>
          <FormattedMessage id="Global.deactivated" />
        </Typography>
      ) : (
        <Typography className={classes.active}>
          <FormattedMessage id="Global.active" />
        </Typography>
      )}
    </>
  );
};

export default OverrideFormatter;
