import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useParams } from '@reach/router';
import { observer } from 'mobx-react';

// COMPONENTS:
import Header from 'common/components/Header';
import HeaderTabs from './HeaderTabs';

// STORES:
import {
  ClientGroupsStoreContext,
  IClientGroupsStore,
} from 'stores/clientGroups';

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(2),
  },
  header: {
    marginBottom: '-10px',
    zIndex: 2,
  },
}));

const ClientGroup = () => {
  const classes = useStyles();
  const {
    currentClientGroup,
    getClientGroupById,
  }: IClientGroupsStore = useContext(ClientGroupsStoreContext);

  const params = useParams();

  useEffect(() => {
    if (currentClientGroup?.PKey !== params.clientGroupId) {
      getClientGroupById(params.clientGroupId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getClientGroupById, params.clientGroupId]);

  const breadcrumbs = [
    {
      name: 'Client Groups',
      path: '/clientGroups',
    },
    {
      name: 'Client Group Details',
      path: `/clientGroups/${params.clientGroupId}`,
    },
  ];

  return (
    <div className={classes.container}>
      <Header
        breadcrumbs={breadcrumbs}
        title={currentClientGroup?.Name}
        className={classes.header}
      />
      <HeaderTabs />
    </div>
  );
};

export default observer(ClientGroup);
