import React, { FC, useState, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Tab, Tabs } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import { useLocation } from '@reach/router';

// COMPONENTS
import TabPanel from 'common/components/TabPanel';
import UserOverview from '../UserOverview';
import UserCustomers from '../UserCustomers';
import UserWares from '../UserWares';

// STYLES
import { useStyles } from './index.styles';

// TYPES:
import { UserType } from 'api/users/types';

interface TabularUserViewProps {
  currentCustomer: UserType;
}

const TabularUserView: FC<TabularUserViewProps> = () => {
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();

  const initialTab = (hash: string) => {
    switch (hash) {
      case '#customers':
        return '#customers';
      case '#wares':
        return '#wares';
      default:
        window.history.replaceState(null, '', '');
        return '#overview';
    }
  };

  const [value, setValue] = useState(initialTab(location.hash));

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    if (newValue !== window.location.hash) {
      setValue(newValue);
    }
  };

  useEffect(() => {
    if (value === '#overview') {
      window.history.replaceState(null, '', ' ');
    } else {
      window.location.hash = value;
    }
  }, [value]);

  return (
    <div className={classes.container}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="secondary"
        classes={{
          flexContainer: classes.flexContainer,
          root: classes.root,
        }}
      >
        <Tab
          value="#overview"
          label={<FormattedMessage id="Company.Users.overview" />}
        />
        <Tab
          value="#customers"
          label={<FormattedMessage id="Company.Users.customers" />}
        />
        <Tab
          value="#wares"
          label={<FormattedMessage id="Company.Users.wares" />}
        />
      </Tabs>
      <TabPanel
        value={value}
        index={'#overview'}
        dir={theme.direction}
        tabPanelClassName={classes.tabPanel}
      >
        <UserOverview />
      </TabPanel>
      <TabPanel
        value={value}
        index={'#customers'}
        dir={theme.direction}
        tabPanelClassName={classes.tabPanel}
      >
        <UserCustomers />
      </TabPanel>
      <TabPanel
        value={value}
        index={'#wares'}
        dir={theme.direction}
        tabPanelClassName={classes.tabPanel}
      >
        <UserWares />
      </TabPanel>
    </div>
  );
};

export default observer(TabularUserView);
