import React, { useContext, useEffect } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import * as Yup from 'yup';

// COMPONENTS:
import InputField from 'common/components/formik/InputField';
import ButtonLoading from 'common/components/buttons/ButtonLoading';
import CustomModal from 'common/components/CustomModal';
import ModalRow from 'common/components/CustomModal/ModalRow';
import SelectField from 'common/components/formik/SelectField';
import Alert from 'common/components/Alert';

// TYPES:
import { WareType } from 'api/wares/types';

// STORES:
import { WaresStoreContext, IWaresStore } from 'stores/wares';

interface IWareModal {
  intl: IntlShape;
  currentWare?: WareType;
  isOpen: boolean;
  toggleModal: () => void;
}

interface IFormValues {
  name: string;
  units: any;
  id: string;
  nettoPrice: number | null;
  vat: any;
  changeOnSale: any;
  nutritionalProduct: any;
  vismaName: string;
  number: number | null;
  accountNumber: string;
  wareCode: string;
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  editButton: {
    height: 32,
    display: 'flex',
    justifyContent: 'flex-end',
    '& Svg': {
      width: 14,
    },
  },
  editIcon: {
    height: 14,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  row: {
    display: 'flex',
    borderBottom: `1px solid ${palette.action.disabledBackground}`,
    paddingBottom: spacing(2.5),
  },
  input: {
    padding: spacing(1, 3, 1, 0),
  },
  button: {
    marginTop: spacing(4),
  },
  section: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  additionalSection: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  anotherRow: {
    paddingTop: spacing(2),
  },
}));

const WareModal: React.FC<IWareModal> = ({
  intl,
  currentWare,
  isOpen,
  toggleModal,
}) => {
  const {
    saveWare,
    getUnits,
    units,
    getWares,
    vatOptions,
    addWareError,
  }: IWaresStore = useContext(WaresStoreContext);
  //
  // useEffect(() => {
  //   clearWareError();
  //   if (!units.length || !vatOptions.length) {
  //     getUnits();
  //     getVat();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [getUnits, getVat, units, vatOptions, clearWareError]);

  useEffect(() => {
    getUnits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  const modalTitle = currentWare ? (
    <FormattedMessage
      id="Accounting.Wares.editModal.title"
      values={{
        b: (chunk: string) => <b>{chunk}</b>,
        value: `${currentWare?.BKey}, ${currentWare?.Navn}`,
      }}
    />
  ) : (
    <FormattedMessage id="Accounting.Wares.addWare" />
  );

  const changeOnSaleOptions = [
    {
      label: 'Yes',
      value: 'true',
    },
    {
      label: 'No',
      value: 'false',
    },
  ];

  const nutritionalProductOptions = [
    {
      label: 'Enabled',
      value: '002',
    },
    {
      label: 'Disabled',
      value: '',
    },
  ];

  const unitsOptions = units?.map(unit => ({
    value: unit.PKey.toString(),
    label: unit.Navn,
  }));

  const vatSelectOptions = vatOptions?.map(option => ({
    value: option.PKey.toString(),
    label: option.Navn,
  }));

  const initialValues: IFormValues = {
    name: currentWare?.Navn || '',
    units:
      unitsOptions.find(
        option => JSON.parse(option.value) === currentWare?.EnhetPKey
      ) || '',
    id: currentWare?.BKey || '',
    nettoPrice: currentWare?.VeilPris || null,
    vat:
      vatSelectOptions.find(
        option => option.value === currentWare?.MVAPKeyUt?.toString()
      ) || '1',
    changeOnSale:
      changeOnSaleOptions.find(
        option => JSON.parse(option.value) === currentWare?.EndreVedSalg
      ) || 'false',
    nutritionalProduct: currentWare?.OpphavsKode || null,
    vismaName: currentWare?.AnsvarsEnhetNavn || '',
    number: currentWare?.AnsvarsEnhet || null,
    accountNumber: currentWare?.KontoNr || '',
    wareCode: currentWare?.ExternalId || '',
  };

  const handleAddWare = async (values: IFormValues, { setErrors }: any) => {
    const addingSuccess = await saveWare('add', {
      ...values,
    });

    if (addingSuccess) {
      toggleModal();
      await getWares();
    } else {
      setErrors({ id: '' });
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('This field cannot be empty!'),
    id: Yup.string()
      .required('This field cannot be empty!')
      .max(16, 'ID can be max 16 characters long'),
  });

  return (
    <CustomModal isOpen={isOpen} toggle={toggleModal} modalTitle={modalTitle}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleAddWare}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => {
          return (
            <>
              {addWareError && <Alert type="danger">{addWareError} </Alert>}
              <Form noValidate>
                <>
                  <ModalRow rowTitle="General">
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <InputField
                          name="name"
                          placeholder={intl.formatMessage({
                            id: 'Global.form.placeholders.name',
                          })}
                          label={
                            <FormattedMessage id={`Global.form.labels.name`} />
                          }
                          size="small"
                          required
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <SelectField
                          name="units"
                          options={unitsOptions}
                          defaultValue={initialValues.units}
                          label={
                            <FormattedMessage id={`Global.form.labels.units`} />
                          }
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <InputField
                          name="id"
                          label={
                            <FormattedMessage id={`Global.form.labels.id`} />
                          }
                          placeholder={intl.formatMessage({
                            id: 'Global.form.placeholders.id',
                          })}
                          size="small"
                          required
                          disabled={currentWare !== undefined}
                        />
                      </Grid>
                    </Grid>
                  </ModalRow>

                  <ModalRow rowTitle="Pricing">
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <InputField
                          name="nettoPrice"
                          type="number"
                          placeholder={intl.formatMessage({
                            id: 'Global.form.placeholders.nettoPrice',
                          })}
                          label={
                            <FormattedMessage
                              id={`Global.form.labels.nettoPrice`}
                            />
                          }
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <SelectField
                          name="vat"
                          options={vatSelectOptions}
                          defaultValue={initialValues.vat}
                          label={
                            <FormattedMessage id={`Global.form.labels.vat`} />
                          }
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <SelectField
                          name="changeOnSale"
                          options={changeOnSaleOptions}
                          defaultValue={initialValues.changeOnSale}
                          label={
                            <FormattedMessage
                              id={`Global.form.labels.changeOnSale`}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <SelectField
                          name="nutritionalProduct"
                          options={nutritionalProductOptions}
                          label={
                            <FormattedMessage
                              id={`Global.form.labels.wareNutritionalProduct`}
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  </ModalRow>

                  <ModalRow rowTitle="Accounting">
                    <Grid item xs={3}>
                      <InputField
                        name="wareCode"
                        placeholder={intl.formatMessage({
                          id: 'Global.form.placeholders.wareCode',
                        })}
                        label={
                          <FormattedMessage
                            id={`Global.form.labels.wareCode`}
                          />
                        }
                        size="small"
                      />
                    </Grid>
                  </ModalRow>

                  <ModalRow rowTitle="VISMA – entity responsible ">
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <InputField
                          name="vismaName"
                          placeholder={intl.formatMessage({
                            id: 'Global.form.placeholders.name',
                          })}
                          label={
                            <FormattedMessage id={`Global.form.labels.name`} />
                          }
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <InputField
                          name="number"
                          placeholder={intl.formatMessage({
                            id: 'Global.form.placeholders.number',
                          })}
                          label={
                            <FormattedMessage
                              id={`Global.form.labels.number`}
                            />
                          }
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <InputField
                          name="accountNumber"
                          placeholder={intl.formatMessage({
                            id: 'Global.form.placeholders.accountNumber',
                          })}
                          label={
                            <FormattedMessage
                              id={`Global.form.labels.accountNumber`}
                            />
                          }
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  </ModalRow>

                  <ButtonLoading
                    type="submit"
                    value={currentWare ? 'Global.save' : 'Global.create'}
                    state={isSubmitting}
                    disabled={isSubmitting}
                    className={classes.button}
                  />
                </>
              </Form>
            </>
          );
        }}
      </Formik>
    </CustomModal>
  );
};

export default injectIntl(observer(WareModal));
