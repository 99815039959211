import { createContext } from 'react';
import { observable, action } from 'mobx';

// API
import { getAllClientGroups } from 'api/clients';

// TYPES
import {
  ClientGroupsType,
  ClientGroupType,
  FiltersType,
} from 'api/clientGroups/types';
import { LoadingState, PromiseStates } from 'api/types';
import { SortingOrder } from 'common/components/grid/types';

export interface IClientGroupsStore {
  // Observables
  allClientGroups: ClientGroupsType;
  currentClientGroup: ClientGroupType | null;
  getAllClientGroupsState: LoadingState;
  setCurrentClientGroupState: LoadingState;
  getClientGroupByIdState: LoadingState;
  filtersValues: any;
  filters: any;
  sortBy?: keyof ClientGroupType;
  order?: SortingOrder;

  // Actions
  getAllClientGroups: () => Promise<void>;
  getClientGroupById: (id: string) => Promise<void>;
  setCurrentClientGroup: (rowData: ClientGroupType) => void;
  changeFilters: (filters: FiltersType) => void;
  sortClientGroupsBy: (
    sortBy: keyof ClientGroupType,
    order: SortingOrder
  ) => void;
  sortClientGroupsStringsBy: (
    sortBy: keyof ClientGroupType,
    order: SortingOrder
  ) => void;
}

class ClientGroupsStore implements IClientGroupsStore {
  @observable allClientGroups: IClientGroupsStore['allClientGroups'] = [];
  @observable
  currentClientGroup: IClientGroupsStore['currentClientGroup'] = null;
  @observable
  getAllClientGroupsState: IClientGroupsStore['getAllClientGroupsState'] = null;
  @observable
  setCurrentClientGroupState: IClientGroupsStore['setCurrentClientGroupState'] = null;
  @observable
  getClientGroupByIdState: IClientGroupsStore['getClientGroupByIdState'] = null;
  @observable filtersValues: IClientGroupsStore['filtersValues'] = null;
  @observable filters: IClientGroupsStore['filters'] = null;
  @observable sortBy: IClientGroupsStore['sortBy'] = 'PKey';

  @action
  getAllClientGroups = async () => {
    try {
      this.getAllClientGroupsState = PromiseStates.PENDING;
      const { data } = await getAllClientGroups(this.filtersValues);
      this.allClientGroups = data;
      this.getAllClientGroupsState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getAllClientGroupsState = PromiseStates.REJECTED;
    }
  };

  @action
  getClientGroupById = async (id: string) => {
    try {
      this.getClientGroupByIdState = PromiseStates.PENDING;

      const { data } = await getAllClientGroups({
        '%24filter': `PKey+eq+${id}`,
        '%24format': 'json',
      });
      this.currentClientGroup = data[0];
      this.getClientGroupByIdState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getClientGroupByIdState = PromiseStates.REJECTED;
    }
  };

  @action changeFilters = (filters: FiltersType) => {
    const searchName = `substringof('${filters.searchName}'%2c+Name)`;

    const idFrom = filters.idFrom
      ? `+and+PKey+ge+${parseInt(filters.idFrom)}`
      : '';

    const idTo = filters.idTo ? `+and+PKey+le+${parseInt(filters.idTo)}` : '';

    this.filters = idFrom === '' && idTo === '' ? null : filters;

    this.filtersValues =
      this.filters === null && searchName === ''
        ? null
        : {
            '%24filter': `${searchName}${idFrom}${idTo}`,
            '%24format': 'json',
          };
  };

  @action
  sortClientGroupsBy = (
    sortBy: keyof ClientGroupType,
    order: SortingOrder = 'asc'
  ) => {
    this.sortBy = sortBy;
    this.allClientGroups = this.allClientGroups.slice().sort((a, b) => {
      let comparison = 0;
      if (a[sortBy]! > b[sortBy]!) {
        comparison = 1;
      } else if (a[sortBy]! < b[sortBy]!) {
        comparison = -1;
      }

      return order === 'asc' ? comparison : -comparison;
    });
  };

  @action
  sortClientGroupsStringsBy = (
    sortBy: keyof ClientGroupType,
    order: SortingOrder = 'asc'
  ) => {
    this.sortBy = sortBy;

    this.allClientGroups = this.allClientGroups.sort((a, b) => {
      const firstValue = a[sortBy]?.toString() || '';
      const secondValue = b[sortBy]?.toString() || '';

      return order === 'asc'
        ? firstValue.toString().localeCompare(secondValue)
        : secondValue.localeCompare(firstValue);
    });
  };

  @action setCurrentClientGroup = (rowData: ClientGroupType) => {
    this.currentClientGroup = rowData;
  };
}

export const ClientGroupsStoreContext = createContext(new ClientGroupsStore());
