import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  drawerListIcon: {
    color: palette.common.white,
    minWidth: 0,
    width: '24px !important',
    margin: 'auto',
  },
  selected: {
    color: 'red',
  },
  drawerListText: {
    fontSize: 14,
    paddingLeft: spacing(1.5),
  },
  activeMainCategory: {
    backgroundColor: palette.secondary.light,
    borderLeft: `4px solid ${palette.primary.main}`,
  },
  active: {
    borderLeft: `4px solid ${palette.primary.main}`,
    backgroundColor: palette.secondary.light,
  },
  unactiveMenuItem: {
    borderLeft: `4px solid ${palette.secondary.light}`,
  },
  unactiveCollapseItem: {
    borderLeft: `4px solid ${palette.secondary.dark}`,
  },
  nested: {
    paddingLeft: spacing(6.3),
    '&:hover': {
      backgroundColor: palette.secondary.light,
      borderLeft: `4px solid ${palette.primary.main}`,
    },
  },
  menu: {
    marginLeft: 40,
  },
  list: {
    background: palette.secondary.light,
    color: palette.common.white,
    borderRadius: 0,
    fontWeight: typography.fontWeightMedium,
    padding: 0,
    width: 112,
  },
  paper: {
    borderRadius: 0,
    borderBottomRightRadius: 8,
    borderTopRightRadius: 8,
  },
  anchorOriginBottomRightRectangle: {
    bottom: 2,
    right: 5,
  },
  badge: {
    height: 15,
    lineHeight: 'inherit',
    fontSize: '0.8rem',
    textTransform: 'uppercase',
    marginRight: 8
  },
  menuItem: (isActive: boolean) => ({
    fontSize: 14,
    padding: spacing(1.2),
    borderLeft: isActive ? `4px solid ${palette.primary.main}` : '',
    '&:hover': {
      backgroundColor: '99,107,111,0.5',
      borderLeft: `4px solid ${palette.primary.main}`,
    },
  }),
}));
