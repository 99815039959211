import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Paper } from '@material-ui/core';
import { observer } from 'mobx-react';

// COMPONENTS:
import AllApartmentsTable from './components/AllApartmentsTable';
import ApartmentDetails from './components/ApartmentDetails';

// STYLES:
import { useStyles } from './index.styles';

// ICONS:
import HomeWorkIcon from '@material-ui/icons/HomeWork';

// STORES:
import { CustomersStoreContext, ICustomersStore } from 'stores/customers';

const Apartments = ({ setValue }: any) => {
  const classes = useStyles();

  const { currentApartment, setCurrentApartment }: ICustomersStore = useContext(
    CustomersStoreContext
  );

  useEffect(() => {
    setCurrentApartment(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container className={classes.gridContainer}>
      <Grid item xs={12} sm={6} className={classes.allCardsColumn}>
        <AllApartmentsTable />
      </Grid>
      <Grid item xs={12} sm={6} className={classes.cardsTable}>
        <Paper className={classes.paper}>
          {!currentApartment ? (
            <div className={classes.selectCardPlaceholder}>
              <HomeWorkIcon className={classes.selectCardIcon} />
              <FormattedMessage id="Agreement.Apartments.placeholder" />
            </div>
          ) : (
            <ApartmentDetails
              setValue={setValue}
              apartment={currentApartment}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default observer(Apartments);
