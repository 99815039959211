import React, { Fragment, useEffect } from 'react';
import { makeStyles, Paper, Table, TableContainer } from '@material-ui/core';

// COMPONENTS:
import GridHeaders from './GridHeaders';
import GridRows from './GridRows';
import DefaultLoadingPlaceholder from './DefaultLoadingPlaceholder';
import DefaultError from './DefaultError';
import DefaultEmptyData from './DefaultEmptyData';

// TYPES:
import { IGridTable } from './types';

// HELPERS
import { PromiseStates } from 'api/types';

export const useStyles = makeStyles(({ palette }) => ({
  gridTableContainer: {
    height: '100%',
    // overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  list: {
    flexGrow: 1,
    overflowY: 'unset',
  },
  listOverflow: {
    flexGrow: 1,
    overflowY: 'scroll',
  },
  hidden: {
    display: 'none',
  },
  footer: {
    minHeight: '50px',
    borderTop: `1px solid ${palette.divider}`,
  },
}));

const GridTable = <T extends object>({
  tableSchema,
  data,
  loadingState,
  getData,
  toolbar: Toolbar = Fragment,
  customToolbar,
  className,
  tableClassname,
  footer,
  hasScrollbar = true,
}: IGridTable<T>) => {
  const classes = useStyles();
  const GridWrapper = tableSchema.wrapper || Paper;
  const stickyHeader =
    tableSchema.header.sticky === undefined || tableSchema.header.sticky;

  useEffect(() => {
    getData && getData();
  }, [getData]);

  const successLoadingStatus =
    loadingState === PromiseStates.FULFILLED || loadingState === 'success';

  return (
    <GridWrapper className={`${classes.gridTableContainer} ${className}`}>
      <Toolbar />
      {customToolbar}
      <div className={hasScrollbar ? classes.listOverflow : classes.list}>
        {(() => {
          switch (true) {
            case loadingState === PromiseStates.PENDING ||
              loadingState === 'loading':
              return <DefaultLoadingPlaceholder />;

            case successLoadingStatus && data.length === 0:
              return <DefaultEmptyData />;

            case successLoadingStatus && data.length > 0:
              return (
                <TableContainer>
                  <Table stickyHeader={stickyHeader} className={tableClassname}>
                    <GridHeaders columns={tableSchema.header.columns} />
                    <GridRows data={data} row={tableSchema.row} />
                  </Table>
                </TableContainer>
              );

            case loadingState === PromiseStates.REJECTED ||
              loadingState === 'error':
              return <DefaultError />;

            default:
              return null;
          }
        })()}
        {footer}
      </div>
    </GridWrapper>
  );
};

export default GridTable;
