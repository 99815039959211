export type ConstantsOptions = {
  topBarHeight: number;
  sideBarOpenWidth: number;
  sideBarCloseWidth: number;
  bigTableCellPadding: string;
  smallTableCellPadding: string;
};

export const constants: ConstantsOptions = {
  topBarHeight: 64,
  sideBarOpenWidth: 168,
  sideBarCloseWidth: 56,
  bigTableCellPadding: '10px 12px',
  smallTableCellPadding: '4px 12px',
};
