import React, { ReactNode, useContext } from 'react';
import { makeStyles, TableRow } from '@material-ui/core';
import { observer } from 'mobx-react';

// STORE
import { UsersStoreContext, IUsersStore } from 'stores/users';

// TYPES
import { UserType } from 'api/users/types';

interface IUserRow {
  rowData: UserType;
  children: ReactNode;
}

const useStyles = makeStyles(({ palette }) => ({
  row: {
    padding: 0,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: palette.primary.light,
    },
  },
  selected: {
    backgroundColor: `${palette.primary.light} !important`,
  },
}));

const UserRow: React.FC<IUserRow> = ({ rowData, children }) => {
  const { setCurrentUser, currentUser }: IUsersStore = useContext(
    UsersStoreContext
  );

  const isActive = currentUser?.PKey === rowData.PKey;
  const classes = useStyles(isActive);

  const handleClick = () => {
    setCurrentUser(rowData);
  };

  return (
    <TableRow
      onClick={handleClick}
      className={isActive ? `${classes.row} ${classes.selected}` : classes.row}
    >
      {children}
    </TableRow>
  );
};

export default observer(UserRow);
