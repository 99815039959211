import React from 'react';
import { Field } from 'formik';
import { StandardTextFieldProps } from '@material-ui/core';

// COMPONENTS:
import CustomInput from 'common/components/forms/CustomInput';

const InputField: React.FC<StandardTextFieldProps> = ({ ...props }) => {
  return <Field component={CustomInput} {...props} />;
};

export default InputField;
