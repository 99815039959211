import { createContext } from 'react';
import { observable, action } from 'mobx';

// API:
import {
  getClients,
  getAllClientGroups,
  getClientById,
  saveClient,
} from 'api/clients';

// TYPES:
import {
  ClientsType,
  ClientType,
  ClientGroupTypes,
  FiltersType,
  FormValuesType,
} from 'api/clients/types';
import { LoadingState, PromiseStates } from 'api/types';
import { SortingOrder } from 'common/components/grid/types';

export interface IClientsStore {
  // Observables
  clients: ClientsType;
  allClientGroups: ClientGroupTypes;
  currentClient: ClientType | null;
  getAllClientGroupsState: LoadingState;
  getClientByIdState: LoadingState;
  saveClientState: LoadingState;
  sortBy?: keyof ClientType;
  order?: SortingOrder;

  getClientsState: LoadingState;
  filtersValues: any;
  filters: FiltersType | null;

  // Actions
  getClients: () => Promise<void>;
  setCurrentClient: (rowData: ClientType) => void;
  getAllClientGroups: () => Promise<void>;
  getClientById: (id: number | null) => Promise<void>;
  changeFilters: (filters: FiltersType) => void;
  saveClient: (values: FormValuesType) => Promise<void>;
  sortClientsBy: (sortBy: keyof ClientType, order: SortingOrder) => void;
  sortClientsStringsBy: (sortBy: keyof ClientType, order: SortingOrder) => void;
}

class ClientsStore implements IClientsStore {
  @observable clients: IClientsStore['clients'] = [];
  @observable allClientGroups: IClientsStore['allClientGroups'] = [];
  @observable currentClient: IClientsStore['currentClient'] = null;
  @observable getClientsState: IClientsStore['getClientsState'] = null;
  @observable
  getAllClientGroupsState: IClientsStore['getAllClientGroupsState'] = null;
  @observable
  getClientByIdState: IClientsStore['getClientByIdState'] = null;
  @observable
  saveClientState: IClientsStore['saveClientState'] = null;

  @observable filtersValues: IClientsStore['filtersValues'] = null;
  @observable filters: IClientsStore['filters'] = null;
  @observable sortBy: IClientsStore['sortBy'] = 'PKey';

  @action
  getClients = async () => {
    try {
      this.getClientsState = PromiseStates.PENDING;

      const { data } = await getClients(this.filtersValues);
      this.clients = data;

      this.getClientsState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getClientsState = PromiseStates.REJECTED;
    }
  };

  @action changeFilters = (filters: FiltersType) => {
    const searchName = `substringof('${filters.searchName}'%2c+Name)`;

    const status = filters.clientStatus
      ? `+and+Status+eq+'${parseInt(filters.clientStatus)}'`
      : '';

    const idFrom = filters.idFrom
      ? `+and+PKey+ge+${parseInt(filters.idFrom)}`
      : '';

    const idTo = filters.idTo ? `+and+PKey+le+${parseInt(filters.idTo)}` : '';

    const address = filters.address
      ? `+and+substringof('${filters.address}'%2c+Address1)`
      : '';

    const clientGroup = filters.clientGroup
      ? `+and+ClientGroupPKey+eq+${parseInt(filters.clientGroup)}`
      : '';

    this.filters =
      idFrom === '' &&
      idTo === '' &&
      clientGroup === '' &&
      status === '' &&
      address === ''
        ? null
        : filters;

    this.filtersValues =
      this.filters === null && filters.searchName === ''
        ? null
        : {
            '%24filter': `${searchName}${idFrom}${idTo}${clientGroup}${status}${address}`,
            '%24format': 'json',
          };
  };

  @action
  getAllClientGroups = async () => {
    try {
      this.getAllClientGroupsState = PromiseStates.PENDING;
      const { data } = await getAllClientGroups();
      this.allClientGroups = data;
      this.getAllClientGroupsState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getAllClientGroupsState = PromiseStates.REJECTED;
    }
  };

  @action
  getClientById = async (id: number | null) => {
    try {
      this.getClientByIdState = PromiseStates.PENDING;
      const { data } = await getClientById(id);
      this.currentClient = data;
      this.getClientByIdState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getClientByIdState = PromiseStates.REJECTED;
    }
  };

  @action setCurrentClient = (rowData: ClientType) => {
    this.currentClient = rowData;
  };

  @action
  sortClientsBy = (sortBy: keyof ClientType, order: SortingOrder = 'asc') => {
    this.sortBy = sortBy;
    this.clients = this.clients.slice().sort((a, b) => {
      let comparison = 0;
      if (a[sortBy]! > b[sortBy]!) {
        comparison = 1;
      } else if (a[sortBy]! < b[sortBy]!) {
        comparison = -1;
      }

      return order === 'asc' ? comparison : -comparison;
    });
  };

  @action
  sortClientsStringsBy = (
    sortBy: keyof ClientType,
    order: SortingOrder = 'asc'
  ) => {
    this.sortBy = sortBy;

    this.clients = this.clients.sort((a, b) => {
      const firstValue = a[sortBy]?.toString() || '';
      const secondValue = b[sortBy]?.toString() || '';

      return order === 'asc'
        ? firstValue.toString().localeCompare(secondValue)
        : secondValue.localeCompare(firstValue);
    });
  };

  @action
  saveClient = async (values: FormValuesType) => {
    try {
      this.saveClientState = PromiseStates.PENDING;
      await saveClient({
        Status: 100,
        PKey: values.id,
        Name: values.name,
        ClientGroupPKey: values.clientGroup,
        Password: values.password,
        Address1: values.address,
        Address2: values.address2,
        PostalCode: values.postNr,
        OrgNo: values.orgNr,
        R3: values.r3,
        VippsOverride: values.override,
        VippsClientId: values.vippsClientId,
        VippsClientSecret: values.clientSecret,
        VippsClientSubKey: values.subscriptionKey,
        VippsMerchantId: values.merchantId,
      });
      this.saveClientState = PromiseStates.FULFILLED;
    } catch (err) {
      this.saveClientState = PromiseStates.REJECTED;
    }
  };
}

export const ClientsStoreContext = createContext(new ClientsStore());
