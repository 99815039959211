import React from 'react';
import { Grid } from '@material-ui/core';

// STYLES:
import { useStyles } from './index.styles';

// COMPONENTS:
import Revenue from './components/Revenue';
import Transactions from './components/Transactions';
import Deliveries from './components/Deliveries';
import SomeChart from './components/SomeChart';

const SmallerCharts = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={2} className={classes.gridContainer}>
      <Grid item xs={12} sm={12} md={6}>
        <Revenue />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Transactions />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Deliveries />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <SomeChart />
      </Grid>
    </Grid>
  );
};

export default SmallerCharts;
