import React from 'react';
import { TableCell } from '@material-ui/core';
import { format } from 'date-fns';

// COMPONENTS
import { ICell, IGridTableSchema } from 'common/components/grid/types';

interface IDateCell<T> {
  row: IGridTableSchema<T>['row'];
  cell: ICell<T>;
  rowData: any;
}

const DateCell = <T extends object>({ rowData, row, cell }: IDateCell<T>) => {
  const rowDate = rowData[cell.key];
  const date = new Date(rowDate);

  return (
    <TableCell key={`${row.id}-${cell.key}-date`}>
      {rowDate ? <>{format(date, 'dd.MM.yyyy')}</> : '-'}
    </TableCell>
  );
};

export default DateCell;
