import request from '../request';
import queryString from 'query-string';
import { NewUserCustomerType, UserType, UserRoleType, NewUserWareType } from './types';

export const getUsers = () =>
  request({
    url: '/admin/user/GetUsers',
    method: 'GET',
  });

export const getUserById = (userId: string) =>
  request({
    method: 'GET',
    url: `/admin/user/GetUser?${queryString.stringify({
      userpkey: userId,
    })}`,
  });

export const getAllCustomersForUser = (userId: string) =>
  request({
    method: 'GET',
    url: `/admin/customers/GetAllKunderForUser?${queryString.stringify({
      userpkey: userId,
    })}`,
  });

export const getAllWaresForUser = (userId: string) =>
  request({
    method: 'GET',
    url: `/admin/vare/GetAllVarerForUser?${queryString.stringify({
      userpkey: userId,
    })}`,
  });

export const getAllRoles = () =>
  request({
    method: 'GET',
    url: '/admin/user/GetRoles',
  });

export const getUserRoles = (userId: string) =>
  request({
    method: 'GET',
    url: `/admin/user/GetUserRoles?${queryString.stringify({
      userpkey: userId,
    })}`,
  });

export const addUserRole = (values: UserRoleType) =>
  request({
    method: 'POST',
    url: '/admin/user/AddUserRole',
    data: {
      ...values,
    },
  });


export const removeUserRole = (values: UserRoleType) =>
  request({
    method: 'POST',
    url: '/admin/user/RemoveUserRole',
    data: {
      ...values,
    },
  });


export const addUser = (values: UserType) =>
  request({
    method: 'POST',
    url: '/admin/user/AddUser',
    data: {
      ...values,
    },
  });

export const addCustomerForTheUser = (values: NewUserCustomerType) =>
  request({
    method: 'POST',
    url: '/admin/customers/AddUserKunde',
    data: {
      ...values,
    },
  });


export const addUserWare = (values: NewUserWareType) =>
  request({
    method: 'POST',
    url: '/admin/vare/AddUserVare',
    data: {
      ...values,
    },
  });


export const removeCustomerFromTheUser = (values: NewUserCustomerType) =>
  request({
    method: 'POST',
    url: '/admin/customers/RemoveUserKunde',
    data: {
      ...values,
    },
  });

export const removeUserWare = (values: NewUserWareType) =>
  request({
    method: 'POST',
    url: '/admin/vare/RemoveUserVare',
    data: {
      ...values,
    },
  });


export const editUser = (values: UserType) =>
  request({
    method: 'POST',
    url: '/admin/user/SaveUser',
    data: {
      ...values
    }
  });

export const getAllCompanyCustomers = () => request({
  method: 'GET',
  url: '/admin/customers/GetAllCompanyCustomers'
})
