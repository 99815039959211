import React, { useContext, useState } from 'react';
import {
  makeStyles,
  TableCell,
  MenuItem,
  Menu,
  IconButton,
  Typography,
  Grid,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import { IntlShape, injectIntl, FormattedMessage } from 'react-intl';

// ICONS:
import MoreVertIcon from '@material-ui/icons/MoreVert';

// TYPES:
import { ICell, IGridTableSchema } from 'common/components/grid/types';
import { CustomerType, CustomerTypesNames } from 'api/customers/types';

// STORES:
import { CardsStoreContext, ICardsStore } from 'stores/cards';
import { CustomersStoreContext, ICustomersStore } from 'stores/customers';

// COMPONENTS:
import InputField from 'common/components/formik/InputField';
import ButtonLoading from 'common/components/buttons/ButtonLoading';
import CustomModal from 'common/components/Modal';

import ApartmentCardDetailsModal from './DetailsModals/ApartmentCardDetailsModal';
import CompaniesCardDetailsModal from './DetailsModals/CompaniesCardDetailsModal';
import HouseholdCardDetailsModal from './DetailsModals/HouseholdCardDetailsModal';

interface ICardDropdownCell<T> {
  intl: IntlShape;
  row: IGridTableSchema<T>['row'];
  cell: ICell<T>;
  rowData: any;
}

const useStyles = makeStyles(({ spacing }) => ({
  cell: {
    fontWeight: 'bold',
    borderRadius: '100%',
    transition: '.4s background ease-in-out',
    padding: 0,
    '&:hover': {
      background: '#cee0d0',
    },
  },
  menu: {
    marginLeft: 40,
    marginTop: 82,
  },
  dropdownIcon: {
    width: 18,
  },
  dropdownCell: {},
  modalContainer: {
    width: 496,
    height: '100%',
    padding: spacing(5, 8),
  },
  title: {
    paddingBottom: spacing(2),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
}));

const renderModal = (
  customer: CustomerType,
  toggle: (e?: any) => void,
  cardDetails: any,
  getCards: () => void
) => {
  switch (customer.KundeType) {
    case CustomerTypesNames.FysiskPerson:
      return (
        <HouseholdCardDetailsModal
          toggle={toggle}
          cardDetails={cardDetails}
          customer={customer}
          getCards={getCards}
        />
      );
    case CustomerTypesNames.JuridiskPerson:
      return (
        <CompaniesCardDetailsModal
          toggle={toggle}
          cardDetails={cardDetails}
          customer={customer}
          getCards={getCards}
        />
      );
    case CustomerTypesNames.Ukjent:
      return (
        <ApartmentCardDetailsModal
          toggle={toggle}
          cardDetails={cardDetails}
          customer={customer}
          getCards={getCards}
        />
      );
    default:
      return;
  }
};

const CardDropdownCell = <T extends object>({
  intl,
  row,
  cell,
  rowData,
}: ICardDropdownCell<T>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openedModal, setOpenedModal] = useState<string>('');

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const openEditModal = (e: any) => {
    e.stopPropagation();
    setAnchorEl(null);
    setOpenedModal('edit');
  };

  const openDetailsModal = (e: any) => {
    e.stopPropagation();
    setOpenedModal('details');
    setAnchorEl(null);
  };

  const { editComment }: ICardsStore = useContext(CardsStoreContext);

  const { currentCustomer, getCustomerCards }: ICustomersStore = useContext(
    CustomersStoreContext
  );

  const addComment = async (values: any) => {
    await editComment({
      ID: rowData.ID,
      KundeBnr: rowData.KundeBnr,
      KundeBKey: rowData.KundeBKey,
      Tekst: values.comment,
      DatoAvsluttet: rowData.DatoAvsluttet,
      Kundekortnummer: rowData.Kundekortnummer,
      AvsluttetKommentar: rowData.AvsluttetKommentar,
    });

    // toggleModal();
    await getCustomerCards({ id: currentCustomer!.BKey });
  };

  const classes = useStyles();
  return (
    <TableCell
      key={`${row.id}-${cell.key}-dropdown`}
      className={classes.dropdownCell}
    >
      <div>
        <IconButton onClick={handleClick} className={classes.cell}>
          <MoreVertIcon classes={{ root: classes.dropdownIcon }} />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: 200,
            },
          }}
        >
          <MenuItem onClick={e => openEditModal(e)}>
            <FormattedMessage id="Customer.Cards.addEditComment" />
          </MenuItem>
          <MenuItem onClick={e => openDetailsModal(e)}>
            <FormattedMessage id="Customer.Cards.seeDetails" />
          </MenuItem>
        </Menu>
      </div>

      {/* EDIT MODAL */}
      <CustomModal
        isOpen={openedModal === 'edit'}
        toggleModal={(e: any) => {
          e.stopPropagation();
          setOpenedModal('');
          setAnchorEl(null);
        }}
      >
        <div
          className={classes.modalContainer}
          onClick={(e: any) => e.stopPropagation()}
        >
          <Typography variant="h4" className={classes.title}>
            <FormattedMessage id="Agreement.Cards.addComment" />
          </Typography>

          <Formik
            initialValues={{ comment: rowData?.Tekst }}
            onSubmit={addComment}
          >
            {({ isSubmitting }) => {
              return (
                <Form className={classes.form}>
                  <Grid container>
                    <Grid item xs={12}>
                      <InputField
                        name="comment"
                        placeholder={intl.formatMessage({
                          id: 'Global.form.placeholders.comment',
                        })}
                        label={
                          <FormattedMessage id="Global.form.labels.comment" />
                        }
                      />
                    </Grid>
                  </Grid>
                  <ButtonLoading
                    type="submit"
                    value="Global.addButton"
                    state={isSubmitting}
                    disabled={isSubmitting}
                    style={{ marginTop: 16 }}
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
      </CustomModal>

      {/* DETAILS MODAL */}
      <CustomModal
        isOpen={openedModal === 'details'}
        toggleModal={(e: any) => {
          e.stopPropagation();
          setOpenedModal('');
          setAnchorEl(null);
        }}
      >
        <div
          style={{
            width: 552,
            height: 552,
          }}
          onClick={(e: any) => e.stopPropagation()}
        >
          {renderModal(
            currentCustomer!,
            () => {
              setOpenedModal('');
              setAnchorEl(null);
            },
            rowData,
            () => getCustomerCards({ id: currentCustomer!.BKey })
          )}
        </div>
      </CustomModal>
    </TableCell>
  );
};

export default injectIntl(CardDropdownCell);
