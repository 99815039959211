import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useParams } from '@reach/router';

// COMPONENTS
import TabularCustomerView from './components/TabularCustomerView';
import Header from 'common/components/Header';

// STORE
import { CustomersStoreContext, ICustomersStore } from 'stores/customers';
import { useGetTotalNumberOfApartments } from '../queries';

// TYPES:
import { CustomerTypesNames } from '../../../api/customers/types';

const Customer = () => {
  const { currentCustomer, getCustomerById }: ICustomersStore = useContext(
    CustomersStoreContext
  );

  const isApartment =
    currentCustomer?.BoligType === CustomerTypesNames.Flermannsbolig || false;

  const params = useParams();

  const useStyles = makeStyles(({ spacing }) => ({
    container: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      padding: spacing(2),
    },
    header: {
      marginBottom: '-10px',
      zIndex: 2,
    },
  }));

  const {
    isSuccess: isTotalNumberOfApartmentsFetched,
  } = useGetTotalNumberOfApartments(currentCustomer!);

  useEffect(() => {
    if (!currentCustomer) {
      getCustomerById(params.customerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCustomerById, params.customerId]);

  const breadcrumbs = [
    {
      name: 'Customers',
      path: '/customers',
    },
    {
      name: 'Customer Details',
      path: `/customers/${params.customerId}`,
    },
  ];

  const classes = useStyles();

  return (
    currentCustomer && (
      <div className={classes.container}>
        <Header
          breadcrumbs={breadcrumbs}
          title={currentCustomer?.Navn}
          className={classes.header}
        />
        {(!isApartment || isTotalNumberOfApartmentsFetched) && (
          <TabularCustomerView currentCustomer={currentCustomer!} />
        )}
      </div>
    )
  );
};

export default observer(Customer);
