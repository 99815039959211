// Status is Omnishop.POS.Common.Entities.RowStatusCodes -> could be improved
// navPrisGruppeTabell is Omnishop.POS.Common.Entities.tblPrisGruppeTabell -> could be improved
// navPostSted is Omnishop.POS.Common.Entities.tblPostSted -> could be improved
// navLevAdrPostSted is Omnishop.POS.Common.Entities.tblPostSted -> could be improved
// navKundeKort -> Comparer is System.Collections.Generic.IEqualityComparer`1[Omnishop.POS.Common.Entities.tblAnlegg]	 -> could be improved
// navKundeAnlegg -> Comparer is System.Collections.Generic.IEqualityComparer`1[Omnishop.POS.Common.Entities.tblAnlegg]	 -> could be improved
// OriginCode is System.Nullable`1[Omnishop.POS.Common.Entities.CustomerOriginCodes] -> could be improved
import { ComponentType } from 'react';

export enum CustomerTypesNames {
  JuridiskPerson = 'JuridiskPerson',
  FysiskPerson = 'FysiskPerson',
  Ukjent = 'Ukjent',
  Flermannsbolig = 'Flermannsbolig',
}

export enum HouseTypes {
  House = 'House',
  ApartmentBlock = 'Apartment block',
}

export type CustomerType = {
  Bnr: number;
  BKey: number;
  Status: number | null;
  Navn: string | null;
  Adresse: string | null;
  Adresse2: string | null;
  PostNr: string | null;
  TlfNr: string | null;
  TlfNr2: string | null;
  FaxNr: string | null;
  EPost: string | null;
  EncryptedSSN: string | null;
  SSN: string | null;
  AlternativtNavn: string | null;
  LevAdrNavn: string | null;
  LevAdrNavn2: string | null;
  LevAdrAdresse: string | null;
  LevAdrPostNr: string | null;
  LevAdrTlfNr: string | null;
  FaktNavn: string | null;
  FaktAdresse: string | null;
  FaktPostNr: string | null;
  FaktTlfNr: string | null;
  FaktEPost: string | null;
  FaktRef: string | null;
  OrgNr: string | null;
  KommuneNr?: string;
  KommuneNavn?: string;
  Info?: null | string;
  Kommentar: string | null;
  GaardsNr: string | null;
  BruksNr: string | null;
  SNr: string | null;
  FesteNr: string | null;
  Rabatt: number;
  KredittGodkjent: boolean;
  DatoOpprettet: Date;
  DatoEndret: Date;
  PrisGruppeTabellPKey: number;
  navPrisGruppeTabell: any | null;
  navPostSted: any | null;
  navLevAdrPostSted: any | null;
  nextPage?: number;
  totalResults?: number;
  navKundeKort: {
    Count: number | null;
    Comparer: any | null;
  };
  navKundeAnlegg: {
    Count: number | null;
    Comparer: any | null;
  };
  OriginCode: number | null;
  customerTypeName?: string;
  totalNumberOfApartments?: string;
  KundeType?: string;
  BoligType?: string;
  Eierforhold?: {
    Id?: number | null;
    Navn?: string;
    IdentifikasjonsNummer?: string;
    PersonType?: string;
  }[];
};

export type CustomerFilter = {
  id?: number;
  name?: string;
  address?: string;
  customerCard?: string;
  orgNo?: string;
  postNoFrom?: string;
  postNoTo?: string;
  municipalityNoFrom?: number;
  municipalityNoTo?: number;
  comment?: string;
  gaardsNr?: string;
  bruksNr?: string;
  festeNr?: string;
  sNr?: string;
  PrisGruppeTabellPKey?: string;
  customerKeyFrom?: number | string;
  customerKeyTo?: number | string;
  maxResults?: number;
  kundeType?: number | null;
  boligType?: number | null;
  page?: number | null;
  pageSize?: number | null;
  currentPage?: number | null;
  IncludeCustomerType?: boolean;
};

export type AvailableCustomersType = {
  PKey?: number | null;
  Status?: number | null;
  Navn: string;
  MinstePris?: boolean | null;
  navPrisGruppeTabellDetaljer?: any | null;
};

export type NewCustomerFormValuesType = {
  priceTable: any;
  alternativeName: string;
  info: string;
  id: number | null;
  address: string;
  communeNumber: string;
  postalCode: any;
  phoneNumber: string;
  organizationNumber: string;
  bnr: string;
  gnr: string;
  snr: string;
  festeNr: string;
  cardPayment: any;
  invoiceReference: string;
  invoiceReceiver: string;
};

export type EditCustomerFormValuesType = {
  id: number | null;
  alternativeName?: string;
  info?: string;
  priceTable?: any;
  address?: string;
  communeNumber?: string;
  postalCode?: string;
  phoneNumber?: string;
  altPhoneNumber?: string;
  organizationNumber?: string;
  bnr?: string;
  gnr?: string;
  snr?: string;
  festeNr?: string;
  cardPayment?: any;
  invoiceReference?: string;
  invoiceReceiver?: string;
  quotaType?: number;
};

export type EntityType = {
  Bnr?: number | null;
  BKey?: number | null;
  Status?: number | null;
  Navn?: string;
  PrisGruppeTabellPKey?: number | null;
  AlternativtNavn?: string;
  OrgNr?: string;
  TlfNr?: string;
  TlfNr2?: string;
  EPost?: string;
  KommuneNr?: string;
  KommuneNavn?: string;
  SNr?: string;
  BruksNr?: string;
  GaardsNr?: string;
  FesteNr?: string;
  Adresse?: string;
  Adresse2?: string;
  PostNr?: string;
  Rabatt?: number | null;
  LevAdrNavn?: string;
  LevAdrAdresse?: string;
  LevAdrTlfNr?: string;
  Kommentar?: string;
  KredittGodkjent?: boolean;
  FaktRef?: string;
  KundeType?: string;
  Info?: string;
};

export type NewCustomerType = {
  Operation: number;
  Entity: EntityType;
};

export type CustomerQuotasType = {
  Quantity: number;
  QuotaName: string;
  QuotaPKey: number;
  StartQuantity: number;
};

export type CustomerCards = {
  ID: string;
  Kundekortnummer: string;
  KundeBnr: number;
  KundeBKey: number;
  Tekst: string;
  DatoOpprettet: string;
  DatoAvsluttet: string;
  AvsluttetKommentar: string;
  DisplayID: string;
  GeneratedToken: string;
  navKunde: CustomerType;
};

export type CustomerCardsFilter = {
  id: number;
  bnr?: string;
  registerEnhetId?: number;
  filterCardStatus?: number;
};

export type AvailableQuotaType = {
  PKey?: number;
  Status?: number;
  Navn?: string;
  Unit?: string;
  SeksjonBasisKvote?: number;
};

export type CardQuotasType = {
  AvsluttetKommentar: string;
  DatoAvsluttet: string;
  DatoOpprettet: string;
  DisplayID: string;
  GeneratedToken: string;
  ID: string;
  KundeBKey: number;
  KundeBnr: number;
  Tekst: string;
  navKunde: any;
};

export type QuotasTransactionsType = {
  Antall: number;
  BKey: number;
  Bnr: number;
  CustomerCardID: string;
  Dato: string;
  HasIdentityCustomerId: boolean;
  IdentityCustomerId: string;
  KundeBKey: number;
  KundeBnr: number;
  KvotePKey: number;
  OriginCode: number;
  OriginId: string;
  Tekst: string;
  navKunde: any;
  navKvote: any;
  transactionLocationName?: string;
};

export type DistributionDataType = {
  Quantity: number;
  QuotaName: string;
  QuotaPKey: number;
  StartQuantity: number;
  cardID?: string;
};

export type QuotaTransaction = {
  customerBnr?: number;
  customerBkey?: number;
  dateFrom: string;
  dateTo: string;
  saleNr?: string;
  cardId?: string;
};

// TODO remove later
export type TransactionDetailsType = {
  Date: string;
  Time: string;
  ClientId: string;
  ClientName: string;
  SaleNr: number;
  CustomerBNR: number;
  CustomerBKey: number;
  CustomerName: string;
  ItemBNR: number;
  ItemBKey: string;
  ItemName: string;
  Quantity: number;
  Belop: number;
  SaleType: number;
  PaymentStatus: number;
  CustomerCardID: string;
  SaleComment: string;
};

export type CustomerTransactionType = {
  Date?: string;
  ClientName?: string;
  CustomerId?: number;
  CustomerCardID?: string;
  SaleNr?: number | null;
  OwnerNames?: string[];
  QuotaType?: string;
  UnitType?: string;
  BoligNummer?: string;
  RegisterEnhetId?: number;
  UnitCount?: number;
  HusNummer?: number;
  Bokstav?: string;
  ItemName?: string;
  Quantity?: number;
  Comment?: string;
};

export type NewCustomerCard = {
  ID: string;
  Kundekortnummer?: string;
  KundeBnr: number;
  KundeBKey: number | null;
  Tekst?: string;
  DatoAvsluttet?: string;
  AvsluttetKommentar?: string;
};

export type NewQuotaTransaction = {
  KundeBnr: number;
  KundeBKey: number;
  KvotePKey: number;
  Antall: number;
  Tekst?: string;
  CustomerCardID?: string;
  RegisterEnhetID?: number;
};

export type CustomerDetailsSectionSchema = {
  label: string;
  value?: keyof CustomerType;
  formatter?: ComponentType<any>;
};

export type RegisterEnheterType = {
  BoligNummer?: string;
  HusNummer?: number;
  Bokstav?: string;
  RegisterEnhetId?: number;
  PersonType?: string;
  Personer?: {
    Id?: number;
    Navn?: string;
    IdentifikasjonsNummer?: string;
    PersonType?: string;
  }[];
};

export type ApartmentType = {
  RegisterEnheter?: RegisterEnheterType[];
  Eierforhold?: {
    Id?: string;
    Navn: string;
    IdentifikasjonsNummer?: string;
    PersonType?: string;
  }[];
};

export type CustomersType = CustomerType[];

export type CustomersQuotasType = CustomerQuotasType[];

export type CustomersTransactionType = CustomerTransactionType[];

export type AvailableCustomersTypes = AvailableCustomersType[];

export type CustomersCards = CustomerCards[];

export type AvailableQuotaTypes = AvailableQuotaType[];

export type GetAvailableCustomersTypesSuccess = AvailableCustomersTypes;

export type CardQuotasTypes = CardQuotasType[];

export type QuotasTransactionsTypes = QuotasTransactionsType[];

export type DistributionDataTypes = DistributionDataType[];

export type TransactionDetailsTypes = TransactionDetailsType[];
