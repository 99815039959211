import React from 'react';
import { Box, Typography } from '@material-ui/core';

// TYPES
interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number | string;
  value: number | string;
  className?: string;
  tabPanelClassName?: string;
}

const TabPanel: React.FC<TabPanelProps> = props => {
  const {
    children,
    value,
    index,
    className,
    tabPanelClassName,
    ...other
  } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      className={tabPanelClassName}
      {...other}
    >
      {value === index && (
        <Box className={tabPanelClassName} p={3}>
          {children}
        </Box>
      )}
    </Typography>
  );
};

export default TabPanel;
