import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ spacing }) => ({
  container: {
    padding: spacing(1),
  },
  gridContainer: {
    height: '100%',
  },
  cell: {
    padding: spacing(1),
  },
}));
