import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette }) => ({
  selectCardPlaceholder: {
    color: palette.action.disabledBackground,
    fontSize: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 700,
    height: '100%',
  },
  selectCardIcon: {
    fontSize: 240,
    color: palette.primary.contrastText,
  },
  gridTable: {
    boxShadow: 'none',
  },
}));
