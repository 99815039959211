import React, { useContext } from 'react';
import { IntlProvider } from 'react-intl';
import { observer } from 'mobx-react';
import flatten from 'flat';
import { translations } from 'common/helpers/translations';

// STORES:
import { LoginStoreContext } from 'stores/login';

interface IProps {
  children: React.ReactChild;
}

const LanguageProvider: React.FC<IProps> = ({ children }) => {
  const { language } = useContext(LoginStoreContext);

  return (
    <IntlProvider key={language!} locale={language!} messages={flatten(translations[language!])}>
      {React.Children.only(children)}
    </IntlProvider>
  );
}

export default observer(LanguageProvider);
