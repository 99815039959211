import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { refreshTokenInterceptor } from './refreshTokenInterceptor';

// HELPERS:
import { getAccessToken, isUserAuthenticated } from 'common/helpers/auth';

var apiUrl = window.location.origin;
if (process.env.REACT_APP_VSP_API_URL) {
  apiUrl = process.env.REACT_APP_VSP_API_URL;
} else if ((window as any).Configs) {
  apiUrl = (window as any).Configs.serverurl;
}

const defaultValues: AxiosRequestConfig = {
  baseURL: `${apiUrl}`,
};

const client = axios.create(defaultValues);

/**
 * Setup initial Axios config
 * Handle success and error cases
 */
const request = (options: AxiosRequestConfig) => {
  /**
   * Send credentials with each request
   */

  if (isUserAuthenticated()) {
    options.headers = {
      Authorization: `Token ${getAccessToken()}`,
      'Content-Type': 'application/json',
      ...options.headers,
    };
  }

  const onSuccess = (response: AxiosResponse) => Promise.resolve(response);

  const onError = (error: AxiosError) => {

    if (process.env.NODE_ENV === 'development') {
      /**
       * Request was made but server responded with
       * something other than 2xx
       */
      if (error.response) {
        console.group(
          `%cRequest error: ${error.response.config.url}`,
          'font-size: 0.85rem; font-weight: bold; padding-top: 10px;'
        );
        console.error('Method:', error.response.config.method?.toUpperCase());
        console.error('Status:', error.response.status);
        console.error('Data:', error.response.data);
        console.error('Headers:', error.response.headers);
        console.groupCollapsed('Trace:');
        console.trace();
        console.groupEnd();
        /**
         * Something else happened while setting up the request
         * that triggered the error
         */
      } else {
        console.group('Error message:');
        console.error(error.message);
        console.groupEnd();
      }
    }

    return Promise.reject(error.response || error.message);
  };

  return client(options)
    .then(onSuccess)
    .catch(onError);
};

refreshTokenInterceptor(client);

export default request;
