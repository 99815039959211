import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, spacing }) => ({
  gridContainer: {
    height: '100%',
    paddingTop: spacing(2),
  },
  allQuotasTableColumn: {
    height: '100%',
  },
  quotaOverviewColumn: {
    height: '100%',
    paddingLeft: spacing(2),
  },
  customersDetailsPaper: {
    height: '100%',
    overflow: 'scroll',
    background: palette.secondary.dark,
  },
  paper: {
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  selectCardPlaceholder: {
    textAlign: 'center',
    color: palette.action.disabledBackground,
    fontSize: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 700,
    height: '100%',
  },
  selectCardIcon: {
    fontSize: 240,
    color: palette.primary.contrastText,
  },
  quotasContainer: {
    overflow: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));
