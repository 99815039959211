import React from 'react';
import { Grid, Divider, makeStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl';

// COMPONENTS:
import SearchInput from 'common/components/SearchInput';

const useStyles = makeStyles(({ spacing }) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  dateFrom: {
    padding: spacing(0, 2),
  },
  filterButton: {
    marginLeft: spacing(3),
    padding: 0,
    minWidth: 0,
    height: 32,
    width: 32,
    '& Svg': {
      width: 16,
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  toolbar: {
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'flex-end',
    padding: spacing(3, 3, 0, 3),
    zIndex: 3,
  },
  gridItem: {
    zIndex: 10,
    paddingLeft: spacing(2),
  },
  divider: {
    margin: spacing(2, 0, 1),
  },
  filterIconButton: {
    paddingLeft: 0,
  },
  filterIcon: {
    paddingRight: spacing(0.5),
  },
}));
//
// interface IFormValues {
//   wasteType: string;
//   binType: string;
//   operation: string;
// }

const Toolbar: React.FC<any> = ({ intl, search, setSearch }) => {
  const classes = useStyles();

  // const initialValues: IFormValues = {
  //   wasteType: '',
  //   binType: '',
  //   operation: '',
  // };
  //
  // const operationOptions = [
  //   {
  //     label: 'INN',
  //     value: 'INN',
  //   },
  //   {
  //     label: 'UT',
  //     value: 'UT',
  //   },
  //   {
  //     label: 'Rep',
  //     value: 'Rep',
  //   },
  // ];
  //
  // const wasteTypeOptions = [
  //   {
  //     label: 'Restavfall',
  //     value: 'Restavfall',
  //   },
  //   {
  //     label: 'Papir',
  //     value: 'Papir',
  //   },
  // ];

  // const binTypeOptions = [
  //   {
  //     label: '140L',
  //     value: '140L',
  //   },
  //   {
  //     label: '240L',
  //     value: '240L',
  //   },
  //   {
  //     label: '360L',
  //     value: '360L',
  //   },
  //   {
  //     label: '370L',
  //     value: '370L',
  //   },
  //   {
  //     label: '660L',
  //     value: '660L',
  //   },
  // ];

  // const setFilters = (values: IFormValues) => {
  //   setSelectedFilters({
  //     wasteType: values.wasteType,
  //     binType: values.binType,
  //     operation: values.operation,
  //   });
  // };

  return (
    <div>
      <Grid container className={classes.toolbar}>
        <Grid item xs={4} className={classes.row}>
          <SearchInput
            placeholder={intl.formatMessage({
              id: 'Wastebins.importWorkOrderSearchPlaceholder',
            })}
            onChange={e => {
              setSearch(e.target.value);
            }}
            value={search}
          />
        </Grid>
        {/*<Grid item xs={8} className={classes.gridItem}>*/}
        {/*<Formik initialValues={initialValues} onSubmit={setFilters}>*/}
        {/*  {() => {*/}
        {/*    return (*/}
        {/*      <Form style={{ display: 'flex' }}>*/}
        {/*        <AutoSubmit />*/}
        {/*        <Grid container justify="flex-end" spacing={2}>*/}
        {/*          <Grid item xs={3} className={classes.gridItem}>*/}
        {/*            <SelectField*/}
        {/*              name="operation"*/}
        {/*              isClearable*/}
        {/*              options={operationOptions}*/}
        {/*              label={*/}
        {/*                <FormattedMessage*/}
        {/*                  id={`Global.form.labels.operation`}*/}
        {/*                />*/}
        {/*              }*/}
        {/*            />*/}
        {/*          </Grid>*/}
        {/*          <Grid item xs={3} className={classes.gridItem}>*/}
        {/*            <SelectField*/}
        {/*              name="wasteType"*/}
        {/*              isClearable*/}
        {/*              options={wasteTypeOptions}*/}
        {/*              label={*/}
        {/*                <FormattedMessage*/}
        {/*                  id={`Global.form.labels.wasteType`}*/}
        {/*                />*/}
        {/*              }*/}
        {/*            />*/}
        {/*          </Grid>*/}
        {/*          <Grid item xs={3} className={classes.gridItem}>*/}
        {/*            <SelectField*/}
        {/*              name="binType"*/}
        {/*              isClearable*/}
        {/*              options={binTypeOptions}*/}
        {/*              label={*/}
        {/*                <FormattedMessage id={`Global.form.labels.binType`} />*/}
        {/*              }*/}
        {/*            />*/}
        {/*          </Grid>*/}
        {/*        </Grid>*/}
        {/*      </Form>*/}
        {/*    );*/}
        {/*  }}*/}
        {/*</Formik>*/}
        {/*</Grid>*/}
      </Grid>
      <Divider className={classes.divider} />
    </div>
  );
};

export default injectIntl(Toolbar);
