import React from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';

// API:
import { removeUserRole as removeUserRoleAPI } from 'api/users';

// COMPONENTS:
import ButtonLoading from 'common/components/buttons/ButtonLoading';

const useStyles = makeStyles(({ palette, spacing }) => ({
  noButton: {
    marginLeft: spacing(2),
  },
  buttons: {
    paddingTop: spacing(1.5),
  },
  title: {
    paddingBottom: spacing(2),
  },
  modalContainer: {
    height: '100%',
    padding: spacing(5, 8),
  }
}));

const RemoveUserRoleModal = ({
  role,
  toggleModal,
  userId,
}: {
  role: string;
  toggleModal: () => void;
  userId: string;
}) => {
  const classes = useStyles();
  const queryClient = useQueryClient();

  const { mutate: removeUserRole, status: removeUserRoleStatus, isLoading } = useMutation(
    async (values: any) => await removeUserRoleAPI(values),
    {
      onSuccess: () => toggleModal(),
      onSettled: () => queryClient.invalidateQueries('currentUserRoles'),
    }
  );

  return (
    <div className={classes.modalContainer}>
      <Typography variant="h4" className={classes.title}>
        <FormattedMessage id="Company.User.removeUserRole" />
      </Typography>
      <Typography variant="body1">
        <FormattedMessage
          id="Company.User.removeUserRoleConfirmation"
          values={{
            b: (chunk: string) => <b>{chunk}</b>,
            value: `${role}`,
          }}
        />
      </Typography>
      <div className={classes.buttons}>
        <ButtonLoading
          variant="contained"
          color="primary"
          state={removeUserRoleStatus}
          disabled={isLoading}
          onClick={() => {
            removeUserRole({
              UserPkey: userId,
              RoleName: role,
            });
          }}
        >
          <FormattedMessage id="Global.yes" />
        </ButtonLoading>
        <Button
          variant="outlined"
          color="primary"
          onClick={toggleModal}
          className={classes.noButton}
        >
          <FormattedMessage id="Global.no" />
        </Button>
      </div>
    </div>
  );
};

export default RemoveUserRoleModal;
