import { Grid, makeStyles, Typography, Tooltip, IconButton, Button } from '@material-ui/core';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

// ICONS:
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import Steps from '../../../Cards/components/AllCards/AddCardModal/Steps';
import { useModal } from '../../../../../../../common/helpers/useModal';
import { CustomersStoreContext, ICustomersStore } from '../../../../../../../stores/customers';

const useStyles = makeStyles(({ spacing, typography, palette }) => ({
  header: {
    padding: spacing(3,3,0,3),
    display: 'flex',
    alignItems: 'end',
  },
  heading: {
    fontWeight: typography.fontWeightBold,
  },
  cell: {
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  detailsButton: {
    borderRadius: '100%',
    transition: '.4s background ease-in-out',

    '&:hover': {
      cursor: 'pointer',
      background: '#cee0d0',
    },
  },
  icon: {
    height: 22,
    width: 22,
    color: palette.primary.main
  },
  row: {
    display: 'flex',
    alignItems: 'center'
  },
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  addButton: {
    display: 'flex',
    '& Svg': {
      width: 18,
    },
  },
}));

interface CustomToolbarProps {
  navigateToHash?: string;
  titleTranslationId: string;
  setValue?: any;
  isCard?: boolean;
  getCards?: () => void;
  isApartmentNumberEmpty?: boolean
}
const CustomToolbar: React.FC<CustomToolbarProps> = ({ isCard, titleTranslationId, navigateToHash, setValue, getCards, isApartmentNumberEmpty = false }) => {
  const classes = useStyles();

  const { toggleModal, RenderModal } = useModal();

  const {
    currentCustomer,
    currentApartment,
    setCurrentApartmentCardFilter
  }: ICustomersStore = useContext(CustomersStoreContext);

  const goToDetails = () => {
    if (isCard) {
      setCurrentApartmentCardFilter(currentApartment);
    }
    setValue(navigateToHash);
  };

  return (
    <Grid container className={classes.header}>
      <Grid item xs={8} className={classes.row}>
        <Typography variant="h5" className={classes.heading}>
          <FormattedMessage id={titleTranslationId} />
        </Typography>

        {navigateToHash && !isApartmentNumberEmpty && (
          <Tooltip title={<FormattedMessage id="Global.seeDetailsButton" />} placement="top" TransitionProps={{ timeout: 300 }}>
            <IconButton
              color="inherit"
              onClick={goToDetails}
              className={classes.detailsButton}
            >
              <OpenInNewIcon classes={{ root: classes.icon }} />
            </IconButton>
          </Tooltip>
        )}
      </Grid>

      {isCard && (
        <Grid item xs={4} className={classes.right}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.addButton}
            onClick={toggleModal}
          >
            <AddOutlinedIcon />
            <FormattedMessage id="Customer.Cards.addCard" />
          </Button>
        </Grid>
      )}
      <RenderModal>
        <Steps apartment={currentApartment} toggleModal={toggleModal} customer={currentCustomer!} getCards={getCards!} />
      </RenderModal>
    </Grid>
  );
};

export default CustomToolbar;
