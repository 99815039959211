import React from 'react';
import {
  Grid,
  Button,
  makeStyles,
  Typography,
  IconButton,
} from '@material-ui/core';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

// ICONS:
import CloseIcon from '@material-ui/icons/Close';

// STORES:
import cardPreview from 'assets/images/card-preview.png';
import { Form, Formik } from 'formik';
import InputField from 'common/components/formik/InputField';

// API:
import { RegisterEnheterType, CustomerType } from 'api/customers/types';

interface IStepForAddingCardNumber {
  toggle: () => void;
  intl: IntlShape;
  customer: CustomerType;
  // cardNumber: string;
  nextStep: () => void;
  setCardNumber: (number: string) => void;
  apartment: RegisterEnheterType | undefined;
}
const useStyles = makeStyles(({ palette, typography, spacing }) => ({
  stepContainer: {
    height: '100%',
    padding: spacing(4, 3),
    position: 'relative',
  },
  stepInfo: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'baseline',
    padding: spacing(6),
  },
  formLabel: {
    padding: spacing(4, 0, 1, 0),
  },
  title: {
    color: palette.secondary.main,
  },
  submitButton: {
    marginTop: spacing(14),
  },
  inputRoot: {
    fontSize: 13,
    border: `1px solid ${palette.secondary.light}`,
    borderRadius: '4px',
    transition:
      'border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
  },
  inputInput: {
    padding: '8px',
  },
  inputError: {
    borderColor: palette.error.main,
    '&.Mui-focused': {
      borderColor: palette.error.main,
    },
  },
  inputFocused: {
    borderColor: palette.primary.main,
    borderWidth: 1,
  },
  inputDisabled: {
    backgroundColor: palette.action.disabledBackground,
    borderColor: palette.action.disabledBackground,
    color: palette.action.disabled,
  },
  commentField: {
    paddingBottom: spacing(7),
    width: '321px !important',
  },
  infoIcon: {
    paddingRight: spacing(1),
  },
  cardPreview: {
    // boxShadow: '0px 0px 16px 0px rgba(0,0,0,0.15)',
    borderRadius: 4.8,
    width: '321px',
    height: '204px',
    marginBottom: spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: spacing(2),
    // background: `url(${cardPreview})`,
  },
  cardPreviewContainer: {
    boxShadow: '0px 0px 16px 0px rgba(0,0,0,0.15)',
    marginBottom: spacing(3),
    background: `url(${cardPreview})`,
    width: '321px',
    height: '204px',
  },
  cardText: {
    fontSize: '10px',
    fontWeight: typography.fontWeightMedium,
  },
  button: {
    position: 'absolute',
    bottom: spacing(4),
    right: '42%',
    padding: '12px 24px',
  },
  cardPreviewRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100$',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
  },
  closeIcon: {
    padding: 0,
    '& Svg': {
      color: palette.secondary.main,
    },
  },
  customFieldsArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  scannedCardNumber: {
    paddingBottom: spacing(2),
  },
  displayField: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
    minWidth: 160,
  },
  rowHeader: {
    fontWeight: typography.fontWeightMedium,
    color: palette.secondary.light,
    textAlign: 'left',
  },
  cardNumber: {
    margin: spacing(0, 2),
    minWidth: 200,
  },
}));

const StepForAddingCardNumber: React.FC<IStepForAddingCardNumber> = ({
  intl,
  apartment,
  toggle,
  customer,
  nextStep,
  setCardNumber,
}) => {
  const classes = useStyles();

  const handleSubmit = (values: { cardNumber: string }) => {
    setCardNumber(values.cardNumber);
    nextStep();
  };

  return (
    <>
      {customer.KundeType ? (
        <Grid className={classes.stepContainer}>
          <div className={classes.modalHeader}>
            <Typography variant="h3" className={classes.title}>
              New RFID card
            </Typography>
            <IconButton className={classes.closeIcon} onClick={toggle}>
              <CloseIcon />
            </IconButton>
          </div>
          <Grid item xs={12} className={classes.stepInfo}>
            <div style={{ display: 'flex' }}>
              <Grid item xs={4} className={classes.displayField}>
                <Typography variant="body2" className={classes.rowHeader}>
                  Agreement number
                </Typography>
                <Typography variant="body2">{customer.BKey}</Typography>
              </Grid>
              <Grid item xs={6} className={classes.displayField}>
                <Typography variant="body2" className={classes.rowHeader}>
                  Address
                </Typography>
                <Typography variant="body2">
                  {customer.AlternativtNavn}
                </Typography>
              </Grid>
            </div>
            {apartment && (
              <div style={{ display: 'flex', paddingTop: 32 }}>
                <Grid item xs={6} className={classes.displayField}>
                  <Typography variant="body2" className={classes.rowHeader}>
                    Apartmentnr.
                  </Typography>
                  <Typography variant="body2">
                    {apartment?.BoligNummer || '-'}
                  </Typography>
                </Grid>
                <Grid item xs={4} className={classes.displayField}>
                  <Typography variant="body2" className={classes.rowHeader}>
                    Housenr.
                  </Typography>
                  <Typography variant="body2">
                    {apartment?.HusNummer || '-'}
                  </Typography>
                </Grid>
                <Grid item xs={4} className={classes.displayField}>
                  <Typography variant="body2" className={classes.rowHeader}>
                    Letter
                  </Typography>
                  <Typography variant="body2">
                    {apartment?.Bokstav || '-'}
                  </Typography>
                </Grid>
              </div>
            )}

            <div style={{ width: '100%' }}>
              <Grid
                item
                xs={12}
                style={{
                  marginTop: 32,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Formik
                  initialValues={{ cardNumber: '' }}
                  onSubmit={handleSubmit}
                >
                  {() => {
                    return (
                      <Form>
                        <Grid container>
                          <Grid item className={classes.cardNumber}>
                            <InputField
                              name="cardNumber"
                              label={
                                <FormattedMessage id="Global.form.labels.cardNumber" />
                              }
                              placeholder={intl.formatMessage({
                                id: 'Global.form.placeholders.cardNumber',
                              })}
                            />
                          </Grid>
                        </Grid>
                        <Button
                          className={classes.button}
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          Next
                        </Button>
                      </Form>
                    );
                  }}
                </Formik>
              </Grid>
            </div>
          </Grid>
        </Grid>
      ) : (
        <Grid className={classes.stepContainer}>
          <div className={classes.modalHeader}>
            <Typography variant="h3" className={classes.title}>
              New RFID card
            </Typography>
            <IconButton className={classes.closeIcon} onClick={toggle}>
              <CloseIcon />
            </IconButton>
          </div>
          <Grid item xs={12} className={classes.stepInfo}>
            <Typography variant="body1">
              Unable to add card. Please try again later or contact support.
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default injectIntl(StepForAddingCardNumber);
