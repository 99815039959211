import { IntlShape } from 'react-intl';
import { ICell, IGridTableSchema } from 'common/components/grid/types';
import { makeStyles, TableCell, Checkbox } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { WastebinsStoreContext } from '../../../../stores/wastebins';

interface ICheckRowCell<T> {
  intl: IntlShape;
  row: IGridTableSchema<T>['row'];
  cell: ICell<T>;
  rowData: any;
}

const useStyles = makeStyles(({ spacing }) => ({
  cell: {
    fontWeight: 'bold',
    borderRadius: '100%',
    transition: '.4s background ease-in-out',
    padding: 0,
    '&:hover': {
      background: '#cee0d0',
    },
  },
  menu: {
    marginLeft: 40,
    marginTop: 82,
  },
  dropdownIcon: {
    width: 18,
  },
  dropdownCell: {},
  modalContainer: {
    width: 496,
    height: '100%',
    padding: spacing(5, 8),
  },
  title: {
    paddingBottom: spacing(2),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
}));

const CheckRowCell = <T extends object>({
  row,
  cell,
  rowData,
}: ICheckRowCell<T>) => {
  const classes = useStyles();

  const [checked, setChecked] = useState(false);

  const {
    addSelectedWorkOrderToImport,
    removeSelectedWorkOrderToImport,
    selectedWorkOrdersToImport,
  } = useContext(WastebinsStoreContext);

  useEffect(() => {
    const isRowCheckedByDefault = selectedWorkOrdersToImport.some(
      element => element.Id === rowData.Id
    );
    setChecked(isRowCheckedByDefault);
  }, [rowData.Id, selectedWorkOrdersToImport]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
    isChecked
      ? addSelectedWorkOrderToImport(rowData)
      : removeSelectedWorkOrderToImport(rowData);
    setChecked(isChecked);
  };

  return (
    <TableCell
      key={`${row.id}-${cell.key}-checkbox`}
      className={classes.dropdownCell}
    >
      <Checkbox checked={checked} onChange={handleChange} size="small" />
    </TableCell>
  );
};

export default CheckRowCell;
