import React from 'react';

// TYPES
import { ClientGroupType } from 'api/clientGroups/types';

interface IIdFormatter {
  currentItem: ClientGroupType;
}

const IdFormatter: React.FC<IIdFormatter> = ({ currentItem }) => {
  return <>{currentItem.PKey === 0 ? <p>0</p> : <p>{currentItem.PKey}</p>}</>;
};

export default IdFormatter;
