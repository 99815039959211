import wasteRequest from '../wasteRequest';

// TYPES
import { CreateWorkOrderType, SearchWorkOrderType } from './types';

export const getWastebins = (values: any) =>
  wasteRequest({
    method: 'POST',
    url: '/api/wastebin/SearchWasteBin',
    data: {
      ...values,
    },
  });

export const createWorkOrder = (values: CreateWorkOrderType[]) =>
  wasteRequest({
    method: 'POST',
    url: '/api/wastebin/CreateWorkOrder',
    data: values,
  });

export const getWorkOrderImportHistory = () =>
  wasteRequest({
    method: 'GET',
    url: '/api/wastebin/WorkOrderImportHistory',
  });

export const searchWorkOrder = (values: SearchWorkOrderType) =>
  wasteRequest({
    method: 'POST',
    url: '/api/wastebin/SearchWorkOrder',
    data: {
      ...values,
    },
  });
//
// export const printWorkOrder = (
//   workOrderGroupId: number,
//   workOrderIds?: number[]
// ) =>
//   wasteRequest({
//     method: 'GET',
//     url: `/admin/wastebin/PrintWasteBinWorkOrder${normalizeQueryParamsWithArray({
//       workOrderIds: workOrderIds,
//       workOrderGroupId: workOrderGroupId,
//     })}`,
//   });

export const printWorkOrder = (values: {
  workOrderGroupId?: number;
  workOrderIds?: Array<number>;
}) =>
  wasteRequest({
    method: 'POST',
    url: '/api/wastebin/PrintWasteBinWorkOrder',
    data: {
      ...values,
    },
  });
