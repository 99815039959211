import React, { useEffect, useCallback, useContext, useState } from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { useQuery } from 'react-query';

// COMPONENTS:
import Toolbar from './components/Toolbar';
import GridTable from 'common/components/grid/GridTable';
import DateCell from 'common/components/grid/DateCell';
import DetailsCell from './components/DetailsCell';

// STORES:
import { CustomersStoreContext, ICustomersStore } from 'stores/customers';

// TYPES:
import {
  AvailableQuotaType,
  CustomerTransactionType,
} from 'api/customers/types';
import { IGridTableSchema } from 'common/components/grid/types';

// API:
import { getAvailableQuotaTypes } from 'api/customers';

// HELPERS:
import { useDebounce } from 'common/helpers/useDebounce';

const useStyles = makeStyles(({ spacing }) => ({
  transactionContainer: {
    overflow: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    paddingTop: spacing(2),
    height: '100%',
  },
  gridTableWrapper: {
    overflow: 'unset',
    flexGrow: 1,
  },
}));

export enum TransactionType {
  transaction = 'transaction',
  quotaTransaction = 'quotaTransaction',
}

export interface IDateFormValues {
  dateFrom: string;
  dateTo: string;
}

const transactionsSchema: IGridTableSchema<CustomerTransactionType> = {
  wrapper: ({ children, ...rest }) => <div {...rest}>{children}</div>,
  header: {
    columns: [
      {
        id: 'Date',
        name: <FormattedMessage id="Customer.Transactions.tableHeaders.date" />,
      },
      {
        id: 'SaleNr',
        name: (
          <FormattedMessage id="Customer.Transactions.tableHeaders.bilagNr" />
        ),
      },
      {
        id: 'ClientName',
        name: (
          <FormattedMessage id="Customer.Transactions.tableHeaders.client" />
        ),
      },
      {
        id: 'CustomerCardID',
        name: (
          <FormattedMessage id="Customer.Transactions.tableHeaders.customerId" />
        ),
      },
      {
        id: 'QuotaType',
        name: (
          <FormattedMessage id="Customer.Transactions.tableHeaders.quotaType" />
        ),
      },
      {
        id: 'ItemName',
        name: (
          <FormattedMessage id="Customer.Transactions.tableHeaders.wareName" />
        ),
      },
      {
        id: 'Quantity',
        name: (
          <FormattedMessage id="Customer.Transactions.tableHeaders.amount" />
        ),
      },
      {
        id: 'UnitCount',
        name: (
          <FormattedMessage id="Customer.Transactions.tableHeaders.quotaAmount" />
        ),
      },
      {
        id: 'UnitType',
        name: <FormattedMessage id="Customer.Transactions.tableHeaders.type" />,
      },
      {
        id: 'Comment',
        name: (
          <FormattedMessage id="Customer.Transactions.tableHeaders.comment" />
        ),
      },
    ],
  },
  row: {
    id: 'SaleNr',
    cells: [
      {
        key: 'Date',
        formatter: DateCell,
      },
      {
        key: 'SaleNr',
      },
      {
        key: 'ClientName',
      },
      {
        key: 'CustomerCardID',
      },
      {
        key: 'QuotaType',
      },
      {
        key: 'ItemName',
      },
      {
        key: 'Quantity',
      },
      {
        key: 'UnitCount',
      },
      {
        key: 'UnitType',
      },
      {
        key: 'Comment',
      },
      {
        customKey: 'openDetails',
        formatter: DetailsCell,
      },
    ],
  },
};

const transactionsSchemaForApartments: IGridTableSchema<CustomerTransactionType> = {
  wrapper: ({ children, ...rest }) => <div {...rest}>{children}</div>,
  header: {
    columns: [
      {
        id: 'Date',
        name: <FormattedMessage id="Customer.Transactions.tableHeaders.date" />,
      },
      {
        id: 'SaleNr',
        name: (
          <FormattedMessage id="Customer.Transactions.tableHeaders.bilagNr" />
        ),
      },
      {
        id: 'ClientName',
        name: (
          <FormattedMessage id="Customer.Transactions.tableHeaders.client" />
        ),
      },
      {
        id: 'CustomerCardID',
        name: (
          <FormattedMessage id="Customer.Transactions.tableHeaders.customerId" />
        ),
      },
      {
        id: 'BoligNummer',
        name: (
          <FormattedMessage id="Customer.Transactions.tableHeaders.apartmentId" />
        ),
      },
      {
        id: 'HusNummer',
        name: (
          <FormattedMessage id="Customer.Transactions.tableHeaders.houseNr" />
        ),
      },
      {
        id: 'Bokstav',
        name: (
          <FormattedMessage id="Customer.Transactions.tableHeaders.letter" />
        ),
      },
      {
        id: 'QuotaType',
        name: (
          <FormattedMessage id="Customer.Transactions.tableHeaders.quotaType" />
        ),
      },
      {
        id: 'ItemName',
        name: (
          <FormattedMessage id="Customer.Transactions.tableHeaders.wareName" />
        ),
      },
      {
        id: 'Quantity',
        name: (
          <FormattedMessage id="Customer.Transactions.tableHeaders.amount" />
        ),
      },
      {
        id: 'UnitCount',
        name: (
          <FormattedMessage id="Customer.Transactions.tableHeaders.quotaAmount" />
        ),
      },
      {
        id: 'UnitType',
        name: <FormattedMessage id="Customer.Transactions.tableHeaders.type" />,
      },
      {
        id: 'Comment',
        name: (
          <FormattedMessage id="Customer.Transactions.tableHeaders.comment" />
        ),
      },
    ],
  },
  row: {
    id: 'SaleNr',
    cells: [
      {
        key: 'Date',
        formatter: DateCell,
      },
      {
        key: 'SaleNr',
      },
      {
        key: 'ClientName',
      },
      {
        key: 'CustomerCardID',
      },
      {
        key: 'BoligNummer',
      },
      {
        key: 'HusNummer',
      },
      {
        key: 'Bokstav',
      },
      {
        key: 'QuotaType',
      },
      {
        key: 'ItemName',
      },
      {
        key: 'Quantity',
      },
      {
        key: 'UnitCount',
      },
      {
        key: 'UnitType',
      },
      {
        key: 'Comment',
      },
      {
        customKey: 'openDetails',
        formatter: DetailsCell,
      },
    ],
  },
};

const Transactions = ({ isApartment }: { isApartment: boolean }) => {
  const intl = useIntl();
  const classes = useStyles();

  const { data: availableQuotaTypes } = useQuery<AvailableQuotaType[]>(
    'availableQuotaTypes',
    async () => {
      const { data } = await getAvailableQuotaTypes();
      return data;
    }
  );

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const transactionTypeOptions = [
    {
      value: '0',
      label: intl.formatMessage({
        id: 'Customer.Transactions.transactionType.allTransactions',
      }),
    },
    {
      value: '1',
      label: intl.formatMessage({
        id: 'Customer.Transactions.transactionType.ordinarySalesTransactions',
      }),
    },
    {
      value: '2',
      label: intl.formatMessage({
        id: 'Customer.Transactions.transactionType.quotaSalesTransactions',
      }),
    },
  ];

  const quotaTypesOptions: { value: string; label: string }[] = [];

  if (availableQuotaTypes && availableQuotaTypes.length) {
    availableQuotaTypes.forEach(quotaType => {
      quotaTypesOptions.push({
        value: quotaType.Navn || '',
        label: quotaType.Navn || '',
      });
    });
  }

  const firstDayOfTheYear = new Date(new Date().getFullYear(), 0, 1, 1)
    .toISOString()
    .substring(0, 10);

  console.log(firstDayOfTheYear);

  const [transactionTypeFilter, setTransactionTypeFilter] = useState(
    transactionTypeOptions[0]
  );
  const [customerTypeFilter, setCustomerTypeFilter] = useState(null);
  const [cardFilter, setCardFilter] = useState('');
  const [quotaTypeFilter, setQuotaTypeFilter] = useState('');
  const [apartmentNumberFilter, setApartmentNumberFilter] = useState('');
  const [dateFilters, setDateFilters] = useState({
    dateFrom: firstDayOfTheYear,
    dateTo: '',
  });

  const filterByDate = async (values: IDateFormValues) => {
    setDateFilters({ ...values });
  };

  const {
    getCustomerTransactionsState,
    getCustomerTransactions,
    customerTransactions,
    currentCustomer,
    getApartmentNumbers,
    allApartmentNumbers,
  }: ICustomersStore = useContext(CustomersStoreContext);

  useEffect(() => {
    if (isApartment) {
      getApartmentNumbers({ customerId: currentCustomer!.BKey });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const apartmentsNumbers =
    (allApartmentNumbers.length && allApartmentNumbers[0].RegisterEnheter) ||
    [];

  // Filter out empty apartment numbers and then map options for select input
  // const apartmentNumbersFilters = apartmentsNumbers.filter(apartment => apartment.BoligNummer !== '').map(apartment => ({
  //   value: apartment.BoligNummer || '',
  //   label: apartment.BoligNummer || '',
  // }));

  const cardNumbers = customerTransactions
    .map(cardTransaction => cardTransaction.CustomerCardID)
    .filter(cardNumber => cardNumber !== null);

  const transactionsApartments = customerTransactions
    .map(cardTransaction => cardTransaction.BoligNummer)
    .filter(apartmentNr => apartmentNr !== '');

  const cardNumbersFilter = [...new Set(cardNumbers)].map(cardNumber => ({
    value: cardNumber || '',
    label: cardNumber || '',
  }));

  const apartmentNumbersFilters = [...new Set(transactionsApartments)].map(
    apartmentNr => ({
      value: apartmentNr || '',
      label: apartmentNr || '',
    })
  );

  const getTransactions = useCallback(
    () =>
      getCustomerTransactions({
        customerBkey: currentCustomer!.BKey,
        filter: transactionTypeFilter,
        quotaType: quotaTypeFilter,
        boligNummer: apartmentNumberFilter,
        saleNr: parseInt(search),
        customerCardId: cardFilter,
        ...dateFilters,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      quotaTypeFilter,
      debouncedSearch,
      apartmentNumberFilter,
      transactionTypeFilter,
      dateFilters,
      currentCustomer,
      cardFilter,
    ]
  );

  // const filteredCustomerTransactions = customerTransactions.map(
  //   transaction => ({
  //     ...transaction,
  //     duplicates: customerTransactions.filter(
  //       singleTransaction => singleTransaction.SaleNr === transaction.SaleNr
  //     ),
  //   })
  // );
  // let arrayOfArrays: any = [];
  // let prevSaleNr: any = customerTransactions[0]?.SaleNr;

  // const filteredCustomerTransactions = customerTransactions.forEach(
  //   (transaction, index) => {
  //     if (transaction.SaleNr === prevSaleNr) {
  //       arrayOfArrays.push(transaction);
  //     } else {
  //       prevSaleNr = transaction.SaleNr;
  //     }
  //   }
  // );

  // const firstRowCustomerTransactions = filteredCustomerTransactions.filter(
  //   (customerTransactions: any) =>
  //     customerTransactions.duplicates.length > 0 &&
  //     customerTransactions.QuotaType === ''
  // );

  return (
    <div className={classes.container}>
      <Paper className={classes.transactionContainer}>
        {quotaTypesOptions && apartmentsNumbers && currentCustomer && (
          <>
            <Toolbar
              search={search}
              setSearch={setSearch}
              transactionTypeFilter={transactionTypeFilter}
              customerTypeFilter={customerTypeFilter}
              setCustomerTypeFilter={setCustomerTypeFilter}
              quotaTypeFilter={quotaTypeFilter}
              setQuotaTypeFilter={setQuotaTypeFilter}
              setTransactionTypeFilter={setTransactionTypeFilter}
              apartmentNumberFilter={apartmentNumberFilter}
              setApartmentNumberFilter={setApartmentNumberFilter}
              transactionTypeOptions={transactionTypeOptions}
              quotaTypesOptions={quotaTypesOptions}
              filterByDate={filterByDate}
              isApartment={isApartment}
              apartmentNumbersFilters={apartmentNumbersFilters}
              cardFilter={cardFilter}
              setCardFilter={setCardFilter}
              dateFilters={dateFilters}
              cardNumbersFilter={cardNumbersFilter}
            />

            <GridTable
              className={classes.gridTableWrapper}
              tableSchema={
                isApartment
                  ? transactionsSchemaForApartments
                  : transactionsSchema
              }
              data={customerTransactions}
              loadingState={getCustomerTransactionsState}
              getData={getTransactions}
            />
          </>
        )}
      </Paper>
    </div>
  );
};

export default observer(Transactions);
