import { createContext } from 'react';
import { observable, action, runInAction } from 'mobx';
import { navigate } from '@reach/router';

// API:
import {
  getAllCustomers,
  getAvailableCustomersTypes,
  addNewCustomer,
  editCustomer,
  getCustomerById,
  getCustomerCards,
  getCustomerTransactions,
  getQuotaSummary,
  getAvailableQuotaTypes,
  getQuotasTransactions,
  getQuotaBalance,
  getTransactionsDetails,
  getPostCodes,
  getCommuneNumbers,
  addCustomerCard,
  addQuotaTransaction,
  loginUser,
  getAllCustomersParameters,
  getApartmentNumbers,
} from 'api/customers';

import { getClients } from 'api/clients';

// TYPES:
import {
  CustomerType,
  CustomersType,
  GetAvailableCustomersTypesSuccess,
  NewCustomerFormValuesType,
  CustomersQuotasType,
  CustomersCards,
  EditCustomerFormValuesType,
  CustomersTransactionType,
  AvailableQuotaTypes,
  CardQuotasTypes,
  QuotasTransactionsTypes,
  DistributionDataTypes,
  QuotaTransaction,
  TransactionDetailsTypes,
  CustomerCardsFilter,
  ApartmentType,
} from 'api/customers/types';
import { LoadingState, PromiseStates } from 'api/types';
import { CustomerFilter } from 'api/customers/types';
import { SortingOrder } from 'common/components/grid/types';
import { ClientType } from 'api/clients/types';

// HELPERS:
import { setTokens } from 'common/helpers/auth';

export interface ICustomersStore {
  // Observables
  loginState?: PromiseStates;
  loginError: any;
  savedAccessToken: any;
  customers: CustomersType;
  customer: any;
  customersFilters: CustomerFilter | null;
  getCustomersState: LoadingState;
  getCustomerByIdState: LoadingState;
  sortBy?: keyof CustomerType;
  order?: SortingOrder;
  availableCustomersTypes: GetAvailableCustomersTypesSuccess;
  getAvailableCustomersTypesState: LoadingState;
  addNewCustomerState: LoadingState;
  addCustomerCardState: LoadingState;
  addNewCustomerErrorMessage: string;
  editCustomerState: LoadingState;
  currentCustomer: CustomerType | null;
  currentCustomerSearch: string;
  currentCustomerType: number | null;
  currentCustomerQuota: any | null;
  currentCardQuota: any | null;
  isEditModeActive: boolean;
  quotaSummary: CustomersQuotasType;
  cardQuotaSummary: CustomersQuotasType;
  quotaBalance: CustomersQuotasType;
  customerTransactions: CustomersTransactionType;
  getQuotaSummaryState: LoadingState;
  getCardQuotaSummaryState: LoadingState;
  getCustomerTransactionsState: LoadingState;
  getCardQuotasState: LoadingState;
  customerCards: CustomersCards;
  filteredCustomerCards: CustomersCards;
  cardQuotas: CardQuotasTypes;
  getCustomerCardsState: LoadingState;
  addManualQuotaState: LoadingState;
  availableQuotaTypes: AvailableQuotaTypes;
  getAvailableQuotaTypesState: LoadingState;
  getQuotasTransactionsState: LoadingState;
  getQuotaBalanceState: LoadingState;
  getDistributionDataState: LoadingState;
  quotasTransactions: QuotasTransactionsTypes;
  distributionData: DistributionDataTypes;
  filters: any;
  transactionsDetails: TransactionDetailsTypes;
  getTransactionsDetailsState: LoadingState;
  getPostCodesState: LoadingState;
  getCommuneNumbersState: LoadingState;
  postCodes: any;
  communeNumbers: any;
  activeQuotaFilter: string;
  newlyCreatedCustomerCard: any;
  scannedCardNumber: string;
  allApartmentNumbers: ApartmentType[];
  getApartmentNumbersState: LoadingState;
  currentApartment: any;
  currentApartmentCardFilter: any;

  // Actions
  loginUser(credentials: any): void;
  getCustomers: () => Promise<void>;
  getCustomerById: (customerId: number | null) => Promise<void>;
  getFilteredCustomers: (values: CustomerFilter) => Promise<void>;
  addNewCustomer: (values: NewCustomerFormValuesType) => Promise<boolean>;
  editCustomer: (values: EditCustomerFormValuesType) => Promise<boolean>;
  sortCustomersBy: (sortBy: keyof CustomerType, order: SortingOrder) => void;
  sortCustomersStringBy: (
    sortBy: keyof CustomerType,
    order: SortingOrder
  ) => void;
  getAvailableCustomersTypes: () => Promise<void>;
  setCurrentCustomer: (rowData: CustomerType) => void;
  setCurrentCustomerSearch: (search: string) => void;
  setCurrentCustomerType: (type: number) => void;
  setIsEditModeActive: (isActive: boolean) => void;
  setCurrentCardQuota: (rowData: any) => void;
  setCurrentCustomerQuota: (rowData: any) => void;
  getQuotaSummary: (
    customer?: CustomerType,
    enhetId?: number,
    maxResults?: number
  ) => Promise<void>;
  getCardQuotaSummary: (cardId: string) => Promise<void>;
  getCustomerTransactions: (filters?: any) => Promise<void>;
  clearCustomer: () => void;
  clearCurrentCustomer: () => void;
  clearQuotasTransactions: () => void;
  getCustomerCards: (values: CustomerCardsFilter) => Promise<void>;
  filterCardsStatus: (values: any) => void;
  getCardQuotas: () => Promise<void>;
  clearCurrentQuota: () => void;
  getAvailableQuotaTypes: () => Promise<void>;
  getQuotasTransactions: (values: QuotaTransaction) => Promise<void>;
  getQuotaBalance: (agreementId: number) => Promise<void>;
  getTransactionsDetails: (
    customerId?: number,
    maxResults?: number
  ) => Promise<void>;
  getDistributionData: () => Promise<any>;
  addManualQuota: (values: any) => Promise<any>;
  changeFilters: (filters: any) => void;
  clearCustomerErrorMessage: () => void;
  getPostCodes: (value?: any) => void;
  getCommuneNumbers: () => void;
  addCustomerCard: (values: any) => Promise<void>;
  setActiveQuotaFilter: (filter: string) => void;
  setScannedCardNumber: (value: string) => void;
  getApartmentNumbers: (values: {
    customerId: number;
    findBoligNummer?: string;
  }) => Promise<void>;
  setCurrentApartment: (currentApartment: any) => void;
  setCurrentApartmentCardFilter: (currentApartmentCardFilter: any) => void;
}

class CustomersStore implements ICustomersStore {
  @observable public loginState?: PromiseStates;
  @observable public loginError: any = null;
  @observable public savedAccessToken: string = '';
  @observable public authorizationCredentials = '';
  @observable customers: ICustomersStore['customers'] = [];
  @observable customer: ICustomersStore['customer'] = null;
  @observable customersFilters: ICustomersStore['customersFilters'] = null;
  @observable getCustomersState: ICustomersStore['getCustomersState'] = null;
  @observable
  getCustomerByIdState: ICustomersStore['getCustomerByIdState'] = null;
  @observable sortBy: ICustomersStore['sortBy'] = 'BKey';
  @observable
  availableCustomersTypes: ICustomersStore['availableCustomersTypes'] = [];
  @observable
  getAvailableCustomersTypesState: ICustomersStore['getAvailableCustomersTypesState'] = null;
  @observable
  addNewCustomerState: ICustomersStore['addNewCustomerState'] = null;
  @observable
  addNewCustomerErrorMessage: ICustomersStore['addNewCustomerErrorMessage'] =
    '';
  @observable
  editCustomerState: ICustomersStore['editCustomerState'] = null;
  @observable currentCustomer: ICustomersStore['currentCustomer'] = null;
  @observable currentCustomerSearch: ICustomersStore['currentCustomerSearch'] =
    '';
  @observable
  currentCustomerType: ICustomersStore['currentCustomerType'] = null;
  @observable
  currentCustomerQuota: ICustomersStore['currentCustomerQuota'] = null;
  @observable
  currentCardQuota: ICustomersStore['currentCardQuota'] = null;
  @observable isEditModeActive: ICustomersStore['isEditModeActive'] = false;
  @observable
  quotaSummary: ICustomersStore['quotaSummary'] = [];
  @observable
  cardQuotaSummary: ICustomersStore['cardQuotaSummary'] = [];
  @observable
  getQuotaSummaryState: ICustomersStore['getQuotaSummaryState'] = null;
  @observable
  getCardQuotaSummaryState: ICustomersStore['getCardQuotaSummaryState'] = null;
  @observable
  getCustomerTransactionsState: ICustomersStore['getCustomerTransactionsState'] = null;
  @observable
  getCardQuotasState: ICustomersStore['getCardQuotasState'] = null;
  @observable customerCards: ICustomersStore['customerCards'] = [];
  @observable
  customerTransactions: ICustomersStore['customerTransactions'] = [];
  @observable
  filteredCustomerCards: ICustomersStore['filteredCustomerCards'] = [];
  @observable
  cardQuotas: ICustomersStore['cardQuotas'] = [];
  @observable
  activeQuotaFilter: ICustomersStore['activeQuotaFilter'] = '';
  @observable
  getCustomerCardsState: ICustomersStore['getCustomerCardsState'] = null;
  @observable
  availableQuotaTypes: ICustomersStore['availableQuotaTypes'] = [];
  @observable
  getAvailableQuotaTypesState: ICustomersStore['getAvailableQuotaTypesState'] = null;
  @observable
  getDistributionDataState: ICustomersStore['getDistributionDataState'] = null;
  @observable
  quotasTransactions: ICustomersStore['quotasTransactions'] = [];
  @observable
  getQuotasTransactionsState: ICustomersStore['getQuotasTransactionsState'] = null;
  @observable
  quotaBalance: ICustomersStore['quotaBalance'] = [];
  @observable
  getQuotaBalanceState: ICustomersStore['getQuotaBalanceState'] = null;
  @observable distributionData: ICustomersStore['distributionData'] = [];
  @observable filters: ICustomersStore['filters'] = null;
  @observable
  transactionsDetails: ICustomersStore['transactionsDetails'] = [];

  @observable
  addManualQuotaState: ICustomersStore['addManualQuotaState'] = null;
  @observable
  getTransactionsDetailsState: ICustomersStore['getTransactionsDetailsState'] = null;

  @observable postCodes: ICustomersStore['postCodes'] = [];
  @observable getPostCodesState: ICustomersStore['getPostCodesState'] = null;
  @observable communeNumbers: ICustomersStore['communeNumbers'] = [];
  @observable
  getCommuneNumbersState: ICustomersStore['getPostCodesState'] = null;
  @observable
  addCustomerCardState: ICustomersStore['addCustomerCardState'] = null;
  @observable
  newlyCreatedCustomerCard: ICustomersStore['newlyCreatedCustomerCard'] = null;
  @observable scannedCardNumber: ICustomersStore['scannedCardNumber'] = '';
  @observable currentApartment: ICustomersStore['currentApartment'] = null;
  @observable
  currentApartmentCardFilter: ICustomersStore['currentApartmentCardFilter'] = null;
  @observable allApartmentNumbers: ICustomersStore['allApartmentNumbers'] = [];
  @observable
  getApartmentNumbersState: ICustomersStore['getApartmentNumbersState'] = null;

  @action
  loginUser = async (credentials: any) => {
    this.loginState = PromiseStates.PENDING;

    try {
      // this.authorizationCredentials = encodeCredentialsToBase64(credentials);
      const response = await loginUser(
        this.authorizationCredentials,
        credentials
      );
      const { AccessToken, RefreshToken } = response.data;
      this.savedAccessToken = AccessToken;
      setTokens(AccessToken, RefreshToken);
      navigate('/customers');
    } catch (error) {
      runInAction(() => {
        this.loginState = PromiseStates.REJECTED;
        this.loginError = error?.status;
      });
    }
  };

  @action
  getCustomers = async () => {
    try {
      this.getCustomersState = PromiseStates.PENDING;
      const { data } = await getAllCustomers();
      this.customers = data;
      this.getCustomersState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getCustomersState = PromiseStates.REJECTED;
    }
  };

  @action
  getCustomerById = async (customerId: number | null) => {
    try {
      this.getCustomerByIdState = PromiseStates.PENDING;
      const { data } = await getCustomerById(customerId);
      this.customer = data;
      this.currentCustomer = data;
      this.getCustomerByIdState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getCustomerByIdState = PromiseStates.REJECTED;
    }
  };

  @action
  getCustomerCards = async (values: CustomerCardsFilter) => {
    try {
      this.getCustomerCardsState = PromiseStates.PENDING;
      const { data } = await getCustomerCards(values);

      this.customerCards = data;
      this.filteredCustomerCards = data;

      this.getCustomerCardsState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getCustomerCardsState = PromiseStates.REJECTED;
    }
  };

  @action
  getApartmentNumbers = async (values: {
    customerId: number;
    findBoligNummer?: string;
  }) => {
    try {
      this.getApartmentNumbersState = PromiseStates.PENDING;
      const { data } = await getApartmentNumbers(values);

      this.allApartmentNumbers = data;
      this.getApartmentNumbersState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getApartmentNumbersState = PromiseStates.REJECTED;
    }
  };

  @action
  filterCardsStatus = (values: any) => {
    switch (true) {
      case values.activeFilter === 'active':
        return (this.filteredCustomerCards = this.customerCards.filter(
          (customer: any) =>
            customer.DatoAvsluttet === null &&
            customer.ID.toLowerCase().includes(values.search.toLowerCase())
        ));

      case values.activeFilter === 'unactive':
        return (this.filteredCustomerCards = this.customerCards.filter(
          (customer: any) =>
            customer.DatoAvsluttet !== null &&
            customer.ID.toLowerCase().includes(values.search.toLowerCase())
        ));

      case values.activeFilter === 'all':
        return (this.filteredCustomerCards = this.customerCards.filter(
          (customer: any) =>
            customer.ID.toLowerCase().includes(values.search.toLowerCase())
        ));

      default:
        return (this.filteredCustomerCards = this.customerCards.filter(
          (customer: any) =>
            customer.ID.toLowerCase().includes(values.search.toLowerCase())
        ));
    }
  };

  @action
  getFilteredCustomers = async (values: CustomerFilter) => {
    try {
      this.getCustomersState = PromiseStates.PENDING;

      const availableCustomerTypes = await getAvailableCustomersTypes();
      // const valuesToSend = Object.keys(values).length === 0 ? { currentPage: 0, pageSize: 10 } : values;

      // use getFilteredCustomers() when no search values are provided
      // const response = (Object.keys(values).length === 0) || (values.name === '' || values.address === '' || values.orgNo === '') ? await getFilteredCustomers({ ...values }) : await getAllCustomersParameters({...values});

      const response = await getAllCustomersParameters({ ...values });
      // const response = values.currentPage !== null && values.pageSize !== null && Object.keys(values).length === 2 ? await getFilteredCustomers({ ...values }) : await getAllCustomersParameters({...values});
      // const response = values.name !== '' || values.address !== '' || values.orgNo !== '' || values.id || values.kundeType ? await getAllCustomersParameters(values) : await getFilteredCustomers(values);
      for (const data of response.data) {
        const customerType = availableCustomerTypes.data.find(
          (type: any) => type.PKey === data.PrisGruppeTabellPKey
        );

        data.customerTypeName = customerType ? customerType.Navn : null;
      }

      this.customers = response.data;
      this.customersFilters =
        values.address === '' &&
        values.orgNo === '' &&
        values.kundeType === null &&
        values.id === null
          ? null
          : values;
      this.getCustomersState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getCustomersState = PromiseStates.REJECTED;
    }
  };

  @action
  sortCustomersBy = (
    sortBy: keyof CustomerType,
    order: SortingOrder = 'asc'
  ) => {
    this.sortBy = sortBy;
    this.customers = this.customers.slice().sort((a, b) => {
      let comparison = 0;
      if (a[sortBy] > b[sortBy]) {
        comparison = 1;
      } else if (a[sortBy] < b[sortBy]) {
        comparison = -1;
      }

      return order === 'asc' ? comparison : -comparison;
    });
  };

  @action
  sortCustomersStringBy = (
    sortBy: keyof CustomerType,
    order: SortingOrder = 'asc'
  ) => {
    this.sortBy = sortBy;

    this.customers = this.customers.sort((a, b) => {
      const firstValue = a[sortBy] || '';
      const secondValue = b[sortBy] || '';

      return order === 'asc'
        ? firstValue.localeCompare(secondValue)
        : secondValue.localeCompare(firstValue);
    });
  };

  @action
  getAvailableCustomersTypes = async () => {
    try {
      this.getAvailableCustomersTypesState = PromiseStates.PENDING;
      const { data } = await getAvailableCustomersTypes();
      this.availableCustomersTypes = data;
      this.getAvailableCustomersTypesState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getAvailableCustomersTypesState = PromiseStates.REJECTED;
    }
  };

  @action
  getCustomerTransactions = async (values?: any) => {
    try {
      this.getCustomerTransactionsState = PromiseStates.PENDING;
      const { data } = await getCustomerTransactions({ ...values });
      this.customerTransactions = data;
      this.getCustomerTransactionsState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getCustomerTransactionsState = PromiseStates.REJECTED;
    }
  };

  @action
  getTransactionsDetails = async (customerId?: number, maxResults?: number) => {
    try {
      this.getTransactionsDetailsState = PromiseStates.PENDING;
      const { data } = await getTransactionsDetails(
        {
          Filter: {
            DateTo: new Date().toISOString(),
            CustomerBKey: customerId ? customerId : this.customer!.BKey,
          },
        },
        maxResults
      );
      this.transactionsDetails = data;
      this.getTransactionsDetailsState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getTransactionsDetailsState = PromiseStates.REJECTED;
    }
  };

  @action
  getQuotaSummary = async (customer?: CustomerType, enhetId?: number) => {
    try {
      this.getQuotaSummaryState = PromiseStates.PENDING;
      this.clearCurrentQuota();
      // this.activeQuotaFilter = 'customer';

      // const quotaTypes = await getAvailableQuotaTypes();

      const response = await getQuotaSummary({
        customerBnr: customer ? customer.Bnr : this.customer.Bnr,
        customerBKey: customer ? customer.BKey : this.customer.BKey,
        registerEnhetId: enhetId || null,
      });

      // for (const data of response.data) {
      //   data.unit = quotaTypes.data.find(
      //     (type: any) => type.PKey === data.QuotaPKey
      //   );
      // }

      this.quotaSummary = response.data;

      this.getQuotaSummaryState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getQuotaSummaryState = PromiseStates.REJECTED;
    }
  };

  @action
  getQuotaBalance = async (agreementId: number) => {
    try {
      this.getQuotaBalanceState = PromiseStates.PENDING;

      const response = await getQuotaBalance(agreementId);

      this.quotaBalance = response.data;

      this.getQuotaBalanceState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getQuotaBalanceState = PromiseStates.REJECTED;
    }
  };

  @action
  getQuotasTransactions = async (values: QuotaTransaction) => {
    const startDate = values.dateFrom;

    const endDate = values.dateTo;

    try {
      this.getQuotasTransactionsState = PromiseStates.PENDING;

      const clients = await getClients();

      const response = await getQuotasTransactions({
        customerBnr: this.currentCustomer!.Bnr,
        customerBkey: this.currentCustomer!.BKey,
        startDate: startDate,
        endDate: endDate,
        saleNr: values!.saleNr || '',
        // cardId: values!.cardId || '',
      });

      for (const data of response.data) {
        const transactionLocation = clients.data.find(
          (client: ClientType) => client.PKey === data.Bnr
        );
        data.transactionLocationName = transactionLocation
          ? transactionLocation.Name
          : '';
      }

      this.quotasTransactions = response.data;
      this.getQuotasTransactionsState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getQuotasTransactionsState = PromiseStates.REJECTED;
    }
  };

  @action
  getDistributionData = async () => {
    try {
      this.getDistributionDataState = PromiseStates.PENDING;

      const allCards = await getCustomerCards(this.customer.BKey);

      for (const card of allCards.data) {
        const response = await getQuotaSummary({
          customerBnr: this.customer.Bnr,
          customerBKey: this.customer.BKey,
          cardID: card.ID || '',
        });
        const distributionCardData = response.data;
        distributionCardData.forEach((data: any) => {
          data.cardID = card.ID;
        });
        this.distributionData.push(...response.data);
      }

      this.getDistributionDataState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getDistributionDataState = PromiseStates.REJECTED;
    }
  };

  @action
  getCardQuotaSummary = async (cardId: string) => {
    try {
      this.getCardQuotaSummaryState = PromiseStates.PENDING;
      const { data } = await getQuotaSummary({
        customerBnr: this.customer.Bnr,
        customerBKey: this.customer.BKey,
        cardID: cardId || '',
      });

      this.cardQuotaSummary = data;
      this.getCardQuotaSummaryState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getCardQuotaSummaryState = PromiseStates.REJECTED;
    }
  };

  @action
  getCardQuotas = async () => {
    try {
      this.getCardQuotasState = PromiseStates.PENDING;
      this.clearCurrentQuota();
      // this.activeQuotaFilter = 'card';
      const { data } = await getCustomerCards(this.customer.BKey);
      this.cardQuotas = data;
      this.getCardQuotasState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getCardQuotasState = PromiseStates.REJECTED;
    }
  };

  @action
  getAvailableQuotaTypes = async () => {
    try {
      this.getAvailableQuotaTypesState = PromiseStates.PENDING;
      const { data } = await getAvailableQuotaTypes();
      this.availableQuotaTypes = data;
      this.getAvailableQuotaTypesState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getAvailableQuotaTypesState = PromiseStates.REJECTED;
    }
  };

  @action
  getPostCodes = async (value?: any) => {
    this.getPostCodesState = PromiseStates.PENDING;
    try {
      const { data } = await getPostCodes({
        BKey: 1,
        PKey: value || '',
      });
      this.postCodes = data;
      this.getPostCodesState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getPostCodesState = PromiseStates.REJECTED;
    }
  };

  @action
  getCommuneNumbers = async () => {
    this.getCommuneNumbersState = PromiseStates.PENDING;
    try {
      const { data } = await getCommuneNumbers();
      this.communeNumbers = data;
      this.getCommuneNumbersState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getCommuneNumbersState = PromiseStates.REJECTED;
    }
  };

  @action
  addNewCustomer = async (values: NewCustomerFormValuesType) => {
    this.addNewCustomerState = PromiseStates.PENDING;
    try {
      await addNewCustomer({
        Operation: 10,
        Entity: {
          Bnr: 0,
          BKey: values.id,
          Status: 100,
          PrisGruppeTabellPKey: values.priceTable.value,
          EPost: values.invoiceReference,
          AlternativtNavn: values.alternativeName,
          OrgNr: values.organizationNumber,
          TlfNr: values.phoneNumber,
          KommuneNr: values.communeNumber,
          SNr: values.snr,
          BruksNr: values.bnr,
          GaardsNr: values.gnr,
          FesteNr: values.festeNr,
          Adresse: values.address,
          PostNr: values.postalCode,
          KredittGodkjent: JSON.parse(values.cardPayment),
          Navn: values.invoiceReceiver,
          Info: values.info,
        },
      });
      // this.currentCustomer = response.data.Entity;
      this.addNewCustomerState = PromiseStates.FULFILLED;
      return true;
    } catch (err) {
      this.addNewCustomerErrorMessage = err.data.ExceptionMessage;
      this.addNewCustomerState = PromiseStates.REJECTED;
      return false;
    }
  };

  @action
  addCustomerCard = async (values: any) => {
    this.addCustomerCardState = PromiseStates.PENDING;
    const cardData = {
      KundeBnr: 0,
      KundeBKey: this.currentCustomer?.BKey || null,
      ID: values.scannedCardNumber,
      Kundekortnummer: values.cardNumber,
      DatoAvsluttet: values.expirationDate,
    };

    const apartmentCardData = {
      KundeBnr: 0,
      KundeBKey: this.currentCustomer?.BKey || null,
      ID: values.scannedCardNumber,
      Kundekortnummer: values.cardNumber,
      DatoAvsluttet: values.expirationDate,
      registerEnhetId: values.registerEnhetId,
    };

    try {
      const { data } = await addCustomerCard(
        values.registerEnhetId ? apartmentCardData : cardData
      );

      this.newlyCreatedCustomerCard = data;
      this.addCustomerCardState = PromiseStates.FULFILLED;
    } catch (err) {
      this.addCustomerCardState = PromiseStates.REJECTED;
    }
  };

  @action clearCustomerErrorMessage = () => {
    this.addNewCustomerErrorMessage = '';
  };

  @action
  editCustomer = async (values: EditCustomerFormValuesType) => {
    this.editCustomerState = PromiseStates.PENDING;
    try {
      await editCustomer({
        Operation: 20,
        Entity: {
          Bnr: 0,
          BKey: values.id,
          Status: 100,
          PrisGruppeTabellPKey: values.priceTable.value,
          AlternativtNavn: values.alternativeName,
          OrgNr: values.organizationNumber,
          TlfNr: values.phoneNumber,
          TlfNr2: values.altPhoneNumber,
          KommuneNr: values.communeNumber,
          SNr: values.snr,
          BruksNr: values.bnr,
          GaardsNr: values.gnr,
          FesteNr: values.festeNr,
          Adresse: values.address,
          PostNr: values.postalCode,
          KredittGodkjent: JSON.parse(values.cardPayment.value),
          EPost: values.invoiceReference,
          Navn: values.invoiceReceiver,
          Info: values.info,
        },
      });
      this.editCustomerState = PromiseStates.FULFILLED;
      return true;
    } catch (err) {
      this.editCustomerState = PromiseStates.REJECTED;
      return false;
    }
  };

  @action
  addManualQuota = async (values: any) => {
    try {
      this.addManualQuotaState = PromiseStates.PENDING;
      await addQuotaTransaction({
        KundeBnr: 0,
        KundeBKey: this.currentCustomer!.BKey,
        KvotePKey: values.quotaType.value
          ? parseInt(values.quotaType.value)
          : parseInt(values.quotaType),
        Antall: values.saldo,
        Tekst: values.comment,
        RegisterEnhetID: values.apartmentId,
      });
      this.addManualQuotaState = PromiseStates.FULFILLED;
    } catch (err) {
      this.addManualQuotaState = PromiseStates.REJECTED;
    }
  };

  @action setCurrentCustomer = (rowData: any) => {
    this.currentCustomer = rowData;
    this.customer = rowData;
  };

  @action setCurrentCustomerSearch = (search: string) => {
    console.log(search);
    this.currentCustomerSearch = search;
  };

  @action setCurrentCustomerType = (type: number) => {
    this.currentCustomerType = type;
  };

  @action setIsEditModeActive = (isActive: boolean) => {
    this.isEditModeActive = isActive;
  };

  @action setCurrentCustomerQuota = (rowData: CustomerType) => {
    this.currentCardQuota = null;
    this.currentCustomerQuota = rowData;
  };

  @action setCurrentCardQuota = (rowData: CustomerType) => {
    this.currentCustomerQuota = null;
    this.currentCardQuota = rowData;
  };

  @action clearCurrentQuota = () => {
    this.currentCustomerQuota = null;
    this.currentCardQuota = null;
  };

  @action clearQuotasTransactions = () => {
    this.quotasTransactions = [];
  };

  @action changeFilters = (filters: any) => {
    this.filters = filters;
  };

  @action setActiveQuotaFilter = (filter: string) => {
    this.activeQuotaFilter = filter;
  };

  @action clearCurrentCustomer = () => {
    this.currentCustomer = null;
  };

  @action
  clearCustomer = () => {
    this.customer = [];
    this.currentCustomer = null;
  };

  @action setScannedCardNumber = (value: string) => {
    this.scannedCardNumber = value;
  };

  @action setCurrentApartment = (currentApartment: any) => {
    this.currentApartment = currentApartment;
  };

  @action setCurrentApartmentCardFilter = (currentApartmentCardFilter: any) => {
    this.currentApartmentCardFilter = currentApartmentCardFilter;
  };
}

export const CustomersStoreContext = createContext(new CustomersStore());
const store = new CustomersStore();
export const savedAccessToken = store.savedAccessToken;
