import React from 'react';
import { Paper } from '@material-ui/core';
import { InsertChart } from '@material-ui/icons';
import Chart from 'react-apexcharts';

// STYLES:
import { useStyles } from './index.styles';

const Visitations = () => {
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    grid: {
      show: true,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [4, 0],
    },
    colors: ['#ffd23f', '#636b6f'],
    plotOptions: {
      bar: {
        columnWidth: '40%',
      },
    },
    yaxis: [
      {
        opposite: true,
        title: {
          text: 'Temperature [C]',
        },
      },
      {
        seriesName: 'Visitations',
        title: {
          text: 'Number of Visitations',
        },
      },
    ],
    labels: [
      "Feb' 1",
      "Feb' 2",
      "Feb' 3",
      "Feb' 4",
      "Feb' 5",
      "Feb' 6",
      "Feb' 7",
    ],
    xaxis: {
      type: 'category',
    },
  };

  const series = [
    {
      name: 'Temperature',
      type: 'line',
      data: [12, 18, 10, 8, 12, 19, 22],
    },
    {
      name: 'Visitations',
      type: 'column',
      data: [250, 400, 2590, 1200, 1602, 1802, 765],
    },
  ];

  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <div className={classes.chartContainer}>
        <div className={classes.toolbarContainer}>
          <div className={classes.title}>
            <InsertChart color="primary" />
            <span className={classes.titleText}>Visitations</span>
          </div>
          <div>Last 7 days</div>
        </div>
        <Chart options={options} series={series} />
      </div>
    </Paper>
  );
};

export default Visitations;
