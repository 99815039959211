import React from 'react';
import { isUserAuthenticated } from 'common/helpers/auth';
import { Redirect } from '@reach/router';
import { RouteProps } from './routeTypes';

const MembershipRoute: React.FC<RouteProps> = ({
  component: Component,
  ...rest
}) => {
  // const { savedAccessToken }: ICustomersStore = useContext(CustomersStoreContext);

  if (isUserAuthenticated()) {
    return <Redirect to="/customers" noThrow />;
  } else {
    return <Component {...rest} />;
  }
};

export default MembershipRoute;
