import React, { useContext } from 'react';
import { observer } from 'mobx-react';

// COMPONENTS:
import Overview from 'common/components/Overview';
import ClientGroupOverviewToolbar from './Toolbar';

// STORES:
import {
  ClientGroupsStoreContext,
  IClientGroupsStore,
} from 'stores/clientGroups';

// OVERVIEW DATA
import { sectionsSchema } from '../../ClientGroups/components/Preview/clientGroupsInformation';

const ClientGroupOverview = () => {
  const { currentClientGroup }: IClientGroupsStore = useContext(
    ClientGroupsStoreContext
  );

  return (
    <Overview
      sectionsSchema={sectionsSchema}
      currentItem={currentClientGroup}
      translationsPath="Company.ClientGroups"
      customToolbar={<ClientGroupOverviewToolbar />}
    />
  );
};

export default observer(ClientGroupOverview);
