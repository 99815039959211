import React, { FC, useContext, useEffect } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react';
import { useMutation } from 'react-query';

// COMPONENTS:
import ButtonLoading from 'common/components/buttons/ButtonLoading';
import SelectField from 'common/components/formik/SelectField';

// API:
import {
  addUserWare as addUserWareAPI,
} from 'api/users';

// STORES:
import { IWaresStore, WaresStoreContext } from 'stores/wares';
import { IUsersStore, UsersStoreContext } from 'stores/users';

const useStyles = makeStyles(({ spacing, typography }) => ({
  modalContainer: {
    width: 496,
    height: '100%',
    padding: spacing(5, 8),
  },
  title: {
    paddingBottom: spacing(2),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
}));

interface IAddCustomerWareModal {
  userId: string;
  toggleModal: () => void;
}

const AddCustomerWareModal: FC<IAddCustomerWareModal> = ({
                                                           userId,
                                                           toggleModal,
                                                         }) => {
  const classes = useStyles();

  const { getCurrentUserWares }: IUsersStore = useContext(
    UsersStoreContext
  );

  const { getWares, wares, getWaresState }: IWaresStore = useContext(WaresStoreContext);

  useEffect(() => {
    getWares();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const waresOptions = wares?.map(option => ({
    value: option.BKey!.toString(),
    label: option.Navn || '',
  }));

  const { mutate: addCustomerWare } = useMutation(
    async (values: any) => await addUserWareAPI(values),
    {
      onSuccess: () => toggleModal(),
      onSettled: async () => await getCurrentUserWares(userId),
    }
  );

  const handleSubmit = (values: any) => {
    addCustomerWare({
      UserPkey: userId,
      VareBkey: values.product,
    });
  };

  return (
    <div className={classes.modalContainer}>
      <Typography variant="h4" className={classes.title}>
        <FormattedMessage id="Company.User.addWare" />
      </Typography>
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        {({ isSubmitting }) => {
          return (
            <Form className={classes.form}>
              <Grid container>
                <Grid item xs={12} style={{ margin: '16px 0' }}>
                  <SelectField
                    state={getWaresState}
                    name="product"
                    options={waresOptions}
                    isClearable
                    placeholder={<FormattedMessage id="Global.form.placeholders.product" />}
                    label={<FormattedMessage id="Global.form.labels.product" />}
                  />
                </Grid>
              </Grid>
              <ButtonLoading
                type="submit"
                value="Global.addButton"
                state={isSubmitting}
                disabled={isSubmitting}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default observer(AddCustomerWareModal);
