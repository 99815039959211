import React, { ReactNode } from 'react';
import { TableRow } from '@material-ui/core';

export interface IDefaultRow<T> {
  children: ReactNode;
}

const DefaultRow = <T extends object>({ children }: IDefaultRow<T>) => {
  return <TableRow>{children}</TableRow>;
};

export default DefaultRow;
