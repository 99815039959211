import React, { useContext, useState } from 'react';
import { makeStyles, TableCell, TableSortLabel } from '@material-ui/core';
import { observer } from 'mobx-react';

// TYPES:
import { IColumn, SortingOrder } from 'common/components/grid/types';
import { ClientGroupType } from 'api/clientGroups/types';

// STORE:
import {
  ClientGroupsStoreContext,
  IClientGroupsStore,
} from 'stores/clientGroups';

// ICONS
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

interface ISortableHeader {
  children: React.ReactNode;
  column: IColumn<ClientGroupType>;
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  header: {
    background: palette.common.white,
    borderBottom: 'none',
    paddingBottom: spacing(1),
    color: palette.secondary.light,
  },
}));

const SortableNumericHeader: React.FC<ISortableHeader> = ({
  children,
  column,
}) => {
  const classes = useStyles();
  const [order, setOrder] = useState<SortingOrder>('asc');

  const { sortBy, sortClientGroupsBy }: IClientGroupsStore = useContext(
    ClientGroupsStoreContext
  );

  const handleClick = () => {
    if (order === 'asc') {
      sortClientGroupsBy(column.id, 'desc');
      setOrder('desc');
    } else {
      sortClientGroupsBy(column.id, 'asc');
      setOrder('asc');
    }
  };

  return (
    <TableCell classes={{ stickyHeader: classes.header }}>
      <TableSortLabel
        active={sortBy === column.id}
        direction={sortBy === column.id ? order : 'asc'}
        onClick={handleClick}
        IconComponent={KeyboardArrowDownIcon}
      >
        {children}
      </TableSortLabel>
    </TableCell>
  );
};

export default observer(SortableNumericHeader);
