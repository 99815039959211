import React, { ReactNode, useContext } from 'react';
import { makeStyles, TableRow } from '@material-ui/core';
import { observer } from 'mobx-react';

// STORE
import { CustomersStoreContext, ICustomersStore } from 'stores/customers';

// TYPES
import { CustomerType } from 'api/customers/types';

interface ICustomerRow {
  rowData: CustomerType;
  children: ReactNode;
}

const useStyles = makeStyles(({ palette }) => ({
  row: {
    padding: 0,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: palette.primary.light,
    },
  },
  selected: {
    backgroundColor: `${palette.primary.light} !important`,
  },
}));

const CustomerRow: React.FC<ICustomerRow> = ({ rowData, children }) => {
  const { setCurrentCustomer, currentCustomer }: ICustomersStore = useContext(
    CustomersStoreContext
  );

  const isActive = currentCustomer?.BKey === rowData.BKey;
  const classes = useStyles(isActive);

  const handleClick = () => {
    setCurrentCustomer(rowData);
  };

  return (
    <TableRow
      onClick={handleClick}
      className={isActive ? `${classes.row} ${classes.selected}` : classes.row}
    >
      {children}
    </TableRow>
  );
};

export default observer(CustomerRow);
