import React from 'react';
import { Typography } from '@material-ui/core';
import ButtonLoading from 'common/components/buttons/ButtonLoading';
import { Remove } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import { UserType } from '../../../../../../api/users/types';
import { makeStyles } from '@material-ui/core/styles';
import { useModal } from '../../../../../../common/helpers/useModal';
import RemoveUserRoleModal from './RemoveUserRoleModal';

const useStyles = makeStyles(({ spacing, palette, typography }) => ({
  removeIcon: {
    fontSize: 18,
  },
  roleRow: {
    paddingTop: spacing(1.5),
    display: 'flex',
    alignItems: 'center',
  },
  disconnectButton: {
    border: `1px solid ${palette.error.main}`,
    color: palette.error.main,
    background: palette.common.white,
    marginLeft: spacing(2),
    '&:hover': {
      background: palette.error.main,
      color: palette.common.white,
    },
  },
}));

const UserRoleRow = ({
  role,
  currentUser,
}: {
  role: string;
  currentUser: UserType;
}) => {
  const classes = useStyles();

  const { toggleModal, RenderModal } = useModal();

  return (
    <div className={classes.roleRow}>
      <Typography variant="body2">{role}</Typography>
      <ButtonLoading
        variant="contained"
        size="small"
        onClick={toggleModal}
        className={classes.disconnectButton}
      >
        <Remove className={classes.removeIcon} />
        <FormattedMessage id="Company.User.disconnect" />
      </ButtonLoading>
      <RenderModal>
        <RemoveUserRoleModal
          role={role}
          toggleModal={toggleModal}
          userId={currentUser.PKey}
        />
      </RenderModal>
    </div>
  );
};

export default UserRoleRow;
