import React from 'react';
import { TableCell } from '@material-ui/core';
import { ICell, IGridTableSchema } from 'common/components/grid/types';
import { getNestedValue } from 'common/helpers/getNestedValue';
import { WareType } from 'api/wares/types';

interface IPriceWithVATCell<T> {
  row: IGridTableSchema<T>['row'];
  cell: ICell<T>;
  rowData: T;
}

const PriceWithVATCell = <T extends object>({
  rowData,
  row,
  cell,
}: IPriceWithVATCell<WareType>) => {
  const price = rowData.VeilPris! * getNestedValue('navMVAUt.Faktor', rowData);

  return (
    <TableCell key={`${row.id}-${cell.customKey || cell.key}`}>
      {price && Math.ceil(price * 100) / 100}
    </TableCell>
  );
};

export default PriceWithVATCell;
