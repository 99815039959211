import { createContext } from 'react';
import { observable, action } from 'mobx';

// API:
import {
  getEmployeeById,
  getEmployees,
  saveEmployee,
  getActiveEmployees,
} from 'api/employees';

// TYPES:
import {
  EmployeeType,
  EmployeesType,
  NewEmployeeFormValuesType,
  FiltersType,
} from 'api/employees/types';
import { LoadingState, PromiseStates } from 'api/types';
import { SortingOrder } from 'common/components/grid/types';

export interface IEmployeesStore {
  // Observables
  employees: EmployeesType;
  getEmployeesState: LoadingState;
  getActiveEmployeesState: LoadingState;
  filtersValues: any;
  filters: any;
  sortBy?: keyof EmployeeType;
  order?: SortingOrder;

  employee: EmployeeType | null;
  getEmployeeByIdState: LoadingState;
  addNewEmployeeState: LoadingState;
  editEmployeeState: LoadingState;
  saveEmployeeError: string;

  currentEmployee: EmployeeType | null;

  // Actions
  getEmployees: () => Promise<void>;
  getActiveEmployees: () => Promise<void>;
  changeFilters: (filters: FiltersType) => void;
  addNewEmployee: (values: NewEmployeeFormValuesType) => Promise<boolean>;
  editEmployee: (values: NewEmployeeFormValuesType) => Promise<boolean>;
  setCurrentEmployee: (rowData: EmployeeType) => void;
  getEmployeeById: (id: string) => Promise<void>;
  clearEmployeeError: () => void;
  sortEmployeesBy: (sortBy: keyof EmployeeType, order: SortingOrder) => void;
  sortEmployeesStringsBy: (
    sortBy: keyof EmployeeType,
    order: SortingOrder
  ) => void;
}

class EmployeesStore implements IEmployeesStore {
  @observable employees: IEmployeesStore['employees'] = [];
  @observable getEmployeesState: IEmployeesStore['getEmployeesState'] = null;
  @observable
  getActiveEmployeesState: IEmployeesStore['getActiveEmployeesState'] = null;
  @observable
  addNewEmployeeState: IEmployeesStore['addNewEmployeeState'] = null;
  @observable
  editEmployeeState: IEmployeesStore['editEmployeeState'] = null;
  @observable
  saveEmployeeError: IEmployeesStore['saveEmployeeError'] = '';
  @observable currentEmployee: IEmployeesStore['currentEmployee'] = null;

  @observable employee: IEmployeesStore['employee'] = null;
  @observable filtersValues: IEmployeesStore['filtersValues'] = null;
  @observable filters: IEmployeesStore['filters'] = null;
  @observable
  getEmployeeByIdState: IEmployeesStore['getEmployeeByIdState'] = null;
  @observable sortBy: IEmployeesStore['sortBy'] = 'BKey';

  @action
  getEmployees = async () => {
    try {
      this.getEmployeesState = PromiseStates.PENDING;

      const { data } = await getEmployees(this.filtersValues);
      this.employees = data;

      this.getEmployeesState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getEmployeesState = PromiseStates.REJECTED;
    }
  };

  @action
  getActiveEmployees = async () => {
    try {
      this.getActiveEmployeesState = PromiseStates.PENDING;

      const { data } = await getActiveEmployees(this.filtersValues);
      this.employees = data;

      this.getActiveEmployeesState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getActiveEmployeesState = PromiseStates.REJECTED;
    }
  };

  @action
  getEmployeeById = async (id: string) => {
    try {
      this.getEmployeesState = PromiseStates.PENDING;

      const { data } = await getEmployeeById({
        '%24filter': `BKey+eq+'${id}'`,
      });
      this.currentEmployee = data[0];
      this.getEmployeesState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getEmployeesState = PromiseStates.REJECTED;
    }
  };

  @action changeFilters = (filters: FiltersType) => {
    const searchName = `substringof('${filters.searchName}'%2c+Navn)`;

    const role = filters.role ? `+and+RollePKey+eq+${filters.role}` : '';

    this.filters = !filters.role ? null : filters;

    this.filtersValues =
      this.filters === null && filters.searchName === ''
        ? null
        : {
            '%24filter': `${searchName}${role}`,
            '%24format': 'json',
          };
  };

  @action
  addNewEmployee = async (values: NewEmployeeFormValuesType) => {
    this.addNewEmployeeState = PromiseStates.PENDING;
    try {
      await saveEmployee({
        Operation: 10,
        Entity: {
          Bnr: 0,
          BKey: values.id,
          Navn: values.name,
          SynligNavn: values.visibleName,
          RollePKey: values.role.value
            ? JSON.parse(values.role.value)
            : values.role,
          TlfNr: values.phoneNumber,
          MobNr: values.altPhoneNumber,
          EPost: values.email,
          Passord: values.password,
          Adresse: values.address,
          PostNr: values.postNr,
        },
      });
      this.addNewEmployeeState = PromiseStates.FULFILLED;
      return true;
    } catch (err) {
      this.saveEmployeeError = err.data.Message;
      this.addNewEmployeeState = PromiseStates.REJECTED;
      return false;
    }
  };

  @action
  editEmployee = async (values: NewEmployeeFormValuesType) => {
    this.editEmployeeState = PromiseStates.PENDING;
    try {
      await saveEmployee({
        Operation: 20,
        Entity: {
          Bnr: 0,
          BKey: values.id,
          Navn: values.name,
          SynligNavn: values.visibleName,
          RollePKey: JSON.parse(values.role.value),
          TlfNr: values.phoneNumber,
          MobNr: values.altPhoneNumber,
          EPost: values.email,
          Passord: values.password,
          Adresse: values.address,
          PostNr: values.postNr,
        },
      });
      this.editEmployeeState = PromiseStates.FULFILLED;
      return true;
    } catch (err) {
      this.saveEmployeeError = err.data.Message;
      this.editEmployeeState = PromiseStates.REJECTED;
      return false;
    }
  };

  @action setCurrentEmployee = (rowData: EmployeeType) => {
    this.currentEmployee = rowData;
  };

  @action clearEmployeeError = () => {
    this.saveEmployeeError = '';
  };

  @action
  sortEmployeesBy = (
    sortBy: keyof EmployeeType,
    order: SortingOrder = 'asc'
  ) => {
    this.sortBy = sortBy;
    this.employees = this.employees.slice().sort((a, b) => {
      let comparison = 0;
      if (a[sortBy]! > b[sortBy]!) {
        comparison = 1;
      } else if (a[sortBy]! < b[sortBy]!) {
        comparison = -1;
      }

      return order === 'asc' ? comparison : -comparison;
    });
  };

  @action
  sortEmployeesStringsBy = (
    sortBy: keyof EmployeeType,
    order: SortingOrder = 'asc'
  ) => {
    this.sortBy = sortBy;

    this.employees = this.employees.sort((a, b) => {
      const firstValue = a[sortBy]?.toString() || '';
      const secondValue = b[sortBy]?.toString() || '';

      return order === 'asc'
        ? firstValue.toString().localeCompare(secondValue)
        : secondValue.localeCompare(firstValue);
    });
  };
}

export const EmployeesStoreContext = createContext(new EmployeesStore());
