import React from 'react';
import { TableCell, Typography } from '@material-ui/core';

// COMPONENTS
import { ICell, IGridTableSchema } from 'common/components/grid/types';
import { FormattedMessage } from 'react-intl';

interface IStatusCell<T> {
  row: IGridTableSchema<T>['row'];
  cell: ICell<T>;
  rowData: any;
}

const RoleCell = <T extends object>({ rowData, row, cell }: IStatusCell<T>) => {
  return (
    <TableCell key={`${row.id}-${cell.key}`}>
      {(() => {
        switch (true) {
          case rowData.RollePKey === 0:
            return (
              <Typography variant="body2">
                <FormattedMessage id="Company.Employees.roles.admin" />
              </Typography>
            );

          case rowData.RollePKey === 1:
            return (
              <Typography variant="body2">
                <FormattedMessage id="Company.Employees.roles.leder" />
              </Typography>
            );

          case rowData.RollePKey === 2:
            return (
              <Typography variant="body2">
                <FormattedMessage id="Company.Employees.roles.standard" />
              </Typography>
            );

          default:
            return null;
        }
      })()}
    </TableCell>
  );
};

export default RoleCell;
