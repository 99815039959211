import { ComponentType } from 'react';

// TYPES
import { EmployeeType } from 'api/employees/types';

// COMPONENTS
import StatusFormatter from './StatusFormatter';

export interface IEmployeesInformationsSectionSchema {
  label: string;
  value?: keyof EmployeeType;
  formatter?: ComponentType<any>;
}

export interface ISectionSchema {
  label: string;
  data: IEmployeesInformationsSectionSchema[];
}

export const generalSectionSchema: IEmployeesInformationsSectionSchema[] = [
  {
    label: 'status',
    formatter: StatusFormatter,
  },
  {
    label: 'id',
    value: 'BKey',
  },
  {
    label: 'name',
    value: 'Navn',
  },
  {
    label: 'visibleName',
    value: 'SynligNavn',
  },
  {
    label: 'role',
    value: 'RollePKey',
  },
  {
    label: 'password',
    value: 'Passord',
  },
];

export const contactSectionSchema: IEmployeesInformationsSectionSchema[] = [
  {
    label: 'address',
    value: 'Adresse',
  },
  {
    label: 'postalCode',
    value: 'PostNr',
  },
  {
    label: 'email',
    value: 'EPost',
  },
  {
    label: 'phoneNumber',
    value: 'TlfNr',
  },
  {
    label: 'altPhoneNumber',
    value: 'MobNr',
  },
];

export const sectionsSchema: ISectionSchema[] = [
  {
    label: 'general',
    data: generalSectionSchema,
  },
  {
    label: 'contact',
    data: contactSectionSchema,
  },
];
