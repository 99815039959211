import React, { useContext } from 'react';
import { Grid, makeStyles, Typography, IconButton } from '@material-ui/core';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { Form, Formik } from 'formik';

// ICONS:
import CloseIcon from '@material-ui/icons/Close';

// STORES:
import { CustomersStoreContext, ICustomersStore } from 'stores/customers';

// IMAGES:
import cardPreview from 'assets/images/card-preview.png';

// COMPONENTS:
import InputField from 'common/components/formik/InputField';
import ButtonLoading from 'common/components/buttons/ButtonLoading';

// TYPES:
import { CustomerType } from 'api/customers/types';
import ClearIcon from '@material-ui/icons/Clear';

interface ICompaniesCardDetailsModal {
  toggle: () => void;
  intl: IntlShape;
  cardDetails: any;
  customer: CustomerType;
  getCards: () => void;
}

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  stepContainer: {
    height: '100%',
    padding: spacing(4, 3),
    position: 'relative',
    zIndex: 100000,
  },
  stepInfo: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'baseline',
    paddingTop: spacing(6),
  },
  formLabel: {
    padding: spacing(4, 0, 1, 0),
  },
  title: {
    color: palette.secondary.main,
  },
  submitButton: {
    marginTop: spacing(14),
  },
  inputRoot: {
    fontSize: 13,
    border: `1px solid ${palette.secondary.light}`,
    borderRadius: '4px',
    transition:
      'border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
  },
  inputInput: {
    padding: '8px',
  },
  inputError: {
    borderColor: palette.error.main,
    '&.Mui-focused': {
      borderColor: palette.error.main,
    },
  },
  inputFocused: {
    borderColor: palette.primary.main,
    borderWidth: 1,
  },
  inputDisabled: {
    backgroundColor: palette.action.disabledBackground,
    borderColor: palette.action.disabledBackground,
    color: palette.action.disabled,
  },
  commentField: {
    paddingBottom: spacing(7),
    width: '321px !important',
  },
  infoIcon: {
    paddingRight: spacing(1),
  },
  cardPreviewContainer: {
    boxShadow: '0px 0px 16px 0px rgba(0,0,0,0.15)',
    marginBottom: spacing(3),
    background: `url(${cardPreview})`,
    width: '321px',
    height: '204px',
  },
  cardPreview: {
    borderRadius: 4.8,
    width: '321px',
    height: '204px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  button: {
    padding: '12px 24px',
    right: '42%',
    position: 'absolute',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
  },
  closeIcon: {
    padding: 0,
    '& Svg': {
      color: palette.secondary.main,
    },
  },
  clearIcon: {
    padding: 0,
    '& Svg': {
      color: palette.secondary.main,
      marginTop: spacing(1.8),
      height: 24,
      transition: '.4s background ease-in-out',
      '&:hover': {
        opacity: 0.5,
      },
    },
  },
  customFieldsArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '19px 19px 0 23px',
  },
  cardText: {
    fontSize: '10px',
    fontWeight: typography.fontWeightMedium,
  },
  scannedCardNumber: {
    paddingBottom: spacing(2),
  },
  cardDisplayData: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  rowHeader: {
    fontWeight: typography.fontWeightMedium,
    color: palette.secondary.light,
  },
  date: {
    margin: spacing(0, 2),
    display: 'flex',
  },
  apartmentNumber: {
    paddingTop: spacing(2),
  },
}));

const CompaniesCardDetailsModal: React.FC<ICompaniesCardDetailsModal> = ({
  getCards,
  toggle,
  cardDetails,
  customer,
}) => {
  const classes = useStyles();

  const { addCustomerCard }: ICustomersStore = useContext(
    CustomersStoreContext
  );

  const editCustomerCard = async (values: any) => {
    const cardData = {
      expirationDate: values.expirationDate,
      cardNumber: cardDetails.Kundekortnummer,
      scannedCardNumber: cardDetails.ID,
    };

    // addCustomerCard request is also used for editing customer card
    await addCustomerCard(cardData);
    toggle();
    getCards();
  };

  const initialExpirationDate = cardDetails.DatoAvsluttet
    ? cardDetails?.DatoAvsluttet?.substring(0, 10)
    : '';

  return (
    <Grid className={classes.stepContainer}>
      <div className={classes.modalHeader}>
        <Typography variant="h3" className={classes.title}>
          <FormattedMessage id="Customer.Cards.cardPreview" />
        </Typography>
        <IconButton className={classes.closeIcon} onClick={toggle}>
          <CloseIcon />
        </IconButton>
      </div>
      <Grid item xs={12} className={classes.stepInfo}>
        <div className={classes.cardPreviewContainer}>
          <div className={classes.cardPreview}>
            <div className={classes.customFieldsArea}>
              <Typography className={classes.cardText}>
                Navn: {customer.Navn}
              </Typography>
              <Typography className={classes.cardText}>
                Avtalenr: {customer.BKey}
              </Typography>
              <Typography className={classes.cardText}>
                Kortnr: {cardDetails.Kundekortnummer}
              </Typography>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <Grid item xs={6} className={classes.cardDisplayData}>
            <Typography variant="body2" className={classes.rowHeader}>
              <FormattedMessage id="Customer.Cards.uidFromScannedCard" />
            </Typography>
            <Typography variant="body2">{cardDetails.DisplayID}</Typography>
          </Grid>

          <Grid item xs={6} style={{ marginTop: 4 }}>
            <Formik
              initialValues={{ expirationDate: initialExpirationDate }}
              onSubmit={editCustomerCard}
            >
              {({ isSubmitting, setFieldValue }) => {
                return (
                  <Form>
                    <Grid container>
                      <Grid item className={classes.date}>
                        <InputField
                          name="expirationDate"
                          label={
                            <FormattedMessage id="Global.form.labels.expirationDate" />
                          }
                          type="date"
                        />
                        <IconButton
                          className={classes.clearIcon}
                          onClick={() =>
                            setFieldValue('expirationDate', '', false)
                          }
                        >
                          <ClearIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <div style={{ width: '100%', paddingTop: 32 }}>
                      <ButtonLoading
                        className={classes.button}
                        state={isSubmitting}
                        disabled={isSubmitting}
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        <FormattedMessage id="Customer.Cards.save" />
                      </ButtonLoading>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default injectIntl(CompaniesCardDetailsModal);
