import React, { useCallback, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

// TYPES:
import { CustomerType } from 'api/customers/types';

// COMPONENTS:
import GridTable from 'common/components/grid/GridTable';
import { IGridTableSchema } from 'common/components/grid/types';
import CustomerRow from './CustomerRow';
import Toolbar from './Toolbar';
import CustomerTypeCellFormatter from './CustomerTypeCellFormatter';
import Footer from './Footer';
import DetailsCell from '../DetailsCell';
import SortableNumericHeader from './SortableNumericHeader';
import SortableStringHeader from './SortableStringHeader';

// STORE:
import { CustomersStoreContext, ICustomersStore } from 'stores/customers';

// HELPERS:
import { useDebounce } from 'common/helpers/useDebounce';

const schema: IGridTableSchema<CustomerType> = {
  header: {
    columns: [
      {
        id: 'BKey',
        name: <FormattedMessage id="Customers.List.tableHeaders.id" />,
        formatter: SortableNumericHeader,
      },
      {
        id: 'Navn',
        name: (
          <FormattedMessage id="Customers.List.tableHeaders.invoiceReceiver" />
        ),
        formatter: SortableStringHeader,
      },
      {
        id: 'AlternativtNavn',
        name: <FormattedMessage id="Customers.List.tableHeaders.address" />,
        formatter: SortableStringHeader,
      },
      {
        id: 'KommuneNr',
        name: <FormattedMessage id="Customers.List.tableHeaders.municipal" />,
      },
      {
        id: 'KundeType',
        name: <FormattedMessage id="Customers.List.tableHeaders.type" />,
      },
      {
        id: 'BoligType',
        name: 'Bolig type',
      },
    ],
  },
  row: {
    id: 'BKey',
    formatter: CustomerRow,
    cells: [
      {
        key: 'BKey',
      },
      {
        key: 'Navn',
      },
      {
        key: 'AlternativtNavn',
      },
      {
        key: 'KommuneNr',
      },
      {
        key: 'KundeType',
        formatter: CustomerTypeCellFormatter,
      },
      {
        key: 'BoligType',
      },
      {
        customKey: 'openDetails',
        formatter: DetailsCell,
      },
    ],
  },
};

const CustomersTable = () => {
  const {
    customers,
    getFilteredCustomers,
    getCustomersState,
    customersFilters,
    currentCustomerSearch,
    setCurrentCustomerSearch,
  }: ICustomersStore = useContext(CustomersStoreContext);

  const [search, setSearch] = useState(currentCustomerSearch);
  const [page, setPage] = useState(0);
  const [selectedCustomerType, setSelectedCustomerType] = useState('');
  const [customersToDisplay, setCustomersToDisplay] = useState<any[]>([]);

  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    if (customers) {
      page === 0
        ? setCustomersToDisplay(customers)
        : setCustomersToDisplay(prevState => [...prevState, ...customers]);
    }
  }, [customers, page, customersFilters]);

  const getData = useCallback(
    () => {
      return getFilteredCustomers({
        ...customersFilters,
        name: search,
        address: search,
        orgNo: search,
        boligType: parseInt(selectedCustomerType),
        id: parseInt(search),
        currentPage: page,
        pageSize: 100,
        IncludeCustomerType: true,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearch, selectedCustomerType, page]
  );

  const hasNextPage = customers[0]?.nextPage !== null || false;

  const handleSearch = (search: string) => {
    setCurrentCustomerSearch(search);
    setSearch(search);
  };
  const tableData = Array.from(new Set(customersToDisplay));

  return (
    <GridTable
      tableSchema={schema}
      data={tableData}
      loadingState={getCustomersState}
      customToolbar={
        <Toolbar
          setPage={setPage}
          setSearch={handleSearch}
          search={search}
          setSelectedCustomerType={setSelectedCustomerType}
          selectedCustomerType={selectedCustomerType}
        />
      }
      getData={getData}
      footer={
        <Footer
          page={page}
          setPage={setPage}
          isVisible={hasNextPage && customers.length > 0}
        />
      }
    />
  );
};

export default observer(CustomersTable);
