import React from 'react';

// TYPES
import { WareType } from 'api/wares/types';
import { FormattedMessage } from 'react-intl';

interface IStatusFormatter {
  currentItem: WareType;
}

const StatusFormatter: React.FC<IStatusFormatter> = ({ currentItem }) => {
  return (
    <>
      {currentItem.Status === 0 ? (
        <FormattedMessage id="Global.active" />
      ) : (
        <FormattedMessage id="Global.deactivated" />
      )}{' '}
    </>
  );
};

export default StatusFormatter;
