import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

// COMPONENTS:
import SingleSection from './SingleSection';

// ICONS:
import DevicesOutlinedIcon from '@material-ui/icons/DevicesOutlined';

// STYLES:
import { useStyles } from './index.styles';

// DATA:
import {
  generalSectionSchema,
  IPricetableInformationsSectionSchema,
} from './pricetablesInformations';

// TYPES:
import { PricetableType } from 'api/pricetables/types';

interface IPricetableInformations {
  currentPricetable: PricetableType;
}

interface ISectionSchema {
  label: string;
  data: IPricetableInformationsSectionSchema[];
}

const sectionsSchema: ISectionSchema[] = [
  {
    label: 'general',
    data: generalSectionSchema,
  },
];

const PricetableInformations: React.FC<IPricetableInformations> = ({
  currentPricetable,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {!currentPricetable ? (
        <div className={classes.selectPricetablePlaceholder}>
          <DevicesOutlinedIcon className={classes.selectPricetableIcon} />
          <FormattedMessage id="Company.Pricetables.selectedPricetablePlaceholder" />
        </div>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <Typography variant="h4" className={classes.heading}>
                <FormattedMessage
                  id="Company.Pricetables.header"
                  values={{
                    b: (chunk: string) => (
                      <b className={classes.accent}>{chunk}</b>
                    ),
                    value: currentPricetable.Navn,
                  }}
                />
              </Typography>
            </Grid>
          </Grid>

          {sectionsSchema.map(el => (
            <Grid container spacing={2} key={el.label}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" className={classes.header}>
                  <FormattedMessage id={`Company.Pricetables.${el.label}`} />
                </Typography>
              </Grid>
              <SingleSection
                data={el.data}
                currentPricetable={currentPricetable}
              />
            </Grid>
          ))}
        </>
      )}
    </div>
  );
};

export default PricetableInformations;
