import React, { Component } from 'react';
import 'tableau-api';
import { getAccessToTableau } from '../../../api/tableau';

class TableauChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ticket: '',
    };
  }

  componentDidMount() {
    getAccessToTableau().then(res => {
      this.setState({ ticket: res.data });
      this.initViz();
    });
  }

  initViz() {
    const vizUrl = `https://tableau.vspdemo.no/trusted/${this.state.ticket}/views/Test-db-Int-01/Demo-Db-2_1?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=y#16`;
    const vizContainer = this.vizContainer;
    const options = {
      hideTabs: true,
      hideToolbar: true,
      width: '45vw',
      height: '500px',
    };

    this.viz = new window.tableau.Viz(vizContainer, vizUrl, options);
  }

  render() {
    return (
      <div style={{ marginTop: 20 }}>
        <div
          ref={div => {
            this.vizContainer = div;
          }}
        />
      </div>
    );
  }
}

export default TableauChart;
