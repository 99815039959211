import { ComponentType } from 'react';

// TYPES
import { ClientGroupType } from 'api/clientGroups/types';

// COMPONENTS
import IdFormatter from './idFormatter';

export interface IClientGroupsInformationSectionSchema {
  label: string;
  value?: keyof ClientGroupType;
  formatter?: ComponentType<any>;
}

export interface ISectionSchema {
  label: string;
  data: IClientGroupsInformationSectionSchema[];
}

export const generalSectionSchema: IClientGroupsInformationSectionSchema[] = [
  {
    label: 'id',
    formatter: IdFormatter,
  },
  {
    label: 'name',
    value: 'Name',
  },
];

export const vippsSectionSchema: IClientGroupsInformationSectionSchema[] = [
  {
    label: 'clientId',
    value: 'VippsClientId',
  },
  {
    label: 'subscriptionKey',
    value: 'VippsClientSubKey',
  },
  {
    label: 'clientSecret',
    value: 'VippsClientSecret',
  },
  {
    label: 'merchantId',
    value: 'VippsMerchantId',
  },
];

export const emailSectionSchema: IClientGroupsInformationSectionSchema[] = [
  {
    label: 'username',
    value: 'EmailUsername',
  },
  {
    label: 'address',
    value: 'EmailFromAddress',
  },
  {
    label: 'password',
    value: 'EmailPassword',
  },
  {
    label: 'port',
    value: 'MailPort',
  },
  {
    label: 'SMTPServer',
    value: 'SMTPServer',
  },
  {
    label: 'SSL',
    value: 'SSL',
  },
];

export const sectionsSchema: ISectionSchema[] = [
  {
    label: 'general',
    data: generalSectionSchema,
  },
  {
    label: 'vipps',
    data: vippsSectionSchema,
  },
  {
    label: 'email',
    data: emailSectionSchema,
  },
];
