import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { Form, Formik } from 'formik';

// STORES:
import { CustomersStoreContext, ICustomersStore } from 'stores/customers';

// TYPES:
import { PromiseStates } from 'api/types';

// COMPONENTS:
import SelectField from 'common/components/formik/SelectField';
import InputField from 'common/components/formik/InputField';
import ButtonLoading from 'common/components/buttons/ButtonLoading';
import DefaultLoadingPlaceholder from 'common/components/grid/DefaultLoadingPlaceholder';

interface IFormValues {
  radioGroup: string;
  quotaType: any;
  saldo: number;
  cardID: any;
  comment: string;
}

export enum QuotaFilters {
  CUSTOMER = 'customer',
  CARD = 'card',
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  modalContainer: {
    width: 496,
    height: '100%',
    minHeight: 403,
    padding: spacing(5, 8),
  },
  title: {
    paddingBottom: spacing(2),
  },
  sectionTitle: {
    color: palette.secondary.light,
    paddingBottom: spacing(1),
  },
  selectInfo: {
    padding: spacing(1, 0, 2),
    color: palette.primary.main,
    fontWeight: 700,
  },
  saldoInfo: {
    fontSize: 12,
    color: palette.action.disabled,
    paddingTop: spacing(0.5),
  },
  accent: {
    fontWeight: 400,
    color: palette.secondary.dark,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  submitButton: {
    marginTop: spacing(12.5),
  },
  cardSelectInput: {
    paddingBottom: spacing(2),
  },
  gridTableContainer: {
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    // boxShadow: 'none',
  },
}));

interface IManualQuotaModal {
  intl: IntlShape;
  toggleModal: () => void;
  defaultCardValue?: any;
  defaultQuotaTypeValue?: any;
  defaultRadioValue?: any;
}

const ManualQuotaModal: React.FC<IManualQuotaModal> = ({
  intl,
  toggleModal,
  defaultCardValue,
  defaultQuotaTypeValue,
  defaultRadioValue,
}) => {
  // eslint-disable-next-line
  const [selectedRadioValue] = React.useState(defaultRadioValue || 'Agreement');

  const {
    availableQuotaTypes,
    customerCards,
    addManualQuota,
    getQuotaSummary,
    getCardQuotaSummary,
    setActiveQuotaFilter,
    getAvailableQuotaTypes,
    getAvailableQuotaTypesState,
    clearCurrentQuota,
  }: ICustomersStore = useContext(CustomersStoreContext);

  const classes = useStyles();

  const quotaTypeOptions = availableQuotaTypes?.map(type => ({
    value: type.PKey!.toString(),
    label: type.Navn!,
  }));

  useEffect(() => {
    getAvailableQuotaTypes();
  }, [getAvailableQuotaTypes]);

  const cardOptions = customerCards
    ? customerCards?.map(card => ({
        value: card.ID!,
        label: `${card.ID} ${card.Tekst ? card.Tekst : ''}`,
      }))
    : [];

  const initialValues: IFormValues = {
    radioGroup: selectedRadioValue,
    quotaType: defaultQuotaTypeValue
      ? quotaTypeOptions.find(
          option => option.value === defaultQuotaTypeValue.toString()
        )
      : '',
    saldo: 0,
    cardID: defaultCardValue
      ? cardOptions.find(option => option.value === defaultCardValue.ID)
      : '',
    comment: '',
  };

  const handleSubmit = async (values: IFormValues) => {
    await addManualQuota(values);
    toggleModal();
    clearCurrentQuota();

    if (selectedRadioValue === 'Agreement') {
      setActiveQuotaFilter(QuotaFilters.CUSTOMER);
      await getQuotaSummary();
    } else {
      setActiveQuotaFilter(QuotaFilters.CARD);
      await getCardQuotaSummary(values.cardID);
    }
  };

  return (
    <div
      className={classes.modalContainer}
      onClick={(e: any) => e.stopPropagation()}
    >
      <Typography variant="h4" className={classes.title}>
        <FormattedMessage id="Agreement.Quotas.manualCustomerQuota.add" />
      </Typography>

      {getAvailableQuotaTypesState === PromiseStates.FULFILLED ? (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ isSubmitting }) => {
            return (
              <Form className={classes.form}>
                <Grid container>
                  <Grid item xs={12}>
                    <SelectField
                      name="quotaType"
                      options={quotaTypeOptions}
                      defaultValue={initialValues.quotaType}
                      label={
                        <FormattedMessage
                          id={`Agreement.Quotas.manualCustomerQuota.quotaType`}
                        />
                      }
                    />
                    <Typography variant="body2" className={classes.selectInfo}>
                      <FormattedMessage
                        id="Agreement.Quotas.manualCustomerQuota.quotaTypeInfo"
                        values={{
                          b: (value: string) => (
                            <b className={classes.accent}>{value}</b>
                          ),
                          value: '1000 kg',
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <InputField
                      name="saldo"
                      placeholder={intl.formatMessage({
                        id: 'Global.form.placeholders.balance',
                      })}
                      label={
                        <FormattedMessage id={`Global.form.labels.balance`} />
                      }
                      type="number"
                    />
                    <Typography className={classes.saldoInfo}>
                      <FormattedMessage id="Agreement.Quotas.manualCustomerQuota.adjustBalanceDescription" />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ margin: '16px 0' }}>
                    <InputField
                      name="comment"
                      placeholder={intl.formatMessage({
                        id: 'Global.form.placeholders.comment',
                      })}
                      label={
                        <FormattedMessage id={`Global.form.labels.comment`} />
                      }
                    />
                  </Grid>
                </Grid>
                <ButtonLoading
                  type="submit"
                  value="Global.addButton"
                  state={isSubmitting}
                  disabled={isSubmitting}
                />
              </Form>
            );
          }}
        </Formik>
      ) : (
        <DefaultLoadingPlaceholder />
      )}
    </div>
  );
};

export default injectIntl(observer(ManualQuotaModal));
