import React, { FC } from 'react';
import { Button, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { useModal } from '../../../../../../common/helpers/useModal';

// COMPONENTS:
import AddNewUserRoleModal from './AddNewUserRoleModal';
import { useGetUserRoles } from '../../../queries';
import UserRoleRow from './UserRoleRow';
import DataLoader from 'common/components/DataLoader';

// TYPES:
import { UserType } from 'api/users/types';

const useStyles = makeStyles(({ spacing, palette, typography }) => ({
  container: {
    paddingTop: 16,
    height: '100%',
  },
  row: {
    paddingTop: spacing(3),
  },
  headerWithButton: {
    display: 'flex',
  },
  addIcon: {
    fontSize: 18,
  },
  addButton: {
    marginLeft: spacing(2),
  },
  roleRow: {
    paddingTop: spacing(1.5),
    display: 'flex',
    alignItems: 'center',
  },
  disconnectButton: {
    border: `1px solid ${palette.error.main}`,
    color: palette.error.main,
    background: palette.common.white,
    marginLeft: spacing(2),
    '&:hover': {
      background: palette.error.main,
      color: palette.common.white,
    },
  },
  modalContainer: {
    width: 496,
    height: '100%',
    padding: spacing(5, 8),
  },
  title: {
    paddingBottom: spacing(2),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  addRoleButton: {
    marginTop: spacing(1.5),
  },
}));

interface UserRolesInterface {
  currentUser: UserType;
}

const UserRoles: FC<UserRolesInterface> = ({ currentUser }) => {
  const classes = useStyles();

  const { toggleModal, RenderModal } = useModal();

  const { data: currentUserRoles, isFetching } = useGetUserRoles(
    currentUser!.PKey,
    currentUser
  );

  return (
    <>
      <div className={classes.headerWithButton}>
        <Typography variant="subtitle1">
          <FormattedMessage id="Company.Users.roles" />
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={toggleModal}
          className={classes.addButton}
        >
          <AddOutlinedIcon className={classes.addIcon} />
          <FormattedMessage id="Company.User.addRole" />
        </Button>
      </div>
      {isFetching ? (
        <DataLoader />
      ) : currentUserRoles?.length ? (
        currentUserRoles?.map(role => (
          <UserRoleRow
            role={role!.Name}
            currentUser={currentUser}
          />
        ))
      ) : (
        '-'
      )}

      <RenderModal>
        <div className={classes.modalContainer}>
          <AddNewUserRoleModal
            currentUser={currentUser}
            toggleModal={toggleModal}
          />
        </div>
      </RenderModal>
    </>
  );
};

export default UserRoles;
