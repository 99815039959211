import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useParams } from '@reach/router';

// COMPONENTS
import TabularUserView from './components/TabularUserView';
import Header from 'common/components/Header';

// STORE
import { IUsersStore, UsersStoreContext } from '../../../../stores/users';

// TYPES:

const User = () => {
  const { currentUser, getUserById }: IUsersStore = useContext(
    UsersStoreContext
  );

  const params = useParams();

  const useStyles = makeStyles(({ spacing }) => ({
    container: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      padding: spacing(2),
    },
    header: {
      marginBottom: '-10px',
      zIndex: 2,
    },
  }));

  useEffect(() => {
    if (!currentUser) {
      getUserById(params.userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserById, params.userId]);

  const breadcrumbs = [
    {
      name: 'Users',
      path: '/users',
    },
    {
      name: 'User Details',
      path: `/users/${params.userId}`,
    },
  ];

  const classes = useStyles();

  return (
    currentUser && (
      <div className={classes.container}>
        <Header
          breadcrumbs={breadcrumbs}
          title={currentUser?.Name}
          className={classes.header}
        />
        <TabularUserView currentCustomer={currentUser!} />
      </div>
    )
  );
};

export default observer(User);
