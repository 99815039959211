import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import DefaultValue from './DefaultValue';

const useStyles = makeStyles(({ spacing, palette, typography }) => ({
  header: {
    paddingTop: spacing(1),
  },
  root: {
    height: '100%',
  },
  paperContainer: {
    height: '100%',
    overflow: 'auto',
    padding: spacing(3),
  },
  placeholder: {
    textAlign: 'center',
    color: palette.action.disabledBackground,
    fontSize: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 700,
    height: '100%',
  },
  placeholderIcon: {
    '& Svg': {
      fontSize: 240,
      color: palette.primary.contrastText,
    },
  },
  rowHeader: {
    fontWeight: typography.fontWeightBold,
    color: palette.secondary.light,
  },
  row: {
    borderBottom: `1px solid ${palette.action.disabledBackground}`,
    '&:last-of-type': {
      borderBottom: 'none',
    },
  },
  rowElement: {
    paddingBottom: spacing(4),
  },
  toolbar: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
}));

interface ISectionSchema {
  label: string;
  data: any[];
}

// TODO: add types
interface IModuleOverview {
  sectionsSchema: ISectionSchema[];
  translationsPath?: string;
  currentItem: any;
  placeholderIcon?: any;
  placeholderText?: any;
  customToolbar?: any;
  hasRows?: boolean;
}

// TODO: add custom formatter to Overview component
const ModuleOverview: React.FC<IModuleOverview> = ({
  sectionsSchema,
  translationsPath,
  currentItem,
  placeholderIcon,
  placeholderText,
  customToolbar,
  hasRows,
  children,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paperContainer}>
        {!currentItem ? (
          <div className={classes.placeholder}>
            <div className={classes.placeholderIcon}>{placeholderIcon}</div>
            {placeholderText}
          </div>
        ) : (
          <Grid container>
            <div className={classes.toolbar}>{customToolbar}</div>

            {sectionsSchema.map(section => (
              <Grid container key={section.label} className={classes.row}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" className={classes.header}>
                    <FormattedMessage
                      id={`${translationsPath}.${section.label}`}
                    />
                  </Typography>
                </Grid>

                {section.data.map(sectionElement => {
                  const Value = sectionElement.formatter || DefaultValue;
                  return (
                    <Grid
                      item
                      xs={hasRows ? 4 : 2}
                      key={sectionElement.label}
                      className={classes.rowElement}
                    >
                      <Typography variant="body2" className={classes.rowHeader}>
                        <FormattedMessage
                          id={`Global.form.labels.${sectionElement.label}`}
                        />
                      </Typography>

                      <Value
                        currentItem={currentItem}
                        sectionElement={sectionElement}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            ))}
            {children}
          </Grid>
        )}
      </Paper>
    </div>
  );
};

export default ModuleOverview;
