// TYPES
import { UserDetailsSectionSchemaType } from 'api/users/types';

// COMPONENTS
import StatusFormatter from './StatusFormatter';

interface ISectionSchema {
  label: string;
  data: UserDetailsSectionSchemaType[];
}

export const generalSectionSchema: UserDetailsSectionSchemaType[] = [
  {
    label: 'id',
    value: 'PKey',
  },
  {
    label: 'username',
    value: 'Username',
  },
  {
    label: 'name',
    value: 'Name',
  },
  {
    label: 'email',
    value: 'Email',
  },
  {
    label: 'phoneNumber',
    value: 'Phone',
  },
  {
    label: 'status',
    formatter: StatusFormatter,
  },
];

export const overviewSectionsSchema: ISectionSchema[] = [
  {
    label: 'general',
    data: generalSectionSchema,
  },
];
