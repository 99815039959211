import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

// COMPONENTS:
import ClientModal from './components/EditClientModal';
import Header from 'common/components/Header';
import ClientsTable from './components/Table';
import PreviewToolbar from './components/PreviewToolbar';
import Overview from 'common/components/Overview';
import {
  accountingSectionSchema,
  generalSectionSchema,
  IClientInformationsSectionSchema,
  vippsSectionSchema,
} from './components/Preview/clientInformations';

// STYLES:
import { useStyles } from './index.styles';

// STORE
import { ClientsStoreContext, IClientsStore } from 'stores/clients';

// ICONS:
import DevicesOutlinedIcon from '@material-ui/icons/DevicesOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';

interface ISectionSchema {
  label: string;
  data: IClientInformationsSectionSchema[];
}

const sectionsSchema: ISectionSchema[] = [
  {
    label: 'general',
    data: generalSectionSchema,
  },
  {
    label: 'accounting',
    data: accountingSectionSchema,
  },
  {
    label: 'vipps',
    data: vippsSectionSchema,
  },
];

const Clients = () => {
  const classes = useStyles();

  const breadcrumbs = [
    {
      name: 'Clients List',
      path: '/clients',
    },
  ];

  const { currentClient }: IClientsStore = useContext(ClientsStoreContext);

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={classes.container}>
      <Header
        breadcrumbs={breadcrumbs}
        title={<FormattedMessage id="Company.Clients.clients" />}
      >
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.addButton}
          onClick={toggleModal}
        >
          <AddOutlinedIcon />
          <FormattedMessage id="Company.Clients.addClient" />
        </Button>
      </Header>
      <Grid container className={classes.contentContainer}>
        <Grid item xs={12} sm={12} md={6} className={classes.column}>
          <ClientsTable />
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={classes.column}>
          <Overview
            sectionsSchema={sectionsSchema}
            currentItem={currentClient}
            translationsPath="Company.Clients"
            placeholderIcon={<DevicesOutlinedIcon />}
            placeholderText={
              <FormattedMessage id="Company.Clients.selectedClientPlaceholder" />
            }
            customToolbar={<PreviewToolbar currentClient={currentClient!} />}
            hasRows
          />
        </Grid>
      </Grid>

      {isOpen && <ClientModal isOpen={isOpen} toggleModal={toggleModal} />}
    </div>
  );
};

export default observer(Clients);
