import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ typography, palette, spacing }) => ({
  selectCardPlaceholder: {
    color: palette.action.disabledBackground,
    fontSize: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 700,
    height: '100%',
  },
  selectCardIcon: {
    fontSize: 240,
    color: palette.primary.contrastText,
  },
  gridTable: {
    boxShadow: 'none',
    padding: `0px 24px`,
  },
  header: {
    display: 'flex',
    alignItems: 'end',
  },
  overviewRow: {
    padding: spacing(0, 3),
    display: 'flex'
  },
  heading: {
    fontWeight: typography.fontWeightBold,
    marginBottom: spacing(2),
  },
  rowHeader: {
    fontWeight: typography.fontWeightBold,
    color: palette.secondary.light,
  },
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0px 12px',
    padding: `0px 24px`,
  },
  row: {
    borderBottom: `1px solid ${palette.action.disabledBackground}`,
    '&:last-of-type': {
      borderBottom: 'none',
    },
  },
  gridTableWrapper: {
    flexGrow: 1,
    overflow: 'unset'
  },
}));
