import React from 'react';

// TYPES
import { WareType } from 'api/wares/types';
import { FormattedMessage } from 'react-intl';

interface IChangeOnSaleFormatter {
  currentItem: WareType;
}

const ChangeOnSaleFormatter: React.FC<IChangeOnSaleFormatter> = ({
  currentItem,
}) => {
  return (
    <>
      {currentItem.EndreVedSalg === false ? (
        <FormattedMessage id="Global.no" />
      ) : (
        <FormattedMessage id="Global.yes" />
      )}
    </>
  );
};

export default ChangeOnSaleFormatter;
