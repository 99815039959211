import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette, spacing, constants }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  subheader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 1,
    marginLeft: (isNavigationOpen: boolean) =>
      isNavigationOpen ? '0' : '12px',
    padding: (isNavigationOpen: boolean) =>
      isNavigationOpen ? spacing(0, 2) : spacing(2),
  },
  drawerList: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    color: palette.common.white,
  },
  drawerListIcon: {
    color: palette.common.white,
    minWidth: 0,
    width: '24px !important',
  },
  drawerListText: {
    color: palette.common.white,
    fontSize: 14,
    paddingLeft: spacing(1.5),
    marginTop: 0,
    marginBottom: 0,
  },
  menuIcon: {
    color: palette.common.white,
    padding: 0,
  },
  drawer: {
    background: palette.secondary.dark,
    width: (isNavigationOpen: boolean) =>
      isNavigationOpen
        ? constants.sideBarOpenWidth
        : constants.sideBarCloseWidth,
  },
  bottomList: {
    display: 'flex',
    flexDirection: 'column'
  },
  logo: {
    width: 96,
    padding: spacing(2, 0),
  },
  nested: {
    paddingLeft: spacing(1),
  },
}));
