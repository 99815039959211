import React from 'react';

// HELPERS
import { getNestedValue } from 'common/helpers/getNestedValue';

// TYPES
import { WareType } from 'api/wares/types';

interface IPriceWithVATFormatter {
  currentItem: WareType;
}

const PriceWithVATFormatter: React.FC<IPriceWithVATFormatter> = ({
  currentItem,
}) => {
  const price =
    currentItem.VeilPris! * getNestedValue('navMVAUt.Faktor', currentItem);

  return <>{price && Math.ceil(price * 100) / 100}</>;
};

export default PriceWithVATFormatter;
