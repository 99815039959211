import React from 'react';
import { makeStyles, Typography, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import { injectIntl, IntlShape } from 'react-intl';

// COMPONENTS:
import SearchInput from 'common/components/SearchInput';

// TYPES
interface IToolbar {
  intl: IntlShape;
  search: string;
  setSearch: (value: string) => void;
}

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  header: {
    padding: spacing(3),
    display: 'flex',
    alignItems: 'end',
  },
  heading: {
    fontWeight: typography.fontWeightBold,
  },
  addButton: {
    display: 'flex',
    '& Svg': {
      width: 18,
    },
  },
  modal: {
    backgroundColor: palette.primary.main,
  },
  right: {
    display: 'flex',
  },
  filtersRow: {
    paddingTop: spacing(2),
    display: 'flex',
    width: '100%',
  },
  search: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  select: {
    zIndex: 3,
  },
}));

const Toolbar: React.FC<IToolbar> = ({ intl, search, setSearch }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.header}>
      <Grid item xs={8}>
        <Typography variant="h5" className={classes.heading}>
          <FormattedMessage id="Customer.Apartments.heading" />
        </Typography>
      </Grid>

      <div className={classes.filtersRow}>
        <Grid item xs={8} className={classes.search}>
          <SearchInput
            placeholder={intl.formatMessage({
              id: 'Customers.List.searchPlaceholder',
            })}
            onChange={e => setSearch(e.target.value)}
            value={search}
          />
        </Grid>
      </div>
    </Grid>
  );
};

export default observer(injectIntl(Toolbar));
