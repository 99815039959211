import React from 'react';
import { TableCell } from '@material-ui/core';
import { FormattedDate } from 'react-intl';

// COMPONENTS
import { ICell, IGridTableSchema } from 'common/components/grid/types';

interface IDateCell<T> {
  row: IGridTableSchema<T>['row'];
  cell: ICell<T>;
  rowData: any;
}

const DateCell = <T extends object>({ rowData, row, cell }: IDateCell<T>) => {
  return (
    <TableCell key={`${row.id}-${cell.key}-date`}>
      <FormattedDate value={new Date(rowData[cell.key])} />
    </TableCell>
  );
};

export default DateCell;
