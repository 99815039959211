import request from '../request';
import queryString from 'query-string';

export const getAllClientGroups = (values?: any) =>
  request({
    method: 'GET',
    url: `/admin/clients/GetAllClientGroups?${queryString.stringify(values, {
      encode: false,
    })}`,
  });

export const getClientById = (id: number | null) =>
  request({
    method: 'GET',
    url: `/admin/clients/GetClient/${id}`,
  });

export const getClients = (values?: any) =>
  request({
    url: `/admin/clients/GetAllClients?${queryString.stringify(values, {
      encode: false,
    })}`,
    method: 'GET',
  });

export const saveClient = (values: any) =>
  request({
    method: 'POST',
    url: '/admin/clients/SaveClient',
    data: {
      ...values,
    },
  });
