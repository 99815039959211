import request from '../request';
import { EmployeeType, SaveEmployeeType } from './types';
import queryString from 'query-string';

export const getEmployees = (values?: any) =>
  request({
    url: `/admin/employees/GetAllEmployees?${queryString.stringify(values, {
      encode: false,
    })}`,
    method: 'GET',
  });

export const getActiveEmployees = (values?: any) =>
  request({
    url: `/admin/employees/GetAllActiveEmployees?${queryString.stringify(values, {
      encode: false,
    })}`,
    method: 'GET',
  });

export const getEmployeeById = (values: any) =>
  request({
    url: `/admin/employees/GetAllEmployees?${queryString.stringify(values, {
      encode: false,
    })}`,
    method: 'GET',
  });

export const addNewEmployee = (values: EmployeeType) =>
  request({
    method: 'POST',
    url: '/admin/employees/SaveEmployee',
    data: {
      ...values,
    },
  });

export const saveEmployee = (values: SaveEmployeeType) =>
  request({
    method: 'POST',
    url: '/admin/employees/PostEmployeeChangeset',
    data: {
      ...values,
    },
  });
