import React, { ComponentType } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

// TYPES
import { CustomerType } from 'api/customers/types';

interface IStatusSectionElement {
  label: string;
  value?: keyof CustomerType;
  formatter?: ComponentType<any>;
}

interface ICardPaymentFormatter {
  currentItem: CustomerType;
  sectionElement: IStatusSectionElement;
}

const useStyles = makeStyles(({ palette }) => ({
  active: {
    color: palette.primary.main,
  },
  deactivated: {
    color: palette.error.main,
  },
}));

const CardPaymentFormatter: React.FC<ICardPaymentFormatter> = ({
  currentItem,
}) => {
  const classes = useStyles();

  return (
    <>
      {currentItem?.KredittGodkjent ? (
        <Typography className={classes.active}>
          <FormattedMessage id="Customers.List.enabled" />
        </Typography>
      ) : (
        <Typography className={classes.deactivated}>
          <FormattedMessage id="Customers.List.disabled" />
        </Typography>
      )}
    </>
  );
};

export default CardPaymentFormatter;
