import React, { useCallback, useContext, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

// STORE:
import { IWastebinsStore, WastebinsStoreContext } from 'stores/wastebins';

// TYPES:
import { IGridTableSchema } from 'common/components/grid/types';
import { WastebinType } from 'api/wastebins/types';

// HELPERS:
import { useDebounce } from '../../../../common/helpers/useDebounce';

// COMPONENTS:
import GridTable from 'common/components/grid/GridTable';
import DateCell from 'common/components/grid/DateCell';
import Toolbar from './Toolbar';
import Footer from './Footer';

const schema: IGridTableSchema<WastebinType> = {
  header: {
    columns: [
      {
        id: 'DateCreated',
        name: <FormattedMessage id="Wastebins.tableHeaders.dateCreated" />,
      },
      {
        id: 'AgreementNumber',
        name: <FormattedMessage id="Wastebins.tableHeaders.agreementNr" />,
      },
      {
        id: 'CustomerName',
        name: <FormattedMessage id="Wastebins.tableHeaders.customerName" />,
      },
      {
        id: 'Address',
        name: <FormattedMessage id="Wastebins.tableHeaders.streetName" />,
      },
      {
        id: 'Municipality',
        name: <FormattedMessage id="Wastebins.tableHeaders.municipality" />,
      },
      {
        id: 'Id',
        name: <FormattedMessage id="Wastebins.tableHeaders.wasteBinId" />,
      },
      {
        id: 'WasteType',
        name: <FormattedMessage id="Wastebins.tableHeaders.wasteType" />,
      },
      {
        id: 'BinType',
        name: <FormattedMessage id="Wastebins.tableHeaders.binType" />,
      },
      {
        id: 'LastPrinted',
        name: <FormattedMessage id="Wastebins.tableHeaders.lastPrinted" />,
      },
    ],
  },
  row: {
    id: 'Id',
    cells: [
      {
        key: 'DateCreated',
        formatter: DateCell,
      },
      {
        key: 'AgreementNumber',
      },
      {
        key: 'CustomerName',
      },
      {
        key: 'Address',
      },
      {
        key: 'Municipality',
      },
      {
        key: 'Id',
      },
      {
        key: 'WasteType',
      },
      {
        key: 'BinType',
      },
      {
        key: 'LastPrinted',
        formatter: DateCell,
      },
    ],
  },
};

export enum SearchFilters {
  CustomerName = 'CustomerName',
  Address = 'Address',
  AgreementNumber = 'AgreementNumber',
  Barcode = 'Code128',
}

const Table = () => {
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [selectedSearchFilter, setSelectedSearchFilter] = React.useState(
    SearchFilters.CustomerName
  );

  const [selectedFilters, setSelectedFilters] = useState({
    wasteType: '',
    binType: '',
    dateFrom: '',
    dateTo: '',
  });

  const [currentlyVisibleWastebins, setCurrentlyVisibleWastebins] = useState<
    any[]
  >([]);

  const debouncedSearch = useDebounce(search, 500);

  const {
    getAllWastebins,
    allWastebins,
    getAllWastebinsState,
  }: IWastebinsStore = useContext(WastebinsStoreContext);

  const getData = useCallback(
    () => {
      // Render object key depending on selected search filter
      const searchFilter = {
        ...(selectedSearchFilter === SearchFilters.CustomerName && {
          CustomerName: search,
        }),
        ...(selectedSearchFilter === SearchFilters.Address && {
          Address: search,
        }),
        ...(selectedSearchFilter === SearchFilters.Barcode && {
          Code128: search,
        }),
        ...(selectedSearchFilter === SearchFilters.AgreementNumber && {
          AgreementNumber: parseInt(search),
        }),
      };

      return getAllWastebins({
        page: page,
        pageSize: 100,
        WasteType: selectedFilters.wasteType,
        BinType: selectedFilters.binType,
        DateCreatedFrom: selectedFilters.dateFrom,
        DateCreatedTo: selectedFilters.dateTo,
        ...searchFilter,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearch, page, selectedFilters, selectedSearchFilter]
  );

  useEffect(() => {
    if (allWastebins) {
      page === 0
        ? setCurrentlyVisibleWastebins(allWastebins?.Result.$values)
        : setCurrentlyVisibleWastebins(prevState => [
            ...prevState,
            ...allWastebins?.Result.$values,
          ]);
    }
  }, [allWastebins, page]);

  const tableData = Array.from(new Set(currentlyVisibleWastebins));

  return (
    <GridTable
      tableSchema={schema}
      data={tableData}
      loadingState={getAllWastebinsState}
      getData={getData}
      customToolbar={
        <Toolbar
          selectedSearchFilter={selectedSearchFilter}
          setSelectedSearchFilter={setSelectedSearchFilter}
          setPage={setPage}
          setSearch={setSearch}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
      }
      footer={
        <Footer
          page={page}
          setPage={setPage}
          isVisible={allWastebins?.CurrentPage! < allWastebins?.TotalPages!}
        />
      }
    />
  );
};

export default observer(Table);
