import React, { useContext } from 'react';
import { Form, Formik } from 'formik';
import { Button, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

// COMPONENTS:
import SelectField from 'common/components/formik/SelectField';

// STYLES:
import { useStyles } from './index.styles';

// STORES:
import { EmployeesStoreContext, IEmployeesStore } from 'stores/employees';

interface IFormValues {
  role: any;
}

interface IFiltersForm {
  searchValue: string;
}

const FiltersForm: React.FC<IFiltersForm> = ({ searchValue }) => {
  const { getEmployees, changeFilters, filters }: IEmployeesStore = useContext(
    EmployeesStoreContext
  );

  const classes = useStyles();

  // CONSTANTS
  const roleOptions = [
    { value: '0', label: 'Admin' },
    { value: '1', label: 'Leder' },
    { value: '2', label: 'Standard' },
  ];

  const initialValues: IFormValues = {
    role:
      (filters?.role &&
        roleOptions.find(option => option.value === filters.role)) ||
      null,
  };

  const handleSubmit = async (values: any) => {
    changeFilters({
      ...values,
      searchName: searchValue,
    });

    await getEmployees();
  };

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {() => {
          return (
            <Form>
              <Grid container spacing={4} className={classes.root}>
                <Grid item xs={12} sm={4}>
                  <SelectField
                    name="role"
                    options={roleOptions}
                    defaultValue={initialValues.role}
                    placeholder={
                      <FormattedMessage id="Company.Employees.allPlaceholder" />
                    }
                    label={<FormattedMessage id="Company.Employees.role" />}
                    isClearable
                  />
                </Grid>
              </Grid>
              <Grid container justify="space-between" spacing={4}>
                <Grid item>
                  <Button
                    variant="text"
                    type="reset"
                    className={classes.clearButton}
                  >
                    <FormattedMessage id="Global.clearFilters" />
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    <FormattedMessage id="Global.apply" />
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default FiltersForm;
