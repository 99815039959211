import React, { useContext, useEffect } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { Button, Grid } from '@material-ui/core';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { observer } from 'mobx-react';

// COMPONENTS
import InputField from 'common/components/formik/InputField';
import SelectField from 'common/components/formik/SelectField';

// STYLES
import { useStyles } from './index.styles';

// STORES
import { ClientsStoreContext, IClientsStore } from 'stores/clients';

// TYPES
import { CustomerFilter } from 'api/customers/types';

interface IFormValues {
  clientStatus: any;
  idFrom: string;
  idTo: string;
  address: string;
  clientGroup: any;
}

interface IFiltersForm {
  intl: IntlShape;
  searchValue: string;
}

const FiltersForm: React.FC<IFiltersForm> = ({ intl, searchValue }) => {
  const classes = useStyles();

  const {
    getAllClientGroups,
    allClientGroups,
    getClients,
    changeFilters,
    filters,
  }: IClientsStore = useContext(ClientsStoreContext);

  useEffect(() => {
    getAllClientGroups();
  }, [getAllClientGroups]);

  const allClientGroupsOptions = allClientGroups?.map(type => ({
    value: type.PKey!.toString(),
    label: type.Name,
  }));

  // CONSTANTS
  const statusOptions = [
    { value: '100', label: 'Active' },
    { value: '0', label: 'Deactivated' },
  ];

  const initialValues: IFormValues = {
    clientStatus:
      (filters?.clientStatus &&
        statusOptions.find(option => option.value === filters.clientStatus)) ||
      '',
    idFrom: filters?.idFrom || '',
    idTo: filters?.idTo || '',
    address: filters?.address || '',
    clientGroup:
      (filters?.clientGroup &&
        allClientGroupsOptions.find(
          option => option.value === filters.clientGroup
        )) ||
      '',
  };

  const handleSubmit = async (values: IFormValues) => {
    changeFilters({ ...values, searchName: searchValue });
    await getClients();
  };

  const handleReset = async (
    resetForm: FormikHelpers<CustomerFilter>['resetForm']
  ) => {
    changeFilters({
      clientStatus: '',
      idFrom: '',
      idTo: '',
      clientGroup: '',
      address: '',
      searchName: searchValue,
    });
    resetForm();
    await getClients();
  };

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ resetForm }) => {
          return (
            <Form className={classes.form}>
              <Grid
                container
                justify="space-between"
                spacing={4}
                className={classes.root}
              >
                <Grid item xs={12} sm={4} className={classes.input}>
                  <SelectField
                    name="clientStatus"
                    options={statusOptions}
                    placeholder={
                      <FormattedMessage id="Company.Employees.allPlaceholder" />
                    }
                    label={
                      <FormattedMessage id="Company.Clients.clientStatus" />
                    }
                    defaultValue={initialValues.clientStatus}
                    isClearable
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  style={{ paddingRight: 8 }}
                  className={classes.input}
                >
                  <InputField
                    name="idFrom"
                    placeholder={intl.formatMessage({
                      id: 'Global.form.placeholders.idFrom',
                    })}
                    label={
                      <FormattedMessage id="Global.form.placeholders.idFrom" />
                    }
                    key={filters?.idFrom}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  style={{ paddingLeft: 8 }}
                  className={classes.input}
                >
                  <InputField
                    name="idTo"
                    placeholder={intl.formatMessage({
                      id: 'Global.form.placeholders.idTo',
                    })}
                    label={
                      <FormattedMessage id="Global.form.placeholders.idTo" />
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4} className={classes.input}>
                  <SelectField
                    name="clientGroup"
                    options={allClientGroupsOptions}
                    defaultValue={initialValues.clientGroup}
                    placeholder={
                      <FormattedMessage id="Company.Employees.allPlaceholder" />
                    }
                    label={
                      <FormattedMessage id="Company.Clients.clientGroup" />
                    }
                    isClearable
                  />
                </Grid>
                <Grid item xs={12} sm={4} className={`${classes.input} ${classes.addressInput}`} >
                  <InputField
                    name="address"
                    placeholder={intl.formatMessage({
                      id: 'Global.form.placeholders.address',
                    })}
                    label={<FormattedMessage id="Global.form.labels.address" />}
                    key={filters?.address}
                  />
                </Grid>
              </Grid>
              <Grid container justify="space-between" spacing={4}>
                <Grid item>
                  <Button
                    variant="text"
                    type="reset"
                    className={classes.clearButton}
                    onClick={() => handleReset(resetForm)}
                  >
                    <FormattedMessage id="Global.clearFilters" />
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    <FormattedMessage id="Global.apply" />
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default injectIntl(observer(FiltersForm));
