import { useQuery } from 'react-query';
import { getTotalNumberOfApartments } from '../../api/customers';
import { CustomersStoreContext, ICustomersStore } from '../../stores/customers';
import { useContext } from 'react';
import { CustomerType, CustomerTypesNames } from '../../api/customers/types';

export const useGetTotalNumberOfApartments = (
  customer: CustomerType,
  enabledOption: boolean = true,
  queryFilter: any = undefined
) => {
  const { setCurrentCustomer }: ICustomersStore = useContext(
    CustomersStoreContext
  );

  const isApartment = customer
    ? customer!.BoligType === CustomerTypesNames.Flermannsbolig
    : false;

  console.log(customer);
  return useQuery(
    ['totalNumberOfApartments', queryFilter],
    async () => {
      const res = await getTotalNumberOfApartments(customer.BKey);
      return res.data;
    },
    {
      enabled: isApartment && enabledOption,
      onSuccess: res => {
        setCurrentCustomer({ ...customer, totalNumberOfApartments: res });
      },
    }
  );
};
