import React from 'react';

// TYPES:
import { RouteComponentProps } from '@reach/router';
import { Grid } from '@material-ui/core';

// STYLES:
import { useStyles } from './index.styles';

// COMPONENTS:
import WasteCollectionForecast from './components/WasteCollectionForecast';
import TypeOfCustomers from './components/TypeOfCustomers';
import Visitations from './components/Visitations';
import SmallerCharts from './components/SmallerCharts';

const Dashboard: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12} lg={6} className={classes.cell}>
          <WasteCollectionForecast />
        </Grid>
        <Grid item xs={12} lg={6} className={classes.cell}>
          <TypeOfCustomers />
        </Grid>
        <Grid item xs={12} lg={6} className={classes.cell}>
          <Visitations />
        </Grid>
        <Grid item xs={12} lg={6} className={classes.cell}>
          <SmallerCharts />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
