import React, { useContext, useEffect } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { useParams } from '@reach/router';

// STYLES
import { useStyles } from './index.styles';

// COMPONENTS
import QuotasCustomerTable from './components/QuotasCustomerTable';
import SelectedQuotaDetails from './components/SelectedQuotaDetails';
import Toolbar from './components/Toolbar';

// STORE
import { CustomersStoreContext, ICustomersStore } from 'stores/customers';

const Quotas = () => {
  const classes = useStyles();

  const {
    currentCustomerQuota,
    getCustomerCards,
    getAvailableQuotaTypes,
    customer,
    getQuotaSummary,
    quotaSummary,
    getQuotaSummaryState,
  }: ICustomersStore = useContext(CustomersStoreContext);

  const params = useParams();

  useEffect(() => {
    getQuotaSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCustomerCards, getAvailableQuotaTypes, params.customerId]);

  return (
    <Grid container className={classes.gridContainer}>
      <Grid item xs={12} sm={6} className={classes.allQuotasTableColumn}>
        <Paper className={classes.quotasContainer}>
          <Toolbar />
          {customer && (
            <QuotasCustomerTable
              getData={getQuotaSummary}
              data={quotaSummary}
              loadingState={getQuotaSummaryState}
            />
          )}
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6} className={classes.quotaOverviewColumn}>
        <Paper className={classes.paper}>
          {!currentCustomerQuota ? (
            <div className={classes.selectCardPlaceholder}>
              <FormattedMessage id="Customer.Quotas.chooseQuota" />
            </div>
          ) : (
            <SelectedQuotaDetails />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default observer(Quotas);
