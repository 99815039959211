import React from 'react';
import { makeStyles } from '@material-ui/core';

// COMPONENTS:
import GridTable from 'common/components/grid/GridTable';
import EditCell from './EditCell';
import QuotasRow from './QuotasRow';

// TYPES:
import { FormattedMessage } from 'react-intl';

const customerQuotasSchema: any = {
  wrapper: ({ children, ...rest }: any) => <div {...rest}>{children}</div>,
  header: {
    columns: [
      {
        id: 'QuotaName',
        name: <FormattedMessage id="Customer.Quotas.tableHeaders.quotaType" />,
      },
      {
        id: 'StartQuantity',
        name: <FormattedMessage id="Customer.Quotas.tableHeaders.startQuota" />,
      },
      {
        id: 'Quantity',
        name: <FormattedMessage id="Customer.Quotas.tableHeaders.rest" />,
      },
      {
        id: 'QuotaUnit',
        name: <FormattedMessage id="Customer.Quotas.tableHeaders.unit" />,
      },
      {
        id: 'customCellKey',
        name: '',
      },
    ],
  },
  row: {
    id: 'QuotaPKey',
    formatter: QuotasRow,
    cells: [
      {
        key: 'QuotaName',
      },
      {
        key: 'StartQuantity',
      },
      {
        key: 'Quantity',
      },
      {
        key: 'QuotaUnit',
        // formatter: UnitsCell,
      },
      {
        customKey: 'editCell',
        formatter: EditCell,
      },
    ],
  },
};

const useStyles = makeStyles(() => ({
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0px 12px',
    padding: `0px 24px`,
  },
}));

const QuotasCustomerTable: React.FC<any> = ({
  data,
  getData,
  loadingState,
}) => {
  const classes = useStyles();

  return (
    <GridTable
      tableSchema={customerQuotasSchema}
      data={data}
      loadingState={loadingState}
      tableClassname={classes.table}
      getData={getData}
    />
  );
};

export default QuotasCustomerTable;
