import React, { useContext } from 'react';
import { Formik, Form } from 'formik';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Typography,
  FormControl,
  FormGroup,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';

// IMAGES:
import logo from 'assets/images/logo-vsp-miljo.png';

// TYPES:
import { RouteComponentProps } from '@reach/router';

// COMPONENTS
import Alert from 'common/components/Alert';
import InputField from 'common/components/formik/InputField';
import ButtonLoading from 'common/components/buttons/ButtonLoading';
import { CustomersStoreContext, ICustomersStore } from '../../stores/customers';

interface IFormValues {
  username: string;
  password: string;
}

const Login: React.FC<RouteComponentProps> = () => {
  // STYLES
  const useStyles = makeStyles(({ typography, palette, spacing }) => ({
    container: {
      minHeight: '100vh',
      padding: 0,
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: palette.background.default,
      fontFamily: typography.fontFamily,
      boxShadow: '0px 0px 16px 0px rgba(0,0,0,0.2)',
    },
    card: {
      borderRadius: 8,
      padding: spacing(6, 12),
    },
    logo: {
      width: 241,
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      flex: '1 0 auto',
    },
    cover: {
      width: 525,
    },
    heading: {
      paddingTop: spacing(6),
      fontSize: 24,
      fontWeight: typography.fontWeightBold,
      color: palette.text.primary,
    },
    accent: {
      fontWeight: typography.fontWeightBold,
      color: palette.primary.main,
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: spacing(4),
      width: 240,
    },
    formGroup: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minHeight: 142,
    },
    button: {
      marginTop: spacing(2),
    },
  }));

  const intl = useIntl();

  const classes = useStyles();

  const initialValues: IFormValues = {
    username: '',
    password: '',
  };

  const { loginUser, loginError }: ICustomersStore = useContext(
    CustomersStoreContext
  );

  const onSubmit = async (values: IFormValues) => {
    await loginUser(values);
  };

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <img
            data-testid="login-logo"
            src={logo}
            alt="logo"
            className={classes.logo}
          />

          <Typography
            data-testid="login-heading"
            variant="h5"
            className={classes.heading}
          >
            <FormattedMessage
              id="Login.heading"
              values={{
                b: (chunk: string) => <b className={classes.accent}>{chunk}</b>,
              }}
            />
          </Typography>

          <FormControl>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({ values, handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit} className={classes.form}>
                  {loginError && (
                    <Alert type="danger" messageId="Login.errorAlert" />
                  )}

                  <FormGroup className={classes.formGroup}>
                    <InputField
                      name="username"
                      placeholder={intl.formatMessage({
                        id: 'Login.usernamePlaceholder',
                      })}
                      label={<FormattedMessage id="Login.usernameLabel" />}
                      error={loginError}
                      type="text"
                      size="medium"
                    />
                    <InputField
                      name="password"
                      placeholder={intl.formatMessage({
                        id: 'Login.passwordPlaceholder',
                      })}
                      label={<FormattedMessage id="Login.passwordLabel" />}
                      error={loginError}
                      type="password"
                      size="medium"
                    />
                  </FormGroup>

                  <ButtonLoading
                    type="submit"
                    value="Login.logIn"
                    isLoading={isSubmitting}
                    className={classes.button}
                    disabled={values.username === '' || values.password === ''}
                  />
                </Form>
              )}
            </Formik>
          </FormControl>
        </CardContent>
      </Card>
    </div>
  );
};

export default observer(Login);
