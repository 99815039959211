import React from 'react';

// TYPES
import { CustomerType } from 'api/customers/types';
import { makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

interface ICreditApprovedFormatterProps {
  currentItem: CustomerType;
}

const useStyles = makeStyles(({ palette }) => ({
  active: {
    color: palette.primary.main,
  },
  deactivated: {
    color: palette.error.main,
  },
}));

const CreditApprovedFormatter: React.FC<ICreditApprovedFormatterProps> = ({ currentItem}) => {
  const classes = useStyles();

  return (
    <>
      {currentItem.KredittGodkjent ? (
        <Typography variant="body2" className={classes.active}>
          <FormattedMessage id="Global.yes" />
        </Typography>
      ) : (
        <Typography variant="body2" className={classes.deactivated}>
          <FormattedMessage id="Global.no" />
        </Typography>
      )}
    </>
  );
};

export default CreditApprovedFormatter;
