import React, { FC, useState, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Tab, Tabs } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import { useLocation } from '@reach/router';

// COMPONENTS
import Overview from '../Overview';
import Transactions from '../Transactions';
import Quotas from '../Quotas';
import Cards from '../Cards';
import Apartments from '../Apartments';
import TabPanel from 'common/components/TabPanel';

// STYLES
import { useStyles } from './index.styles';
import { CustomerType } from 'api/customers/types';

// TYPES:
import { CustomerTypesNames } from 'api/customers/types';

interface TabularCustomerViewProps {
  currentCustomer: CustomerType;
}

const TabularCustomerView: FC<TabularCustomerViewProps> = ({
  currentCustomer,
}) => {
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();

  const initialTab = (hash: string) => {
    switch (hash) {
      case '#cards':
        return '#cards';
      case '#transactions':
        return '#transactions';
      case '#quotas':
        return '#quotas';
      case '#apartments':
        return '#apartments';
      default:
        window.history.replaceState(null, '', '');
        return '#overview';
    }
  };

  const [value, setValue] = useState(initialTab(location.hash));

  const isApartment =
    currentCustomer.BoligType === CustomerTypesNames.Flermannsbolig;

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    if (newValue !== window.location.hash) {
      setValue(newValue);
    }
  };

  useEffect(() => {
    if (value === '#overview') {
      window.history.replaceState(null, '', ' ');
    } else {
      window.location.hash = value;
    }
  }, [value]);

  return (
    <div className={classes.container}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="secondary"
        classes={{
          flexContainer: classes.flexContainer,
          root: classes.root,
        }}
      >
        <Tab
          value="#overview"
          label={<FormattedMessage id="Customer.overview" />}
        />
        <Tab value="#transactions" label="Transactions" />
        <Tab value="#quotas" label="Quotas" />
        <Tab value="#cards" label="Cards" />
        {isApartment && <Tab value="#apartments" label="Apartments" />}
      </Tabs>
      <TabPanel
        value={value}
        index={'#overview'}
        dir={theme.direction}
        tabPanelClassName={classes.tabPanel}
      >
        <div style={{ paddingTop: 16, height: '100%' }}>
          <Overview />
        </div>
      </TabPanel>
      <TabPanel
        value={value}
        index={'#transactions'}
        dir={theme.direction}
        tabPanelClassName={classes.tabPanel}
      >
        <Transactions isApartment={isApartment} />
      </TabPanel>
      <TabPanel
        value={value}
        index={'#quotas'}
        dir={theme.direction}
        tabPanelClassName={classes.tabPanel}
      >
        <Quotas />
      </TabPanel>
      <TabPanel
        value={value}
        index={'#cards'}
        dir={theme.direction}
        tabPanelClassName={classes.tabPanel}
      >
        <Cards isApartment={isApartment} />
      </TabPanel>
      {isApartment && (
        <TabPanel
          value={value}
          index={'#apartments'}
          dir={theme.direction}
          tabPanelClassName={classes.tabPanel}
        >
          <Apartments setValue={setValue} />
        </TabPanel>
      )}
    </div>
  );
};

export default observer(TabularCustomerView);
