import React, { useContext, useEffect, useState } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Menu,
  MenuItem,
  Badge,
} from '@material-ui/core';
import { navigate } from '@reach/router';
import { observer } from 'mobx-react';

// TYPES
import { SideBarSubcategoryType } from '../types';

// STYLES
import { useStyles } from './SideBarItem.styles';

// STORES
import { ISidebarStore, SidebarStoreContext } from 'stores/sidebar';
import { FormattedMessage } from 'react-intl';

interface ISideBarItem {
  isNavigationOpen: boolean;
  isActive?: boolean;
  isDisabled?: boolean;
  id: number | string;
  text: string;
  icon?: React.ReactElement;
  subcategories?: SideBarSubcategoryType[];
  href?: string;
  willBeAvailableSoon?: boolean;
  defaultOpenedItem?: any;
}

const SideBarItem: React.FC<ISideBarItem> = ({
  isNavigationOpen,
  isActive = false,
  id,
  text,
  icon,
  subcategories,
  href,
  willBeAvailableSoon,
  defaultOpenedItem,
  isDisabled = false
}) => {
  const classes = useStyles(isActive);

  const {
    currentlyOpenedItem,
    setCurrentlyOpenedItem,
    setActiveItem,
    activeItem,
  }: ISidebarStore = useContext(SidebarStoreContext);

  const [isOpen, setIsOpen] = useState(
    isNavigationOpen
      ? defaultOpenedItem === id || currentlyOpenedItem === id
      : false
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (subcategories!.length) {
      setAnchorEl(event.currentTarget);
      setIsOpen(!isOpen);
      setCurrentlyOpenedItem(id);
      return;
    } else {
      setCurrentlyOpenedItem(null);
      setActiveItem(href);
      navigate(href!);
      return;
    }
  };

  useEffect(() => {
    if (defaultOpenedItem && defaultOpenedItem === id) {
      setIsOpen(true);
    }
  }, [defaultOpenedItem, id]);

  return (
    <>
      <ListItem
        button
        key={id}
        onClick={handleClick}
        className={isActive ? classes.activeMainCategory : ''}
        disabled={isDisabled}
      >
        {willBeAvailableSoon && isNavigationOpen ? (
          <Badge
            color="primary"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            overlap="rectangle"
            badgeContent={<FormattedMessage id="Global.comingSoon" />}
            classes={{
              anchorOriginBottomRightRectangle:
                classes.anchorOriginBottomRightRectangle,
              badge: classes.badge,
            }}
          >
            <ListItemIcon className={classes.drawerListIcon}>
              {icon}
            </ListItemIcon>
            <ListItemText className={classes.drawerListText}>
              {text}
            </ListItemText>
          </Badge>
        ) : (
          <>
            <ListItemIcon className={classes.drawerListIcon}>
              {icon}
            </ListItemIcon>
            <ListItemText className={classes.drawerListText}>
              {text}
            </ListItemText>
          </>
        )}
      </ListItem>

      {subcategories && !isNavigationOpen && (
        <Menu
          id="simple-menu"
          keepMounted
          open={Boolean(isOpen)}
          onClose={handleClick}
          anchorEl={anchorEl}
          className={classes.menu}
          classes={{ list: classes.list, paper: classes.paper }}
        >
          {subcategories.map((subcategory: SideBarSubcategoryType) => (
            <MenuItem
              key={subcategory.text}
              onClick={() => {
                navigate(subcategory.href);
              }}
              classes={{ root: classes.menuItem }}
              className={`${
                subcategory.href === activeItem
                  ? classes.active
                  : classes.unactiveMenuItem
              }`}
            >
              {subcategory.text}
            </MenuItem>
          ))}
        </Menu>
      )}

      {subcategories && isNavigationOpen && (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {subcategories.map((subcategory: SideBarSubcategoryType) => {
              return (
                <ListItem
                  key={subcategory.text}
                  button
                  className={`${classes.nested} ${
                    subcategory.href === activeItem
                      ? classes.active
                      : classes.unactiveCollapseItem
                  }`}
                  onClick={() => {
                    setActiveItem(subcategory.href);
                    navigate(subcategory.href);
                    setIsOpen(false);
                  }}
                >
                  <ListItemText primary={subcategory.text} />
                </ListItem>
              );
            })}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default observer(SideBarItem);
