import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';

// STORES:
import { IWaresStore, WaresStoreContext } from 'stores/wares';

// TYPES:
import { WareType } from 'api/wares/types';
import {
  OverviewSchemaArray,
  OverviewSectionSchemaArray,
} from 'common/components/Overview/types';

// COMPONENTS:
import Overview from 'common/components/Overview';
import Toolbar from './Toolbar';
import ChangeOnSaleFormatter from '../Formatters/ChangeOnSaleFormatter';
import NutrtionalProductFormatter from '../Formatters/NutritionalProductFormatter';
import PriceWithVATFormatter from '../Formatters/PriceWithVATFormatter';
import UnitFormatter from '../Formatters/UnitFormatter';
import StatusFormatter from '../Formatters/StatusFormatter';

const generalSectionSchema: OverviewSectionSchemaArray<WareType> = [
  {
    label: 'status',
    formatter: StatusFormatter,
  },
  {
    label: 'wareName',
    value: 'Navn',
  },
  {
    label: 'id',
    value: 'BKey',
  },
  {
    label: 'units',
    formatter: UnitFormatter,
  },
];

const pricingSectionSchema: OverviewSectionSchemaArray<WareType> = [
  {
    label: 'price',
    value: 'VeilPris',
  },
  {
    label: 'priceWithVat',
    formatter: PriceWithVATFormatter,
  },
  {
    label: 'vat',
    value: 'navMVAUt.Navn',
  },
  {
    label: 'changeOnSale',
    formatter: ChangeOnSaleFormatter,
  },
  {
    label: 'wareNutritionalProduct',
    formatter: NutrtionalProductFormatter,
  },
];

const accountingSectionSchema: OverviewSectionSchemaArray<WareType> = [
  {
    label: 'externalId',
    value: 'ExternalId',
  },
];

const economySectionSchema: OverviewSectionSchemaArray<WareType> = [
  {
    label: 'name',
    value: 'AnsvarsEnhetNavn',
  },
  {
    label: 'number',
    value: 'AnsvarsEnhet',
  },
  {
    label: 'accountNumber',
    value: 'KontoNr',
  },
];

const sectionsSchema: OverviewSchemaArray<WareType> = [
  {
    label: 'general',
    data: generalSectionSchema,
  },
  {
    label: 'pricing',
    data: pricingSectionSchema,
  },
  {
    label: 'accounting',
    data: accountingSectionSchema,
  },
  {
    label: 'economy',
    data: economySectionSchema,
  },
];

const Preview = () => {
  const { currentWare }: IWaresStore = useContext(WaresStoreContext);
  return (
    <Overview
      sectionsSchema={sectionsSchema}
      currentItem={currentWare}
      translationsPath="Accounting.Wares"
      placeholderIcon={<WorkOutlineIcon />}
      placeholderText={
        <FormattedMessage id="Accounting.Wares.selectedWarePlaceholder" />
      }
      customToolbar={
        <Toolbar
          header={<FormattedMessage id="Accounting.Wares.previewHeader" />}
        />
      }
      hasRows
    />
  );
};

export default observer(Preview);
