import { useMutation, useQuery } from 'react-query';
import { getUserRoles, addUser } from 'api/users';

export const useGetUserRoles = (
  userId: string,
  queryFilter: any = undefined
) => {
  return useQuery<{ Name: string }[]>(
    ['currentUserRoles', queryFilter],
    async () => {
      const { data } = await getUserRoles(userId);
      return data;
    }
  );
};

export function useAddUser() {
  return useMutation(async (values: any) => {
    await addUser(values);
  });
}
