import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

// COMPONENTS:
import Header from 'common/components/Header';
import EmployeesTable from './components/Table';
import Overview from 'common/components/Overview';
import PreviewToolbar from './components/Preview/PreviewToolbar';

// STYLES:
import { useStyles } from './index.styles';

// STORE
import { EmployeesStoreContext, IEmployeesStore } from 'stores/employees';

// ICONS
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';

// SECTION DATA
import { sectionsSchema } from './components/Preview/employeeInformations';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import EmployeeModal from './components/EditEmployeeModal';

const Employees = () => {
  const classes = useStyles();

  const breadcrumbs = [
    {
      name: 'Employees List',
      path: '/employees',
    },
  ];

  const { currentEmployee }: IEmployeesStore = useContext(
    EmployeesStoreContext
  );

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={classes.container}>
      <Header
        breadcrumbs={breadcrumbs}
        title={<FormattedMessage id="Company.Employees.employees" />}
      >
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.addButton}
          onClick={toggleModal}
        >
          <AddOutlinedIcon />
          <FormattedMessage id="Company.Employees.addEmployee" />
        </Button>
      </Header>
      <Grid container className={classes.contentContainer}>
        <Grid item xs={12} sm={12} md={6} className={classes.column}>
          <EmployeesTable />
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={classes.column}>
          <Overview
            sectionsSchema={sectionsSchema}
            currentItem={currentEmployee}
            placeholderIcon={<WorkOutlineIcon />}
            translationsPath="Company.Employees"
            placeholderText={
              <FormattedMessage id="Company.Employees.selectedEmployeePlaceholder" />
            }
            customToolbar={
              <PreviewToolbar currentEmployee={currentEmployee!} />
            }
            hasRows
          />
        </Grid>
      </Grid>
      {isOpen && <EmployeeModal isOpen={isOpen} toggleModal={toggleModal} />}
    </div>
  );
};

export default observer(Employees);
