import React, { useRef } from 'react';
import {
  Grid,
  Button,
  makeStyles,
  Typography,
  IconButton,
} from '@material-ui/core';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { useReactToPrint } from 'react-to-print';

// ICONS:
import CloseIcon from '@material-ui/icons/Close';

// STORES:
import cardPreview from 'assets/images/card-preview.png';
import { Form, Formik } from 'formik';
import InputField from 'common/components/formik/InputField';

// TYPES:
import { CustomerType } from 'api/customers/types';

// COMPONENTS
import ButtonLoading from 'common/components/buttons/ButtonLoading';

interface IPrintStepHousehold {
  toggle: () => void;
  nextStep: () => void;
  setExpirationDate: (value: string) => void;
  intl: IntlShape;
  customer: CustomerType;
  cardNumber: string;
}
const useStyles = makeStyles(({ palette, typography, spacing }) => ({
  stepContainer: {
    height: '100%',
    padding: spacing(4, 3),
    position: 'relative',
  },
  stepInfo: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'baseline',
    paddingTop: spacing(6),
  },
  formLabel: {
    padding: spacing(4, 0, 1, 0),
  },
  title: {
    color: palette.secondary.main,
  },
  submitButton: {
    marginTop: spacing(14),
  },
  inputRoot: {
    fontSize: 13,
    border: `1px solid ${palette.secondary.light}`,
    borderRadius: '4px',
    transition:
      'border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
  },
  inputInput: {
    padding: '8px',
  },
  inputError: {
    borderColor: palette.error.main,
    '&.Mui-focused': {
      borderColor: palette.error.main,
    },
  },
  inputFocused: {
    borderColor: palette.primary.main,
    borderWidth: 1,
  },
  inputDisabled: {
    backgroundColor: palette.action.disabledBackground,
    borderColor: palette.action.disabledBackground,
    color: palette.action.disabled,
  },
  commentField: {
    paddingBottom: spacing(7),
    width: '321px !important',
  },
  infoIcon: {
    paddingRight: spacing(1),
  },
  cardPreview: {
    // boxShadow: '0px 0px 16px 0px rgba(0,0,0,0.15)',
    borderRadius: 4.8,
    width: '321px',
    height: '204px',
    marginBottom: spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: spacing(2),
    // background: `url(${cardPreview})`,
  },
  cardPreviewContainer: {
    boxShadow: '0px 0px 16px 0px rgba(0,0,0,0.15)',
    marginBottom: spacing(3),
    background: `url(${cardPreview})`,
    width: '321px',
    height: '204px',
  },
  cardText: {
    fontSize: '10px',
    fontWeight: typography.fontWeightMedium,
  },
  buttons: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: spacing(3, 20, 0),
  },
  cardPreviewRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100$',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
  },
  closeIcon: {
    padding: 0,
    '& Svg': {
      color: palette.secondary.main,
    },
  },
  customFieldsArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // maxHeight: 75,
  },
  scannedCardNumber: {
    paddingBottom: spacing(2),
  },
  uidCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  rowHeader: {
    fontWeight: typography.fontWeightMedium,
    color: palette.secondary.light,
  },
  date: {
    margin: spacing(0, 12),
  },
}));

const PrintStepHousehold: React.FC<IPrintStepHousehold> = ({ setExpirationDate, toggle, customer, cardNumber, nextStep}) => {
  const classes = useStyles();

  // const addCard = async (values: { expirationDate: string }) => {
  //   const cardData = {
  //     expirationDate: values.expirationDate,
  //     cardNumber: cardNumber,
  //     scannedCardNumber: scannedCardNumber,
  //   }
  //   await addCustomerCard(cardData);
  //   toggle();
  //   getCards();
  // };

  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleSubmit = (values: { expirationDate: string }) => {
    setExpirationDate(values.expirationDate);
    nextStep();
  };

  return (
    <Grid className={classes.stepContainer}>
      <div className={classes.modalHeader}>
        <Typography variant="h3" className={classes.title}>
          Preview (Households template)
        </Typography>
        <IconButton className={classes.closeIcon} onClick={toggle}>
          <CloseIcon />
        </IconButton>
      </div>
      <Grid item xs={12} className={classes.stepInfo}>
        <div className={classes.cardPreviewContainer}>
          <div className={classes.cardPreview} ref={componentRef}>
            <div className={classes.customFieldsArea}>
              <Typography className={classes.cardText}>Gateadresse: {customer.AlternativtNavn}</Typography>
              <Typography className={classes.cardText}>Avtalenr: {customer.BKey}</Typography>
              {cardNumber && <Typography className={classes.cardText}>Kortnr: {cardNumber}</Typography>}
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', width: '100%' }}>
          <Grid item style={{ marginTop: 4, width: '100%' }}>
          <Formik
            initialValues={{ expirationDate: '' }}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => {
              return (
                <Form>
                  <Grid container style={{ padding: '0 40px' }}>
                    {/*<Grid item xs={6} className={classes.uidCard}>*/}
                    {/*  <Typography variant="body2" className={classes.rowHeader}>*/}
                    {/*    UID nr from scanned card:*/}
                    {/*  </Typography>*/}
                    {/*  <Typography variant="body2">*/}
                    {/*    {scannedCardNumber}*/}
                    {/*  </Typography>*/}
                    {/*</Grid>*/}
                    <Grid item xs={12} className={classes.date}>
                      <InputField
                        name="expirationDate"
                        label={
                          <FormattedMessage id="Global.form.labels.expirationDate" />
                        }
                        type="date"
                      />
                    </Grid>
                  </Grid>
                  <div className={classes.buttons}>
                    <Button variant="outlined" color="primary" onClick={handlePrint}>Print</Button>
                    <ButtonLoading
                      state={isSubmitting}
                      disabled={isSubmitting}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      next
                    </ButtonLoading>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default injectIntl(PrintStepHousehold);
