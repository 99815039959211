import { createContext } from 'react';
import { action, observable, computed } from 'mobx';
import { navigate } from '@reach/router';
import flatten from 'flat';

// HELPERS
import { deleteTokens } from 'common/helpers/auth';
import { translations } from 'common/helpers/translations';

export interface ILoginStore {
  // Observables:
  language: string | null;
  messages: any;

  // Actions:
  changeLanguage(language: string): void;
  logout(): void;
}

export class LoginStore implements ILoginStore {
  @observable public language: string = 'en';

  @computed
  public get messages() {
    return flatten(translations[this.language]);
  }

  @action
  logout = () => {
    deleteTokens();
    navigate('/login');
  };

  @action
  changeLanguage = (language: string) => {
    this.language = language;
  };
}

export const loginStoreInstance = new LoginStore();
export const LoginStoreContext = createContext(loginStoreInstance);
