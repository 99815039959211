import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

// STORE:
import { WastebinsStoreContext } from 'stores/wastebins';

// TYPES:
import { IGridTableSchema } from 'common/components/grid/types';
import { WorkOrderImportHistoryType } from 'api/wastebins/types';

// COMPONENTS:
import GridTable from 'common/components/grid/GridTable';
import DateCell from 'common/components/grid/DateCell';
import DetailsCell from './DetailsCell';

const schema: IGridTableSchema<WorkOrderImportHistoryType> = {
  header: {
    columns: [
      {
        id: 'WorkOrderDate',
        name: <FormattedMessage id="Wastebins.tableHeaders.date" />,
      },
      {
        id: 'WorkOrderCount',
        name: <FormattedMessage id="Wastebins.tableHeaders.numberOfOrders" />,
      },
    ],
  },
  row: {
    id: 'WorkOrderGroupId',
    cells: [
      {
        key: 'WorkOrderDate',
        formatter: DateCell,
      },
      {
        key: 'WorkOrderCount',
      },
      {
        customKey: 'openDetails',
        formatter: DetailsCell,
      },
    ],
  },
};

const Table = () => {
  const {
    getWorkOrderImportHistory,
    workOrderImportHistoryData,
    getWorkOrderImportHistoryState,
  } = useContext(WastebinsStoreContext);

  return (
    <GridTable
      tableSchema={schema}
      data={workOrderImportHistoryData}
      getData={getWorkOrderImportHistory}
      loadingState={getWorkOrderImportHistoryState}
    />
  );
};
export default observer(Table);
