import React from 'react';
import { TableCell, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

// TYPES
import { CustomerTypesNames } from 'api/customers/types';

interface ICustomerTypeCellFormatter<T> {
  rowData: T;
}

const renderCustomerTypeName = (type: string) => {
  switch (true) {
    case type === CustomerTypesNames.JuridiskPerson:
      return <FormattedMessage id="Customers.List.companyHousing" />;
    case type === CustomerTypesNames.FysiskPerson:
      return <FormattedMessage id="Customers.List.privateHousing" />;
    case type === CustomerTypesNames.Ukjent:
      return <FormattedMessage id="Customers.List.apartmentBuilding" />;
    default:
      return '-';
  }
};

const CustomerTypeCellFormatter = <T extends { [key: string]: any }>({
  rowData,
}: ICustomerTypeCellFormatter<T>) => {
  return (
    <TableCell>
      <Typography variant="body2">
        {renderCustomerTypeName(rowData.KundeType)}
      </Typography>
    </TableCell>
  );
};

export default CustomerTypeCellFormatter;
