import React, { useContext, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';

// STORE:
import { IWaresStore, WaresStoreContext } from 'stores/wares';

// COMPONENTS:
import SelectField from 'common/components/formik/SelectField';
import InputField from 'common/components/formik/InputField';

export type IFormValues = {
  wareStatus: string;
  priceFrom: string;
  priceTo: string;
  vat: string;
};

interface IFilters {
  search: string;
}

const useStyles = makeStyles(({ palette }) => ({
  clearButton: {
    color: palette.secondary.light,
    padding: 0,
  },
  input: {
    whiteSpace: 'nowrap'
  }
}));

const Filters: React.FC<IFilters> = ({ search }) => {
  const classes = useStyles();

  const { setFilters, getWares, getVat, vatOptions }: IWaresStore = useContext(
    WaresStoreContext
  );
  const intl = useIntl();
  const initialValues: IFormValues = {
    wareStatus: '',
    priceFrom: '',
    priceTo: '',
    vat: '',
  };

  const handleSubmit = (values: IFormValues) => {
    setFilters(values);
    getWares(search);
  };

  const handleReset = () => {
    getWares(search);
  };

  const statusOptions = [
    {
      value: '0',
      label: 'Active',
    },
    {
      value: '1',
      label: 'Expired',
    },
    {
      value: '999',
      label: 'Deleted',
    },
  ];

  useEffect(() => {
    getVat();
  }, [getVat]);

  const vatSelectOptions = vatOptions?.map(option => ({
    value: option.PKey!.toString(),
    label: option.Navn,
  }));

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onReset={handleReset}
    >
      {() => {
        return (
          <Form>
            <Grid container justify="space-between" spacing={4}>
              <Grid item xs={12} sm={4}>
                <SelectField
                  name="wareStatus"
                  options={statusOptions}
                  label={
                    <FormattedMessage id="Accounting.Wares.filters.status" />
                  }
                  isClearable
                />
              </Grid>
              <Grid item xs={12} sm={2} style={{ paddingRight: 8 }}>
                <InputField
                  name="priceFrom"
                  placeholder={intl.formatMessage({
                    id: 'Accounting.Wares.filters.priceFrom',
                  })}
                  label={
                    <FormattedMessage id="Accounting.Wares.filters.priceFrom" />
                  }
                  type="number"
                  className={classes.input}
                />
              </Grid>
              <Grid item xs={12} sm={2} style={{ paddingLeft: 8 }}>
                <InputField
                  name="priceTo"
                  placeholder={intl.formatMessage({
                    id: 'Accounting.Wares.filters.priceTo',
                  })}
                  label={
                    <FormattedMessage id="Accounting.Wares.filters.priceTo" />
                  }
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SelectField
                  name="vat"
                  options={vatSelectOptions}
                  label={<FormattedMessage id="Accounting.Wares.filters.vat" />}
                  isClearable
                />
              </Grid>
            </Grid>
            <Grid container justify="space-between" spacing={4}>
              <Grid item>
                <Button
                  variant="text"
                  type="reset"
                  className={classes.clearButton}
                >
                  <FormattedMessage id="Global.clearFilters" />
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  <FormattedMessage id="Global.apply" />
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Filters;
