import React, { useContext, FC, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

// TYPES:
import { IGridTableSchema } from 'common/components/grid/types';
import {
  UserType,
  UserDetailsSectionSchemaType,
  UserCustomersType,
  UserWaresType,
} from 'api/users/types';

// STYLES
import { useStyles } from './index.styles';

// STORES:
import { IUsersStore, UsersStoreContext } from '../../../../../../stores/users';

// COMPONENTS
import GridTable from 'common/components/grid/GridTable';
import CustomToolbar from './components/CustomToolbar';

// QUERIES:
import { useGetUserRoles } from '../../../queries';

const overviewSectionSchema: UserDetailsSectionSchemaType[] = [
  {
    label: 'username',
    value: 'Username',
  },
  {
    label: 'name',
    value: 'Name',
  },
  {
    label: 'phoneNumber',
    value: 'Phone',
  },
  {
    label: 'email',
    value: 'Email',
  },
  {
    label: 'status',
    value: 'Active',
  },
];

const customersDataSchema: IGridTableSchema<UserCustomersType> = {
  wrapper: ({ children, ...rest }: any) => <div {...rest}>{children}</div>,
  header: {
    columns: [
      {
        id: 'BKey',
        name: 'Customer ID',
      },
      {
        id: 'Navn',
        name: 'Name',
      },
    ],
  },
  row: {
    id: 'BKey',
    cells: [
      {
        key: 'BKey',
      },
      {
        key: 'Navn',
      },
    ],
  },
};

const waresDataSchema: IGridTableSchema<UserWaresType> = {
  wrapper: ({ children, ...rest }: any) => <div {...rest}>{children}</div>,
  header: {
    columns: [
      {
        id: 'Navn',
        name: <FormattedMessage id="Global.form.labels.name" />,
      },
    ],
  },
  row: {
    id: 'BKey',
    cells: [
      {
        key: 'Navn',
      },
    ],
  },
};

interface Props {
  user: UserType;
}

const UserDetails: FC<Props> = ({ user }) => {
  const classes = useStyles();

  const {
    currentUser,
    getCurrentUserCustomers: getCurrentUserCustomersAPI,
    getCurrentUserWares: getCurrentUserWaresAPI,
    currentUserWares,
    currentUserCustomers,
    getCurrentUserWaresState,
    getCurrentUserCustomersState,
  }: IUsersStore = useContext(UsersStoreContext);

  const { data: currentUserRoles } = useGetUserRoles(
    currentUser!.PKey,
    currentUser
  );

  const getCurrentUserCustomers = useCallback(
    () => getCurrentUserCustomersAPI(currentUser!.PKey),
    [currentUser, getCurrentUserCustomersAPI]
  );

  const getCurrentUserWares = useCallback(
    () => getCurrentUserWaresAPI(currentUser!.PKey),
    [currentUser, getCurrentUserWaresAPI]
  );

  return (
    <>
      <Grid container className={classes.header}>
        <Grid container className={classes.row}>
          <Grid item xs={12}>
            <CustomToolbar
              titleTranslationId="Company.Users.overview"
              navigateToUrl={`users/${user.PKey}`}
            />
          </Grid>
          <Grid className={classes.overviewRow} container spacing={2}>
            {overviewSectionSchema.map(item => (
              <Grid item key={item.label} xs={4}>
                <Typography variant="body2" className={classes.rowHeader}>
                  <FormattedMessage id={`Global.form.labels.${item.label}`} />
                </Typography>
                <Typography variant="body2">
                  {user[item!.value!] || '-'}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      <Grid container className={classes.header}>
        <Grid container>
          <Grid item xs={12}>
            <CustomToolbar titleTranslationId="Company.Users.roles" />
          </Grid>
        </Grid>
        <Grid className={classes.overviewRow} item xs={12}>
          <div>
            {currentUserRoles?.length
              ? currentUserRoles?.map(role => (
                  <Grid item xs={12}>
                    <Typography variant="body2">{role.Name}</Typography>
                  </Grid>
                ))
              : '-'}
          </div>
        </Grid>
      </Grid>

      <Grid container className={classes.header}>
        <Grid container>
          <Grid item xs={12}>
            <CustomToolbar titleTranslationId="Company.Users.employee" />
          </Grid>
        </Grid>
        <Grid className={classes.overviewRow} item xs={12}>
          <Typography variant="body2">
            {currentUser!.EmployeeName || '-'}
          </Typography>
        </Grid>
      </Grid>

      <GridTable
        className={classes.gridTableWrapper}
        tableSchema={customersDataSchema}
        data={currentUserCustomers}
        loadingState={getCurrentUserCustomersState}
        getData={getCurrentUserCustomers}
        hasScrollbar={false}
        customToolbar={
          <CustomToolbar
            titleTranslationId="Company.Users.customers"
            navigateToUrl={`users/${currentUser!.PKey}/#customers`}
          />
        }
      />
      <GridTable
        className={classes.gridTableWrapper}
        tableSchema={waresDataSchema}
        data={currentUserWares}
        loadingState={getCurrentUserWaresState}
        getData={getCurrentUserWares}
        hasScrollbar={false}
        customToolbar={
          <CustomToolbar
            titleTranslationId="Company.Users.wares"
            navigateToUrl={`users/${currentUser!.PKey}/#wares`}
          />
        }
      />
    </>
  );
};

export default observer(UserDetails);
