import request from '../request';
import queryString from 'query-string';
import { NewCustomerCard } from '../customers/types';

export const deactivateCard = (id: string) =>
  request({
    url: `/admin/customers/DeleteCustomerCard/${id}`,
    method: 'DELETE',
  });

export const getAgreementTransactions = (id: number, cardId: string) =>
  request({
    url: `/admin/customers/GetAgreementTransactions?${queryString.stringify({
      clientPKey: id,
      customerCardId: cardId
    })}`,
    method: 'GET'
  });

export const getNextAvailableCardNumber = () =>
  request({
    url: '/admin/customers/GetNextAvailableCustomerCardNumber',
    method: 'GET'
  });

export const editCustomerCard = (values: NewCustomerCard) =>
  request({
    method: 'POST',
    url: '/admin/customers/SaveCustomerCard',
    data: {
      ...values,
    },
  });

