import React from 'react';
import { Typography } from '@material-ui/core';

// TYPES
import { CustomerType } from 'api/customers/types';

interface IPriceTableFormatterProps {
  currentItem: CustomerType;
}

const PriceTableFormatter: React.FC<IPriceTableFormatterProps> = ({ currentItem}) => {
  return (
    <Typography variant="body2">
      {currentItem.navPrisGruppeTabell?.Navn || '-'}
    </Typography>
  );
};

export default PriceTableFormatter;
