import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { Grid, Typography } from '@material-ui/core';
import SelectField from 'common/components/formik/SelectField';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation, useQuery, useQueryClient } from 'react-query';

// API:
import { addUserRole as addUserRoleAPI, getAllRoles } from 'api/users';

// TYPES:
import { AvailableUserRoles, UserType } from 'api/users/types';

// COMPONENTS:
import ButtonLoading from 'common/components/buttons/ButtonLoading';

const useStyles = makeStyles(({ spacing, palette, typography }) => ({
  container: {
    paddingTop: 16,
    height: '100%',
  },
  row: {
    paddingTop: spacing(3),
  },
  headerWithButton: {
    display: 'flex',
  },
  addIcon: {
    fontSize: 18,
  },
  addButton: {
    marginLeft: spacing(2),
  },
  roleRow: {
    paddingTop: spacing(1.5),
    display: 'flex',
    alignItems: 'center',
  },
  disconnectButton: {
    border: `1px solid ${palette.error.main}`,
    color: palette.error.main,
    background: palette.common.white,
    marginLeft: spacing(2),
    '&:hover': {
      background: palette.error.main,
      color: palette.common.white,
    },
  },
  modalContainer: {
    width: 496,
    height: '100%',
    padding: spacing(5, 8),
  },
  title: {
    paddingBottom: spacing(2),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  addRoleButton: {
    marginTop: spacing(1.5),
  },
}));

const AddNewUserRoleModal = ({
  toggleModal,
  currentUser,
}: {
  toggleModal: () => void;
  currentUser: UserType;
}) => {
  const classes = useStyles();
  const [allRolesOptions, setAllRolesOptions] = useState([]);

  const queryClient = useQueryClient();

  const { isLoading } = useQuery('allRoles', async () => await getAllRoles(), {
    onSuccess: data => {
      setAllRolesOptions(
        data.data.map((role: AvailableUserRoles) => ({
          value: role,
          label: role,
        }))
      );
    },
  });

  const { mutate: addUserRole, status: addUserRoleStatus, isLoading: isAddUserRoleLoading } = useMutation(
    async (values: any) => await addUserRoleAPI(values),
    {
      onSuccess: () => toggleModal(),
      onSettled: () => queryClient.invalidateQueries('currentUserRoles'),
    }
  );

  return (
    <>
      <Typography variant="h4" className={classes.title}>
        <FormattedMessage id="Company.User.addNewRole" />
      </Typography>
      <Formik initialValues={{ role: '' }} onSubmit={() => {}}>
        {({ values }) => {
          return (
            <Form className={classes.form}>
              <Grid container>
                <Grid item xs={12}>
                  <SelectField
                    name="role"
                    options={allRolesOptions}
                    placeholder={<FormattedMessage id="Company.Users.role" />}
                    label={<FormattedMessage id="Company.Users.role" />}
                    isClearable
                    isLoading={isLoading}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <ButtonLoading
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={isAddUserRoleLoading}
                  state={addUserRoleStatus}
                  className={classes.addRoleButton}
                  onClick={() => {
                    addUserRole({
                      UserPkey: currentUser!.PKey,
                      RoleName: values.role,
                    });
                  }}
                >
                  <FormattedMessage id="Global.add" />
                </ButtonLoading>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AddNewUserRoleModal;
