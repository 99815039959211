import { Overrides } from '@material-ui/core/styles/overrides';

interface IOverrides extends Overrides {
  MuiBox: any;
}
export const overrides: IOverrides = {
  MuiButton: {
    sizeSmall: {
      height: 32,
      borderRadius: 8,
      padding: '4px 12px',
    },
    root: {
      borderRadius: 9.6,
      boxShadow: 'none',
      textTransform: 'lowercase',
      minWidth: 0,
      padding: 9,
      lineHeight: 'none',
    },
    containedPrimary: {
      boxShadow: 'none',
    },
    sizeLarge: {
      borderRadius: 11.2,
      padding: '8px 20px',
    },
    text: {
      padding: '6px 16px',
    },
    label: {
      textTransform: 'lowercase',
    },
    outlined: {
      padding: 9,
    },
    outlinedSizeSmall: {
      padding: '3px 11px !important',
    },
    outlinedSizeLarge: {
      padding: '7px 19px !important',
    },
    contained: {
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
    },
  },
  MuiIconButton: {
    root: {
      borderRadius: 8,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  MuiFormGroup: {
    root: {
      marginTop: 8,
    },
  },
  MuiPaper: {
    elevation1: {
      boxShadow: '0px 0px 16px 0px rgba(0,0,0,0.26)',
    },
  },
  MuiCardContent: {
    root: {
      padding: 0,
      '&:last-child': {
        paddingBottom: 0,
      },
    },
  },
  MuiTooltip: {
    tooltip: {
      backgroundColor: '#3d464c',
      fontSize: 12,
    },
    arrow: {
      color: '#3d464c',
    },
  },
  MuiListItemIcon: {
    root: {
      minWidth: 0,
      width: '24px !important',
    },
  },
  MuiExpansionPanelSummary: {
    root: {
      minHeight: 0,
      padding: 0,
      '&.Mui-expanded': {
        minHeight: 0,
      },
    },
    content: {
      margin: 0,
      '&.Mui-expanded': {
        margin: 0,
      },
    },
  },
  MuiExpansionPanelDetails: {
    root: {
      display: 'block',
      padding: 0,
    },
  },
  MuiTab: {
    root: {
      padding: 0,
    },
  },
  MuiBox: {
    root: {
      padding: 0,
    },
  },
  MuiTableRow: {
    root: {
      '&:nth-child(odd)': {
        backgroundColor: '#f5f5f5',
      },
    },
  },
  MuiTableCell: {
    root: {
      padding: '0 12px',
    },
  },
  MuiInputBase: {
    multiline: {
      padding: 0,
    },
  },
};
