import React from 'react';
import { Grid, Divider, makeStyles, IconButton } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form, Formik } from 'formik';

// COMPONENTS:
import SearchInput from 'common/components/SearchInput';
import SelectField from 'common/components/formik/SelectField';
import { AutoSubmit } from 'common/components/formik/AutoSubmitForm';
import InputField from '../../../../common/components/formik/InputField';

// ICONS:
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(({ spacing, palette }) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  dateFrom: {
    padding: spacing(0, 2),
  },
  filterButton: {
    marginLeft: spacing(3),
    padding: 0,
    minWidth: 0,
    height: 32,
    width: 32,
    '& Svg': {
      width: 16,
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  toolbar: {
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: spacing(3, 3, 0, 3),
    zIndex: 3,
  },
  gridItem: {
    zIndex: 10,
    paddingLeft: spacing(2),
  },
  divider: {
    margin: spacing(2, 0, 1),
  },
  filterIconButton: {
    paddingLeft: 0,
  },
  filterIcon: {
    paddingRight: spacing(0.5),
  },
  clearIcon: {
    padding: 0,
    '& Svg': {
      color: palette.secondary.main,
      marginTop: spacing(1.8),
      height: 24,
      transition: '.4s background ease-in-out',
      '&:hover': {
        opacity: 0.5,
      },
    },
  },
  date: {
    display: 'flex',
  },
}));

interface IFormValues {
  wasteType: string;
  binType: string;
  dateFrom: string;
  dateTo: string;
}

const Toolbar: React.FC<any> = ({
  intl,
  search,
  setSearch,
  setSelectedFilters,
}) => {
  const classes = useStyles();

  const initialValues: IFormValues = {
    wasteType: '',
    binType: '',
    dateFrom: '',
    dateTo: '',
  };

  const wasteTypeOptions = [
    {
      label: 'Restavfall',
      value: 'Restavfall',
    },
    {
      label: 'Papir',
      value: 'Papir',
    },
  ];

  const binTypeOptions = [
    {
      label: '140L',
      value: '140L',
    },
    {
      label: '240L',
      value: '240L',
    },
    {
      label: '360L',
      value: '360L',
    },
    {
      label: '370L',
      value: '370L',
    },
    {
      label: '660L',
      value: '660L',
    },
  ];

  const setFilters = (values: IFormValues) => {
    setSelectedFilters({
      wasteType: values.wasteType,
      binType: values.binType,
      dateFrom: values.dateFrom,
      dateTo: values.dateTo,
    });
  };

  return (
    <div>
      <Grid container className={classes.toolbar}>
        <Grid item xs={4} className={classes.row}>
          <SearchInput
            placeholder={intl.formatMessage({
              id: 'Wastebins.workOrderSearchPlaceholder',
            })}
            onChange={e => {
              setSearch(e.target.value);
            }}
            value={search}
          />
        </Grid>
        <Grid item xs={8} className={classes.gridItem}>
          <Formik initialValues={initialValues} onSubmit={setFilters}>
            {({ setFieldValue }) => {
              return (
                <Form style={{ display: 'flex' }}>
                  <AutoSubmit />
                  <Grid container justify="space-around" spacing={2}>
                    <Grid item xs={3} className={classes.gridItem}>
                      <SelectField
                        name="wasteType"
                        isClearable
                        options={wasteTypeOptions}
                        label={
                          <FormattedMessage
                            id={`Global.form.labels.wasteType`}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.gridItem}>
                      <SelectField
                        name="binType"
                        isClearable
                        options={binTypeOptions}
                        label={
                          <FormattedMessage id={`Global.form.labels.binType`} />
                        }
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.date}>
                      <InputField
                        name="dateFrom"
                        label={
                          <FormattedMessage id="Global.form.labels.dateFrom" />
                        }
                        type="date"
                      />
                      <IconButton
                        className={classes.clearIcon}
                        onClick={() => setFieldValue('dateFrom', '', false)}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={3} className={classes.date}>
                      <InputField
                        name="dateTo"
                        label={
                          <FormattedMessage id="Global.form.labels.dateTo" />
                        }
                        type="date"
                      />
                      <IconButton
                        className={classes.clearIcon}
                        onClick={() => setFieldValue('dateTo', '', false)}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
    </div>
  );
};

export default injectIntl(Toolbar);
