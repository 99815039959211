import React from 'react';
import { TableCell, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

// TYPES:
import { ICell, IGridTableSchema } from 'common/components/grid/types';
import { WareType } from 'api/wares/types';

interface IStatusCell<T> {
  row: IGridTableSchema<T>['row'];
  cell: ICell<T>;
  rowData: any;
}

const StatusCell = <T extends object>({
  rowData,
  row,
  cell,
}: IStatusCell<WareType>) => {
  return (
    <TableCell key={`${row.id}-${cell.customKey || cell.key}`}>
      {(() => {
        switch (true) {
          case rowData.Status === 0:
            return (
              <Typography variant="body2">
                <FormattedMessage id="Accounting.Wares.status.active" />
              </Typography>
            );
          case rowData.Status === 1:
            return (
              <Typography variant="body2">
                <FormattedMessage id="Accounting.Wares.status.expired" />
              </Typography>
            );
          case rowData.Status === 999:
            return (
              <Typography variant="body2">
                <FormattedMessage id="Accounting.Wares.status.deleted" />
              </Typography>
            );
          default:
            return null;
        }
      })()}
    </TableCell>
  );
};

export default StatusCell;
