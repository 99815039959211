import React, { ReactNode, useContext } from 'react';
import { makeStyles, TableRow } from '@material-ui/core';
import { observer } from 'mobx-react';

// STORE
import { WaresStoreContext, IWaresStore } from 'stores/wares';

// TYPES
import { WareType } from 'api/wares/types';

interface IWareRow {
  rowData: WareType;
  children: ReactNode;
}

const useStyles = makeStyles(({ palette }) => ({
  row: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: palette.primary.light,
    },
  },
  selected: {
    backgroundColor: `${palette.primary.light} !important`,
  },
}));

const WareRow: React.FC<IWareRow> = ({ rowData, children }) => {
  const { setCurrentWare, currentWare }: IWaresStore = useContext(
    WaresStoreContext
  );

  const isActive = currentWare?.BKey === rowData.BKey;
  const classes = useStyles(isActive);

  const handleClick = () => {
    setCurrentWare(rowData);
  };

  return (
    <TableRow
      onClick={handleClick}
      className={isActive ? `${classes.row} ${classes.selected}` : classes.row}
    >
      {children}
    </TableRow>
  );
};

export default observer(WareRow);
