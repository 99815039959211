import React from 'react';

// TYPES
import { WareType } from 'api/wares/types';
import { FormattedMessage } from 'react-intl';

interface INutrtionalProductFormatter {
  currentItem: WareType;
}

const NutrtionalProductFormatter: React.FC<INutrtionalProductFormatter> = ({
  currentItem,
}) => {
  return (
    <>
      {currentItem.OpphavsKode === '002' ? (
        <FormattedMessage id="Global.yes" />
      ) : (
        <FormattedMessage id="Global.no" />
      )}
    </>
  );
};

export default NutrtionalProductFormatter;
