import React, { ReactNode, useContext } from 'react';
import { makeStyles, TableRow } from '@material-ui/core';
import { observer } from 'mobx-react';

// TYPES
import { PricetableType } from 'api/pricetables/types';

// STORE
import { PricetablesStoreContext, IPricetablesStore } from 'stores/pricetables';

interface IEmployeeRow {
  rowData: PricetableType;
  children: ReactNode;
}

const useStyles = makeStyles(({ palette }) => ({
  row: {
    '&:hover': {
      backgroundColor: palette.primary.light,
      cursor: 'pointer',
    },
  },
  selected: {
    backgroundColor: `${palette.primary.light} !important`,
  },
}));

const PricetableRow: React.FC<IEmployeeRow> = ({ rowData, children }) => {
  const {
    currentPricetable,
    setCurrentPricetable,
  }: IPricetablesStore = useContext(PricetablesStoreContext);

  const isActive = currentPricetable?.PKey === rowData.PKey;
  const classes = useStyles(isActive);

  const handleClick = () => {
    setCurrentPricetable(rowData);
  };

  return (
    <TableRow
      onClick={handleClick}
      className={isActive ? `${classes.selected} ${classes.row}` : classes.row}
    >
      {children}
    </TableRow>
  );
};

export default observer(PricetableRow);
