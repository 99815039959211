import React, { useContext, useState } from 'react';
import { makeStyles, TableCell, TableSortLabel } from '@material-ui/core';
import { observer } from 'mobx-react';

// TYPES:
import { IColumn, SortingOrder } from 'common/components/grid/types';
import { EmployeeType } from 'api/employees/types';

// ICONS:
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

// STORES:
import { EmployeesStoreContext, IEmployeesStore } from 'stores/employees';

interface ISortableNumericHeader {
  children: React.ReactNode;
  column: IColumn<EmployeeType>;
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  header: {
    background: palette.common.white,
    borderBottom: 'none',
    paddingBottom: spacing(1),
    color: palette.secondary.light,
  },
}));

const SortableNumericHeader: React.FC<ISortableNumericHeader> = ({
  children,
  column,
}) => {
  const classes = useStyles();
  const [order, setOrder] = useState<SortingOrder>('asc');
  const { sortBy, sortEmployeesBy }: IEmployeesStore = useContext(
    EmployeesStoreContext
  );

  const handleClick = () => {
    if (order === 'asc') {
      sortEmployeesBy(column.id, 'desc');
      setOrder('desc');
    } else {
      sortEmployeesBy(column.id, 'asc');
      setOrder('asc');
    }
  };

  return (
    <TableCell classes={{ stickyHeader: classes.header }}>
      <TableSortLabel
        active={sortBy === column.id}
        direction={sortBy === column.id ? order : 'asc'}
        onClick={handleClick}
        IconComponent={KeyboardArrowDownIcon}
      >
        {children}
      </TableSortLabel>
    </TableCell>
  );
};

export default observer(SortableNumericHeader);
