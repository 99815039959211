import React from 'react';
import {
  makeStyles,
  TextField,
  StandardTextFieldProps,
} from '@material-ui/core';
import { FieldProps } from 'formik';

export type ICustomInput = StandardTextFieldProps & FieldProps;

const CustomInput: React.FC<ICustomInput> = ({
  field,
  form,
  className,
  InputProps,
  ...props
}) => {
  const { errors } = form;
  const { size, autoFocus, onBlur } = props;

  const error = errors[field.name!];

  const useStyles = makeStyles(({ palette, typography, spacing }) => ({
    inputRoot: {
      fontSize: 13,
      border: `1px solid ${palette.secondary.light}`,
      borderRadius: '4px',
      transition:
        'border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    },
    inputRootError: {
      fontSize: 13,
      border: `1px solid ${palette.error.main}`,
      borderRadius: '4px',
      transition:
        'border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    },
    inputInput: {
      padding: size === 'medium' ? '13px 8px' : '8px',
    },
    inputError: {
      borderColor: palette.error.main,
      '&.Mui-focused': {
        borderColor: palette.error.main,
      },
    },
    inputFocused: {
      borderColor: palette.primary.main,
    },
    inputDisabled: {
      backgroundColor: palette.action.disabledBackground,
      borderColor: palette.action.disabledBackground,
      color: palette.action.disabled,
    },
    label: {
      textTransform: 'capitalize',
      color: `${palette.secondary.light} !important`,
      fontWeight: typography.fontWeightMedium,
      padding: spacing(0, 0, 0.5, 1),
      minWidth: 80,
    },
    labelAsterisk: {
      color: palette.error.main,
    },
    labelShrink: {
      transform: 'translate(0, -1px) scale(0.875)',
    },
    labelDisabled: {
      color: palette.action.disabled,
    },
    helperTextRoot: {
      marginTop: 0,
    },
    textFieldRoot: {
      minHeight: size === 'medium' ? 75 : 'auto',
      minWidth: size === 'medium' ? 240 : 'auto',
      width: '100%',
    },
    error: {
      color: palette.error.main,
      textAlign: 'left',
      margin: 0,
    },
  }));

  const classes = useStyles();

  // InputLabelProps custom overrides
  const InputLabelProps = {
    shrink: true,
    classes: {
      root: classes.label,
      asterisk: classes.labelAsterisk,
      shrink: classes.labelShrink,
      disabled: classes.labelDisabled,
    },
  };

  // InputProps custom overrides
  const CustomInputProps = {
    disableUnderline: true,
    classes: {
      root: error ? classes.inputRootError : classes.inputRoot,
      input: classes.inputInput,
      focused: classes.inputFocused,
      error: classes.inputError,
      disabled: classes.inputDisabled,
    },
  };

  // FormHelperTextProps custom overrides
  const FormHelperTextProps = {
    classes: {
      root: classes.helperTextRoot,
    },
  };

  return (
    <>
      <TextField
        id={props.id}
        className={`${classes.textFieldRoot} ${className} ${error &&
          classes.error}`}
        InputLabelProps={{ ...InputLabelProps, ...props.InputLabelProps }}
        InputProps={{ ...CustomInputProps, ...InputProps }}
        FormHelperTextProps={{
          ...FormHelperTextProps,
          ...props.FormHelperTextProps,
        }}
        autoFocus={autoFocus}
        {...field}
        {...props}
        onBlur={onBlur}
      />
      <p className={classes.error}>{error}</p>
    </>
  );
};

export default CustomInput;
