import React, { useContext, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { useParams } from '@reach/router';
import { makeStyles } from '@material-ui/core';

// TYPES
import { CustomerCardType } from 'api/cards/types';
import { IGridTableSchema } from 'common/components/grid/types';

// COMPONENTS:
import GridTable from 'common/components/grid/GridTable';
import DateCell from 'common/components/grid/DateCell';
import CardRow from './CardRow';
import Toolbar from './Toolbar';
import CardDropdownCell from './CardDropdownCell';

// STORE:
import { CustomersStoreContext, ICustomersStore } from 'stores/customers';
import { FormattedMessage } from 'react-intl';

// HELPERS:
import { useDebounce } from 'common/helpers/useDebounce';
import useLocalSearch from 'common/helpers/useLocalSearch';

const cardsSchema: IGridTableSchema<CustomerCardType> = {
  header: {
    columns: [
      {
        id: 'Kundekortnummer',
        name: <FormattedMessage id="Customer.Cards.tableHeaders.cardNr" />,
      },
      {
        id: 'DatoOpprettet',
        name: (
          <FormattedMessage id="Customer.Cards.tableHeaders.creationDate" />
        ),
      },
      {
        id: 'Tekst',
        name: <FormattedMessage id="Customer.Cards.tableHeaders.comment" />,
      },
      {
        id: 'CardStatus',
        name: <FormattedMessage id="Customer.Cards.tableHeaders.status" />,
      },
    ],
  },
  row: {
    id: 'ID',
    formatter: CardRow,
    cells: [
      {
        key: 'Kundekortnummer',
      },
      {
        key: 'DatoOpprettet',
        formatter: DateCell,
      },
      {
        key: 'Tekst',
      },
      {
        key: 'CardStatus',
      },
      {
        customKey: 'dropdownCell',
        formatter: CardDropdownCell,
        formatterProps: {
          options: [
            { name: 'Customer.Cards.dropdown.option1' },
            { name: 'Customer.Cards.dropdown.option2' },
          ],
        },
      },
    ],
  },
};

const cardsSchemaForApartments: IGridTableSchema<CustomerCardType> = {
  header: {
    columns: [
      {
        id: 'Kundekortnummer',
        name: <FormattedMessage id="Customer.Cards.tableHeaders.cardNr" />,
      },
      {
        id: 'DatoOpprettet',
        name: (
          <FormattedMessage id="Customer.Cards.tableHeaders.creationDate" />
        ),
      },
      {
        id: 'Apartment.BoligNummer',
        name: <FormattedMessage id="Customer.Cards.tableHeaders.apartmentNr" />,
      },
      {
        id: 'Tekst',
        name: <FormattedMessage id="Customer.Cards.tableHeaders.comment" />,
      },
      {
        id: 'CardStatus',
        name: <FormattedMessage id="Customer.Cards.tableHeaders.status" />,
      },
    ],
  },
  row: {
    id: 'ID',
    formatter: CardRow,
    cells: [
      {
        key: 'Kundekortnummer',
      },
      {
        key: 'DatoOpprettet',
        formatter: DateCell,
      },
      {
        key: 'Apartment.BoligNummer',
      },
      {
        key: 'Tekst',
      },
      {
        key: 'CardStatus',
      },
      {
        customKey: 'dropdownCell',
        formatter: CardDropdownCell,
        formatterProps: {
          options: [
            { name: 'Customer.Cards.dropdown.option1' },
            { name: 'Customer.Cards.dropdown.option2' },
          ],
        },
      },
    ],
  },
};

const useStyles = makeStyles(() => ({
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0px 12px',
    padding: `0px 24px`,
  },
}));

const AllCards = ({ isApartment }: { isApartment: boolean }) => {
  const {
    getCustomerCards,
    getCustomerCardsState,
    filteredCustomerCards,
    currentApartment,
    currentApartmentCardFilter,
  }: ICustomersStore = useContext(CustomersStoreContext);

  // const [search, setSearch] = useState('');
  const [search, setSearch] = useState(
    currentApartmentCardFilter ? currentApartmentCardFilter.BoligNummer : ''
  );
  const [selectedFilter, setSelectedFilter] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const classes = useStyles();
  const params = useParams();

  const removeEmptyValues = (values: any) => {
    const fieldValues: any = {
      ...values,
    };

    Object.keys(fieldValues).forEach(key => {
      if (!fieldValues[key]) {
        delete fieldValues[key];
      }
    });

    return fieldValues;
  };

  const getCards = useCallback(() => {
    const valuesToSend = removeEmptyValues({
      id: params.customerId,
      filterCardStatus: parseInt(selectedFilter) || null,
      // registerEnhetId: currentApartment ? currentApartment.RegisterEnhetId : null,
    });
    return getCustomerCards(valuesToSend);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCustomerCards, params.customerId, debouncedSearch, selectedFilter]);

  const { data: cardData } = useLocalSearch(
    search,
    ['BoligNummer', 'Kundekortnummer'],
    filteredCustomerCards
  );

  return (
    <GridTable
      tableSchema={isApartment ? cardsSchemaForApartments : cardsSchema}
      data={cardData}
      loadingState={getCustomerCardsState}
      getData={getCards}
      customToolbar={
        <Toolbar
          apartment={currentApartment}
          getCards={getCards}
          search={search}
          setSearch={setSearch}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      }
      tableClassname={classes.table}
    />
  );
};

export default observer(AllCards);
