import React, { useContext } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useQueryClient } from 'react-query';

// COMPONENTS:
import InputField from 'common/components/formik/InputField';
import ButtonLoading from 'common/components/buttons/ButtonLoading';

// STORES:
import { CustomersStoreContext, ICustomersStore } from 'stores/customers';

// TYPES:
import { RegisterEnheterType } from 'api/customers/types';

interface IStepScanning {
  nextStep: () => void;
  getCards: () => void;
  toggle: () => void;
  cardNumberValue: any;
  expirationDate: string;
  cardNumber: string;
  intl: IntlShape;
  apartment: RegisterEnheterType | undefined;
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  stepContainer: {
    height: '100%',
    padding: spacing(4, 3),
  },
  stepInfo: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'baseline',
    '& Svg': {
      color: palette.primary.main,
      fontSize: 120,
    },
  },
  title: {
    color: palette.secondary.main,
  },
  cardNumberField: {
    width: '282px',
  },
  nextButton: {
    marginTop: spacing(7),
    padding: spacing(1.5, 7),
  },
}));

const StepScanning: React.FC<IStepScanning> = ({
  cardNumberValue,
  intl,
  cardNumber,
  getCards,
  toggle,
  expirationDate,
  apartment
}) => {
  const classes = useStyles();
  const queryClient = useQueryClient();

  const { addCustomerCard }: ICustomersStore = useContext(
    CustomersStoreContext
  );

  const validationSchema = Yup.object().shape({
    cardNumberValue: Yup.string()
      .required('This field is required')
      .matches(
        /^[-+]?[0-9A-Fa-f]+\.?[0-9A-Fa-f]*?$/,
        'Card number must be hexadecimal string'
      ),
  });

  const addCard = async (values: { cardNumberValue: string }) => {
    const cardData = {
      expirationDate: expirationDate,
      cardNumber: cardNumber,
      scannedCardNumber: values.cardNumberValue,
    };

    const apartmentCardData = {
      expirationDate: expirationDate,
      cardNumber: cardNumber,
      scannedCardNumber:  values.cardNumberValue,
      registerEnhetId: apartment?.RegisterEnhetId,
    };

    await addCustomerCard(apartment ? apartmentCardData : cardData);
    queryClient.removeQueries('nextAvailableCardNumber', { exact: true });
    toggle();
    getCards();
  };

  return (
    <Grid container className={classes.stepContainer}>
      <Typography variant="h3" className={classes.title}>
        <FormattedMessage id="Agreement.Cards.newCardTitle" />
      </Typography>
      <Grid item xs={12} className={classes.stepInfo}>
        <Formik
          initialValues={cardNumberValue}
          onSubmit={addCard}
          validationSchema={validationSchema}
          validateOnChange={true}
          validateOnBlur={false}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <InputField
                name="cardNumberValue"
                placeholder={intl.formatMessage({
                  id: 'Global.form.placeholders.registerUID',
                })}
                label={<FormattedMessage id="Global.form.labels.registerUID" />}
                type="text"
                autoFocus
                onBlur={({ target }) => target.focus()}
                className={classes.cardNumberField}
                size="medium"
              />
              <Typography variant="h5">
                <FormattedMessage id="Customer.Cards.placeNewCardInReader" />
              </Typography>
              {/*<Typography variant="body1">*/}
              {/*  <FormattedMessage id="Customer.Cards.placeNewCardInReader" />*/}
              {/*</Typography>*/}
              <ButtonLoading
                type="submit"
                className={classes.nextButton}
                state={isSubmitting}
                disabled={isSubmitting}
              >
                <FormattedMessage id="Customer.Cards.saveCard" />
              </ButtonLoading>

            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default injectIntl(StepScanning);
