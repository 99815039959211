import React from 'react';
import { Form, Formik } from 'formik';
import { Button, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

// COMPONENTS
import SelectField from 'common/components/formik/SelectField';

// STYLES
import { useStyles } from './index.styles';

interface IFormValues {
  pricetableStatus: string;
  price: string;
}

const FiltersForm = () => {
  const classes = useStyles();

  // CONSTANTS
  const initialValues: IFormValues = {
    pricetableStatus: '',
    price: '',
  };

  const options = [
    { value: 'option1', label: 'option 1' },
    { value: 'option2', label: 'option 2' },
  ];

  const handleSubmit = (values: any) => {
    console.log(values);
  };

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {() => {
          return (
            <Form>
              <Grid
                container
                justify="flex-start"
                spacing={4}
                className={classes.root}
              >
                <Grid item xs={12} sm={4}>
                  <SelectField
                    name="pricetableStatus"
                    options={options}
                    placeholder={
                      <FormattedMessage id="Company.Pricetables.allPlaceholder" />
                    }
                    label={<FormattedMessage id="Company.Pricetables.status" />}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SelectField
                    name="price"
                    options={options}
                    placeholder={
                      <FormattedMessage id="Company.Pricetables.allPlaceholder" />
                    }
                    label={<FormattedMessage id="Company.Pricetables.price" />}
                  />
                </Grid>
              </Grid>
              <Grid container justify="space-between" spacing={4}>
                <Grid item>
                  <Button
                    variant="text"
                    type="reset"
                    className={classes.clearButton}
                  >
                    <FormattedMessage id="Global.clearFilters" />
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    <FormattedMessage id="Global.apply" />
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default FiltersForm;
