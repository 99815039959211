import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

// COMPONENTS:
import Header from 'common/components/Header';
import ClientGroupsTable from './components/Table';
import Overview from 'common/components/Overview';

// SECTIONS DATA
import { sectionsSchema } from './components/Preview/clientGroupsInformation';

// STYLES:
import { useStyles } from './index.styles';

// ICONS
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';

// STORES
import {
  ClientGroupsStoreContext,
  IClientGroupsStore,
} from 'stores/clientGroups';

const ClientGroups = () => {
  const classes = useStyles();

  const breadcrumbs = [
    {
      name: 'Client Groups List',
      path: '/clientGroups',
    },
  ];
  const { currentClientGroup }: IClientGroupsStore = useContext(
    ClientGroupsStoreContext
  );

  return (
    <div className={classes.container}>
      <Header
        breadcrumbs={breadcrumbs}
        title={<FormattedMessage id="Company.ClientGroups.clientGroups" />}
      />
      <Grid container className={classes.contentContainer}>
        <Grid item xs={12} sm={12} md={6} className={classes.column}>
          <ClientGroupsTable />
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={classes.column}>
          <Overview
            sectionsSchema={sectionsSchema}
            currentItem={currentClientGroup}
            translationsPath="Company.ClientGroups"
            placeholderIcon={<WorkOutlineIcon />}
            placeholderText={
              <FormattedMessage id="Company.ClientGroups.selectedClientGroupPlaceholder" />
            }
            hasRows
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default observer(ClientGroups);
