import React from 'react';
import { Typography } from '@material-ui/core';

// TYPES
import { CustomerType } from 'api/customers/types';

interface ICityFormatterProps {
  currentItem: CustomerType;
}

const CityFormatter: React.FC<ICityFormatterProps> = ({ currentItem}) => {
  return (
    <Typography variant="body2">
      {currentItem?.navPostSted?.Navn || '-'}
    </Typography>
  );
};

export default CityFormatter;
