import request from '../request';
import { WarePayload } from './types';

export const getAllWares = (data: WarePayload) =>
  request({
    method: 'POST',
    url: '/admin/products/GetProducts',
    data,
  });

export const getVat = () =>
  request({
    method: 'GET',
    url: '/admin/products/GetVAT',
  });

export const getUnit = () =>
  request({
    method: 'GET',
    url: '/admin/products/GetUnit',
  });

export const saveWare = (data: any) =>
  request({
    method: 'POST',
    url: '/admin/products/SaveProduct',
    data,
  });
