import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ typography, palette, spacing }) => ({
  paper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: palette.primary.contrastText,
    boxShadow: 'none',
    borderRadius: 8,
    width: '100%',
    height: 32,
  },
  input: {
    padding: spacing(0, 1),
    fontSize: 13,
    fontWeight: typography.fontWeightLight,
    flex: 1,
    width: '100%',
  },
  searchButton: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(0.5, 1, 0.5, 0.5),
    '& Svg': {
      width: 18,
    },
  },
  form: {
    display: 'flex',
    width: '100%',
  },
}));
