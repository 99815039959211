// TYPES
import { CustomerDetailsSectionSchema } from 'api/customers/types';

// COMPONENTS
import CustomerTypeFormatter from './CustomerTypeFormatter';
import CardPaymentFormatter from './CardPaymentFormatter';
import StatusFormatter from './StatusFormatter';
import CombinedNumbersFormatter from './CombinedNumbersFormatter';
import CityFormatter from './CityFormatter';
import CreditApprovedFormatter from './CreditApprovedFormatter';
import PriceTableFormatter from './PriceTableFormatter';

interface ISectionSchema {
  label: string;
  data: CustomerDetailsSectionSchema[];
}

export const generalSectionSchema: CustomerDetailsSectionSchema[] = [
  {
    label: 'id',
    value: 'BKey',
  },
  {
    label: 'alternativeName',
    value: 'AlternativtNavn',
  },
  {
    label: 'customerType',
    value: 'KundeType',
  },
  {
    label: 'customerStatus',
    formatter: StatusFormatter,
  },
  {
    label: 'phoneNumber',
    value: 'TlfNr',
  },
  {
    label: 'info',
    value: 'Info',
  },
  {
    label: 'addressOrganizationNumbers',
    formatter: CombinedNumbersFormatter,
  },
  {
    label: 'sumUnits',
    value: 'totalNumberOfApartments',
  },
];

export const address1SectionSchema: CustomerDetailsSectionSchema[] = [
  {
    label: 'streetName',
    value: 'Adresse',
  },
  {
    label: 'postNr',
    value: 'PostNr',
  },
  {
    label: 'city',
    value: 'navPostSted',
    formatter: CityFormatter,
  },
  {
    label: 'communeNumber',
    value: 'KommuneNr',
  },
  {
    label: 'commune',
    value: 'KommuneNavn',
  },
];

export const groupAndPricingSectionSchema: CustomerDetailsSectionSchema[] = [
  {
    label: 'discount',
    value: 'Rabatt',
  },
  {
    label: 'cardPayment',
    formatter: CardPaymentFormatter,
  },
  {
    label: 'priceTable',
    formatter: CustomerTypeFormatter,
  },
  {
    label: 'invoiceReference',
    value: 'FaktRef',
  },
];

export const invoiceSectionSchema: CustomerDetailsSectionSchema[] = [
  {
    label: 'creditApproved',
    value: 'KredittGodkjent',
    formatter: CreditApprovedFormatter,
  },
  {
    label: 'pricetable',
    value: 'navPrisGruppeTabell',
    formatter: PriceTableFormatter,
  },
  {
    label: 'invoiceReceiver',
    value: 'Navn',
  },
  {
    label: 'invoiceReference',
    value: 'EPost',
  },
];

export const overviewSectionsSchema: ISectionSchema[] = [
  {
    label: 'general',
    data: generalSectionSchema,
  },
  {
    label: 'address1',
    data: address1SectionSchema,
  },
  {
    label: 'invoice',
    data: invoiceSectionSchema,
  },
];
