import React from 'react';
import { makeStyles, TableCell } from '@material-ui/core';

// TYPES:
import { IColumn } from './types';

interface IDefaultHeader<T> {
  children: React.ReactNode;
  column: IColumn<T>;
}

const useStyles = makeStyles(({ palette, constants }) => ({
  header: {
    background: palette.common.white,
    borderBottom: 'none',
    padding: constants.bigTableCellPadding,
    color: palette.secondary.light,
    whiteSpace: 'nowrap',
    zIndex: 1,
  },
}));

const DefaultHeader = <T extends object>({ children }: IDefaultHeader<T>) => {
  const classes = useStyles();
  return (
    <TableCell classes={{ stickyHeader: classes.header }}>{children}</TableCell>
  );
};

export default DefaultHeader;
