import React from 'react';
import { makeStyles, Typography, Grid, Button } from '@material-ui/core';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

// COMPONENTS
import SearchInput from 'common/components/SearchInput';
import AddCustomerWareModal from './AddCustomerWareModal';

// ICONS:
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';

// HELPERS:
import { useModal } from 'common/helpers/useModal';

interface IToolbar {
  intl: IntlShape;
  search: string;
  userId: string;
  setSearch: (value: any) => void;
}

const useStyles = makeStyles(({ spacing, typography }) => ({
  header: {
    padding: spacing(3, 3, 0, 3),
  },
  heading: {
    fontWeight: typography.fontWeightBold,
    marginBottom: spacing(2),
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(0, 8, 2, 0),
  },
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  addButton: {
    display: 'flex',
    '& Svg': {
      width: 18,
    },
  },
}));

const Toolbar: React.FC<IToolbar> = ({ intl, search, setSearch, userId }) => {
  const classes = useStyles();

  const { toggleModal, RenderModal } = useModal();


  return (
    <>
    <Grid container className={classes.header}>
      <Grid item xs={8}>
        <Typography variant="h4" className={classes.heading}>
          <FormattedMessage id="Company.Users.wares" />
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.right}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.addButton}
          onClick={toggleModal}
        >
          <AddOutlinedIcon />
          <FormattedMessage id="Company.User.addWare" />
        </Button>
      </Grid>

      <Grid item xs={6} className={classes.search}>
        <SearchInput
          placeholder={intl.formatMessage({
            id: 'Company.User.searchWaresPlaceholder',
          })}
          onChange={e => setSearch(e.target.value)}
          value={search}
        />
      </Grid>
    </Grid>
      <RenderModal>
        <AddCustomerWareModal toggleModal={toggleModal} userId={userId} />
      </RenderModal>
    </>
  );
};

export default injectIntl(Toolbar);
