export enum PromiseStates {
  PENDING = 'pending',
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected',
}

export type LoadingState =
  | PromiseStates.PENDING
  | PromiseStates.FULFILLED
  | PromiseStates.REJECTED
  | null;
