import React, { ReactNode, useContext } from 'react';
import { makeStyles, TableRow } from '@material-ui/core';
import { observer } from 'mobx-react';

// TYPES
import { ClientGroupType } from 'api/clientGroups/types';

// STORE
import {
  ClientGroupsStoreContext,
  IClientGroupsStore,
} from 'stores/clientGroups';

interface IClientGroupsRow {
  rowData: ClientGroupType;
  children: ReactNode;
}

const useStyles = makeStyles(({ palette }) => ({
  row: {
    padding: '20px !important',
    '&:hover': {
      backgroundColor: palette.primary.light,
      cursor: 'pointer',
    },
  },
  selected: {
    backgroundColor: `${palette.primary.light} !important`,
  },
}));

const ClientGroupsRow: React.FC<IClientGroupsRow> = ({ rowData, children }) => {
  const {
    currentClientGroup,
    setCurrentClientGroup,
  }: IClientGroupsStore = useContext(ClientGroupsStoreContext);

  const isActive = currentClientGroup?.PKey === rowData.PKey;
  const classes = useStyles(isActive);

  const handleClick = () => {
    setCurrentClientGroup(rowData);
  };

  return (
    <TableRow
      onClick={handleClick}
      className={
        isActive ? `${classes.row} ${classes.selected}` : `${classes.row} `
      }
    >
      {children}
    </TableRow>
  );
};

export default observer(ClientGroupsRow);
