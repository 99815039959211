import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import { Button, Grid, IconButton, InputAdornment } from '@material-ui/core';
import { observer } from 'mobx-react';

// COMPONENTS:
import InputField from 'common/components/formik/InputField';
import ButtonLoading from 'common/components/buttons/ButtonLoading';
import CustomModal from 'common/components/CustomModal';
import ModalRow from 'common/components/CustomModal/ModalRow';
import SelectField from 'common/components/formik/SelectField';

// TYPES
import { ClientType, FormValuesType } from 'api/clients/types';

// ICONS
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import RemoveIcon from '@material-ui/icons/Remove';

// STORES
import { ClientsStoreContext, IClientsStore } from 'stores/clients';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import * as Yup from 'yup';

interface IEditClientModal {
  intl: IntlShape;
  currentClient?: ClientType;
  isOpen: boolean;
  toggleModal: () => void;
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  additionalSectionButton: {
    '& Svg': {
      width: 16,
      marginRight: spacing(0.5),
    },
  },
  removeSectionButton: {
    color: palette.error.main,
    '& Svg': {
      width: 16,
      marginRight: spacing(0.5),
      color: palette.error.main,
    },
  },
  editIcon: {
    height: 14,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  row: {
    display: 'flex',
    paddingBottom: spacing(2.5),
  },
  sectionHeader: {
    paddingBottom: spacing(1),
  },
  button: {
    marginTop: spacing(4),
  },
  section: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  additionalSection: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
}));

const EditClientModal: React.FC<IEditClientModal> = ({
  intl,
  currentClient,
  isOpen,
  toggleModal,
}) => {
  const {
    saveClient,
    getClientById,
    getClients,
    getAllClientGroups,
    allClientGroups,
  }: IClientsStore = useContext(ClientsStoreContext);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    getAllClientGroups();
  }, [getAllClientGroups]);
  const [hasAdditionalAddress, setHasAdditionalAddress] = useState(false);

  const handleSubmit = async (values: FormValuesType) => {
    await saveClient(values);
    toggleModal();

    currentClient
      ? await getClientById(currentClient!.PKey)
      : await getClients();
  };

  const classes = useStyles();

  const modalTitle = currentClient ? (
    <FormattedMessage
      id="Company.Client.editModal.title"
      values={{
        b: (chunk: string) => <b>{chunk}</b>,
        value: `${currentClient?.PKey}, ${currentClient?.Name}`,
      }}
    />
  ) : (
    <FormattedMessage id="Company.Clients.addClient" />
  );

  const allClientGroupsOptions = allClientGroups?.map(type => ({
    value: type.PKey!.toString(),
    label: type.Name,
  }));

  const initialValues: FormValuesType = {
    name: currentClient?.Name || '',
    id: currentClient?.PKey || null,
    clientGroup:
      (currentClient &&
        allClientGroupsOptions.find(
          option => option.value === currentClient?.ClientGroupPKey.toString()
        )) ||
      '',
    password: currentClient?.Password || '',
    address: currentClient?.Address1 || '',
    address2: currentClient?.Address2 || '',
    postNr: currentClient?.PostalCode || '',
    orgNr: currentClient?.OrgNo || '',
    r3: currentClient?.R3 || '',
    bnr: '',
    override: currentClient?.VippsOverride || false,
    vippsClientId: currentClient?.VippsClientId || '',
    clientSecret: currentClient?.VippsClientSecret || '',
    subscriptionKey: currentClient?.VippsClientSubKey || '',
    merchantId: currentClient?.VippsMerchantId || '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('This field cannot be empty!')
      .max(100, 'Name can be max 100 characters long'),
    password: Yup.string()
      .required('This field cannot be empty!')
      .max(50, 'Password can be max 50 characters long'),
    postNr: Yup.string()
      .min(0)
      .max(4)
      .strict(true),
    orgNr: Yup.string()
      .min(0)
      .max(20)
      .strict(true),
    address: Yup.string().max(100, 'Address can be max 100 characters long'),
    address2: Yup.string().max(100, 'Address can be max 100 characters long'),
    clientSecret: Yup.string().max(
      100,
      'Client secret can be max 100 characters long'
    ),
    subscriptionKey: Yup.string().max(
      100,
      'Subscription key can be max 100 characters long'
    ),
    merchantId: Yup.string().max(
      100,
      'Merchant ID can be max 100 characters long'
    ),
  });

  return (
    <CustomModal isOpen={isOpen} toggle={toggleModal} modalTitle={modalTitle}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => {
          return (
            <Form noValidate>
              <>
                <ModalRow rowTitle="General">
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <InputField
                        name="name"
                        placeholder={intl.formatMessage({
                          id: 'Global.form.placeholders.name',
                        })}
                        label={
                          <FormattedMessage id={`Global.form.labels.name`} />
                        }
                        size="small"
                        required
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <SelectField
                        name="clientGroup"
                        options={allClientGroupsOptions}
                        defaultValue={initialValues.clientGroup}
                        placeholder={
                          <FormattedMessage id="Company.Employees.allPlaceholder" />
                        }
                        label={
                          <FormattedMessage id="Company.Clients.clientGroup" />
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <InputField
                        name="id"
                        label={
                          <FormattedMessage id={`Global.form.labels.id`} />
                        }
                        placeholder={intl.formatMessage({
                          id: 'Global.form.placeholders.auto',
                        })}
                        size="small"
                        disabled
                      />
                    </Grid>
                  </Grid>
                </ModalRow>

                <ModalRow rowTitle="Client password">
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <InputField
                        name="password"
                        placeholder={intl.formatMessage({
                          id: 'Global.form.placeholders.password',
                        })}
                        label={
                          <FormattedMessage
                            id={`Global.form.labels.password`}
                          />
                        }
                        size="small"
                        required
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </ModalRow>

                <ModalRow rowTitle="Address 1">
                  <Grid container spacing={2} className={classes.section}>
                    <Grid item xs={6}>
                      <InputField
                        name="address"
                        placeholder={intl.formatMessage({
                          id: 'Global.form.placeholders.address',
                        })}
                        label={
                          <FormattedMessage id={`Global.form.labels.address`} />
                        }
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <InputField
                        name="postNr"
                        placeholder={intl.formatMessage({
                          id: 'Global.form.placeholders.postNr',
                        })}
                        label={
                          <FormattedMessage id={`Global.form.labels.postNr`} />
                        }
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <InputField
                        name="orgNr"
                        placeholder={intl.formatMessage({
                          id: 'Global.form.placeholders.orgNr',
                        })}
                        label={
                          <FormattedMessage id={`Global.form.labels.orgNr`} />
                        }
                        size="small"
                      />
                    </Grid>
                    {!hasAdditionalAddress && (
                      <Button
                        variant="text"
                        color="primary"
                        onClick={() =>
                          setHasAdditionalAddress(!hasAdditionalAddress)
                        }
                        className={classes.additionalSectionButton}
                      >
                        <AddOutlinedIcon />
                        <FormattedMessage
                          id={`Company.Client.additionalAddress`}
                        />
                      </Button>
                    )}
                  </Grid>
                </ModalRow>

                {hasAdditionalAddress && (
                  <ModalRow rowTitle="Address 2">
                    <Grid
                      container
                      spacing={2}
                      className={classes.additionalSection}
                    >
                      <Grid item xs={6}>
                        <InputField
                          name="address2"
                          placeholder={intl.formatMessage({
                            id: 'Global.form.placeholders.address',
                          })}
                          label={
                            <FormattedMessage
                              id={`Global.form.labels.address`}
                            />
                          }
                          size="small"
                        />
                      </Grid>

                      <Button
                        variant="text"
                        color="primary"
                        onClick={() =>
                          setHasAdditionalAddress(!hasAdditionalAddress)
                        }
                        className={classes.removeSectionButton}
                      >
                        <RemoveIcon />
                        <FormattedMessage
                          id={`Company.Client.removeAdditionalAddress`}
                        />
                      </Button>
                    </Grid>
                  </ModalRow>
                )}
                <ModalRow rowTitle="Accounting">
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <InputField
                        name="r3"
                        placeholder={intl.formatMessage({
                          id: 'Global.form.placeholders.r3',
                        })}
                        label={
                          <FormattedMessage id={`Global.form.labels.r3`} />
                        }
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </ModalRow>

                <ModalRow rowTitle="Vipps">
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <InputField
                        name="vippsClientId"
                        label={
                          <FormattedMessage
                            id={`Global.form.labels.clientId`}
                          />
                        }
                        placeholder={intl.formatMessage({
                          id: 'Global.form.placeholders.clientId',
                        })}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputField
                        name="subscriptionKey"
                        placeholder={intl.formatMessage({
                          id: 'Global.form.placeholders.subscriptionKey',
                        })}
                        label={
                          <FormattedMessage
                            id={`Global.form.labels.subscriptionKey`}
                          />
                        }
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <InputField
                        name="clientSecret"
                        placeholder={intl.formatMessage({
                          id: 'Global.form.placeholders.clientSecret',
                        })}
                        label={
                          <FormattedMessage
                            id={`Global.form.labels.clientSecret`}
                          />
                        }
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <InputField
                        name="merchantId"
                        placeholder={intl.formatMessage({
                          id: 'Global.form.placeholders.merchantId',
                        })}
                        label={
                          <FormattedMessage
                            id={`Global.form.labels.merchantId`}
                          />
                        }
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </ModalRow>

                <ButtonLoading
                  type="submit"
                  value={currentClient ? 'Global.save' : 'Global.create'}
                  state={isSubmitting}
                  disabled={isSubmitting}
                  className={classes.button}
                />
              </>
            </Form>
          );
        }}
      </Formik>
    </CustomModal>
  );
};

export default injectIntl(observer(EditClientModal));
