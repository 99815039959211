import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '@material-ui/core';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import { makeStyles } from '@material-ui/core';
import { useIntl } from 'react-intl';

// COMPONENTS:
import SearchInput from 'common/components/SearchInput';
import Filters from './Filters';

// HELPERS
import { useDebounce } from 'common/helpers/useDebounce';

// STORES
import { IWaresStore, WaresStoreContext } from 'stores/wares';

const useStyles = makeStyles(({ spacing }) => ({
  row: {
    display: 'flex',
  },
  filterButton: {
    marginLeft: spacing(3),
    padding: 0,
    minWidth: 0,
    height: 32,
    width: 32,
    '& Svg': {
      width: 16,
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  expansion: {
    boxShadow: 'none',
    padding: spacing(3, 3, 0, 3),
    zIndex: 3,
  },
  expansionDetails: {
    paddingTop: spacing(2),
  },
  divider: {
    margin: spacing(2, 0, 1),
  },
}));

interface IToolbar {
  search: string;
  setSearch: (value: string) => void;
}

const Toolbar: React.FC<IToolbar> = () => {
  const classes = useStyles();
  const intl = useIntl();

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const { getWares }: IWaresStore = useContext(WaresStoreContext);

  useEffect(() => {
    getWares(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  return (
    <div>
      <ExpansionPanel className={classes.expansion}>
        <div className={classes.row}>
          <SearchInput
            placeholder={intl.formatMessage({
              id: 'Accounting.Wares.filters.searchPlaceholder',
            })}
            onChange={e => setSearch(e.target.value)}
            value={search}
          />
          <ExpansionPanelSummary aria-controls="panel1a-content">
            <Button
              variant="outlined"
              color="primary"
              size="small"
              className={classes.filterButton}
            >
              <FilterListOutlinedIcon />
            </Button>
          </ExpansionPanelSummary>
        </div>
        <ExpansionPanelDetails className={classes.expansionDetails}>
          <Filters search={search} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <Divider className={classes.divider} />
    </div>
  );
};

export default Toolbar;
