export const palette = {
  primary: {
    main: '#3aaa46',
    light: '#e6f4e7',
    dark: '#007a18',
    contrastText: '#f5f5f5',
  },
  secondary: {
    main: '#3d464c',
    light: '#636b6f',
    dark: '#2a3135',
    contrastText: '#f5f5f5',
  },
  action: {
    disabled: '#919191',
    disabledBackground: '#d3d3d3',
  },
  background: {
    paper: '#fff',
    default: '#f5f5f5',
  },
  text: {
    primary: '#3d464c',
  },
  error: {
    main: '#d30000',
  },
  common: {
    white: '#fff',
    black: '#000',
  },
};
