import React, { useContext, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { useParams } from '@reach/router';
import { makeStyles } from '@material-ui/core';

// TYPES
import { IGridTableSchema } from 'common/components/grid/types';

// COMPONENTS:
import GridTable from 'common/components/grid/GridTable';
import ApartmentRow from './ApartmentRow';
import Toolbar from './Toolbar';

// STORE:
import { CustomersStoreContext, ICustomersStore } from 'stores/customers';
import { useDebounce } from 'common/helpers/useDebounce';
import useLocalSearch from 'common/helpers/useLocalSearch';

const schema: IGridTableSchema<any> = {
  header: {
    columns: [
      {
        id: 'BoligNummer',
        name: 'Apartment nr',
      },
      {
        id: 'HusNummer',
        name: 'Housenr',
      },
      {
        id: 'Bokstav',
        name: 'Letter',
      },
      {
        id: 'person.Navn',
        name: 'Owner',
      },
    ],
  },
  row: {
    id: 'ID',
    formatter: ApartmentRow,
    cells: [
      {
        key: 'BoligNummer',
      },
      {
        key: 'HusNummer',
      },
      {
        key: 'Bokstav'
      },
      {
        key: 'person.Navn',
      },
    ],
  },
};

const useStyles = makeStyles(() => ({
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0px 12px',
    padding: `0px 24px`,
  },
}));

const AllApartmentsTable = () => {
  const {
    getApartmentNumbers,
    getApartmentNumbersState,
    allApartmentNumbers,
  }: ICustomersStore = useContext(CustomersStoreContext);

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const classes = useStyles();
  const params = useParams();

  const getApartmentsData = useCallback(() => getApartmentNumbers( {
    customerId: params.customerId,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [
    getApartmentNumbers,
    params.customerId,
    debouncedSearch
  ]);

  let apartmentsWithDuplicates: any[] = [];
  const apartmentsData = allApartmentNumbers[0]?.RegisterEnheter;

  apartmentsData?.forEach((apartment: any) => apartment.Personer?.forEach((person: any) => {
    const BoligNummer = apartment.BoligNummer;
    const HusNummer = apartment.HusNummer;
    const Bokstav = apartment.Bokstav;
    const RegisterEnhetId = apartment.RegisterEnhetId;
    return apartmentsWithDuplicates.push({ BoligNummer, HusNummer, Bokstav, RegisterEnhetId, person })
  }));

  const { data } = useLocalSearch(
    search,
    ['Navn', 'HusNummer', 'BoligNummer', 'Bokstav'],
    apartmentsWithDuplicates
  );

  return (
    <GridTable
      tableSchema={schema}
      data={data}
      loadingState={getApartmentNumbersState}
      getData={getApartmentsData}
      customToolbar={<Toolbar search={search} setSearch={setSearch} />}
      tableClassname={classes.table}
    />
  );
};

export default observer(AllApartmentsTable);
