import { createContext } from 'react';
import { action, observable } from 'mobx';

// API:
import { getAccessToTableau } from 'api/tableau';

// TYPES:
import { LoadingState, PromiseStates } from 'api/types';

export interface ITableauStore {
  // Observables
  getAccessToTableauState: LoadingState;
  ticket: string;
  tableauVizUrl: string;

  // Actions
  getAccessToTableau: () => Promise<void>;
}

class TableauStore implements ITableauStore {
  @observable
  getAccessToTableauState: ITableauStore['getAccessToTableauState'] = null;
  @observable ticket: ITableauStore['ticket'] = '';
  @observable tableauVizUrl: ITableauStore['tableauVizUrl'] = '';

  @action
  getAccessToTableau = async () => {
    try {
      this.getAccessToTableauState = PromiseStates.PENDING;
      const response = await getAccessToTableau();
      this.ticket = response.data;
      this.getAccessToTableauState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getAccessToTableauState = PromiseStates.REJECTED;
    }
  };
}

export const TableauStoreContext = createContext(new TableauStore());
