import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import { Grid, Paper, Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

// COMPONENTS:
import CustomersTable from './components/Table';
import Header from 'common/components/Header';
import CustomerDetails from './components/CustomerPreviewDetails';
import CustomerModal from '../Customer/components/Modal';

// STORE:
import { CustomersStoreContext, ICustomersStore } from 'stores/customers';

// STYLES:
import { useStyles } from './index.styles';

// ICONS:
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';

const List = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const { currentCustomer }: ICustomersStore = useContext(
    CustomersStoreContext
  );

  const classes = useStyles();

  const breadcrumbs = [
    {
      name: 'Customers',
      path: '/customers',
    },
  ];

  return (
    <div className={classes.container}>
      <Header
        breadcrumbs={breadcrumbs}
        title={<FormattedMessage id="Customers.List.customers" />}
      >
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.addButton}
          onClick={toggleModal}
        >
          <AddOutlinedIcon />
          <FormattedMessage id="Agreements.List.addCustomer" />
        </Button>
      </Header>
      <Grid container className={classes.contentContainer}>
        <Grid item xs={12} md={6} className={classes.column}>
          <CustomersTable />
        </Grid>

        <Grid item xs={12} md={6} className={classes.column}>
          <Paper className={classes.paper}>
            {!currentCustomer ? (
              <div className={classes.selectCustomerPlaceholder}>
                <WorkOutlineIcon className={classes.selectedIcon} />
                <FormattedMessage id="Customers.List.selectCustomerPlaceholder" />
              </div>
            ) : (
              <CustomerDetails customer={currentCustomer} />
            )}
          </Paper>
        </Grid>
      </Grid>
      {isOpen && <CustomerModal isOpen={isOpen} toggleModal={toggleModal} />}
    </div>
  );
};

export default observer(List);
