import { createContext } from 'react';
import { action, observable } from 'mobx';

// API:
import { getAllWares, getVat, getUnit, saveWare } from 'api/wares';

// TYPES:
import {
  WareFilters,
  WaresType,
  WareType,
  VatOptionTypes,
  UnitTypes,
} from 'api/wares/types';
import { LoadingState, PromiseStates } from 'api/types';
import { IFormValues } from 'modules/Accounting/Wares/Table/Toolbar/Filters';
import { SortingOrder } from 'common/components/grid/types';

export interface IWaresStore {
  // Observables
  wares: WaresType;
  currentWare: WareType | null;
  getWaresState: LoadingState;
  getVatState: LoadingState;
  saveWareState: LoadingState;
  getUnitState: LoadingState;
  filters: WareFilters;
  vatOptions: VatOptionTypes;
  units: UnitTypes;
  addWareError: string;
  sortBy?: keyof WareType;
  order?: SortingOrder;

  // Actions
  getWares: (search?: string) => Promise<void>;
  getWareById: (id: string) => Promise<void>;
  setCurrentWare: (rowData: WareType | null) => void;
  setFilters: (filters: IFormValues) => void;
  getVat: () => Promise<void>;
  getUnits: () => Promise<void>;
  saveWare: (operation: string, values: any) => Promise<boolean>;
  clearWareError: () => void;
  sortWaresBy: (sortBy: keyof WareType, order: SortingOrder) => void;
  sortWaresStringsBy: (sortBy: keyof WareType, order: SortingOrder) => void;
}

class WaresStore implements IWaresStore {
  @observable wares: IWaresStore['wares'] = [];
  @observable currentWare: IWaresStore['currentWare'] = null;
  @observable
  getWaresState: IWaresStore['getWaresState'] = null;
  @observable
  saveWareState: IWaresStore['saveWareState'] = null;
  @observable
  getVatState: IWaresStore['getVatState'] = null;
  @observable
  getUnitState: IWaresStore['getUnitState'] = null;
  @observable filters: IWaresStore['filters'] = {};
  @observable vatOptions: IWaresStore['vatOptions'] = [];
  @observable units: IWaresStore['units'] = [];
  @observable addWareError: IWaresStore['addWareError'] = '';
  @observable sortBy: IWaresStore['sortBy'] = 'BKey';

  @action
  getWares = async (search: string = '') => {
    try {
      this.getWaresState = PromiseStates.PENDING;
      const { data } = await getAllWares({
        BNr: 0,
        ...this.filters,
        Navn: search,
      });
      this.wares = data;
      this.getWaresState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getWaresState = PromiseStates.REJECTED;
    }
  };

  @action
  saveWare = async (operation: string, values: any) => {
    try {
      this.saveWareState = PromiseStates.PENDING;
      await saveWare({
        Operation: operation === 'add' ? 10 : 20,
        Entity: {
          BKey: values.id,
          Navn: values.name,
          Status: 0,
          MVAPKeyUt: values.vat.value
            ? parseInt(values.vat.value)
            : parseInt(values.vat),
          VeilPris: values.nettoPrice,
          EndreVedSalg: values.changeOnSale.value
            ? JSON.parse(values.changeOnSale.value)
            : JSON.parse(values.changeOnSale),
          OpphavsKode: values.nutritionalProduct,
          AnsvarsEnhetNavn: values.vismaName,
          AnsvarsEnhet: values.number,
          KontoNr: values.accountNumber,
          EnhetPKey: values.units.value
            ? parseInt(values.units.value)
            : parseInt(values.units),
          ExternalId: values.wareCode,
        },
      });
      this.saveWareState = PromiseStates.FULFILLED;
      return true;
    } catch (err) {
      this.addWareError = err.data.ExceptionMessage;
      this.saveWareState = PromiseStates.REJECTED;
      return false;
    }
  };

  @action
  getVat = async () => {
    try {
      this.getVatState = PromiseStates.PENDING;
      const { data } = await getVat();
      this.vatOptions = data;
      this.getVatState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getVatState = PromiseStates.REJECTED;
    }
  };

  @action
  getUnits = async () => {
    try {
      this.getUnitState = PromiseStates.PENDING;
      const { data } = await getUnit();
      this.units = data;
      this.getUnitState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getUnitState = PromiseStates.REJECTED;
    }
  };

  @action
  getWareById = async (id: string) => {
    try {
      this.getWaresState = PromiseStates.PENDING;
      const { data }: { data: WareType[] } = await getAllWares({
        BNr: 0,
        BKey: id,
      });
      if (data.length > 1) {
        this.currentWare = data.filter(ware => ware.BKey === id)[0];
      } else {
        this.currentWare = data[0];
      }
      this.getWaresState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getWaresState = PromiseStates.REJECTED;
    }
  };

  @action setCurrentWare = (rowData: WareType | null) => {
    this.currentWare = rowData;
  };

  @action setFilters = ({
    wareStatus,
    priceFrom,
    priceTo,
    vat,
  }: IFormValues) => {
    const bindedFilters: any = {
      VeilPrisFra: priceFrom,
      VeilPrisTil: priceTo,
      MVAPKeyUt: vat && parseInt(vat),
      Status: wareStatus && parseInt(wareStatus),
    };

    this.filters = Object.keys(bindedFilters).reduce((acc, curr) => {
      if (bindedFilters[curr] !== '' || !isNaN(bindedFilters[curr])) {
        return { ...acc, [curr]: bindedFilters[curr] };
      } else {
        return acc;
      }
    }, {});
  };

  @action
  sortWaresBy = (sortBy: keyof WareType, order: SortingOrder = 'asc') => {
    this.sortBy = sortBy;
    this.wares = this.wares.slice().sort((a, b) => {
      let comparison = 0;
      if (a[sortBy]! > b[sortBy]!) {
        comparison = 1;
      } else if (a[sortBy]! < b[sortBy]!) {
        comparison = -1;
      }

      return order === 'asc' ? comparison : -comparison;
    });
  };

  @action
  sortWaresStringsBy = (
    sortBy: keyof WareType,
    order: SortingOrder = 'asc'
  ) => {
    this.sortBy = sortBy;

    this.wares = this.wares.sort((a, b) => {
      const firstValue = a[sortBy]?.toString() || '';
      const secondValue = b[sortBy]?.toString() || '';

      return order === 'asc'
        ? firstValue.toString().localeCompare(secondValue)
        : secondValue.localeCompare(firstValue);
    });
  };

  @action clearStore = () => {
    this.wares = [];
    this.currentWare = null;
    this.getWaresState = null;
  };

  @action clearWareError = () => {
    this.addWareError = '';
  };
}

export const WaresStoreContext = createContext(new WaresStore());
