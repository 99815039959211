import React, { useState, useEffect, useRef } from 'react';
import {
  makeStyles,
  Button,
  ButtonProps,
  CircularProgress,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

// ICONS
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

// HELPERS
import { PromiseStates } from 'api/types';

interface IButton {
  disabled?: boolean;
  value?: string;
  state?: any;
  isLoading?: boolean;
}

const ButtonLoading: React.FC<ButtonProps & IButton> = ({
  disabled,
  state,
  isLoading,
  value,
  children,
  ...props
}) => {
  const useStyles = makeStyles(({ palette }) => ({
    buttonProgress: {
      color: palette.common.white,
      position: 'absolute',
    },
    button: {
      minHeight: 32,
    },
  }));

  const [successState, setSuccessState] = useState(false);

  const [failState, setFailState] = useState(false);
  const timeout: any = useRef(null);

  const classes = useStyles();

  const disableSuccess = () => {
    timeout.current = setTimeout(() => {
      setSuccessState(false);
    }, 1000);
  };

  const disableFail = () => {
    timeout.current = setTimeout(() => {
      setFailState(false);
    }, 1000);
  };

  useEffect(() => {
    if (successState) {
      disableSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successState]);

  useEffect(() => {
    if (failState) {
      disableFail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [failState]);

  useEffect(() => {
    const loadingState = isLoading || state === PromiseStates.PENDING;
    const success = state === PromiseStates.FULFILLED;
    const failed = state === PromiseStates.REJECTED;

    switch (true) {
      case !loadingState && success:
        setSuccessState(true);
        break;

      case !loadingState && failed:
        setFailState(true);
        break;

      default:
        break;
    }
  }, [isLoading, state]);

  useEffect(() => {
    return () => clearTimeout(timeout.current);
  }, []);


  const loading = isLoading || state === PromiseStates.PENDING;
  return (
    <Button
      color="primary"
      variant="contained"
      disabled={disabled || loading || successState}
      classes={{ root: classes.button }}
      {...props}
    >
      {loading && (
        <CircularProgress
          data-testid="button-loading-loading"
          size={18}
          className={classes.buttonProgress}
        />
      )}

      {successState && !isLoading && (
        <CheckIcon
          data-testid="button-loading-success"
          fontSize="small"
          className={classes.buttonProgress}
        />
      )}

      {failState && (
        <CloseIcon
          data-testid="button-loading-failure"
          fontSize="small"
          className={classes.buttonProgress}
        />
      )}

      {value && !(loading || successState || failState) && (
        <FormattedMessage id={value} />
      )}

      {children && !(loading || successState || failState) && children}
    </Button>
  );
};

export default ButtonLoading;
