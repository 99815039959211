import React, { Fragment, ReactNode, useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { Location } from '@reach/router';
import { observer } from 'mobx-react';

// COMPONENTS:
import SideBar from 'common/components/SideBar';

// STORE:
import { SidebarStoreContext, ISidebarStore } from 'stores/sidebar';

interface IPrivateLayout {
  children: ReactNode;
}

const useStyles = makeStyles(({ constants }) => ({
  container: {
    marginLeft: (isSidebarOpen: boolean) =>
      isSidebarOpen ? constants.sideBarOpenWidth : constants.sideBarCloseWidth,
    maxWidth: (isSidebarOpen: boolean) =>
      isSidebarOpen
        ? `calc(100vw - ${constants.sideBarOpenWidth}px)`
        : `calc(100vw - ${constants.sideBarCloseWidth}px)`,
    minHeight: '100vh',
  },
}));

const PrivateLayout: React.FC<IPrivateLayout> = ({ children }) => {
  const { isSidebarOpen, toggleSidebar }: ISidebarStore = useContext(
    SidebarStoreContext
  );

  const toggleNavigation = () => {
    toggleSidebar();
  };

  const classes = useStyles(isSidebarOpen);
  return (
    <Fragment>
      <Location>
        {({ location }) => (
          <SideBar
            currentLocation={location.pathname}
            isNavigationOpen={isSidebarOpen}
            toggleNavigation={toggleNavigation}
          />
        )}
      </Location>

      <div className={classes.container}>{children}</div>
    </Fragment>
  );
};

export default observer(PrivateLayout);
