import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(({ palette, spacing, shape }) => ({
  alert: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(1),
    marginBottom: spacing(2),
    borderRadius: shape.borderRadius,
    background: palette.error.main,
    color: palette.common.white,
  },
  message: {
    paddingLeft: spacing(1),
    fontSize: 12,
  },
  alertIcon: {
    fontSize: 18,
  },
}));

interface IAlertProps {
  type?: string;
  messageId?: string;
  children?: React.ReactNode;
}

const Alert: React.FC<IAlertProps> = ({ type, messageId, children }) => {
  const classes = useStyles();

  const renderIcon = () => {
    switch (true) {
      case type === 'danger':
        return <ErrorOutlineIcon className={classes.alertIcon} />;
      case type === 'success':
        return <CheckCircleIcon className={classes.alertIcon} />;
      default:
        return '';
    }
  };

  return (
    <div className={classes.alert}>
      {renderIcon()}
      <Typography className={classes.message}>
        {messageId && <FormattedMessage id={messageId!} />}
        {children}
      </Typography>
    </div>
  );
};

export default Alert;
