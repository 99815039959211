import { createContext } from 'react';
import { action, observable } from 'mobx';

export interface ISidebarStore {
  // Observables
  isSidebarOpen: boolean;
  currentlyOpenedItem: any;
  activeItem: any;

  // Actions
  toggleSidebar: () => void;
  setCurrentlyOpenedItem: (item: any) => void;
  setActiveItem: (item: any) => void;
}

class SidebarStore implements ISidebarStore {
  @observable isSidebarOpen: ISidebarStore['isSidebarOpen'] =
    localStorage.getItem('navigationToggle') === 'true';

  @observable currentlyOpenedItem: ISidebarStore['currentlyOpenedItem'] = null;

  @observable activeItem: ISidebarStore['activeItem'] = null;

  @action toggleSidebar = () => {
    this.isSidebarOpen = !this.isSidebarOpen;
    const navigationStatus = localStorage.getItem('navigationToggle');

    if (navigationStatus === 'false') {
      localStorage.setItem('navigationToggle', 'true');
    } else {
      localStorage.setItem('navigationToggle', 'false');
    }

    // trigger resize event for MUI <Tabs /> component to update tab indicator position
    window.dispatchEvent(new CustomEvent('resize'));
  };

  @action setCurrentlyOpenedItem = (item: any) => {
    this.currentlyOpenedItem = item;
  };

  @action setActiveItem = (item: any) => {
    this.activeItem = item;
  };
}

export const SidebarStoreContext = createContext(new SidebarStore());
