import React from 'react';
import { makeStyles, Typography, Grid } from '@material-ui/core';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { Form, Formik } from 'formik';

// COMPONENTS
import SearchInput from 'common/components/SearchInput';
import { AutoSubmit } from 'common/components/formik/AutoSubmitForm';
import InputField from 'common/components/formik/InputField';

// STORE
import SelectField from 'common/components/formik/SelectField';

// TYPES
import { IDateFormValues } from '../index';

type SelectValue = { value: string; label: string };

interface IToolbar {
  intl: IntlShape;
  transactionTypeOptions: SelectValue[];
  quotaTypesOptions: SelectValue[];
  apartmentNumbersFilters: SelectValue[];
  cardNumbersFilter: SelectValue[];
  apartmentNumberFilter: string;
  transactionTypeFilter: any;
  quotaTypeFilter: any;
  customerTypeFilter: any;
  setTransactionTypeFilter: (value: any) => void;
  setCustomerTypeFilter: (value: any) => void;
  setApartmentNumberFilter: (value: any) => void;
  setQuotaTypeFilter: (value: any) => void;
  search: string;
  setSearch: (value: any) => void;
  filterByDate: (values: IDateFormValues) => Promise<void>;
  isApartment: boolean;
  cardFilter: string;
  setCardFilter: (value: string) => void;
  dateFilters: {
    dateFrom: string;
    dateTo: string;
  }
}

const useStyles = makeStyles(({ spacing, typography }) => ({
  header: {
    padding: spacing(3),
  },
  heading: {
    fontWeight: typography.fontWeightBold,
    marginBottom: spacing(2),
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(0, 8, 2, 0),
  },
  select: {
    zIndex: 3,
  },
  dateFrom: {
    margin: spacing(0, 2),
  },
}));

const Toolbar: React.FC<IToolbar> = ({
  intl,
                                       setTransactionTypeFilter,
  search,
  setSearch,
  filterByDate,
                                       transactionTypeOptions,
                                       quotaTypeFilter,
  setQuotaTypeFilter,
  quotaTypesOptions,
  customerTypeFilter,
                                       isApartment,
                                       apartmentNumbersFilters,
  apartmentNumberFilter,
  setApartmentNumberFilter,
  cardFilter,
  setCardFilter,
  cardNumbersFilter,
  dateFilters
}) => {
  const classes = useStyles();

  const initialValues = {
    transactionTypeFilter: transactionTypeOptions[0],
    quotaTypeFilter: quotaTypeFilter,
    customerTypeFilter: customerTypeFilter,
    apartmentNumberFilter: apartmentNumberFilter,
    cardFilter: cardFilter
  };

  const initialDateValues: IDateFormValues = {
    dateFrom: dateFilters.dateFrom,
    dateTo: '',
  };

  return (
    <Grid container className={classes.header}>
      <Grid item xs={12}>
        <Typography variant="h4" className={classes.heading}>
            <FormattedMessage id="Customer.Transactions.heading" />
        </Typography>
      </Grid>

      <Grid item xs={12} className={classes.search}>
        <SearchInput
          placeholder={intl.formatMessage({
            id: 'Customer.Transactions.searchPlaceholder',
          })}
          onChange={e => setSearch(e.target.value)}
          value={search}
        />
      </Grid>

      <Grid item xs={2}>
        <Formik initialValues={{ transactionTypeFilter: initialValues.transactionTypeFilter }} onSubmit={value => setTransactionTypeFilter(value.transactionTypeFilter)}>
          {() => {
            return (
              <Form>
                <AutoSubmit />
                <SelectField
                  defaultValue={initialValues.transactionTypeFilter}
                  name="transactionTypeFilter"
                  options={transactionTypeOptions}
                  placeholder={
                    <FormattedMessage id="Customer.Transactions.transactionType.label" />
                  }
                  label={
                    <FormattedMessage id="Customer.Transactions.transactionType.label" />
                  }
                  className={classes.select}
                />
              </Form>
            )
          }}
        </Formik>
      </Grid>

      <Grid item xs={2} style={{ paddingLeft: 16 }}>
        <Formik initialValues={{ quotaTypeFilter: initialValues.quotaTypeFilter }} onSubmit={value => setQuotaTypeFilter(value.quotaTypeFilter)}>
          {() => {
            return (
              <Form>
                <AutoSubmit />
                <SelectField
                  name="quotaTypeFilter"
                  options={quotaTypesOptions}
                  isClearable
                  placeholder={
                    <FormattedMessage id="Customer.Transactions.quotaType" />
                  }
                  label={
                    <FormattedMessage id="Customer.Transactions.quotaType" />
                  }
                  className={classes.select}
                />
              </Form>
            )
          }}
        </Formik>
      </Grid>

      {isApartment && (
        <Grid item xs={2} style={{ paddingLeft: 16 }}>
          <Formik initialValues={{ apartmentNumberFilter: initialValues.apartmentNumberFilter }} onSubmit={value => setApartmentNumberFilter(value.apartmentNumberFilter)}>
            {() => {
              return (
                <Form>
                  <AutoSubmit />
                  <SelectField
                    name="apartmentNumberFilter"
                    options={apartmentNumbersFilters}
                    isClearable
                    placeholder={
                      <FormattedMessage id="Global.form.placeholders.apartmentNr" />
                    }
                    label={
                      <FormattedMessage id="Global.form.labels.apartmentNr" />
                    }
                    className={classes.select}
                  />
                </Form>
              )
            }}
          </Formik>
        </Grid>
      )}

      <Grid item xs={2} style={{ paddingLeft: 16 }}>
        <Formik initialValues={{ cardFilter: initialValues.cardFilter }} onSubmit={value => setCardFilter(value.cardFilter)}>
          {() => {
            return (
              <Form>
                <AutoSubmit />
                <SelectField
                  name="cardFilter"
                  options={cardNumbersFilter}
                  isClearable
                  placeholder={
                    <FormattedMessage id="Global.form.placeholders.cardNumber" />
                  }
                  label={
                    <FormattedMessage id="Global.form.labels.cardNumber" />
                  }
                  className={classes.select}
                />
              </Form>
            )
          }}
        </Formik>
      </Grid>

      <Grid item xs={4} style={{ marginTop: 4 }}>
        <Formik
          initialValues={initialDateValues}
          onSubmit={values => filterByDate(values)}
        >
          {() => {
            return (
              <Form>
                <AutoSubmit />
                <Grid container>
                  <Grid item xs={3} className={classes.dateFrom}>
                    <InputField
                      name="dateFrom"
                      label={
                        <FormattedMessage id="Global.form.labels.dateFrom" />
                      }
                      type="date"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InputField
                      name="dateTo"
                      label={
                        <FormattedMessage id="Global.form.labels.dateTo" />
                      }
                      type="date"
                    />
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default injectIntl(Toolbar);
