import React from 'react';
import {
  Grid,
  Divider,
  makeStyles,
  Menu,
  MenuItem,
  IconButton,
  Typography,
} from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';

// COMPONENTS:
import SearchInput from 'common/components/SearchInput';
import SelectField from 'common/components/formik/SelectField';

// HELPERS:
import { Form, Formik } from 'formik';
import { AutoSubmit } from 'common/components/formik/AutoSubmitForm';
import InputField from '../../../../common/components/formik/InputField';

// ICONS:
import FilterListIcon from '@material-ui/icons/FilterList';

// TYPES:
import { SearchFilters } from './index';

const useStyles = makeStyles(({ spacing }) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  dateFrom: {
    padding: spacing(0, 2),
  },
  filterButton: {
    marginLeft: spacing(3),
    padding: 0,
    minWidth: 0,
    height: 32,
    width: 32,
    '& Svg': {
      width: 16,
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  toolbar: {
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: spacing(3, 3, 0, 3),
    zIndex: 3,
  },
  gridItem: {
    zIndex: 10,
    paddingLeft: spacing(2),
  },
  divider: {
    margin: spacing(2, 0, 1),
  },
  filterIconButton: {
    paddingLeft: 0,
  },
  filterIcon: {
    paddingRight: spacing(0.5),
  },
}));

interface IFormValues {
  wasteType: string;
  binType: string;
  dateFrom: string;
  dateTo: string;
}

const renderFilterName = (filterName: string) => {
  switch (filterName) {
    case SearchFilters.CustomerName:
      return <FormattedMessage id="Wastebins.searchFilters.customerName" />;
    case SearchFilters.Address:
      return <FormattedMessage id="Wastebins.searchFilters.address" />;
    case SearchFilters.AgreementNumber:
      return <FormattedMessage id="Wastebins.searchFilters.agreementNr" />;
    case SearchFilters.Barcode:
      return <FormattedMessage id="Wastebins.searchFilters.barcode" />;
  }
};

const Toolbar: React.FC<any> = ({
  intl,
  search,
  setSearch,
  setPage,
  selectedSearchFilter,
  setSelectedSearchFilter,
  setSelectedFilters,
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const initialValues: IFormValues = {
    wasteType: '',
    binType: '',
    dateFrom: '',
    dateTo: '',
  };

  const wasteTypeOptions = [
    {
      label: 'Restavfall',
      value: 'Restavfall',
    },
    {
      label: 'Papir',
      value: 'Papir',
    },
  ];

  const binTypeOptions = [
    {
      label: '140L',
      value: '140L',
    },
    {
      label: '240L',
      value: '240L',
    },
    {
      label: '360L',
      value: '360L',
    },
    {
      label: '370L',
      value: '370L',
    },
    {
      label: '660L',
      value: '660L',
    },
  ];

  const setFilters = (values: IFormValues) => {
    setSelectedFilters({
      wasteType: values.wasteType,
      binType: values.binType,
      dateFrom: values.dateFrom,
      dateTo: values.dateTo,
    });
    setPage(0);
  };

  const changeSelectedSearchFilter = (filter: string) => {
    setSelectedSearchFilter(filter);
    handleClose();
  };

  return (
    <div>
      <Grid container className={classes.toolbar}>
        <Grid item xs={4} className={classes.row}>
          <IconButton
            onClick={handleClick}
            className={classes.filterIconButton}
          >
            <FilterListIcon classes={{ root: classes.filterIcon }} />
            <Typography variant="body1">
              {renderFilterName(selectedSearchFilter)}
            </Typography>
          </IconButton>
          <Menu
            id="selected-search-filter"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() =>
                changeSelectedSearchFilter(SearchFilters.CustomerName)
              }
            >
              <FormattedMessage id="Wastebins.searchFilters.customerName" />
            </MenuItem>
            <MenuItem
              onClick={() => changeSelectedSearchFilter(SearchFilters.Address)}
            >
              <FormattedMessage id="Wastebins.searchFilters.address" />
            </MenuItem>
            <MenuItem
              onClick={() =>
                changeSelectedSearchFilter(SearchFilters.AgreementNumber)
              }
            >
              <FormattedMessage id="Wastebins.searchFilters.agreementNr" />
            </MenuItem>
            <MenuItem
              onClick={() => changeSelectedSearchFilter(SearchFilters.Barcode)}
            >
              <FormattedMessage id="Wastebins.searchFilters.barcode" />
            </MenuItem>
          </Menu>
          <SearchInput
            placeholder={intl.formatMessage({
              id: 'Customers.List.searchPlaceholder',
            })}
            onChange={e => {
              setSearch(e.target.value);
              setPage(0);
            }}
            value={search}
          />
        </Grid>
        <Grid item xs={8} className={classes.gridItem}>
          <Formik initialValues={initialValues} onSubmit={setFilters}>
            {() => {
              return (
                <Form style={{ display: 'flex' }}>
                  <AutoSubmit />
                  <Grid container justify="space-around" spacing={2}>
                    <Grid item xs={3} className={classes.gridItem}>
                      <SelectField
                        name="wasteType"
                        isClearable
                        options={wasteTypeOptions}
                        label={
                          <FormattedMessage
                            id={`Global.form.labels.wasteType`}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.gridItem}>
                      <SelectField
                        name="binType"
                        isClearable
                        options={binTypeOptions}
                        label={
                          <FormattedMessage id={`Global.form.labels.binType`} />
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <InputField
                        name="dateFrom"
                        label={
                          <FormattedMessage id="Global.form.labels.dateFrom" />
                        }
                        type="date"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <InputField
                        name="dateTo"
                        label={
                          <FormattedMessage id="Global.form.labels.dateTo" />
                        }
                        type="date"
                      />
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
    </div>
  );
};

export default injectIntl(Toolbar);
