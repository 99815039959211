import React from 'react';
import { makeStyles } from '@material-ui/core';

// COMPONENTS:
import GridTable from 'common/components/grid/GridTable';
import DateCell from './DateCell';

// TYPES
import { IGridTableSchema } from 'common/components/grid/types';
import { QuotasTransactionsType } from 'api/customers/types';

const schema: IGridTableSchema<QuotasTransactionsType> = {
  wrapper: ({ children, ...rest }) => <div {...rest}>{children}</div>,
  header: {
    columns: [
      {
        id: 'Dato',
        name: 'Date',
      },
      {
        id: 'SaleNr',
        name: 'Billag',
      },
      {
        id: 'CustomerCardID',
        name: 'Card ID',
      },
      {
        id: 'Antall',
        name: 'Amount',
      },
      {
        id: 'Tekst',
        name: 'Comment',
      },
    ],
  },
  row: {
    id: 'BKey',
    cells: [
      {
        key: 'Dato',
        formatter: DateCell,
      },
      {
        key: 'SaleNr',
      },
      {
        key: 'CustomerCardID',
      },
      {
        key: 'Antall',
      },
      {
        key: 'Tekst',
      },
    ],
  },
};
const useStyles = makeStyles(({ palette }) => ({
  selectCardPlaceholder: {
    color: palette.action.disabledBackground,
    fontSize: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 700,
    height: '100%',
  },
  selectCardIcon: {
    fontSize: 240,
    color: palette.primary.contrastText,
  },
  gridTableContainer: {
    overflow: 'unset',
  },
}));

interface IQuotaTransactionsTable {
  loadingState: any;
  data: any;
  getData: any;
}

const QuotaTransactionsTable: React.FC<IQuotaTransactionsTable> = ({ loadingState, data, getData }) => {

  const classes = useStyles();

  return (
    <GridTable
      className={classes.gridTableContainer}
      tableSchema={schema}
      data={data}
      loadingState={loadingState}
      getData={getData}
    />
  );
};

export default QuotaTransactionsTable;
