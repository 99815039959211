import { createContext } from 'react';
import { action, observable } from 'mobx';

// API:
import {
  getWastebins,
  getWorkOrderImportHistory,
  createWorkOrder,
  searchWorkOrder,
  printWorkOrder,
} from 'api/wastebins';

// TYPES:
import { LoadingState, PromiseStates } from 'api/types';
import {
  CreateWorkOrderType,
  SearchWorkOrderResponseType,
  SearchWorkOrderType,
  WastebinResponseType,
} from 'api/wastebins/types';

export interface IWastebinsStore {
  // Observables
  allWastebins: WastebinResponseType | undefined;
  getAllWastebinsState: LoadingState;

  printWorkOrderState: LoadingState;

  importedWorkOrders: any[];
  selectedWorkOrdersToImport: CreateWorkOrderType[];

  selectedWorkOrdersToPrint: number[];

  workOrderImportHistoryData: any[];
  getWorkOrderImportHistoryState: LoadingState;

  createWorkOrderState: LoadingState;

  workOrderGroupData: SearchWorkOrderResponseType | undefined;
  searchWorkOrderState: LoadingState;

  // Actions
  getAllWastebins: (values: any) => Promise<void>;
  getWorkOrderImportHistory: (values: any) => Promise<void>;

  createWorkOrder: (values: CreateWorkOrderType[]) => Promise<void>;

  printWorkOrder: (values: {
    workOrderGroupId?: number;
    workOrderIds?: Array<number>;
  }) => Promise<void>;

  searchWorkOrder: (values: SearchWorkOrderType) => Promise<void>;

  setImportedWorkOrders: (data: CreateWorkOrderType[]) => void;

  addSelectedWorkOrderToImport: (selectedWorkOrder: any) => void;
  removeSelectedWorkOrderToImport: (selectedWorkOrder: any) => void;

  addSelectedWorkOrderToPrint: (selectedWorkOrder: any) => void;
  removeSelectedWorkOrderToPrint: (selectedWorkOrder: any) => void;

  clearSelectedWorkOrderToPrint: () => void;
  setSelectedWorkOrderToImport: (value: any[]) => void;
}

class WastebinsStore implements IWastebinsStore {
  @observable allWastebins: IWastebinsStore['allWastebins'] = undefined;
  @observable
  getAllWastebinsState: IWastebinsStore['getAllWastebinsState'] = null;

  @observable
  printWorkOrderState: IWastebinsStore['printWorkOrderState'] = null;

  @observable
  importedWorkOrders: IWastebinsStore['importedWorkOrders'] = [];
  workOrderImportHistoryData: IWastebinsStore['workOrderImportHistoryData'] = [];

  @observable
  selectedWorkOrdersToPrint: IWastebinsStore['selectedWorkOrdersToPrint'] = [];

  @observable
  selectedWorkOrdersToImport: IWastebinsStore['selectedWorkOrdersToImport'] = [];
  @observable
  getWorkOrderImportHistoryState: IWastebinsStore['getWorkOrderImportHistoryState'] = null;

  @observable
  createWorkOrderState: IWastebinsStore['createWorkOrderState'] = null;

  @observable
  workOrderGroupData: IWastebinsStore['workOrderGroupData'] = undefined;
  @observable
  searchWorkOrderState: IWastebinsStore['searchWorkOrderState'] = null;

  @action
  getAllWastebins = async (values: any) => {
    try {
      this.getAllWastebinsState = PromiseStates.PENDING;
      const { data } = await getWastebins(values);
      this.allWastebins = data;
      this.getAllWastebinsState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getAllWastebinsState = PromiseStates.REJECTED;
    }
  };

  @action
  getWorkOrderImportHistory = async () => {
    try {
      this.getWorkOrderImportHistoryState = PromiseStates.PENDING;
      const { data } = await getWorkOrderImportHistory();
      this.workOrderImportHistoryData = data;
      this.getWorkOrderImportHistoryState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getWorkOrderImportHistoryState = PromiseStates.REJECTED;
    }
  };

  @action
  createWorkOrder = async (values: CreateWorkOrderType[]) => {
    try {
      this.createWorkOrderState = PromiseStates.PENDING;
      await createWorkOrder(values);
      this.createWorkOrderState = PromiseStates.FULFILLED;
    } catch (err) {
      this.createWorkOrderState = PromiseStates.REJECTED;
    }
  };

  @action
  printWorkOrder = async (values: {
    workOrderGroupId?: number;
    workOrderIds?: Array<number>;
  }) => {
    try {
      this.printWorkOrderState = PromiseStates.PENDING;
      await printWorkOrder(values);
      this.printWorkOrderState = PromiseStates.FULFILLED;
    } catch (err) {
      this.printWorkOrderState = PromiseStates.REJECTED;
    }
  };

  @action
  searchWorkOrder = async (values: SearchWorkOrderType) => {
    try {
      this.searchWorkOrderState = PromiseStates.PENDING;
      const { data } = await searchWorkOrder(values);
      this.workOrderGroupData = data;
      this.searchWorkOrderState = PromiseStates.FULFILLED;
    } catch (err) {
      this.searchWorkOrderState = PromiseStates.REJECTED;
    }
  };

  @action
  setImportedWorkOrders = (data: any[]) => (this.importedWorkOrders = data);

  @action
  addSelectedWorkOrderToImport = (selectedWorkOrder: any) =>
    this.selectedWorkOrdersToImport.push(selectedWorkOrder);

  @action
  removeSelectedWorkOrderToImport = (selectedWorkOrder: any) =>
    (this.selectedWorkOrdersToImport = this.selectedWorkOrdersToImport.filter(
      listItem => listItem.Id !== selectedWorkOrder.Id
    ));

  @action
  addSelectedWorkOrderToPrint = (selectedWorkOrder: any) =>
    this.selectedWorkOrdersToPrint.push(selectedWorkOrder.Id);

  @action
  removeSelectedWorkOrderToPrint = (selectedWorkOrder: any) => {
    this.selectedWorkOrdersToPrint = this.selectedWorkOrdersToPrint.filter(
      selectedWorkOrderId => selectedWorkOrderId !== selectedWorkOrder.Id
    );
  };

  @action setSelectedWorkOrderToImport = (value: any[]) => {
    this.selectedWorkOrdersToImport = value;
  };

  @action clearSelectedWorkOrderToPrint = () => {
    this.selectedWorkOrdersToPrint = [];
  };
}

export const WastebinsStoreContext = createContext(new WastebinsStore());
