import React, { useContext } from 'react';
import { makeStyles, Typography, Grid, Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import { injectIntl, IntlShape } from 'react-intl';
import { Form, Formik } from 'formik';

// HELPERS
import { useModal } from 'common/helpers/useModal';
import { AutoSubmit } from 'common/components/formik/AutoSubmitForm';

// COMPONENTS:
import Steps from '../AddCardModal/Steps';
import SearchInput from 'common/components/SearchInput';
import SelectField from 'common/components/formik/SelectField';

// ICONS:
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';

// STORES:
import { CustomersStoreContext, ICustomersStore } from 'stores/customers';

// TYPES:
import { RegisterEnheterType } from 'api/customers/types';

// TYPES
interface IToolbar {
  intl: IntlShape;
  search: string;
  setSearch: (value: string) => void;
  setSelectedFilter: (value: string) => void;
  selectedFilter: string;
  getCards: () => void;
  apartment?: RegisterEnheterType;
}

interface IFormValues {
  activeFilter: string;
}

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  header: {
    padding: spacing(3),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'end',
  },
  heading: {
    fontWeight: typography.fontWeightBold,
  },
  addButton: {
    display: 'flex',
    '& Svg': {
      width: 18,
    },
  },
  modal: {
    backgroundColor: palette.primary.main,
  },
  rowHeader: {
    fontWeight: typography.fontWeightMedium,
    color: palette.secondary.light,
    textAlign: 'left',
  },
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  filtersRow: {
    paddingTop: spacing(2),
    display: 'flex',
    width: '100%',
  },
  search: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingLeft: spacing(2),
  },
  displayField: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
    minWidth: 160,
  },
}));

const Toolbar: React.FC<IToolbar> = ({
  intl,
  getCards,
  search,
  setSearch,
  selectedFilter,
  setSelectedFilter,
}) => {
  const classes = useStyles();

  const filterOptions = [
    {
      value: '1',
      label: intl.formatMessage({
        id: 'Customer.Cards.active',
      }),
    },
    {
      value: '2',
      label: intl.formatMessage({
        id: 'Customer.Cards.expiresSoon',
      }),
    },
    {
      value: '3',
      label: intl.formatMessage({
        id: 'Customer.Cards.inactive',
      }),
    },
  ];

  const {
    getCustomerCardsState,
    currentCustomer,
  }: ICustomersStore = useContext(CustomersStoreContext);

  const { toggleModal, RenderModal } = useModal();

  const initialValues: IFormValues = {
    activeFilter: selectedFilter,
  };

  const filterCards = async (values: IFormValues) => {
    setSelectedFilter(values.activeFilter);
  };

  return (
    <Grid container className={classes.header}>
      <Grid item xs={8}>
        <Typography variant="h5" className={classes.heading}>
          <FormattedMessage id="Customer.Cards.allCustomerCards" />
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.right}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.addButton}
          onClick={toggleModal}
        >
          <AddOutlinedIcon />
          <FormattedMessage id="Customer.Cards.addCard" />
        </Button>
      </Grid>

      <div className={classes.filtersRow}>
        <Grid item xs={4}>
          <Formik initialValues={initialValues} onSubmit={filterCards}>
            {() => {
              return (
                <Form>
                  <AutoSubmit />
                  <SelectField
                    state={getCustomerCardsState}
                    name="activeFilter"
                    options={filterOptions}
                    isClearable
                    placeholder={<FormattedMessage id="Customer.Cards.all" />}
                    label={<FormattedMessage id="Global.form.labels.status" />}
                  />
                </Form>
              );
            }}
          </Formik>
        </Grid>

        <Grid item xs={8} className={classes.search}>
          <SearchInput
            placeholder={intl.formatMessage({
              id: 'Customer.Cards.allCardsSearch',
            })}
            onChange={e => setSearch(e.target.value)}
            value={search}
          />
        </Grid>
      </div>

      <RenderModal>
        <Steps
          toggleModal={toggleModal}
          customer={currentCustomer!}
          getCards={getCards}
        />
      </RenderModal>
    </Grid>
  );
};

export default injectIntl(observer(Toolbar));
