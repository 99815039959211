import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ spacing, palette }) => ({
  container: {
    padding: spacing(2),
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  contentContainer: {
    flexGrow: 1,
    minHeight: 0,
  },
  column: {
    padding: spacing(1),
    height: '100%',
  },
  customersDetailsPaper: {
    height: '100%',
    overflow: 'auto',
  },
  addButton: {
    display: 'flex',
    '& Svg': {
      width: 18,
    },
  },
  selectCustomerPlaceholder: {
    textAlign: 'center',
    color: palette.action.disabledBackground,
    fontSize: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 700,
    height: '100%',
  },
  selectedIcon: {
    fontSize: 240,
    color: palette.primary.contrastText,
  },
  paper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
}));
