import React, { useCallback, useContext, useState } from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';

// COMPONENTS:
import Toolbar from './components/Toolbar';
import GridTable from 'common/components/grid/GridTable';
import RemoveCustomerUserCell from './components/RemoveCustomerUserCell';

// STORE:
import { IUsersStore, UsersStoreContext } from 'stores/users';

// TYPES
import { UserCustomersType } from 'api/users/types';
import { IGridTableSchema } from 'common/components/grid/types';
import useLocalSearch from '../../../../../../common/helpers/useLocalSearch';

const useStyles = makeStyles(({ spacing }) => ({
  transactionContainer: {
    overflow: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    paddingTop: spacing(2),
    height: '100%',
  },
  gridTableWrapper: {
    overflow: 'unset',
    flexGrow: 1,
  },
}));

export enum TransactionType {
  transaction = 'transaction',
  quotaTransaction = 'quotaTransaction',
}

export interface IDateFormValues {
  dateFrom: string;
  dateTo: string;
}

const customersDataSchema: IGridTableSchema<UserCustomersType> = {
  wrapper: ({ children, ...rest }: any) => <div {...rest}>{children}</div>,
  header: {
    columns: [
      {
        id: 'BKey',
        name: 'Customer ID',
      },
      {
        id: 'Navn',
        name: 'Name',
      },
      {
        id: 'Adresse',
        name: 'Address',
      },
      {
        id: 'PostNr',
        name: 'PostNr',
      },
      {
        id: 'OrgNr',
        name: 'OrgNr',
      },
    ],
  },
  row: {
    id: 'BKey',
    cells: [
      {
        key: 'BKey',
      },
      {
        key: 'Navn',
      },
      {
        key: 'Adresse',
      },
      {
        key: 'PostNr',
      },
      {
        key: 'OrgNr',
      },
      {
        customKey: 'removeCustomer',
        formatter: RemoveCustomerUserCell,
      },
    ],
  },
};

const UserCustomers = () => {
  const classes = useStyles();

  const {
    currentUser,
    getCurrentUserCustomers: getCurrentUserCustomersAPI,
    currentUserCustomers,
    getCurrentUserCustomersState,
  }: IUsersStore = useContext(UsersStoreContext);

  const getCurrentUserCustomers = useCallback(
    () => getCurrentUserCustomersAPI(currentUser!.PKey),
    [currentUser, getCurrentUserCustomersAPI]
  );

  const [search, setSearch] = useState('');

  const { data: customerUsersData } = useLocalSearch(
    search,
    ['Navn'],
    currentUserCustomers
  );

  return (
    <div className={classes.container}>
      <Paper className={classes.transactionContainer}>
        {currentUser && (
          <>
            <Toolbar
              search={search}
              setSearch={setSearch}
              userId={currentUser.PKey}
            />

            <GridTable
              className={classes.gridTableWrapper}
              tableSchema={customersDataSchema}
              data={customerUsersData}
              loadingState={getCurrentUserCustomersState}
              getData={getCurrentUserCustomers}
            />
          </>
        )}
      </Paper>
    </div>
  );
};

export default observer(UserCustomers);
