import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';

// COMPONENTS:
import Overview from 'common/components/Overview';
import UserOverviewToolbar from './UserOverviewToolbar';
import UserRoles from './UserRoles';
// STORES:
import { IUsersStore, UsersStoreContext } from 'stores/users';

// OVERVIEW DATA
import { overviewSectionsSchema } from './schema';
import { Typography, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(({ spacing, palette, typography }) => ({
  container: {
    paddingTop: 16,
    height: '100%',
  },
  row: {
    paddingTop: spacing(3),
  },
  headerWithButton: {
    display: 'flex',
  },
  addIcon: {
    fontSize: 18,
  },
  addButton: {
    marginLeft: spacing(2),
  },
  roleRow: {
    paddingTop: spacing(1.5),
    display: 'flex',
    alignItems: 'center',
  },
  disconnectButton: {
    border: `1px solid ${palette.error.main}`,
    color: palette.error.main,
    background: palette.common.white,
    marginLeft: spacing(2),
    '&:hover': {
      background: palette.error.main,
      color: palette.common.white,
    },
  },
  modalContainer: {
    width: 496,
    height: '100%',
    padding: spacing(5, 8),
  },
  title: {
    paddingBottom: spacing(2),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  addRoleButton: {
    marginTop: spacing(1.5),
  },
}));

const UserOverview = () => {
  const { currentUser }: IUsersStore = useContext(UsersStoreContext);

  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        <Overview
          sectionsSchema={overviewSectionsSchema}
          currentItem={currentUser!}
          translationsPath="Customers.List"
          hasRows
          customToolbar={<UserOverviewToolbar currentCustomer={currentUser!} />}
        >
          <Grid container>
            <Grid item xs={6} className={classes.row}>
              <Typography variant="subtitle1">
                <FormattedMessage id="Company.Users.employee" />
              </Typography>
              <Typography variant="body2">
                {currentUser!.EmployeeBKey || '-'}
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.row}>
              <UserRoles currentUser={currentUser!} />
            </Grid>
          </Grid>
        </Overview>
      </div>
    </>
  );
};

export default observer(UserOverview);
