import React, { ComponentType } from 'react';
import { makeStyles, TableCell, Tooltip, IconButton } from '@material-ui/core';
import { navigate } from '@reach/router';

// TYPES:
import { IGridTableSchema } from 'common/components/grid/types';

// HELPERS:
import { FormattedMessage } from 'react-intl';

// ICONS:
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

interface ITypeCell<T> {
  row: IGridTableSchema<T>['row'];
  cell: {
    key: keyof T;
    customKey?: string;
    formatter?: ComponentType<any>;
    formatterProps?: any;
  };
  rowData: T;
}

const useStyles = makeStyles(({ palette }) => ({
  button: {
    textTransform: 'capitalize',
  },
  detailsButton: {
    borderRadius: '100%',
    transition: '.4s background ease-in-out',

    '&:hover': {
      cursor: 'pointer',
      background: '#cee0d0',
    },
  },
  icon: {
    height: 20,
    width: 20,
  },
}));

const DetailsCell = <T extends { [key: string]: any }>({
  rowData,
  row,
  cell,
}: ITypeCell<T>) => {
  const classes = useStyles();
  return (
    <TableCell key={`${row.id}-${cell.customKey || cell.key}`}>
      <Tooltip
        title={<FormattedMessage id="Global.seeDetailsButton" />}
        placement="top"
        TransitionProps={{ timeout: 300 }}
      >
        <IconButton
          color="inherit"
          onClick={() => navigate(`/work-orders/${rowData?.WorkOrderGroupId}`)}
          className={classes.detailsButton}
        >
          <OpenInNewIcon classes={{ root: classes.icon }} />
        </IconButton>
      </Tooltip>
    </TableCell>
  );
};

export default DetailsCell;
