import React from 'react';
import Chart from 'react-apexcharts';
import { Paper } from '@material-ui/core';
import { InsertChart } from '@material-ui/icons';

// COMPONENTS:
import ChartFilter from '../ChartFilter';

// STYLES:
import { useStyles } from './index.styles';

const WasteCollectionForecast = () => {
  const options = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#3bceac'],
    yaxis: {
      labels: {
        show: false,
      },
      title: {
        text: 'Amount [kg]',
      },
    },
    xaxis: {
      type: 'category',
      categories: [
        '2:00',
        '4:00',
        '6:00',
        '8:00',
        '10:00',
        '12:00',
        '14:00',
        '16:00',
        '18:00',
        '20:00',
        '22:00',
        '24:00',
      ],
    },
    tooltip: {
      x: {
        formatter: (value: any) => {
          return `${value.split(':')[0] - 2}:01 - ${value}`;
        },
      },
      y: {
        formatter: (value: any) => {
          return `${value} kg`;
        },
      },
      marker: {
        show: false,
      },
    },
  };

  const series = [
    {
      name: 'Waste collected',
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 10, 11, 24],
    },
  ];

  const classes = useStyles();

  const filterOptions = [
    {
      value: 'thisweek',
      label: 'This week',
    },
    {
      value: 'nextweek',
      label: 'Next week',
    },
  ];

  return (
    <Paper className={classes.paper}>
      <div className={classes.toolbarContainer}>
        <div className={classes.title}>
          <InsertChart color="primary" />
          <span className={classes.titleText}>Waste collection forecast</span>
        </div>
        <ChartFilter
          options={filterOptions}
          onChangeCallback={o => console.log(o)}
        />
      </div>
      <div className={classes.chartContainer}>
        <Chart options={options} series={series} type="bar" />
      </div>
    </Paper>
  );
};

export default WasteCollectionForecast;
