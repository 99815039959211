import React, { CSSProperties } from 'react';
import Select, { Props as SelectProps, OptionsType, Theme } from 'react-select';
import { makeStyles } from '@material-ui/core';
import { FieldProps } from 'formik';

// COLOR PALETTE
import { palette } from '../../theme/main/palette';

// HELPERS
import { LoadingState, PromiseStates } from 'api/types';

// TYPES:
import { QueryStatus } from 'react-query';

export type SelectInputType = SelectProps & FieldProps;

export type SelectOption = {
  value: any;
  label: string;
  isDisabled?: boolean;
};

export interface ISelectInput {
  label?: React.ReactElement;
  isRequired?: boolean;
  state?: LoadingState | QueryStatus;
  options: OptionsType<SelectOption>;
}

const SelectInput: React.FC<ISelectInput & SelectInputType> = ({
  label,
  isRequired,
  state,
  options,
  field,
  form,
  onChange,
  className,
  ...props
}) => {
  const { errors } = form;

  const error = errors[field.name!];

  const useStyles = makeStyles(({ palette, typography, spacing }) => ({
    label: {
      color: palette.secondary.light,
      textTransform: 'capitalize',
      fontWeight: typography.fontWeightMedium,
      paddingLeft: spacing(1),
      fontSize: 14,
    },
    selectInput: {
      marginTop: '-3px',
    },
    required: {
      color: palette.error.main,
      paddingLeft: spacing(0.5),
    },
    error: {
      color: palette.error.main,
    },
  }));

  const customStyles = {
    container: (provided: CSSProperties) => ({
      ...provided,
      width: '100%',
      borderColor: '',
      minHeight: 0,
      padding: 0,
      zIndex: 3,
    }),
    input: (provided: CSSProperties) => ({
      ...provided,
      padding: 0,
      margin: 0,
    }),
    control: (provided: CSSProperties, state: any) => ({
      ...provided,
      fontSize: 13,
      minHeight: 0,
      height: '100%',
      padding: '4px 0',
      borderColor:
        error && !state.isFocused
          ? palette.error.main
          : state.isDisabled
          ? palette.action.disabledBackground
          : palette.secondary.light,
      backgroundColor: state.isDisabled
        ? palette.action.disabledBackground
        : palette.common.white,
    }),
    option: (provided: CSSProperties) => ({
      ...provided,
      fontSize: 13,
    }),
    placeholder: (provided: CSSProperties) => ({
      ...provided,
      margin: 0,
      padding: '0 8px',
    }),
    singleValue: (provided: CSSProperties) => ({
      ...provided,
      margin: 0,
    }),
    menu: (provided: CSSProperties) => ({
      ...provided,
      width: '100%',
      marginTop: 0,
    }),
    menuList: (provided: CSSProperties) => ({
      ...provided,
      padding: 0,
    }),
    indicatorSeparator: () => ({
      width: 0,
    }),
    indicatorContainer: (provided: CSSProperties) => ({
      ...provided,
      '& Svg': {
        height: 16,
      },
    }),
    dropdownIndicator: (provided: CSSProperties) => ({
      ...provided,
      color: palette.secondary.main,
      padding: '0px 8px',
      '& Svg': {
        width: 16,
        height: 16,
      },
    }),
  };

  const classes = useStyles();

  const selectOptions =
    state === PromiseStates.REJECTED
      ? [{ label: 'Error fetching data', value: 'error' }]
      : options;

  return (
    <div className={className}>
      {label && (
        <span className={classes.label}>
          {label}
          {isRequired && <span className={classes.required}>*</span>}
        </span>
      )}
      <Select
        isLoading={state === PromiseStates.PENDING || state === 'loading'}
        // isOptionDisabled={option => option.value === 'error'}
        name={field.name}
        options={selectOptions}
        value={
          field.value === ''
            ? null
            : options.find(option => option.value === field.value) ||
              props.value
        }
        onChange={(option: any) =>
          option === null
            ? form.setFieldValue(field.name, null, false)
            : form.setFieldValue(field.name, option.value, false)
        }
        onBlur={field.onBlur}
        styles={customStyles}
        theme={(theme: Theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#e6f4e7',
            primary: '#3aaa46',
            primary50: '#e6f4e7',
          },
        })}
        {...props}
      />
      {error && !field.value && <p className={classes.error}>{error}</p>}
    </div>
  );
};

export default SelectInput;
