import React, { useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';

// TYPES:
import { EmployeeType } from 'api/employees/types';

// ICONS:
import EditIcon from '@material-ui/icons/Edit';

// COMPONENTS:
import EditEmployeeModal from '../../EditEmployeeModal';

export const useStyles = makeStyles(({ typography, palette }) => ({
  detailsButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  accent: {
    fontWeight: typography.fontWeightBold,
    color: palette.primary.main,
  },
  heading: {
    fontWeight: typography.fontWeightBold,
  },
  editIcon: {
    height: 14,
  },
}));

interface IPreviewToolbar {
  currentEmployee: EmployeeType;
}
const PreviewToolbar: React.FC<IPreviewToolbar> = ({ currentEmployee }) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Grid item xs={12} sm={8}>
        <Typography variant="h4" className={classes.heading}>
          <FormattedMessage id="Company.Employee.employeeInformation" />
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} className={classes.detailsButton}>
        <Button variant="outlined" color="secondary" onClick={toggleModal}>
          <EditIcon classes={{ root: classes.editIcon }} />
        </Button>
      </Grid>
      {isOpen && (
        <EditEmployeeModal
          currentEmployee={currentEmployee!}
          isOpen={isOpen}
          toggleModal={toggleModal}
        />
      )}
    </>
  );
};

export default PreviewToolbar;
