import React, { useContext, useEffect, useState } from 'react';
import {
  ExpansionPanel,
  Divider,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Button,
} from '@material-ui/core';
import { injectIntl, IntlShape } from 'react-intl';
import { observer } from 'mobx-react';

// COMPONENTS:
import SearchInput from 'common/components/SearchInput';
import FiltersForm from './FiltersForm';

// ICONS:
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';

// STYLES:
import { useStyles } from './index.styles';

// STORE:
import { EmployeesStoreContext, IEmployeesStore } from 'stores/employees';

// HELPERS
import { useDebounce } from 'common/helpers/useDebounce';

// TYPES
interface IToolbar {
  intl: IntlShape;
}

const Toolbar: React.FC<IToolbar> = ({ intl }) => {
  const classes = useStyles();

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const { getEmployees, filters, changeFilters }: IEmployeesStore = useContext(
    EmployeesStoreContext
  );

  useEffect(() => {
    changeFilters({ ...filters, searchName: search });
    getEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  return (
    <div>
      <ExpansionPanel
        className={classes.expansion}
        defaultExpanded={filters !== null}
      >
        <div className={classes.row}>
          <SearchInput
            placeholder={intl.formatMessage({
              id: 'Agreements.List.searchPlaceholder',
            })}
            onChange={e => setSearch(e.target.value)}
            value={search}
          />
          <ExpansionPanelSummary aria-controls="panel1a-content">
            <Button
              variant="outlined"
              color="primary"
              size="small"
              className={classes.filterButton}
            >
              <FilterListOutlinedIcon />
            </Button>
          </ExpansionPanelSummary>
        </div>
        <ExpansionPanelDetails className={classes.expansionDetails}>
          <FiltersForm searchValue={search} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <Divider className={classes.divider} />
    </div>
  );
};

export default injectIntl(observer(Toolbar));
