import request from '../request';
import {
  CustomerFilter,
  NewCustomerType,
  NewCustomerCard,
  NewQuotaTransaction,
  CustomerCardsFilter,
} from 'api/customers/types';
import queryString from 'query-string';

export const getAllCustomers = () =>
  request({
    method: 'GET',
    url: '/admin/customers/GetAllCustomers',
  });

export const getCustomerById = (customerId: number | null) =>
  request({
    method: 'GET',
    url: `/admin/customers/GetCustomer/${customerId}`,
  });

export const getFilteredCustomers = (values: CustomerFilter) =>
  request({
    method: 'POST',
    url: '/admin/customers/GetAllCustomersFiltered',
    data: {
      ...values,
    },
  });

export const getAllCustomersParameters = (values: CustomerFilter) =>
  request({
    method: 'POST',
    url: '/admin/customers/GetAllCustomersParameters',
    data: {
      ...values,
    },
  });

export const getAvailableCustomersTypes = () =>
  request({
    method: 'GET',
    url: '/admin/pricelists/GetAvailablePriceListTables',
  });

export const addNewCustomer = (values: NewCustomerType) =>
  request({
    method: 'POST',
    url: '/admin/customers/SaveCustomer',
    data: {
      ...values,
    },
  });

export const addCustomerCard = (values: NewCustomerCard) =>
  request({
    method: 'POST',
    url: '/admin/customers/SaveCustomerCard',
    data: {
      ...values,
    },
  });

export const editCustomer = (values: any) =>
  request({
    method: 'POST',
    url: '/admin/customers/SaveCustomer',
    data: {
      ...values,
    },
  });

export const getQuotasTransactions = (values: any) =>
  request({
    url: `/admin/customers/GetQuotaTransactions?${queryString.stringify({
      ...values,
    })}`,
    method: 'GET',
  });

export const getQuotaBalance = (agreementId: number) =>
  request({
    url: `/admin/customers/GetQuotaBalance?${queryString.stringify({ agreementId })}`,
    method: 'GET',
  });

export const getQuotaSummary = (values: any) =>
  request({
    url: `/admin/customers/GetQuotaSummary?${queryString.stringify(values)}`,
    method: 'GET',
  });

export const getCustomerCards = (values: CustomerCardsFilter) =>
  request({
    method: 'GET',
    url: `/admin/customers/GetCustomerCardsForCustomer?${queryString.stringify(
      values
    )}`,
  });

export const getCustomerTransactions = (values?: any) =>
  request({
    url: `/admin/customers/GetAgreementTransactions?${queryString.stringify(values)}`,
    method: 'GET',
  });

export const getTransactionsDetails = (filters: any, maxResults?: number) =>
  request({
    method: 'POST',
    url: `/admin/reports/GetReportDataDetails`,
    data: {
      ...filters,
      maxResults,
    },
  });

export const getAvailableQuotaTypes = () =>
  request({
    method: 'GET',
    url: `/admin/customers/GetAvailableQuotaTypes/`,
  });

export const getPostCodes = (values: any) =>
  request({
    method: 'POST',
    url: `/admin/zipcode/GetZipCodes`,
    data: values,
  });

export const getCommuneNumbers = () =>
  request({
    method: 'GET',
    url: `/admin/customers/GetAllActiveMunicipalities`,
  });

export const addQuotaTransaction = (values: NewQuotaTransaction) =>
  request({
    method: 'POST',
    url: '/admin/customers/AddQuotaTransaction',
    data: {
      ...values,
    },
  });

export const getInvoiceReceiver = (agreementId: number) =>
  request({
    method: 'GET',
    url: `/admin/customers/GetInvoiceReceiver?${queryString.stringify({
      agreementId,
    })}`,
  });

export const getApartmentNumbers = (values: {
  customerId: number;
  findBoligNummer?: string;
}) =>
  request({
    method: 'GET',
    url: `/admin/customers/GetMatrikkelDataByCustomerBkey?${queryString.stringify({
      customerBKey: values.customerId,
      findBoligNummer: values.findBoligNummer,
    })}`,
  });

export const getTotalNumberOfApartments = (customerId: number) =>
  request({
    method: 'GET',
    url: `/admin/customers/GetCustomerRegisterenhetIdCount?${queryString.stringify({
      customerBkey: customerId,
    })}`,
  });

export const loginUser = (
  encodedBase64Credentials: string,
  loginValues: { username: string; password: string }
) =>
  request({
    url: `/admin/customers/Login?username=${loginValues.username}&Password=${loginValues.password}`,
    method: 'GET',
  });

export const refreshAccessToken = (accessToken: string, refreshToken: string) =>
  request({
    url: `/admin/customers/RefreshToken?accessToken=${accessToken}&refreshToken=${refreshToken}`,
    method: 'GET',
  });
