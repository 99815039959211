import React from 'react';
import { Link } from '@reach/router';
import { makeStyles, Typography, Breadcrumbs } from '@material-ui/core';

// ICONS
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

interface IBreadcrumbs {
  breadcrumbs: Array<{
    name: string | React.ReactNode;
    path: string;
    isActive?: boolean;
  }>;
}

const useStyles = makeStyles(({ palette, typography }) => ({
  breadcrumbs: {
    padding: '4px 0px 0px 2px',
  },
  link: {
    textDecoration: 'none',
    color: palette.secondary.dark,

    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const HeaderBreadcrumbs: React.FC<IBreadcrumbs> = ({ breadcrumbs }) => {
  const classes = useStyles();

  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        className={classes.breadcrumbs}
      >
        {breadcrumbs.map(breadcrumb =>
          breadcrumb.isActive ? (
            <Typography
              key={`${breadcrumb.path}-${breadcrumb.name}`}
              variant="body2"
              color="primary"
            >
              {breadcrumb.name}
            </Typography>
          ) : (
            <Link
              key={`${breadcrumb.path}-${breadcrumb.name}`}
              to={breadcrumb.path}
              className={classes.link}
            >
              <Typography variant="body2">{breadcrumb.name}</Typography>
            </Link>
          )
        )}
        ;
      </Breadcrumbs>
    </>
  );
};

export default HeaderBreadcrumbs;
