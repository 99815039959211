import 'mobx-react-lite/batchingForReactDom';
import React from 'react';
import ReactDOM from 'react-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { mainTheme } from 'common/theme/main';
import { QueryClientProvider, QueryClient } from 'react-query';

// COMPONENTS:
import App from './App';
import LanguageProvider from 'common/components/LanguageProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <LanguageProvider>
    <QueryClientProvider client={queryClient}>
      <MuiThemeProvider theme={mainTheme}>
        <CssBaseline />
        <App />
      </MuiThemeProvider>
    </QueryClientProvider>
  </LanguageProvider>,
  document.getElementById('root')
);
