import React from 'react';

// TYPES
import { ClientType } from 'api/clients/types';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Typography } from '@material-ui/core';

interface IStatusFormatter {
  currentItem: ClientType;
}

const useStyles = makeStyles(({ palette }) => ({
  active: {
    color: palette.primary.main,
  },
  deactivated: {
    color: palette.error.main,
  },
}));

const StatusFormatter: React.FC<IStatusFormatter> = ({ currentItem }) => {
  const classes = useStyles();

  return (
    <>
      {currentItem.Status === 100 ? (
        <Typography className={classes.active}>
          <FormattedMessage id="Global.active" />
        </Typography>
      ) : (
        <Typography className={classes.deactivated}>
          <FormattedMessage id="Global.deactivated" />
        </Typography>
      )}{' '}
    </>
  );
};

export default StatusFormatter;
