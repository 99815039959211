import { Grid, makeStyles, Typography, Tooltip, IconButton } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { navigate } from '@reach/router';

// ICONS:
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const useStyles = makeStyles(({ spacing, typography, palette }) => ({
  header: {
    padding: spacing(3,3,0,3),
    display: 'flex',
    alignItems: 'end',
  },
  heading: {
    fontWeight: typography.fontWeightBold,
  },
  cell: {
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  detailsButton: {
    borderRadius: '100%',
    transition: '.4s background ease-in-out',

    '&:hover': {
      cursor: 'pointer',
      background: '#cee0d0',
    },
  },
  icon: {
    height: 22,
    width: 22,
    color: palette.primary.main
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: spacing(1),
  },
  rowHeader: {
    fontWeight: typography.fontWeightBold,
    color: palette.secondary.light,
  },
}));

interface ApartmentDetailsOverviewProps {
  apartment: any;
}
const ApartmentDetailsOverview: React.FC<ApartmentDetailsOverviewProps> = ({ apartment }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.header}>
      <Grid item xs={12} className={classes.row}>
        <Typography variant="h5" className={classes.heading}>
          <FormattedMessage id='Customer.Apartments.apartmentOverview' />
        </Typography>

        <Tooltip title={<FormattedMessage id="Global.seeDetailsButton" />} placement="top" TransitionProps={{ timeout: 300 }}>
          <IconButton
            color="inherit"
            onClick={() => navigate(`customers`)}
            className={classes.detailsButton}
          >
            <OpenInNewIcon classes={{ root: classes.icon }} />
          </IconButton>
        </Tooltip>
      </Grid>
      {apartment.person && (
          <Grid item xs={12} className={classes.row}>
            <Grid item xs={4}>
              <Typography variant="body2" className={classes.rowHeader}>
                <FormattedMessage
                  id="Global.form.labels.name"
                />
              </Typography>
              <Typography variant="body2">
                {apartment.person.Navn}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body2" className={classes.rowHeader}>
                <FormattedMessage
                  id="Global.form.labels.idNrOrgNr"
                />
              </Typography>
              <Typography variant="body2">
                {apartment.person.IdentifikasjonsNummer}
              </Typography>
            </Grid>
          </Grid>
      )}
    </Grid>
  );
};

export default ApartmentDetailsOverview;
