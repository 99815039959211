import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

// TYPES:
import { UserType } from 'api/users/types';
import { IGridTableSchema } from 'common/components/grid/types';

// COMPONENTS:
import GridTable from 'common/components/grid/GridTable';
import UserRow from './UserRow';
import DetailsCell from './DetailsCell';
import UserStatusCellFormatter from './UserStatusCellFormatter';
import UsersListToolbar from './UsersListToolbar';

// STORES:
import { IUsersStore, UsersStoreContext } from 'stores/users';

// HELPERS:
import useLocalSearch from 'common/helpers/useLocalSearch';

const schema: IGridTableSchema<UserType> = {
  header: {
    columns: [
      {
        id: 'Username',
        name: <FormattedMessage id="Company.Users.tableHeaders.username" />
      },
      {
        id: 'Name',
        name: <FormattedMessage id="Company.Users.tableHeaders.name" />,
      },
      {
        id: 'Email',
        name: <FormattedMessage id="Company.Users.tableHeaders.email" />,
      },
      {
        id: 'Phone',
        name: <FormattedMessage id="Company.Users.tableHeaders.phone" />,
      },
      {
        id: 'Active',
        name: <FormattedMessage id="Company.Users.tableHeaders.status" />,
      },
    ],
  },
  row: {
    id: 'PKey',
    formatter: UserRow,
    cells: [
      {
        key: 'Username',
      },
      {
        key: 'Name',
      },
      {
        key: 'Email',
      },
      {
        key: 'Phone',
      },
      {
        key: 'Active',
        formatter: UserStatusCellFormatter,
      },
      {
        customKey: 'openDetails',
        formatter: DetailsCell,
      },
    ],
  },
};

const UsersTable = () => {
  const { getUsers, users, getUsersState }: IUsersStore = useContext(
    UsersStoreContext
  );

  const [search, setSearch] = useState('');
  const { data: usersData } = useLocalSearch(
    search,
    ['Name'],
    users
  );

  return (
    <GridTable
      tableSchema={schema}
      data={usersData}
      loadingState={getUsersState}
      getData={getUsers}
      customToolbar={<UsersListToolbar search={search} setSearch={setSearch} />}
    />
  );
};

export default observer(UsersTable);
