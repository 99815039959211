import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

// TYPES:
import { IGridTableSchema } from 'common/components/grid/types';
import { WastebinType } from 'api/wastebins/types';

// COMPONENTS:
import GridTable from 'common/components/grid/GridTable';
import Toolbar from './Toolbar';
import CheckRowCell from './CheckRowCell';

// HELPERS:
import useLocalSearch from '../../../../common/helpers/useLocalSearch';

const schema: IGridTableSchema<WastebinType> = {
  header: {
    columns: [
      {
        id: 'checkboxCell',
        name: '',
      },
      {
        id: 'OrderType',
        name: <FormattedMessage id="Wastebins.tableHeaders.operation" />,
      },
      {
        id: 'AgreementNumber',
        name: <FormattedMessage id="Wastebins.tableHeaders.agreementNr" />,
      },
      {
        id: 'WasteBinId',
        name: <FormattedMessage id="Wastebins.tableHeaders.wasteBinId" />,
      },
      {
        id: 'WasteBinWasteType',
        name: <FormattedMessage id="Wastebins.tableHeaders.wasteType" />,
      },
      {
        id: 'WasteBinType',
        name: <FormattedMessage id="Wastebins.tableHeaders.binType" />,
      },
      {
        id: 'Comment',
        name: <FormattedMessage id="Wastebins.tableHeaders.comment" />,
      },
    ],
  },
  row: {
    id: 'Id',
    cells: [
      {
        customKey: 'checkboxCell',
        formatter: CheckRowCell,
      },
      {
        key: 'OrderType',
      },
      {
        key: 'AgreementNumber',
      },
      {
        key: 'WasteBinId',
      },
      {
        key: 'WasteBinWasteType',
      },
      {
        key: 'WasteBinType',
      },
      {
        key: 'Comment',
      },
    ],
  },
};

// export enum SearchFilters {
//   CustomerName = 'CustomerName',
//   Address = 'Address',
//   AgreementNumber = 'AgreementNumber',
//   Barcode = 'Code128',
// }

const Table = ({ tableData }: any) => {
  const [search, setSearch] = useState('');

  const parsedData = tableData.map((element: any) => ({
    Id: element.Id,
    OrderType: element.Bestillingstype,
    AgreementNumber: element.Avtalenr,
    WasteBinId: element.BeholderId,
    Comment: element.Kommentar,
    WasteBinType: element.Beholdertype.split('-')[0].trim(),
    WasteBinWasteType: element.Beholdertype.split('-')[1],
  }));

  // const [dataToDisplay, setDataToDisplay] = useState(parsedData);

  const { data } = useLocalSearch(
    search,
    ['AgreementNumber', 'OrderType', 'WasteBinWasteType', 'WasteBinType'],
    parsedData
  );

  // const [selectedFilters, setSelectedFilters] = useState({
  //   wasteType: '',
  //   binType: '',
  //   operation: '',
  // });

  // const filterLocallyDataByOperation = () => {
  //   const filteredData = parsedData.filter(
  //     (element: any) => element.OrderType === selectedFilters.operation
  //   );
  //   setDataToDisplay(filteredData);
  // };
  //
  // useEffect(() => {
  //   if (selectedFilters.operation !== '') {
  //     if (selectedFilters.operation === null) {
  //       setDataToDisplay(parsedData);
  //     } else {
  //       filterLocallyDataByOperation();
  //     }
  //   }
  // }, [selectedFilters]);

  return (
    <GridTable
      tableSchema={schema}
      data={data}
      loadingState={'success'}
      customToolbar={
        <Toolbar
          setSearch={setSearch}
          // selectedFilters={selectedFilters}
          // setSelectedFilters={setSelectedFilters}
        />
      }
    />
  );
};
export default observer(Table);
