import React, { useContext } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

// STYLES
import { useStyles } from './index.styles';

// COMPONENTS
import AllCards from './components/AllCards';
import CardTransactionsTable from './components/CardTransactionsTable';

// STORES:
import { CardsStoreContext, ICardsStore } from 'stores/cards';

// ICONS:
import CreditCardIcon from '@material-ui/icons/CreditCard';

const CardsView = ({ isApartment }: { isApartment: boolean }) => {
  const classes = useStyles();

  const { currentCard }: ICardsStore = useContext(CardsStoreContext);

  return (
    <Grid container className={classes.gridContainer}>
      <Grid item xs={12} sm={6} className={classes.allCardsColumn}>
        <AllCards isApartment={isApartment} />
      </Grid>
      <Grid item xs={12} sm={6} className={classes.cardsTable}>
        <Paper className={classes.paper}>
          {!currentCard ? (
            <div className={classes.selectCardPlaceholder}>
              <CreditCardIcon className={classes.selectCardIcon} />
              <FormattedMessage id="Agreement.Cards.selectCardPlaceholder" />
            </div>
          ) : (
            <CardTransactionsTable />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default observer(CardsView);
