import React, { ComponentType, useContext, useState } from 'react';
import {
  makeStyles,
  TableCell,
  Tooltip,
  Button,
  IconButton,
  Typography,
} from '@material-ui/core';
import { useParams } from '@reach/router';
import { useMutation } from 'react-query';

// TYPES:
import { IGridTableSchema } from 'common/components/grid/types';

// HELPERS:
import { FormattedMessage } from 'react-intl';

// ICONS:
import { RemoveCircleOutline } from '@material-ui/icons';

// API:
import {  removeUserWare as removeUserWareAPI } from 'api/users';

// STORES:
import { IUsersStore, UsersStoreContext } from 'stores/users';

// COMPONENTS:
import Modal from 'common/components/Modal';
import ButtonLoading from 'common/components/buttons/ButtonLoading';

interface ITypeCell<T> {
  row: IGridTableSchema<T>['row'];
  cell: {
    key: keyof T;
    customKey?: string;
    formatter?: ComponentType<any>;
    formatterProps?: any;
  };
  rowData: T;
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  button: {
    textTransform: 'capitalize',
  },
  detailsButton: {
    padding: spacing(0.5),
    borderRadius: '100%',
    transition: '.4s background ease-in-out',

    '&:hover': {
      cursor: 'pointer',
      background: '#cee0d0',
    },
  },
  icon: {
    height: 20,
    width: 20,
  },
  modalContainer: {
    width: 496,
    height: '100%',
    padding: spacing(5, 8),
  },
  title: {
    paddingBottom: spacing(2),
  },
  noButton: {
    marginLeft: spacing(2),
  },
  buttons: {
    paddingTop: spacing(1.5),
  },
}));

const RemoveUserWareCell = <T extends { [key: string]: any }>({
                                                                    row,
                                                                    cell,
                                                                    rowData,
                                                                  }: ITypeCell<T>) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const params = useParams();

  const { getCurrentUserWares }: IUsersStore = useContext(
    UsersStoreContext
  );

  const { mutate: removeUserWare, isLoading } = useMutation(
    async () => await removeUserWareAPI({
      UserPkey: params.userId,
      VareBkey: rowData.BKey,
      VareBnr: rowData.Bnr,
    }),
    {
      onSuccess: () => toggleModal(),
      onSettled: async () => await getCurrentUserWares(params.userId)
    }
  );

  return (
    <>
      <TableCell key={`${row.id}-${cell.customKey || cell.key}`}>
        <Tooltip
          title={<FormattedMessage id="Company.User.removeCustomer" />}
          placement="top"
          TransitionProps={{ timeout: 300 }}
        >
          <IconButton
            color="inherit"
            onClick={toggleModal}
            className={classes.detailsButton}
          >
            <RemoveCircleOutline classes={{ root: classes.icon }} />
          </IconButton>
        </Tooltip>
      </TableCell>
      <Modal isOpen={isOpen} toggleModal={toggleModal}>
        <div className={classes.modalContainer}>
          <Typography variant="h4" className={classes.title}>
            <FormattedMessage id="Company.User.removeWare" />
          </Typography>
          <Typography variant="body1">
            <FormattedMessage
              id="Company.User.removeWareConfirmation"
              values={{
                b: (chunk: string) => <b>{chunk}</b>,
                value: `${rowData.Navn}`,
              }}
            />
          </Typography>
          <div className={classes.buttons}>
            <ButtonLoading
              disabled={isLoading}
              variant="contained"
              color="primary"
              onClick={() => removeUserWare()}
            >
              <FormattedMessage id="Global.yes" />
            </ButtonLoading>
            <Button
              variant="outlined"
              color="primary"
              onClick={toggleModal}
              className={classes.noButton}
            >
              <FormattedMessage id="Global.no" />
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RemoveUserWareCell;
