import React from 'react';
import { TableHead, TableRow } from '@material-ui/core';

// COMPONENTS:
import DefaultHeader from './DefaultHeader';

// TYPES:
import { IColumnScheme } from './types';

interface IGridHeaders<T> {
  columns: IColumnScheme<T>[];
}

const GridHeaders = <T extends object>({ columns }: IGridHeaders<T>) => (
  <TableHead>
    <TableRow>
      {columns.map(column => {
        const Header = column.formatter || DefaultHeader;
        return (
          <Header key={column.id} column={column}>
            {column.name}
          </Header>
        );
      })}
    </TableRow>
  </TableHead>
);

export default GridHeaders;
