import React, { useCallback, useContext, useState } from 'react';
import { observer } from 'mobx-react';
import { useParams } from '@reach/router';

// COMPONENTS:
import GridTable from 'common/components/grid/GridTable';
import { IGridTableSchema } from 'common/components/grid/types';
import Toolbar from './Toolbar';
import DateCell from 'common/components/grid/DateCell';

// STORES:
import { CustomersStoreContext, ICustomersStore } from 'stores/customers';
import { CardsStoreContext, ICardsStore } from 'stores/cards';

// STYLES
import { useStyles } from './index.styles';

// TYPES:
import { CustomerTransactionType } from 'api/customers/types';

// HELPERS:
import { useDebounce } from 'common/helpers/useDebounce';

const schema: IGridTableSchema<CustomerTransactionType> = {
  header: {
    columns: [
      {
        id: 'Date',
        name: 'Date',
      },
      {
        id: 'SaleNr',
        name: 'Bilagsnr',
      },
      {
        id: 'ClientName',
        name: 'Client',
      },
      {
        id: 'QuotaType',
        name: 'Quota type',
      },
      {
        id: 'ItemName',
        name: 'Ware name',
      },
      {
        id: 'Quantity',
        name: 'Amount',
      },
    ],
  },
  row: {
    id: 'SaleNr',
    cells: [
      {
        key: 'Date',
        formatter: DateCell
      },
      {
        key: 'SaleNr'
      },
      {
        key: 'ClientName'
      },
      {
        key: 'QuotaType'
      },
      {
        key: 'ItemName'
      },
      {
        key: 'Quantity'
      },
    ],
  },
};

const CardTransactionsTable = () => {
  const { currentCard }: ICardsStore = useContext(
    CardsStoreContext
  );

  const params = useParams();

  const [filters, setFilters] = useState({});
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const {
    getCustomerTransactionsState,
    getCustomerTransactions,
    customerTransactions,
  }: ICustomersStore = useContext(CustomersStoreContext);

  const classes = useStyles();

  const getTransactions = useCallback(() => getCustomerTransactions({
    customerBkey: params.customerId,
    customerCardId: currentCard!.ID,
    ItemName: search,
    ...filters
    // eslint-disable-next-line react-hooks/exhaustive-deps
}), [
    getCustomerTransactions,
    params.customerId,
    currentCard,
    filters,
    debouncedSearch
  ]);

  return (
    <GridTable
      tableSchema={schema}
      data={customerTransactions}
      loadingState={getCustomerTransactionsState}
      getData={getTransactions}
      className={classes.gridTable}
      customToolbar={<Toolbar search={search} setSearch={setSearch} filters={filters} setFilters={setFilters} /> }
    />
  );
};

export default observer(CardTransactionsTable);
