import { createContext } from 'react';
import { observable, action } from 'mobx';

// API:
import { getAllPricetables } from 'api/pricetables';

// TYPES:
import { PricetablesType, PricetableType } from 'api/pricetables/types';
import { LoadingState, PromiseStates } from 'api/types';
import { SortingOrder } from '../common/components/grid/types';

export interface IPricetablesStore {
  // Observables
  pricetables: PricetablesType;
  currentPricetable: PricetableType | null;
  getPricetablesState: LoadingState;
  sortBy?: keyof PricetableType;

  // Actions
  getPricetables: () => Promise<void>;
  setCurrentPricetable: (rowData: PricetableType) => void;
  sortPricetableStringsBy: (sortBy: keyof PricetableType, order: SortingOrder) => void;

}

class PricetablesStore implements IPricetablesStore {
  @observable pricetables: IPricetablesStore['pricetables'] = [];
  @observable currentPricetable: IPricetablesStore['currentPricetable'] = null;
  @observable
  getPricetablesState: IPricetablesStore['getPricetablesState'] = null;
  @observable sortBy: IPricetablesStore['sortBy'] = 'PKey';


  @action
  getPricetables = async () => {
    try {
      this.getPricetablesState = PromiseStates.PENDING;
      const { data } = await getAllPricetables();
      this.pricetables = data;
      this.getPricetablesState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getPricetablesState = PromiseStates.REJECTED;
    }
  };

  @action setCurrentPricetable = (rowData: PricetableType) => {
    this.currentPricetable = rowData;
  };

  @action
  sortPricetableStringsBy = (
    sortBy: keyof PricetableType,
    order: SortingOrder = 'asc'
  ) => {
    this.sortBy = sortBy;

    this.pricetables = this.pricetables.sort((a, b) => {
      const firstValue = a[sortBy]?.toString() || '';
      const secondValue = b[sortBy]?.toString() || '';

      return order === 'asc'
        ? firstValue.toString().localeCompare(secondValue)
        : secondValue.localeCompare(firstValue);
    });
  };
}

export const PricetablesStoreContext = createContext(new PricetablesStore());
