import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ typography, spacing, palette }) => ({
  root: {
    padding: spacing(3),
    height: '100%',
  },
  rowHeader: {
    fontWeight: typography.fontWeightBold,
    color: palette.secondary.light,
  },
  header: {
    paddingTop: spacing(2),
  },
  selectPricetablePlaceholder: {
    textAlign: 'center',
    color: palette.action.disabledBackground,
    fontSize: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 700,
    height: '100%',
  },
  selectPricetableIcon: {
    fontSize: 240,
    color: palette.primary.contrastText,
  },
  accent: {
    fontWeight: typography.fontWeightBold,
    color: palette.primary.main,
  },
  heading: {
    fontWeight: typography.fontWeightBold,
  },
}));
