import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

// STORE:
import { IWaresStore, WaresStoreContext } from 'stores/wares';

// TYPES:
import { IGridTableSchema } from 'common/components/grid/types';
import { WareType } from 'api/wares/types';

// COMPONENTS:
import GridTable from 'common/components/grid/GridTable';
import PriceWithVATCell from './PriceWithVATCell';
import StatusCell from './StatusCell';
import WareRow from './WareRow';
import Toolbar from './Toolbar';
import SortableStringHeader from './SortableStringHeader';
import SortableNumericHeader from './SortableNumericHeader';


const schema: IGridTableSchema<WareType> = {
  header: {
    columns: [
      {
        id: 'BKey',
        name: <FormattedMessage id="Accounting.Wares.tableHeaders.code" />,
        formatter: SortableStringHeader,
      },
      {
        id: 'Navn',
        name: <FormattedMessage id="Accounting.Wares.tableHeaders.name" />,
        formatter: SortableStringHeader,
      },
      {
        id: 'VeilPris',
        name: <FormattedMessage id="Accounting.Wares.tableHeaders.netPrice" />,
        formatter: SortableNumericHeader,
      },
      {
        id: 'PriceWithVAT',
        name: <FormattedMessage id="Accounting.Wares.tableHeaders.grossPrice" />,
      },
      {
        id: 'navMVAUt.Sats',
        name: <FormattedMessage id="Accounting.Wares.tableHeaders.vat" />,
      },
      {
        id: 'Status',
        name: <FormattedMessage id="Accounting.Wares.tableHeaders.status" />,
      },
    ],
  },
  row: {
    id: 'BKey',
    formatter: WareRow,
    cells: [
      {
        key: 'BKey',
      },
      {
        key: 'Navn',
      },
      {
        key: 'VeilPris',
      },
      {
        customKey: 'PriceWithVAT',
        formatter: PriceWithVATCell,
      },
      {
        key: 'navMVAUt.Sats',
      },
      {
        customKey: 'Status',
        formatter: StatusCell,
      },
    ],
  },
};

const Table = () => {
  const {
    getWares,
    setCurrentWare,
    getWaresState,
    wares,
  }: IWaresStore = useContext(WaresStoreContext);

  useEffect(() => {
    setCurrentWare(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GridTable
      tableSchema={schema}
      data={wares}
      loadingState={getWaresState}
      getData={getWares}
      toolbar={Toolbar}
    />
  );
};

export default observer(Table);
