import React, { useEffect, useState } from 'react';
import { TableCell } from '@material-ui/core';
import { ICell, IGridTableSchema } from './types';
import { makeStyles } from '@material-ui/core/styles';

interface IDefaultCell<T> {
  row: IGridTableSchema<T>['row'];
  cell: ICell<T>;
  children: React.ReactNode;
}

const useStyles = makeStyles(({ spacing, constants }) => ({
  cellWithBigPadding: {
    padding: constants.bigTableCellPadding,
  },
  cellWithSmallPadding: {
    padding: constants.smallTableCellPadding,
  },
}));

const DefaultCell = <T extends { [key: string]: any }>({
  row,
  cell,
  children,
}: IDefaultCell<T>) => {
  const classes = useStyles();
  const [isCompactViewActive, setIsCompactViewActive] = useState(false);

  useEffect(() => {
    setIsCompactViewActive(
      localStorage.getItem('rowStylingView') === 'compact'
    );
  }, []);

  return (
    <TableCell
      key={`${row.id}-${cell.customKey || cell.key}`}
      className={
        isCompactViewActive
          ? classes.cellWithSmallPadding
          : classes.cellWithBigPadding
      }
    >
      {children == null || children === 'undefined' || children === ''
        ? '-'
        : children}
    </TableCell>
  );
};

export default DefaultCell;
