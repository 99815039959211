import React from 'react';
import { observer } from 'mobx-react';

// COMPONENTS
import TableauReport from './TableauReport';

const ExampleReport = () => {
  return (
    <div style={{ padding: 20 }}>
      <TableauReport />
    </div>
  );
};

export default observer(ExampleReport);
