import { createContext } from 'react';
import { observable, action } from 'mobx';

// TYPES:
import { CustomerCardType, CardBalanceType } from 'api/cards/types';
import { deactivateCard, editCustomerCard, getAgreementTransactions } from 'api/cards';
import { LoadingState, PromiseStates } from '../api/types';

export interface ICardsStore {
  // Observables
  currentCard: CustomerCardType | null;
  currentCardBalance: any;
  agreementTransactions: any;
  deactivateCardState: LoadingState;
  getAgreementTransactionsState: LoadingState;

  currentApartment: any | null;

  // Actions
  setCurrentCard: (rowData: CustomerCardType | null) => void;
  setCurrentApartment: (rowData: any) => void;
  setCurrentCardBalance: (rowData: CardBalanceType) => void;
  clearCurrentCardBalance: () => void;
  clearCurrentApartment: () => void;
  deactivateCard: (id: string) => Promise<void>;
  editComment: (values: any) => Promise<void>;
  getAgreementTransactions: (id: number, cardId: string) => Promise<void>;
}

class CardsStore implements ICardsStore {
  @observable currentCard: ICardsStore['currentCard'] = null;
  @observable agreementTransactions: ICardsStore['agreementTransactions'] = [];
  @observable currentApartment: ICardsStore['currentApartment'] = null;
  @observable currentCardBalance: ICardsStore['currentCardBalance'] = null;
  @observable deactivateCardState: ICardsStore['deactivateCardState'] = null;
  @observable getAgreementTransactionsState: ICardsStore['getAgreementTransactionsState'] = null;
  @observable editingComment: ICardsStore['deactivateCardState'] = null;

  @action setCurrentCard = (rowData: CustomerCardType | null) => {
    this.currentCard = rowData || null;
  };

  @action setCurrentApartment = (rowData: any) => {
    this.currentApartment = rowData;
  };

  @action setCurrentCardBalance = (rowData: CardBalanceType) => {
    this.currentCardBalance = rowData;
  };

  @action
  clearCurrentCardBalance = () => {
    this.currentCardBalance = null;
  };

  @action
  clearCurrentApartment = () => {
    this.currentApartment = null;
  };

  @action
  getAgreementTransactions = async (id: number, cardId: string) => {
    try {
      this.getAgreementTransactionsState = PromiseStates.PENDING;
      const response = await getAgreementTransactions(id, cardId);
      this.agreementTransactions = response.data;
      this.getAgreementTransactionsState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getAgreementTransactionsState = PromiseStates.REJECTED;
    }
  };

  @action
  deactivateCard = async (id: string) => {
    try {
      this.deactivateCardState = PromiseStates.PENDING;
      await deactivateCard(id);
      this.deactivateCardState = PromiseStates.FULFILLED;
    } catch (err) {
      this.deactivateCardState = PromiseStates.REJECTED;
    }
  };

  @action
  editComment = async (values: any) => {
    try {
      this.editingComment = PromiseStates.PENDING;
      await editCustomerCard(values);
      this.editingComment = PromiseStates.FULFILLED;
    } catch (err) {
      this.editingComment = PromiseStates.REJECTED;
    }
  };
}

export const CardsStoreContext = createContext(new CardsStore());
