import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core';

// COMPONENTS:
import Header from 'common/components/Header';
import Table from './Table';

const useStyles = makeStyles(({ spacing, typography }) => ({
  container: {
    padding: spacing(2),
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  contentContainer: {
    flexGrow: 1,
    minHeight: 0,
  },
  column: {
    padding: spacing(1),
    height: '100%',
  },
  waresPaper: {
    height: '100%',
    overflow: 'auto',
  },
  button: {
    marginLeft: spacing(2),
    display: 'flex',
    '& Svg': {
      width: 18,
    },
  },
  icon: {
    marginRight: spacing(0.5),
  },
  buttonText: {
    textTransform: 'uppercase',
    fontWeight: typography.fontWeightMedium,
  },
  modalContainer: {
    width: 496,
    height: '100%',
    padding: spacing(5, 8),
  },
  title: {
    paddingBottom: spacing(2),
  },
}));

const Wastebins = () => {
  const classes = useStyles();

  const breadcrumbs = [
    {
      name: <FormattedMessage id="Wastebins.wastebins" />,
      path: '/wares',
    },
  ];

  return (
    <>
      <div className={classes.container}>
        <Header
          breadcrumbs={breadcrumbs}
          title={<FormattedMessage id="Wastebins.wastebins" />}
        />
        <Grid container className={classes.contentContainer}>
          <Grid item xs={12} className={classes.column}>
            <Paper className={classes.waresPaper}>
              <Table />
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Wastebins;
