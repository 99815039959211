import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, spacing }) => ({
  container: {
    display: 'flex',
  },
  root: {
    flexGrow: 1,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  content: {
    padding: spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: spacing(2),
  },
  filterButton: {
    marginLeft: spacing(3),
    padding: 0,
    minWidth: 0,
  },
  clearButton: {
    color: palette.secondary.light,
    padding: 0,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
}));
