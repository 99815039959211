import React, { useState, useEffect, useContext, memo } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListSubheader,
  IconButton,
  ListItemText,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { navigate } from '@reach/router';

// COMPONENTS
import SideBarItem from './SideBarItem/SideBarItem';

// STYLES
import { useStyles } from './index.styles';

// ICONS
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import SettingsIcon from '@material-ui/icons/Settings';

// IMAGES
import logo from 'assets/images/white-logo-vsp-miljo.png';

// STORE
import { LoginStoreContext } from 'stores/login';
import { ISidebarStore, SidebarStoreContext } from 'stores/sidebar';

// DATA
import sideBarItems from './SideBarItemsList';

// TYPES
import { SideBarItemType } from './types';

interface ISideBar {
  currentLocation: string;
  isNavigationOpen: boolean;
  toggleNavigation: () => void;
}

const SideBar: React.FC<ISideBar> = ({
  currentLocation,
  isNavigationOpen,
  toggleNavigation,
}) => {
  const intl = useIntl();
  const { logout } = useContext(LoginStoreContext);

  const {
    setActiveItem,
    activeItem,
    setCurrentlyOpenedItem,
    currentlyOpenedItem,
  }: ISidebarStore = useContext(SidebarStoreContext);

  const [navigationItems, setNavigationItems] = useState([...sideBarItems]);

  useEffect(() => {
    if (!activeItem) {
      setActiveItem(currentLocation);
    }

    if (!currentlyOpenedItem) {
      const parentWithActiveChild = sideBarItems.find(item => {
        if (item!.subcategories!.length > 0) {
          return item!.subcategories!.find(
            subcategory => subcategory.href === activeItem
          );
        } else {
          return null;
        }
      });

      if (parentWithActiveChild) {
        setCurrentlyOpenedItem(parentWithActiveChild!.text);
      }
    }
    const updatedNavigationItems = markActive(navigationItems);
    setNavigationItems(updatedNavigationItems);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocation, activeItem]);

  /**
   * Checks which menu item is active
   */
  const markActive = (sideBarItems: SideBarItemType[]) => {
    return sideBarItems.map(item => {
      item.subcategories?.length === 0
        ? (item.isActive = currentLocation.includes(item.href!))
        : (item.isActive = currentLocation === item.href);

      if (item.subcategories) {
        markActive(item.subcategories);
      }

      return item;
    });
  };

  const parentWithActiveChild = sideBarItems.find(item => {
    if (item!.subcategories!.length > 0) {
      return item!.subcategories!.find(
        subcategory => subcategory.href === activeItem
      );
    } else {
      return null;
    }
  });

  const drawerBottomItems = [
    {
      id: 1,
      icon: <SettingsIcon />,
      text: intl.formatMessage({ id: 'Global.settings' }),
      onClick: () => navigate('/settings'),
    },
    {
      id: 2,
      icon: <ExitToAppOutlinedIcon />,
      text: intl.formatMessage({ id: 'Global.logout' }),
      onClick: () => logout(),
    },
  ];

  const classes = useStyles(isNavigationOpen);

  const menuItem = isNavigationOpen ? <ArrowBackOutlinedIcon /> : <MenuIcon />;

  return (
    <div className={classes.root}>
      <Drawer
        variant="permanent"
        classes={{
          paper: classes.drawer,
        }}
      >
        <List
          className={classes.drawerList}
          subheader={
            <ListSubheader
              component="div"
              id="logo-vsp"
              classes={{ root: classes.subheader }}
            >
              {isNavigationOpen && (
                <img src={logo} alt="logo" className={classes.logo} />
              )}
              <IconButton
                color="inherit"
                onClick={toggleNavigation}
                edge="start"
                className={classes.menuIcon}
              >
                {menuItem}
              </IconButton>
            </ListSubheader>
          }
        >
          {sideBarItems.map(item => (
            <SideBarItem
              key={item.text}
              text={isNavigationOpen ? item.text : ''}
              id={item.text}
              icon={item.icon}
              subcategories={item.subcategories}
              href={item.href}
              isActive={item.isActive}
              isDisabled={item.isDisabled}
              willBeAvailableSoon={item.willBeAvailableSoon}
              isNavigationOpen={isNavigationOpen}
              defaultOpenedItem={
                parentWithActiveChild ? parentWithActiveChild.text : null
              }
            />
          ))}
        </List>
        <List className={classes.bottomList}>
          {drawerBottomItems.map(drawerBottomItem => (
            <ListItem
              button
              key={drawerBottomItem.id}
              onClick={drawerBottomItem.onClick}
            >
              <ListItemIcon className={classes.drawerListIcon}>
                {drawerBottomItem.icon}
              </ListItemIcon>
              {isNavigationOpen && (
                <ListItemText className={classes.drawerListText}>
                  {drawerBottomItem.text}
                </ListItemText>
              )}
            </ListItem>
          ))}
        </List>
      </Drawer>
    </div>
  );
};

export default memo(SideBar);
