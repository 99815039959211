import React, { ComponentType } from 'react';
import {
  makeStyles,
  TableCell,
  Tooltip,
  IconButton,
  Typography,
} from '@material-ui/core';

// TYPES:
import { IGridTableSchema } from 'common/components/grid/types';

// HELPERS:
import { FormattedMessage } from 'react-intl';

// ICONS:
import SearchIcon from '@material-ui/icons/Search';
import { useModal } from '../../../../../../common/helpers/useModal';

interface ITypeCell<T> {
  row: IGridTableSchema<T>['row'];
  cell: {
    key: keyof T;
    customKey?: string;
    formatter?: ComponentType<any>;
    formatterProps?: any;
  };
  rowData: T;
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  button: {
    textTransform: 'capitalize',
  },
  detailsButton: {
    padding: spacing(0.5),
    borderRadius: '100%',
    transition: '.4s background ease-in-out',

    '&:hover': {
      cursor: 'pointer',
      background: '#cee0d0',
    },
  },
  icon: {
    height: 20,
    width: 20,
  },
  modalContainer: {
    width: 496,
    height: '100%',
    padding: spacing(5, 8),
  },
  title: {
    paddingBottom: spacing(2),
  },
}));

const DetailsCell = <T extends { [key: string]: any }>({
  row,
  cell,
}: ITypeCell<T>) => {
  const classes = useStyles();

  const customToggleModal = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const { toggleModal, RenderModal } = useModal(e => customToggleModal(e));

  return (
    <>
      <TableCell key={`${row.id}-${cell.customKey || cell.key}`}>
        <Tooltip
          title={<FormattedMessage id="Global.seeDetailsButton" />}
          placement="top"
          TransitionProps={{ timeout: 300 }}
        >
          <IconButton
            color="inherit"
            onClick={toggleModal}
            className={classes.detailsButton}
          >
            <SearchIcon classes={{ root: classes.icon }} />
          </IconButton>
        </Tooltip>
      </TableCell>
      <RenderModal>
        <div className={classes.modalContainer}>
          <Typography variant="h4" className={classes.title}>
            <FormattedMessage id="Agreement.Cards.addComment" />
          </Typography>
        </div>
      </RenderModal>
    </>
  );
};

export default DetailsCell;
