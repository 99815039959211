import React from 'react';
import { Typography } from '@material-ui/core';
import { getNestedValue } from 'common/helpers/getNestedValue';

export interface ISectionElement {
  label: string;
  value: string;
}
interface IDefaultValue {
  currentItem: any;
  sectionElement: ISectionElement;
}
const DefaultValue: React.FC<IDefaultValue> = ({
  currentItem,
  sectionElement,
}) => {
  return (
    <Typography variant="body2">
      {currentItem && getNestedValue(sectionElement.value, currentItem)
        ? getNestedValue(sectionElement.value, currentItem)
        : '-'}
    </Typography>
  );
};

export default DefaultValue;
