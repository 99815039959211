import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(({ spacing }) => ({
  error: {
    textAlign: 'center',
    paddingTop: spacing(5),
  },
}));

const DefaultEmptyData = () => {
  const classes = useStyles();

  return (
    <div className={classes.error}>
      <Typography variant="h5">
        <FormattedMessage id="Global.defaultEmptyDataTitle" />
      </Typography>
      <div>
        <FormattedMessage id="Global.defaultEmptyDataSubtitle" />
      </div>
    </div>
  );
};

export default DefaultEmptyData;
