import React from 'react';
import { Grid, Divider } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';

// COMPONENTS:
import SearchInput from 'common/components/SearchInput';
import SelectField from 'common/components/formik/SelectField';

// STYLES:
import { useStyles } from './index.styles';

// HELPERS:
import { Form, Formik } from 'formik';
import { AutoSubmit } from 'common/components/formik/AutoSubmitForm';
import { HouseTypes } from 'api/customers/types';

const Toolbar: React.FC<any> = ({
  intl,
  search,
  setSearch,
  selectedCustomerType,
  setSelectedCustomerType,
  setPage,
}) => {
  const classes = useStyles();

  const customerTypeOptions = [
    {
      label: HouseTypes.House,
      value: '0',
    },
    {
      label: HouseTypes.ApartmentBlock,
      value: '1',
    },
  ];

  const filterByCustomerType = (values: { houseType: number }) => {
    setSelectedCustomerType(values.houseType);
    setPage(0);
  };

  return (
    <div>
      <Grid container className={classes.toolbar}>
        <Grid item xs={7} className={classes.row}>
          <SearchInput
            placeholder={intl.formatMessage({
              id: 'Customers.List.searchPlaceholder',
            })}
            onChange={e => {
              setSearch(e.target.value);
              setPage(0);
            }}
            value={search}
          />
        </Grid>
        <Grid item xs={5} className={classes.select}>
          <Formik
            initialValues={{ houseType: selectedCustomerType }}
            onSubmit={filterByCustomerType}
          >
            {() => {
              return (
                <Form>
                  <AutoSubmit />
                  <SelectField
                    name="houseType"
                    isClearable
                    options={customerTypeOptions}
                    label={
                      <FormattedMessage id={`Global.form.labels.houseType`} />
                    }
                  />
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
    </div>
  );
};

export default injectIntl(Toolbar);
