import React, { ReactNode, useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';

// STORE
import { CustomersStoreContext, ICustomersStore } from 'stores/customers';

interface ICardRow {
  rowData: any;
  children: ReactNode;
}

const useStyles = makeStyles(({ palette }) => ({
  /**
   *  To achieve spacing between rows:
   *  following properties must be set in parent table styles:
   *  - border-collapse: 'separate'
   *  - border-spacing: '0 10px'
   */
  row: {
    borderRadius: '10px',
    border: '1px solid rgba(0,0,0,1)',
    '& td:first-of-type': {
      borderRadius: '10px 0px 0px 10px',
    },
    '& td:last-of-type': {
      borderRadius: '0px 10px 10px 0px',
    },
    '&:hover': {
      backgroundColor: palette.primary.light,
      cursor: 'pointer',
      '& td': {
        borderTop: '1px solid rgba(0,0,0,0.05)',
        borderBottom: '1px solid rgba(0,0,0,0.05)',
      },
      '& td:first-of-type': {
        borderLeft: '1px solid rgba(0,0,0,0.05)',
      },
      '& td:last-of-type': {
        borderRight: '1px solid rgba(0,0,0,0.05)',
      },
    },
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.05)',
    '& td': {
      border: '1px solid rgba(0,0,0,0)',
    },
  },
  selected: {
    backgroundColor: `${palette.primary.light} !important`,
    '& td': {
      borderTop: '1px solid rgba(0,0,0,0.05)',
      borderBottom: '1px solid rgba(0,0,0,0.05)',
    },
    '& td:first-of-type': {
      borderLeft: '1px solid rgba(0,0,0,0.05)',
    },
    '& td:last-of-type': {
      borderRight: '1px solid rgba(0,0,0,0.05)',
    },
  },
}));

const QuotasRow: React.FC<ICardRow> = ({ rowData, children }) => {
  const {
    setCurrentCustomerQuota,
    currentCustomerQuota,
    clearQuotasTransactions,
  }: ICustomersStore = useContext(CustomersStoreContext);

  const isActive = currentCustomerQuota?.QuotaPKey === rowData.QuotaPKey;

  const classes = useStyles();

  const handleClick = () => {
    clearQuotasTransactions();
    setCurrentCustomerQuota(rowData);
  };

  return (
    <tr
      onClick={handleClick}
      className={
        isActive ? `${classes.row} ${classes.selected}` : `${classes.row} `
      }
    >
      {children}
    </tr>
  );
};

export default observer(QuotasRow);
