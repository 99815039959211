import React, { ReactNode, useContext } from 'react';
import { makeStyles, TableRow } from '@material-ui/core';
import { observer } from 'mobx-react';

// TYPES
import { EmployeeType } from 'api/employees/types';

// STORE
import { EmployeesStoreContext, IEmployeesStore } from 'stores/employees';

interface IEmployeeRow {
  rowData: EmployeeType;
  children: ReactNode;
}

const useStyles = makeStyles(({ palette }) => ({
  row: {
    padding: '20px !important',
    '&:hover': {
      backgroundColor: palette.primary.light,
      cursor: 'pointer'
    },
  },
  selected: {
    backgroundColor: `${palette.primary.light} !important`,
  },
}));

const EmployeeRow: React.FC<IEmployeeRow> = ({ rowData, children }) => {
  const { currentEmployee, setCurrentEmployee }: IEmployeesStore = useContext(
    EmployeesStoreContext
  );

  const isActive = currentEmployee?.BKey === rowData.BKey;
  const classes = useStyles(isActive);

  const handleClick = () => {
    setCurrentEmployee(rowData);
  };

  return (
    <TableRow
      onClick={handleClick}
      className={
        isActive ? `${classes.row} ${classes.selected}` : `${classes.row} `
      }
    >
      {children}
    </TableRow>
  );
};

export default observer(EmployeeRow);
