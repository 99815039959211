import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ spacing }) => ({
  row: {
    display: 'flex',
  },
  filterButton: {
    marginLeft: spacing(3),
    padding: 0,
    minWidth: 0,
    height: 32,
    width: 32,
    '& Svg': {
      width: 16,
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  expansion: {
    boxShadow: 'none',
    padding: spacing(3, 3, 0, 3),
    zIndex: 3,
  },
  expansionDetails: {
    paddingTop: spacing(2),
  },
  divider: {
    margin: spacing(2, 0, 1),
  },
}));
