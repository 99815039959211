import React, { useState, useContext, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Tab, Tabs } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

// COMPONENTS
import EmployeeOverview from '../Overview';
import TabPanel from 'common/components/TabPanel';

// STORE
import { CustomersStoreContext, ICustomersStore } from 'stores/customers';

// STYLES
import { useStyles } from './index.styles';

const HeaderTabs = () => {
  const {
    getAvailableCustomersTypes,
    setIsEditModeActive,
  }: ICustomersStore = useContext(CustomersStoreContext);

  useEffect(() => {
    setIsEditModeActive(false);
    getAvailableCustomersTypes();
  }, [getAvailableCustomersTypes, setIsEditModeActive]);

  const classes = useStyles();
  const theme = useTheme();

  const [value, setValue] = useState('#overview');

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    if (newValue !== window.location.hash) {
      setValue(newValue);
    }
  };

  useEffect(() => {
    if (value === '#overview') {
      window.history.replaceState(null, '', ' ');
    } else {
      window.location.hash = value;
    }
  }, [value]);

  return (
    <div className={classes.container}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="secondary"
        classes={{
          flexContainer: classes.flexContainer,
          root: classes.root,
        }}
      >
        <Tab
          value="#overview"
          label={<FormattedMessage id="Company.Employees.overview" />}
        />
      </Tabs>
      <TabPanel
        value={value}
        index={'#overview'}
        dir={theme.direction}
        tabPanelClassName={classes.tabPanelClassName}
      >
        <div style={{ paddingTop: 16, height: '100%' }}>
          <EmployeeOverview />
        </div>
      </TabPanel>
    </div>
  );
};

export default observer(HeaderTabs);
