import {
  Grid,
  makeStyles,
  Typography,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { navigate } from '@reach/router';

// ICONS:
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const useStyles = makeStyles(({ spacing, typography, palette }) => ({
  header: {
    padding: spacing(3, 3, 1, 3),
    display: 'flex',
    alignItems: 'end',
  },
  heading: {
    fontWeight: typography.fontWeightBold,
  },
  cell: {
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  detailsButton: {
    borderRadius: '100%',
    transition: '.4s background ease-in-out',

    '&:hover': {
      cursor: 'pointer',
      background: '#cee0d0',
    },
  },
  icon: {
    height: 22,
    width: 22,
    color: palette.primary.main,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
}));

interface CustomToolbarProps {
  navigateToUrl?: string;
  titleTranslationId: string;
}
const CustomToolbar: React.FC<CustomToolbarProps> = ({
  titleTranslationId,
  navigateToUrl,
}) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.header}>
      <Grid item xs={8} className={classes.row}>
        <Typography variant="h5" className={classes.heading}>
          <FormattedMessage id={titleTranslationId} />
        </Typography>

        {navigateToUrl && (
          <Tooltip
            title={<FormattedMessage id="Global.seeDetailsButton" />}
            placement="top"
            TransitionProps={{ timeout: 300 }}
          >
            <IconButton
              color="inherit"
              onClick={() => navigate(navigateToUrl)}
              className={classes.detailsButton}
            >
              <OpenInNewIcon classes={{ root: classes.icon }} />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );
};

export default CustomToolbar;
