import React from 'react';
import { makeStyles, Typography, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { injectIntl, IntlShape } from 'react-intl';
import { Form, Formik, useFormikContext } from 'formik';

// COMPONENTS:
import SearchInput from 'common/components/SearchInput';
import InputField from 'common/components/formik/InputField';

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  header: {
    padding: spacing(3),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'end',
  },
  heading: {
    fontWeight: typography.fontWeightBold,
    borderBottom: `2px solid ${palette.primary.contrastText}`,
  },
  addButton: {
    display: 'flex',
    '& Svg': {
      width: 18,
    },
  },
  addIcon: {
    paddingRight: '3px',
  },
  modal: {
    backgroundColor: palette.primary.main,
  },
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  filtersRow: {
    paddingTop: spacing(2),
    display: 'flex',
    width: '100%',
  },
  search: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  select: {
    zIndex: 3,
  },
  editIcon: {
    height: 14,
  },
  accent: {
    color: palette.secondary.light,
  },
  dateFrom: {
    padding: spacing(0, 2),
  },
}));

const AutoSubmit = () => {
  const { values, submitForm } = useFormikContext();

  React.useEffect(() => {
    submitForm();
  }, [values, submitForm]);
  return null;
};

interface IFormValues {
  dateFrom: string;
  dateTo: string;
}

interface IToolbar {
  intl: IntlShape;
  search: string;
  setSearch: (value: any) => void;
  filterByDate: (values: IFormValues) => Promise<void>;
  currentCustomerQuota: any
  dateFilters: {
    dateFrom: string;
    dateTo: string;
  }
  minDate: string;
  maxDate: string;
}

const Toolbar: React.FC<IToolbar> = ({ intl, search, setSearch, filterByDate, dateFilters, currentCustomerQuota, minDate, maxDate }) => {
  const classes = useStyles();

  const initialValues: IFormValues = {
    dateFrom: dateFilters.dateFrom,
    dateTo: dateFilters.dateTo,
  };

  return (
    <Grid container className={classes.header}>
      <Grid item xs={12}>
        <Typography variant="subtitle1" className={classes.heading}>
          <FormattedMessage
            id="Customer.Quotas.quotaName"
            values={{
              b: (value: string) => <b className={classes.accent}>{value}</b>,
              value: `${currentCustomerQuota.QuotaName}`,
            }}
          />
        </Typography>
      </Grid>

      <div className={classes.filtersRow}>
        <Grid item xs={4} className={classes.search}>
          <SearchInput
            placeholder={intl.formatMessage({
              id: 'Customer.Quotas.searchQuota',
            })}
            onChange={e => setSearch(e.target.value)}
            value={search}
          />
        </Grid>

        <Grid item xs={8}>
          <Formik initialValues={initialValues} onSubmit={values => filterByDate(values)}>
            {() => {
              return (
                <Form>
                  <AutoSubmit />
                  <Grid container justify="space-around">
                    <AutoSubmit />

                    {/*<Grid item xs={6} className={classes.dateFrom}>*/}
                      {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>*/}
                      {/*<KeyboardDatePicker*/}
                      {/*  name="dateFrom"*/}
                      {/*  label={*/}
                      {/*    <FormattedMessage id="Global.form.labels.dateFrom" />*/}
                      {/*  }*/}
                      {/*  value={dateFilters.dateFrom}*/}
                      {/*  onChange={() => console.log('changed date')}*/}
                      {/*  minDate={minDate}*/}
                      {/*  maxDate={maxDate}*/}
                      {/*/>*/}
                      {/*</MuiPickersUtilsProvider>*/}
                    {/*</Grid>*/}
                    <Grid item xs={6} className={classes.dateFrom}>
                      <InputField
                        name="dateFrom"
                        label={
                          <FormattedMessage id="Global.form.labels.dateFrom" />
                        }
                        type="date"
                        inputProps={{
                          max: maxDate,
                          min: minDate,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputField
                        name="dateTo"
                        label={
                          <FormattedMessage id="Global.form.labels.dateTo" />
                        }
                        type="date"
                        inputProps={{
                          max: maxDate,
                          min: minDate,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </div>
    </Grid>
  );
};

export default injectIntl(Toolbar);
