import React, { useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';

const Row = (props: any) => {
  const random = Math.random() * (1 - 0.6) + 0.6;
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height={40}
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect
        x="3%"
        y="13"
        rx="6"
        ry="6"
        width={`${0.06 * random * 100}%`}
        height="12"
      />
      <rect
        x="15%"
        y="13"
        rx="6"
        ry="6"
        width={`${0.33 * random * 100}%`}
        height="12"
      />
      <rect
        x="51%"
        y="13"
        rx="6"
        ry="6"
        width={`${0.17 * random * 100}%`}
        height="12"
      />
      <rect
        x="76%"
        y="13"
        rx="6"
        ry="6"
        width={`${0.1 * random * 100}%`}
        height="12"
      />
      <rect
        x="100%"
        y="13"
        rx="6"
        ry="6"
        width={20}
        height="12"
        transform="translate(-34)"
      />
      <rect x="0" y="39" rx="6" ry="6" width="100%" height="1" />
    </ContentLoader>
  );
};

const DefaultLoadingPlaceholder = () => {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(document.getElementById('loading-container')!.clientHeight);
  }, []);

  return (
    <>
      <div id="loading-container" style={{ width: '100%', height: '100%' }}>
        {[...Array(Math.floor(height / 40))].map((e, i) => (
          <div key={i} style={{ width: '100%', height: 40 }}>
            <Row />
          </div>
        ))}
      </div>
    </>
  );
};

export default DefaultLoadingPlaceholder;
