import { createContext } from 'react';
import { observable, action } from 'mobx';

// TYPES:
import {
  getUsers,
  getUserById,
  getAllCustomersForUser,
  getAllWaresForUser,
  addUserRole,
  removeUserRole,
  getUserRoles,
} from 'api/users';
import { UserType } from '../api/users/types';
import { LoadingState, PromiseStates } from '../api/types';

export interface IUsersStore {
  // Observables
  users: any;
  currentUser: UserType | null;
  currentUserCustomers: any;
  currentUserWares: any;
  getUsersState: LoadingState;
  getUserByIdState: LoadingState;
  getCurrentUserCustomersState: LoadingState;
  getCurrentUserWaresState: LoadingState;
  addingNewUserRole: LoadingState;
  removingUserRole: LoadingState;
  gettingUserRoles: LoadingState;

  // Actions
  getUsers: () => Promise<void>;
  setCurrentUser: (user: any) => void;
  getUserById: (userId: string) => Promise<void>;
  getCurrentUserCustomers: (userId: string) => Promise<void>;
  getCurrentUserWares: (userId: string) => Promise<void>;
  addNewUserRole: (values: any) => Promise<void>;
  removeUserRole: (values: any) => Promise<void>;
  getUserRoles: (userId: string) => Promise<void>;
}

class UsersStore implements IUsersStore {
  @observable users: IUsersStore['users'] = [];
  @observable currentUserCustomers: IUsersStore['currentUserCustomers'] = [];
  @observable currentUserWares: IUsersStore['currentUserWares'] = [];
  @observable currentUser: IUsersStore['currentUser'] = null;
  @observable getUsersState: IUsersStore['getUsersState'] = null;
  @observable getUserByIdState: IUsersStore['getUserByIdState'] = null;
  @observable
  getCurrentUserCustomersState: IUsersStore['getCurrentUserCustomersState'] = null;
  @observable
  getCurrentUserWaresState: IUsersStore['getCurrentUserWaresState'] = null;
  @observable
  addingNewUserRole: IUsersStore['addingNewUserRole'] = null;
  @observable
  removingUserRole: IUsersStore['removingUserRole'] = null;
  @observable
  gettingUserRoles: IUsersStore['gettingUserRoles'] = null;

  @action
  getUsers = async () => {
    try {
      this.getUsersState = PromiseStates.PENDING;
      const { data } = await getUsers();
      this.users = data;
      this.getUsersState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getUsersState = PromiseStates.REJECTED;
    }
  };

  @action
  getUserById = async (userId: string) => {
    try {
      this.getUserByIdState = PromiseStates.PENDING;
      const { data } = await getUserById(userId);
      this.currentUser = data;
      this.getUserByIdState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getUserByIdState = PromiseStates.REJECTED;
    }
  };

  @action
  getCurrentUserCustomers = async (userId: string) => {
    try {
      this.getCurrentUserCustomersState = PromiseStates.PENDING;
      const { data } = await getAllCustomersForUser(userId);
      this.currentUserCustomers = data;
      this.getCurrentUserCustomersState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getCurrentUserCustomersState = PromiseStates.REJECTED;
    }
  };

  @action
  getCurrentUserWares = async (userId: string) => {
    try {
      this.getCurrentUserWaresState = PromiseStates.PENDING;
      const { data } = await getAllWaresForUser(userId);
      this.currentUserWares = data;
      this.getCurrentUserWaresState = PromiseStates.FULFILLED;
    } catch (err) {
      this.getCurrentUserWaresState = PromiseStates.REJECTED;
    }
  };

  @action
  addNewUserRole = async (values: any) => {
    try {
      this.addingNewUserRole = PromiseStates.PENDING;
      await addUserRole(values);
      this.addingNewUserRole = PromiseStates.FULFILLED;
    } catch (err) {
      this.addingNewUserRole = PromiseStates.REJECTED;
    }
  };

  @action
  removeUserRole = async (values: any) => {
    try {
      this.removingUserRole = PromiseStates.PENDING;
      await removeUserRole(values);
      this.removingUserRole = PromiseStates.FULFILLED;
    } catch (err) {
      this.removingUserRole = PromiseStates.REJECTED;
    }
  };

  @action
  getUserRoles = async (userId: string) => {
    try {
      this.gettingUserRoles = PromiseStates.PENDING;
      await getUserRoles(userId);
      this.gettingUserRoles = PromiseStates.FULFILLED;
    } catch (err) {
      this.gettingUserRoles = PromiseStates.REJECTED;
    }
  };

  @action setCurrentUser = (user: any) => {
    this.currentUser = user;
  };
}

export const UsersStoreContext = createContext(new UsersStore());
