import React, { useContext } from 'react';
import { observer } from 'mobx-react';

// TYPES:
import { ClientType } from 'api/clients/types';
import { IGridTableSchema } from 'common/components/grid/types';

// COMPONENTS:
import GridTable from 'common/components/grid/GridTable';
import ClientRow from './ClientRow';
import Toolbar from './Toolbar';
import StatusCell from './StatusCell';
import ClientGroupCell from './ClientGroupCell';
import SortableNumericHeader from './SortableNumericHeader';
import SortableStringHeader from './SortableStringHeader';

// STORE:
import { ClientsStoreContext, IClientsStore } from 'stores/clients';
import { FormattedMessage } from 'react-intl';

const schema: IGridTableSchema<ClientType> = {
  header: {
    columns: [
      {
        id: 'PKey',
        name: <FormattedMessage id="Company.Clients.tableHeaders.id" />,
        formatter: SortableNumericHeader
      },
      {
        id: 'Name',
        name: <FormattedMessage id="Company.Clients.tableHeaders.name" />,
        formatter: SortableStringHeader
      },

      {
        id: 'Address1',
        name: <FormattedMessage id="Company.Clients.tableHeaders.address" />,
        formatter: SortableStringHeader
      },
      {
        id: 'ClientGroupPKey',
        name: <FormattedMessage id="Company.Clients.tableHeaders.clientGroup" />,
        formatter: SortableNumericHeader
      },
      {
        id: 'Status',
        name: <FormattedMessage id="Company.Clients.tableHeaders.status" />,
      },
    ],
  },
  row: {
    id: 'PKey',
    formatter: ClientRow,
    cells: [
      {
        key: 'PKey',
      },
      {
        key: 'Name',
      },
      {
        key: 'Address1',
      },
      {
        key: 'ClientGroupPKey',
        formatter: ClientGroupCell,
      },
      {
        key: 'Status',
        formatter: StatusCell,
      },
    ],
  },
};

const ClientsTable = () => {
  const { clients, getClients, getClientsState }: IClientsStore = useContext(
    ClientsStoreContext
  );

  return (
    <GridTable
      tableSchema={schema}
      data={clients}
      toolbar={Toolbar}
      loadingState={getClientsState}
      getData={getClients}
    />
  );
};

export default observer(ClientsTable);
