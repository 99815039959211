import React, { useCallback, useContext, useState } from 'react';
import { observer } from 'mobx-react';

// COMPONENTS:
import QuotaTransactionsTable from '../TabularQuotasCustomerTables/QuotaTransactionsTable';
import QuotaTransactionsToolbar from '../TabularQuotasCustomerTables/QuotaTransactionsTable/Toolbar';

// STORES:
import { CustomersStoreContext, ICustomersStore } from 'stores/customers';

// HELPERS:
import { useDebounce } from 'common/helpers/useDebounce';

// TYPES:
import { IDateFormValues } from '../../../Transactions';

const SelectedQuotaDetails = () => {
  const {
    getQuotasTransactionsState,
    quotasTransactions,
    getQuotasTransactions,
    currentCustomerQuota,
    currentCustomer
  }: ICustomersStore = useContext(CustomersStoreContext);

  const filteredData = quotasTransactions?.filter(
    (quota: any) => quota.KvotePKey === currentCustomerQuota.QuotaPKey
  );

  // const saldo = currentCustomerQuota.Quantity;

  const firstDayOfTheYear = new Date(new Date().getFullYear(), 0, 1, 1).toISOString().substring(0,10);
  const lastDayOfTheYear = new Date(new Date().getFullYear(), 11, 31).toISOString().substring(0,10);

  const [search, setSearch] = useState('');
  const [dateFilters, setDateFilters] = useState({
    dateFrom: firstDayOfTheYear,
    dateTo: lastDayOfTheYear,
  });

  const debouncedSearch = useDebounce(search, 500);

  const filterByDate = async (values: IDateFormValues) => {
    setDateFilters({...values});
  };

  const getSelectedQuotasTransactions = useCallback(() => {
    getQuotasTransactions({
      customerBnr: currentCustomer!.Bnr,
      customerBkey: currentCustomer!.BKey,
      dateFrom: dateFilters.dateFrom,
      dateTo: dateFilters.dateTo,
      saleNr: search,
      cardId: search,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, dateFilters, currentCustomer, currentCustomerQuota, getQuotasTransactions]);

  return (
    <div>
          {/*<TestChart chartData={filteredData} saldo={saldo} />*/}
          <QuotaTransactionsToolbar currentCustomerQuota={currentCustomerQuota} search={search} setSearch={setSearch} dateFilters={dateFilters} filterByDate={filterByDate} minDate={firstDayOfTheYear} maxDate={lastDayOfTheYear} />
          <QuotaTransactionsTable data={filteredData} getData={getSelectedQuotasTransactions} loadingState={getQuotasTransactionsState}/>
    </div>
  );
};

export default observer(SelectedQuotaDetails);
