import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ spacing }) => ({
  paper: {
    height: '100%',
    padding: spacing(3),
    display: 'flex',
    alignItems: 'center',
  },
  chartContainer: {
    width: '100%',
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: spacing(1),
    marginLeft: spacing(1),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  titleText: {
    fontWeight: 'bold',
    marginLeft: spacing(1),
  },
}));
