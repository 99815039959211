import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

// COMPONENTS:
import Overview from 'common/components/Overview';
import CustomerOverviewToolbar from './Toolbar';

// STORES:
import { CustomersStoreContext, ICustomersStore } from 'stores/customers';

// OVERVIEW DATA
import { overviewSectionsSchema } from './schema';

// TYPES
import { CustomerTypesNames } from 'api/customers/types';

const useStyles = makeStyles(({ spacing, palette, typography }) => ({
  rowHeader: {
    fontWeight: typography.fontWeightBold,
    color: palette.secondary.light,
  },
  rowElement: {
    paddingBottom: spacing(4),
  },
  header: {
    paddingTop: spacing(1),
  },
  row: {
    borderBottom: `1px solid ${palette.action.disabledBackground}`,
    '&:last-of-type': {
      borderBottom: 'none',
    },
  },
  owner: {
    display: 'flex',
  },
}));

const CustomerOverview = () => {
  const { currentCustomer }: ICustomersStore = useContext(
    CustomersStoreContext
  );

  const classes = useStyles();

  const isApartment =
    currentCustomer!.BoligType === CustomerTypesNames.Flermannsbolig;

  return (
    <Overview
      sectionsSchema={overviewSectionsSchema}
      currentItem={currentCustomer!}
      translationsPath="Customers.List"
      customToolbar={
        <CustomerOverviewToolbar currentCustomer={currentCustomer!} />
      }
    >
      {!isApartment && currentCustomer!.Eierforhold!.length > 0 && (
        <Grid container className={classes.row}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className={classes.header}>
              <FormattedMessage id="Customers.List.owners" />
            </Typography>
          </Grid>
          <div style={{ width: '100%' }}>
            {currentCustomer!.Eierforhold!.map(item => (
              <div className={classes.owner} key={String(item!.Id)}>
                <Grid item xs={2} className={classes.rowElement}>
                  <Typography variant="body2" className={classes.rowHeader}>
                    <FormattedMessage id="Global.form.labels.name" />
                  </Typography>
                  <Typography variant="body2">{item.Navn}</Typography>
                </Grid>
                <Grid item xs={2} className={classes.rowElement}>
                  <Typography variant="body2" className={classes.rowHeader}>
                    <FormattedMessage id="Global.form.labels.idNrOrgNr" />
                  </Typography>
                  <Typography variant="body2">
                    {item.IdentifikasjonsNummer}
                  </Typography>
                </Grid>
              </div>
            ))}
          </div>
        </Grid>
      )}
    </Overview>
  );
};

export default observer(CustomerOverview);
