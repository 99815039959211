import { refreshAccessToken } from 'api/customers';
import {
  deleteTokens, getAccessToken,
  getRefreshToken,
  setTokens,
} from 'common/helpers/auth';
import { navigate } from '@reach/router';

const shouldIntercept = (error: any) => {
  try {
    return (
      error.response.status === 401 &&
      !error.response.config.url.includes('RefreshToken') &&
      !error.response.config.url.includes('Login?')
    );
  } catch (e) {
    return false;
  }
};

const setTokenData = (accessToken: string, refreshToken: string) => {
  setTokens(accessToken, refreshToken);
};

const handleTokenRefresh = () => {
  const currentRefreshToken = getRefreshToken();
  const accessToken = getAccessToken();
  return new Promise((resolve, reject) => {
   if (accessToken && currentRefreshToken) {
     refreshAccessToken(accessToken!, currentRefreshToken!)
       .then((response) => {
         // setTokenData(response.data.AccessToken, response.data.RefreshToken);
         const { AccessToken, RefreshToken, ExpirationDate } = response.data;
         const tokenData = {
           AccessToken: AccessToken,
           RefreshToken: RefreshToken,
           ExpirationDate: ExpirationDate
         };
         resolve(tokenData);
       })
       .catch((err) => {
         deleteTokens();
         navigate('/login');
         reject(err);
       });
    }
  });
};

const attachTokenToRequest = (request: any, accessToken: string) => {
  request.headers.Authorization = `Token ${accessToken}`;

  // If there is an edge case where access token is also set in request query,
  // this is also a nice place to add it
  // Example: /orders?token=xyz-old-token
};

export const refreshTokenInterceptor = (axiosClient: any, customOptions = {}) => {
  let isRefreshing = false;
  let failedQueue: any[] = [];

  const options = {
    attachTokenToRequest,
    handleTokenRefresh,
    setTokenData,
    shouldIntercept,
    ...customOptions,
  };

  const processQueue = (error: any, token = undefined) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    failedQueue = [];
  };

  const errorInterceptor = (error: any) => {
    if (!options.shouldIntercept(error)) {
      return Promise.reject(error);
    }

    if (error.config._retry || error.config._queued) {
      return Promise.reject(error);
    }

    const originalRequest = error.config;

    if (isRefreshing) {
      return (
        new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token: any) => {
            originalRequest._queued = true;
            console.log(originalRequest);
            console.log(token);
            options.attachTokenToRequest(originalRequest, token);
            return axiosClient.request(originalRequest);
          })
          // Ignore refresh token request's "err" and return actual "error" for the original request
          .catch(() => {
            return Promise.reject(error);
          })
      );
    }

    originalRequest._retry = true;
    isRefreshing = true;

    /* https://github.com/amilajack/eslint-plugin-compat/issues/252 */
    return new Promise((resolve, reject) => {
      options.handleTokenRefresh
        .call(options.handleTokenRefresh)
        .then((tokenData: any) => {
          options.setTokenData(tokenData.AccessToken, tokenData.RefreshToken);
          options.attachTokenToRequest(originalRequest, tokenData.AccessToken);
          processQueue(null, tokenData.AccessToken);
          resolve(axiosClient.request(originalRequest));
        })
        .catch((err) => {
          processQueue(err, undefined);
          reject(err);
        })
        .finally(() => {
          isRefreshing = false;
        });
    });
  };

  axiosClient.interceptors.response.use(undefined, errorInterceptor);
};
