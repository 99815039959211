import React, { useState } from 'react';
import { useQuery } from 'react-query';

// STEPS COMPONENTS:
// import StepThree from './StepThree';
import StepForAddingCardNumber from './StepForAddingCardNumber';
import PrintStepCompanies from './PrintStepCompanies';
import PrintStepHousehold from './PrintStepHousehold';
import PrintCardApartments from './PrintCardApartments';
import StepScanning from './StepScanning';

// STORE:
// import { CustomersStoreContext, ICustomersStore } from 'stores/customers';

// API:
import { getNextAvailableCardNumber } from 'api/cards';

// TYPES
import {
  CustomerTypesNames,
  CustomerType,
  RegisterEnheterType,
} from 'api/customers/types';
import { makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

interface ISteps {
  toggleModal: () => void;
  getCards: () => void;
  customer: CustomerType;
  apartment?: RegisterEnheterType;
}
export interface IFormValues {
  digitalCardId: string;
  comment: string;
}

export interface ICardNumberFormValues {
  cardNumberValue: string;
}

const initialCardNumberValue: ICardNumberFormValues = {
  cardNumberValue: '',
};

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    height: '100%',
    padding: spacing(4, 3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Steps: React.FC<ISteps> = ({
  toggleModal,
  customer,
  getCards,
  apartment,
}) => {
  const { data: availableCardNumber, isLoading } = useQuery<string>(
    'nextAvailableCardNumber',
    async () => {
      const { data } = await getNextAvailableCardNumber();
      return data;
    },
    {
      onSuccess: data => setCardNumber(data),
    }
  );

  const setNextAvailableCardNumberAutomatically = localStorage.getItem(
    'wayOfAddingCard'
  );

  const [step, setStep] = useState(
    setNextAvailableCardNumberAutomatically === 'automatically' ? 2 : 1
  );
  const [cardNumber, setCardNumber] = useState(availableCardNumber || '');
  const [expirationDate, setExpirationDate] = useState('');

  const classes = useStyles();

  const nextStep = () => setStep(step + 1);

  // const prevStep = () => setStep(step + -1);

  // const forceStep = () => {
  //   setTimeout(() => {
  //     nextStep();
  //   }, 20000);
  // };

  // useEffect(() => {
  //   if (step === 4) forceStep();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [step]);

  //
  // const handleCardNumberChange = (values: any) => {
  //   setScannedCardNumber(values.cardNumberValue);
  //   nextStep();
  // };

  const renderCardTemplate = () => {
    switch (customer.KundeType) {
      case CustomerTypesNames.FysiskPerson:
        return (
          <PrintStepHousehold
            toggle={toggleModal}
            customer={customer}
            cardNumber={cardNumber}
            nextStep={nextStep}
            setExpirationDate={setExpirationDate}
          />
        );
      case CustomerTypesNames.JuridiskPerson:
        return (
          <PrintStepCompanies
            toggle={toggleModal}
            customer={customer}
            cardNumber={cardNumber}
            nextStep={nextStep}
            setExpirationDate={setExpirationDate}
          />
        );
      case CustomerTypesNames.Ukjent:
        return (
          <PrintCardApartments
            toggle={toggleModal}
            customer={customer}
            cardNumber={cardNumber}
            apartment={apartment}
            nextStep={nextStep}
            setExpirationDate={setExpirationDate}
          />
        );
      default:
        return;
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <StepForAddingCardNumber
            apartment={apartment}
            toggle={toggleModal}
            customer={customer}
            nextStep={nextStep}
            setCardNumber={setCardNumber}
          />
        );
      case 2:
        return renderCardTemplate();
      case 3:
        return (
          <StepScanning
            nextStep={nextStep}
            cardNumber={cardNumber}
            cardNumberValue={initialCardNumberValue}
            getCards={getCards}
            toggle={toggleModal}
            expirationDate={expirationDate}
            apartment={apartment}
          />
        );
      default:
        return '';
    }
  };

  return (
    <div
      style={{
        width: 552,
        height: 552,
      }}
      data-testid="steps-modal"
    >
      {!isLoading && availableCardNumber ? (
        renderStep()
      ) : (
        <div className={classes.container}>
          <Typography variant="h5">
            <FormattedMessage id="Global.loading" />
          </Typography>
        </div>
      )}
    </div>
  );
};

export default Steps;
