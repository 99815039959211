import React, { ComponentType } from 'react';
import { Typography } from '@material-ui/core';

// TYPES
import { CustomerType } from 'api/customers/types';

interface IStatusSectionElement {
  label: string;
  value?: keyof CustomerType;
  formatter?: ComponentType<any>;
}

interface ICombinedNumbersFormatterProps {
  currentItem: CustomerType;
  sectionElement: IStatusSectionElement;
}

const CombinedNumbersFormatter: React.FC<ICombinedNumbersFormatterProps> = ({
                                                                 currentItem,
                                                               }) => {

  const { GaardsNr, BruksNr, SNr, FesteNr } = currentItem;

  const bnrValue = BruksNr || '-';
  const gnrValue = GaardsNr || '-';
  const fnrValue = FesteNr || '-';
  const snrValue = SNr || '-';

  return (
    <Typography variant="body2">
      {gnrValue} / {bnrValue} / {fnrValue} / {snrValue}
    </Typography>
  );
};

export default CombinedNumbersFormatter;
