import React, { useContext, FC, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

// TYPES:
import { IGridTableSchema } from 'common/components/grid/types';
import {
  CustomerCards,
  CustomerType,
  CustomerTransactionType,
  CustomerDetailsSectionSchema,
  CustomerTypesNames,
} from 'api/customers/types';

// STYLES
import { useStyles } from './index.styles';

// HELPERS:
import { useGetTotalNumberOfApartments } from '../../../queries';

// STORES:
import { CustomersStoreContext, ICustomersStore } from 'stores/customers';

// COMPONENTS
import DateCell from 'common/components/grid/DateCell';
import GridTable from 'common/components/grid/GridTable';
import CustomToolbar from './components/CustomToolbar';
import EditCell from '../../../Customer/components/Quotas/components/QuotasCustomerTable/EditCell';

const overviewSectionSchema: CustomerDetailsSectionSchema[] = [
  {
    label: 'id',
    value: 'BKey',
  },
  {
    label: 'alternativeName',
    value: 'Adresse',
  },
  {
    label: 'customerType',
    value: 'KundeType',
  },
  {
    label: 'status',
    value: 'Status',
  },
];

const transactionsDataSchema: IGridTableSchema<CustomerTransactionType> = {
  wrapper: ({ children, ...rest }) => <div {...rest}>{children}</div>,
  header: {
    columns: [
      {
        id: 'Date',
        name: 'Date',
      },
      {
        id: 'SaleNr',
        name: 'Bilagsnr.',
      },
      {
        id: 'QuotaType',
        name: 'Quota type',
      },
      {
        id: 'ItemName',
        name: 'Ware name',
      },
      {
        id: 'Quantity',
        name: 'Amount',
      },
      {
        id: 'UnitCount',
        name: 'Quota amount',
      },
    ],
  },
  row: {
    id: 'SaleNr',
    cells: [
      {
        key: 'Date',
        formatter: DateCell,
      },
      {
        key: 'SaleNr',
      },
      {
        key: 'QuotaType',
      },
      {
        key: 'ItemName',
      },
      {
        key: 'Quantity',
      },
      {
        key: 'UnitCount',
      },
    ],
  },
};

const quotaSummarySchema: any = {
  wrapper: ({ children, ...rest }: any) => <div {...rest}>{children}</div>,
  header: {
    columns: [
      {
        id: 'QuotaName',
        name: 'Name',
      },
      {
        id: 'Quota',
        name: 'StartQuantity',
      },
      {
        id: 'Quantity',
        name: 'Rest',
      },
      {
        id: 'unit',
        name: 'Unit',
      },
      {
        id: 'customCellKey',
        name: '',
      },
    ],
  },
  row: {
    id: 'QuotaPKey',
    // formatter: QuotasRow,
    cells: [
      {
        key: 'QuotaName',
      },
      {
        key: 'StartQuantity',
      },
      {
        key: 'Quantity',
      },
      {
        key: 'unit.Unit',
        // formatter: UnitsCell,
      },
      {
        customKey: 'editCell',
        formatter: EditCell,
      },
    ],
  },
};

const cardsSchema: IGridTableSchema<CustomerCards> = {
  wrapper: ({ children, ...rest }: any) => <div {...rest}>{children}</div>,
  header: {
    columns: [
      {
        id: 'ID',
        name: <FormattedMessage id="Agreement.Cards.tableHeaders.cardNumber" />,
      },
      {
        id: 'DatoOpprettet',
        name: <FormattedMessage id="Agreement.Cards.tableHeaders.created" />,
      },
      {
        id: 'Tekst',
        name: <FormattedMessage id="Agreement.Cards.tableHeaders.status" />,
      },
      {
        id: 'CardStatus',
        name: <FormattedMessage id="Agreement.Cards.tableHeaders.comment" />,
      },
    ],
  },
  row: {
    id: 'ID',
    cells: [
      {
        key: 'ID',
      },
      {
        key: 'DatoOpprettet',
        formatter: DateCell,
      },
      {
        key: 'CardStatus',
      },
      {
        key: 'Tekst',
      },
    ],
  },
};

interface Props {
  customer: CustomerType;
}

const CustomerPreviewDetails: FC<Props> = ({ customer }) => {
  const classes = useStyles();

  const {
    getCustomerTransactions,
    getCustomerTransactionsState,
    customerTransactions,
    getQuotaSummary,
    quotaSummary,
    getQuotaSummaryState,
    getCustomerCards,
    getCustomerCardsState,
    customerCards,
  }: ICustomersStore = useContext(CustomersStoreContext);

  const isApartment = customer!.BoligType === CustomerTypesNames.Flermannsbolig;

  const {
    isSuccess: isTotalNumberOfApartmentsFetched,
  } = useGetTotalNumberOfApartments(customer!, undefined, customer!.BKey);

  const getLastTransactions = useCallback(
    () => getCustomerTransactions({ customerBkey: customer.BKey }),
    [customer, getCustomerTransactions]
  );

  const getQuotas = useCallback(() => getQuotaSummary(customer), [
    customer,
    getQuotaSummary,
  ]);

  const getCards = useCallback(
    () =>
      getCustomerCards({
        id: customer.BKey,
      }),
    [customer, getCustomerCards]
  );

  const firstCards = customerCards.slice(0, 3);
  const firstTransactions = customerTransactions.slice(0, 3);

  return (
    <>
      <Grid container className={classes.header}>
        <Grid container className={classes.row}>
          <Grid item xs={12}>
            <CustomToolbar
              titleTranslationId="Customers.List.overview"
              navigateToUrl={`customers/${customer.BKey}`}
            />
          </Grid>
          {(!isApartment || isTotalNumberOfApartmentsFetched) && (
            <Grid className={classes.overviewRow} item xs={12}>
              {overviewSectionSchema.map(item => (
                <Grid item key={item.label} xs={3}>
                  <Typography variant="body2" className={classes.rowHeader}>
                    <FormattedMessage id={`Global.form.labels.${item.label}`} />
                  </Typography>
                  <Typography variant="body2">
                    {customer &&
                      (item!.value === 'Status' ? (
                        customer[item!.value!] === 100 ? (
                          <FormattedMessage id="Global.active" />
                        ) : (
                          <FormattedMessage id="Global.deactivated" />
                        )
                      ) : (
                        customer[item!.value!]
                      ))}
                  </Typography>
                </Grid>
              ))}
              {isApartment && (
                <Grid item xs={3}>
                  <Typography variant="body2" className={classes.rowHeader}>
                    <FormattedMessage id="Global.form.labels.sumUnits" />
                  </Typography>
                  <Typography variant="body2">
                    {customer.totalNumberOfApartments}
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      <GridTable
        className={classes.gridTableWrapper}
        tableSchema={transactionsDataSchema}
        data={firstTransactions}
        loadingState={getCustomerTransactionsState}
        getData={getLastTransactions}
        hasScrollbar={false}
        customToolbar={
          <CustomToolbar
            titleTranslationId="Customers.List.lastTransactions"
            navigateToUrl={`customers/${customer.BKey}/#transactions`}
          />
        }
      />
      <GridTable
        className={classes.gridTableWrapper}
        tableSchema={quotaSummarySchema}
        data={quotaSummary}
        loadingState={getQuotaSummaryState}
        getData={getQuotas}
        hasScrollbar={false}
        customToolbar={
          <CustomToolbar
            titleTranslationId="Customer.quotas"
            navigateToUrl={`customers/${customer.BKey}/#quotas`}
          />
        }
      />
      <GridTable
        className={classes.gridTableWrapper}
        tableSchema={cardsSchema}
        data={firstCards}
        loadingState={getCustomerCardsState}
        getData={getCards}
        hasScrollbar={false}
        customToolbar={
          <CustomToolbar
            titleTranslationId="Customer.cards"
            navigateToUrl={`customers/${customer.BKey}/#cards`}
          />
        }
      />
    </>
  );
};

export default observer(CustomerPreviewDetails);
