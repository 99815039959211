import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import { Grid, makeStyles, Typography, Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Form, Formik } from 'formik';

// COMPONENTS:
import SelectField from 'common/components/formik/SelectField';

// STORES:
import { LoginStoreContext } from 'stores/login';

export const useStyles = makeStyles(({ spacing, palette }) => ({
  container: {
    padding: spacing(3, 4.5, 5, 3.5),
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'hidden',
  },
  title: {
    minHeight: 33,
    paddingBottom: spacing(2),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    marginTop: spacing(2),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: spacing(2),
  },
  selectInput: {
    marginTop: spacing(1),
  },
  savedButton: {
    background: palette.primary.dark,
  },
}));

interface IFormValues {
  language: string;
  rowStyle: string;
  wayOfAddingCard: string;
}

const Settings = () => {
  const classes = useStyles();

  const { changeLanguage, language } = useContext(LoginStoreContext);

  const [isFormSaved, setIsFormSaved] = useState(false);

  const languageOptions = [
    {
      label: 'English',
      value: 'en',
    },
    {
      label: 'Norsk',
      value: 'no',
    },
  ];

  const rowStyleOptions = [
    {
      label: 'Compact view',
      value: 'compact',
    },
    {
      label: 'Normal view',
      value: 'normal',
    },
  ];

  const addingCardOptions = [
    {
      label: 'Automatically choose next available card number',
      value: 'automatically',
    },
    {
      label: 'Set card number manually',
      value: 'manually',
    },
  ];

  const initialValues: IFormValues = {
    language: language,
    rowStyle: localStorage.getItem('rowStylingView') || '',
    wayOfAddingCard: localStorage.getItem('wayOfAddingCard') || 'manually',
  };

  const handleSubmit = (values: IFormValues) => {
    changeLanguage(values.language);
    localStorage.setItem('rowStylingView', values.rowStyle);
    localStorage.setItem('wayOfAddingCard', values.wayOfAddingCard);
    setIsFormSaved(true);
  };

  return (
    <div className={classes.container}>
      <Typography variant="h3" component="h3" className={classes.title}>
        <FormattedMessage id="Settings.settings" />
      </Typography>
      <Grid container className={classes.content}>
        <Typography variant="h5" component="h3" className={classes.title}>
          <FormattedMessage id="Settings.general" />
        </Typography>

        <Grid item xs={3}>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {() => {
              return (
                <Form
                  className={classes.form}
                  onClick={() => setIsFormSaved(false)}
                >
                  <SelectField
                    name="language"
                    options={languageOptions}
                    label={<FormattedMessage id="Settings.language" />}
                  />
                  <SelectField
                    name="rowStyle"
                    options={rowStyleOptions}
                    label={<FormattedMessage id="Settings.rowStyling" />}
                    className={classes.selectInput}
                  />
                  <SelectField
                    name="wayOfAddingCard"
                    options={addingCardOptions}
                    label={<FormattedMessage id="Settings.wayOfAddingCard" />}
                    className={classes.selectInput}
                  />
                  {isFormSaved ? (
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      className={`${classes.savedButton} ${classes.button}`}
                    >
                      <FormattedMessage id="Global.saved" />
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      className={classes.button}
                    >
                      <FormattedMessage id="Global.save" />
                    </Button>
                  )}
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </div>
  );
};

export default observer(Settings);
