import v from 'validator';

export const encodeCredentialsToBase64 = (credentials: any): string => {
  const textToEncode = `${credentials.username}:${credentials.password}`;
  const base64Credentials = window.btoa(textToEncode);
  return `Basic ${base64Credentials}`;
};

export enum UserCookie {
  AccessToken = 'AccessToken',
  RefreshToken = 'RefreshToken',
}

export const setTokens = (accessToken: string, refreshToken: string) => {
  if (accessToken && refreshToken) {
    localStorage.setItem(UserCookie.AccessToken, accessToken);
    localStorage.setItem(UserCookie.RefreshToken, refreshToken);
  }
};

export const deleteTokens = () => {
  localStorage.removeItem(UserCookie.AccessToken);
  localStorage.removeItem(UserCookie.RefreshToken);
};

export const getAccessToken = (): string | null =>
  localStorage.getItem(UserCookie.AccessToken);

export const getRefreshToken = (): string | null =>
  localStorage.getItem(UserCookie.RefreshToken);

export const isUserAuthenticated = () => {
  const accessToken = localStorage.getItem(UserCookie.AccessToken);
  const refreshToken = localStorage.getItem(UserCookie.RefreshToken);

  return !!accessToken && !!refreshToken && v.isJWT(accessToken!);
};
