import React from 'react';

// ICONS:
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import BusinessIcon from '@material-ui/icons/Business';

// TYPES
import { SideBarItemType } from './types';

const sideBarItems: SideBarItemType[] = [
  {
    icon: <DashboardOutlinedIcon />,
    text: 'Dashboard',
    href: '/dashboard',
    isActive: false,
    willBeAvailableSoon: true,
    isDisabled: true,
    subcategories: [],
  },
  {
    icon: <PersonOutlineOutlinedIcon />,
    text: 'Customers',
    href: '/customers',
    isActive: false,
    subcategories: [],
  },
  {
    icon: <WorkOutlineOutlinedIcon />,
    text: 'Company',
    isActive: false,
    subcategories: [
      {
        text: 'Clients',
        href: '/clients',
        isActive: false,
      },
      {
        text: 'Client Groups',
        href: '/clientGroups',
        isActive: false,
      },
      {
        text: 'Users',
        href: '/users',
        isActive: false,
      },
      {
        text: 'Employees',
        href: '/employees',
        isActive: false,
      },
      {
        text: 'Pricetables',
        href: '/pricetables',
        isActive: false,
      },
    ],
  },
  {
    icon: <BusinessIcon />,
    text: 'Accounting',
    isActive: false,
    subcategories: [
      {
        text: 'Wares',
        href: '/wares',
        isActive: false,
      },
    ],
  },
  {
    icon: <DashboardOutlinedIcon />,
    text: 'WasteBins',
    isActive: false,
    subcategories: [
      {
        text: 'WasteBins',
        href: '/wastebins',
        isActive: false,
      },
      {
        text: 'Work Orders',
        href: '/work-orders',
        isActive: false,
      },
    ],
  },
];

export default sideBarItems;
