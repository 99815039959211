import React, { ComponentType } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

// TYPES
import { EmployeeType } from 'api/employees/types';

interface IStatusSectionElement {
  label: string;
  value?: keyof EmployeeType;
  formatter?: ComponentType<any>;
}

interface IStatusFormatter {
  currentItem: EmployeeType;
  sectionElement: IStatusSectionElement;
}

const useStyles = makeStyles(({ palette }) => ({
  active: {
    color: palette.primary.main,
  },
  deactivated: {
    color: palette.error.main,
  },
}));

const StatusFormatter: React.FC<IStatusFormatter> = ({ currentItem }) => {
  const classes = useStyles();

  return (
    <>
      {!currentItem.DatoSluttet ? (
        <Typography className={classes.active}>
          <FormattedMessage id="Global.active" />
        </Typography>
      ) : (
        <Typography className={classes.deactivated}>
          <FormattedMessage id="Global.deactivated" />
        </Typography>
      )}
    </>
  );
};

export default StatusFormatter;
