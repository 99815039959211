import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { navigate } from '@reach/router';
import { observer } from 'mobx-react';

// COMPONENTS:
import Header from 'common/components/Header';
import Table from './Table';

// ICONS:
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CloseIcon from '@material-ui/icons/Close';

// STORES:
import { WastebinsStoreContext } from 'stores/wastebins';

const useStyles = makeStyles(({ spacing, typography }) => ({
  container: {
    padding: spacing(2),
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  contentContainer: {
    flexGrow: 1,
    minHeight: 0,
  },
  column: {
    padding: spacing(1),
    height: '100%',
  },
  waresPaper: {
    height: '100%',
    overflow: 'auto',
  },
  button: {
    marginLeft: spacing(2),
    display: 'flex',
    '& Svg': {
      width: 18,
    },
  },
  icon: {
    marginRight: spacing(0.5),
  },
  buttonText: {
    textTransform: 'uppercase',
    fontWeight: typography.fontWeightMedium,
  },
  modalContainer: {
    width: 496,
    height: '100%',
    padding: spacing(5, 8),
  },
  title: {
    paddingBottom: spacing(2),
  },
}));

const ImportWorkOrders = () => {
  const classes = useStyles();

  const breadcrumbs = [
    {
      name: <FormattedMessage id="Wastebins.wastebins" />,
      path: '/wares',
    },
  ];

  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [csvData, setCsvData] = useState<any[]>([]);
  // const [fileStatus, setFileStatus] = useState('idle');

  const {
    importedWorkOrders,
    selectedWorkOrdersToImport,
    createWorkOrder,
  } = useContext(WastebinsStoreContext);

  const handleCreateWorkOrderButton = async () => {
    await createWorkOrder(selectedWorkOrdersToImport);
    navigate('/work-orders');
  };

  useEffect(() => {
    if (!importedWorkOrders.length) {
      navigate('/work-orders');
    }
  });

  return (
    <>
      <div className={classes.container}>
        <Header
          breadcrumbs={breadcrumbs}
          title={<FormattedMessage id="Wastebins.importWorkOrder" />}
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
            onClick={() => navigate('work-orders')}
          >
            <CloseIcon classes={{ root: classes.icon }} />
            <Typography variant="caption" className={classes.buttonText}>
              <FormattedMessage id="Global.cancel" />
            </Typography>
          </Button>{' '}
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
            onClick={handleCreateWorkOrderButton}
            disabled={!selectedWorkOrdersToImport.length}
          >
            <ArrowUpwardIcon classes={{ root: classes.icon }} />
            <Typography variant="caption" className={classes.buttonText}>
              <FormattedMessage id="Global.import" />
            </Typography>
          </Button>
        </Header>
        <Grid container className={classes.contentContainer}>
          <Grid item xs={12} className={classes.column}>
            <Paper className={classes.waresPaper}>
              <Table tableData={importedWorkOrders} />
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default observer(ImportWorkOrders);
