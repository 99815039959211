import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';

// TYPES
import { WareType } from 'api/wares/types';

// STORES
import { IWaresStore, WaresStoreContext } from 'stores/wares';

interface IUnitFormatter {
  currentItem: WareType;
}

const UnitFormatter: React.FC<IUnitFormatter> = ({ currentItem }) => {
  const { getUnits, units }: IWaresStore = useContext(WaresStoreContext);

  useEffect(() => {
    getUnits();
  }, [getUnits]);

  const wareUnit = units?.find(unit => unit.PKey === currentItem.EnhetPKey);

  return <>{wareUnit ? wareUnit.Navn : '-'}</>;
};

export default observer(UnitFormatter);
