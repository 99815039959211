import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

// TYPES:
import { IPricetableInformationsSectionSchema } from '../pricetablesInformations';
import { PricetableType } from 'api/pricetables/types';

interface ISingleSection {
  data: IPricetableInformationsSectionSchema[];
  currentPricetable: PricetableType;
}

const useStyles = makeStyles(({ typography, spacing, palette }) => ({
  root: {
    padding: spacing(3),
  },
  rowHeader: {
    fontWeight: typography.fontWeightBold,
    color: palette.secondary.light,
  },
  header: {
    paddingTop: spacing(2),
  },
}));

const SingleSection: React.FC<ISingleSection> = ({
  data,
  currentPricetable,
}) => {
  const classes = useStyles();

  return (
    <>
      {data.map(el => (
        <Grid item xs={4} key={el.label}>
          <Typography variant="body2" className={classes.rowHeader}>
            <FormattedMessage id={`Company.Pricetables.${el.label}`} />
          </Typography>
          <Typography variant="body2">
            {(currentPricetable && currentPricetable[el.value]) ||
            currentPricetable[el.value] === 0
              ? currentPricetable[el.value]
              : '-'}
          </Typography>
        </Grid>
      ))}
    </>
  );
};

export default SingleSection;
