import React from 'react';
import { Router } from '@reach/router';

// ROUTES:
import MembershipRoute from './common/components/routes/MembershipRoute';
import PrivateRoute from './common/components/routes/PrivateRoute';
import DefaultRoute from './common/components/routes/DefaultRoute';

// CUSTOMERS MODULE
import Customers from './modules/Customers/List';
import Customer from './modules/Customers/Customer';

// COMPANY MODULE
import Employees from './modules/Company/Employees';
import Clients from './modules/Company/Clients';
import Pricetables from './modules/Company/Pricetables';
import ClientGroups from './modules/Company/ClientGroups';
import ClientGroup from './modules/Company/ClientGroup';
import UsersList from './modules/Company/Users/UsersList';
import User from './modules/Company/Users/User';

// ACCOUNTING MODULE
import Wares from './modules/Accounting/Wares';

// DASHBOARD MODULE
import Dashboard from './modules/Dashboard';
import TableauCharts from './modules/Dashboard/TableauCharts';
import ExampleReport from './modules/Dashboard/TableauCharts/ExampleReport';

// WASTEBINS
import Wastebins from './modules/WasteBinModule/Wastebins';
import WorkOrders from './modules/WasteBinModule/WorkOrders';
import ImportWorkOrders from './modules/WasteBinModule/ImportWorkOrders';

// OTHER
import Login from './modules/Login';
import Settings from './modules/Settings';
import WorkOrder from './modules/WasteBinModule/WorkOrder';

const App = () => {
  return (
    <Router>
      {/* Public routes */}
      <MembershipRoute component={Login} path="/login" />

      {/* Customers */}
      <PrivateRoute component={Customers} path="/customers" />
      <PrivateRoute component={Customer} path="/customers/:customerId" />

      {/* Dashboards and reports */}
      <PrivateRoute component={Dashboard} path="/dashboard" />
      <PrivateRoute component={TableauCharts} path="/dashboard-tableau" />
      <PrivateRoute component={ExampleReport} path="/report" />

      {/* Company */}
      <PrivateRoute component={Employees} path="/employees" />
      <PrivateRoute component={Clients} path="/clients" />
      <PrivateRoute component={UsersList} path="/users" />
      <PrivateRoute component={User} path="/users/:userId" />
      <PrivateRoute component={Pricetables} path="/pricetables" />
      <PrivateRoute component={ClientGroups} path="/clientGroups" />
      <PrivateRoute
        component={ClientGroup}
        path="/clientGroups/:clientGroupId"
      />

      {/* Accounting */}
      <PrivateRoute component={Wares} path="/wares" />

      {/* Wastebins */}
      <PrivateRoute component={Wastebins} path="/wastebins" />
      <PrivateRoute component={WorkOrders} path="/work-orders" />
      <PrivateRoute
        component={WorkOrder}
        path="/work-orders/:workOrderGroupId"
      />
      <PrivateRoute component={ImportWorkOrders} path="/import-work-orders" />

      {/* Other */}
      <PrivateRoute component={Settings} path="/settings" />

      {/* Default route - redirect to dashboard or login */}
      <DefaultRoute path="/" />
    </Router>
  );
};

export default App;
