import React from 'react';
import { makeStyles, TableCell, Typography } from '@material-ui/core';

// COMPONENTS
import { ICell, IGridTableSchema } from 'common/components/grid/types';
import { FormattedMessage } from 'react-intl';

interface IStatusCell<T> {
  row: IGridTableSchema<T>['row'];
  cell: ICell<T>;
  rowData: any;
}

const useStyles = makeStyles(({ palette }) => ({
  active: {
    color: palette.primary.main,
  },
  deactivated: {
    color: palette.error.main,
  },
}));

const StatusCell = <T extends object>({
  rowData,
  row,
  cell,
}: IStatusCell<T>) => {
  const classes = useStyles();

  return (
    <TableCell key={`${row.id}-${cell.key}`}>
      {rowData.Status === 100 ? (
        <Typography className={classes.active} variant="body2">
          <FormattedMessage id="Global.active" />
        </Typography>
      ) : (
        <Typography className={classes.deactivated} variant="body2">
          <FormattedMessage id="Global.deactivated" />
        </Typography>
      )}
    </TableCell>
  );
};

export default StatusCell;
