import React, { useContext, useEffect } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import * as Yup from 'yup';

// COMPONENTS:
import InputField from 'common/components/formik/InputField';
import ButtonLoading from 'common/components/buttons/ButtonLoading';
import CustomModal from 'common/components/CustomModal';
import ModalRow from 'common/components/CustomModal/ModalRow';
import SelectField from 'common/components/formik/SelectField';
import Alert from 'common/components/Alert';
import { FixedSizeList as List } from 'react-window';

// TYPES:
import { CustomerType, NewCustomerFormValuesType } from 'api/customers/types';

// STORES:
import { CustomersStoreContext, ICustomersStore } from 'stores/customers';

interface ICustomerModal {
  intl: IntlShape;
  currentCustomer?: CustomerType;
  isOpen: boolean;
  toggleModal: () => void;
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  editButton: {
    height: 32,
    display: 'flex',
    justifyContent: 'flex-end',
    '& Svg': {
      width: 14,
    },
  },
  editIcon: {
    height: 14,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  row: {
    display: 'flex',
    borderBottom: `1px solid ${palette.action.disabledBackground}`,
    paddingBottom: spacing(2.5),
  },
  input: {
    padding: spacing(1, 3, 1, 0),
  },
  button: {
    marginTop: spacing(4),
  },
  sectionEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  sectionStart: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  additionalSection: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  anotherRow: {
    paddingTop: spacing(2),
  },
  additionalSectionButton: {
    '& Svg': {
      width: 16,
      marginRight: spacing(0.5),
    },
  },
  removeSectionButton: {
    color: palette.error.main,
    '& Svg': {
      width: 16,
      marginRight: spacing(0.5),
      color: palette.error.main,
    },
  },
}));

const CustomerModal: React.FC<ICustomerModal> = ({
  intl,
  currentCustomer,
  isOpen,
  toggleModal,
}) => {
  const {
    getCustomerById,
    getCustomers,
    addNewCustomer,
    editCustomer,
    availableCustomersTypes,
    getAvailableCustomersTypes,
    addNewCustomerErrorMessage,
    clearCustomerErrorMessage,
    postCodes,
    getPostCodes,
    getCommuneNumbers,
    communeNumbers,
  }: ICustomersStore = useContext(CustomersStoreContext);

  const classes = useStyles();

  const modalTitle = currentCustomer ? (
    <FormattedMessage
      id="Customers.List.editCustomer"
      values={{
        b: (chunk: string) => <b>{chunk}</b>,
        value: `${currentCustomer?.BKey}, ${currentCustomer?.Navn}`,
      }}
    />
  ) : (
    <FormattedMessage id="Customers.List.addCustomer" />
  );

  const handleAddCustomer = async (
    values: NewCustomerFormValuesType,
    { setErrors }: any
  ) => {
    clearCustomerErrorMessage();
    const addingSuccess = await addNewCustomer(values);

    if (addingSuccess) {
      toggleModal();
      await getCustomers();
    } else {
      setErrors({ id: '' });
    }
  };

  const handleEditCustomer = async (values: NewCustomerFormValuesType) => {
    clearCustomerErrorMessage();
    const editingSuccess = await editCustomer(values);

    if (editingSuccess) {
      toggleModal();
      await getCustomerById(currentCustomer!.BKey || null);
    }
  };

  useEffect(() => {
    clearCustomerErrorMessage();
    getAvailableCustomersTypes();
    getPostCodes();
    getCommuneNumbers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAvailableCustomersTypes, clearCustomerErrorMessage, getPostCodes]);

  const priceTableOptions = availableCustomersTypes?.map(type => ({
    value: type.PKey!.toString(),
    label: type.Navn,
  }));

  const postCodesOptions = postCodes?.map((code: any) => ({
    value: code.PKey,
    label: code.PKey,
  }));

  const communeNumbersOptions = communeNumbers?.map((code: any) => ({
    value: code.Nummer,
    label: code.Nummer,
  }));

  const cardPaymentOptions = [
    {
      value: 'true',
      label: 'Yes',
    },
    {
      value: 'false',
      label: 'No',
    },
  ];

  const validationSchema = Yup.object().shape({
    snr: Yup.string().required('This field cannot be empty!'),
    communeNumber: Yup.string().required('This field cannot be empty!'),
    priceTable: Yup.string().required('This field cannot be empty!'),
    cardPayment: Yup.string().required('This field cannot be empty!'),
    postalCode: Yup.string().required('This field cannot be empty!'),
    address: Yup.string().required('This field cannot be empty!'),
    invoiceReceiver: Yup.string().required('This field cannot be empty!'),
    id: Yup.string().required('This field cannot be empty!'),
    bnr: Yup.string().required('This field cannot be empty!'),
    festeNr: Yup.string().required('This field cannot be empty!'),
    gnr: Yup.string().required('This field cannot be empty!'),
  });

  const initialValues: NewCustomerFormValuesType = {
    priceTable:
      currentCustomer?.PrisGruppeTabellPKey !== null && priceTableOptions
        ? priceTableOptions?.find(
            option =>
              option.value === currentCustomer?.PrisGruppeTabellPKey.toString()
          )
        : '',
    alternativeName: currentCustomer?.AlternativtNavn || '',
    id: currentCustomer?.BKey || null,
    address: currentCustomer?.Adresse || '',
    communeNumber: currentCustomer?.navPostSted || '',
    postalCode: currentCustomer?.PostNr || null,
    phoneNumber: currentCustomer?.TlfNr || '',
    info: currentCustomer?.Info || '',
    organizationNumber: currentCustomer?.OrgNr || '',
    bnr: currentCustomer?.BruksNr || '',
    gnr: currentCustomer?.GaardsNr || '',
    snr: currentCustomer?.SNr || '',
    festeNr: currentCustomer?.FesteNr || '',
    cardPayment:
      cardPaymentOptions.find(
        option => JSON.parse(option.value) === currentCustomer?.KredittGodkjent
      ) || false,
    invoiceReference: currentCustomer?.EPost || '',
    invoiceReceiver: currentCustomer?.Navn || '',
  };

  const MenuList = (props: any) => {
    const height = 35;

    const { options, children, maxHeight, maxWidth, getValue } = props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;

    return (
      <List
        width={maxWidth}
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  };

  return (
    <CustomModal isOpen={isOpen} toggle={toggleModal} modalTitle={modalTitle}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={currentCustomer ? handleEditCustomer : handleAddCustomer}
        // onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ isSubmitting }) => {
          return (
            <>
              <Form noValidate>
                <>
                  {addNewCustomerErrorMessage && (
                    <Alert type="danger">{addNewCustomerErrorMessage}</Alert>
                  )}
                  <ModalRow rowTitle="General">
                    <Grid item xs={3} className={classes.input}>
                      <InputField
                        name="id"
                        placeholder={intl.formatMessage({
                          id: `Global.form.placeholders.id`,
                        })}
                        label={
                          <FormattedMessage id={`Global.form.labels.id`} />
                        }
                        size="small"
                        disabled={currentCustomer !== undefined}
                        required
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.input}>
                      <InputField
                        name="alternativeName"
                        placeholder={intl.formatMessage({
                          id: `Global.form.placeholders.alternativeName`,
                        })}
                        label={
                          <FormattedMessage
                            id={`Global.form.labels.alternativeName`}
                          />
                        }
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.input}>
                      <InputField
                        name="phoneNumber"
                        placeholder={intl.formatMessage({
                          id: `Global.form.placeholders.phoneNumber`,
                        })}
                        label={
                          <FormattedMessage
                            id={`Global.form.labels.phoneNumber`}
                          />
                        }
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.input}>
                      <InputField
                        name="info"
                        placeholder={intl.formatMessage({
                          id: `Global.form.placeholders.info`,
                        })}
                        label={
                          <FormattedMessage id={`Global.form.labels.info`} />
                        }
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.input}>
                      <InputField
                        name="organizationNumber"
                        placeholder={intl.formatMessage({
                          id: `Global.form.placeholders.idNrOrgNr`,
                        })}
                        label={
                          <FormattedMessage
                            id={`Global.form.labels.idNrOrgNr`}
                          />
                        }
                        size="small"
                      />
                    </Grid>
                  </ModalRow>

                  <ModalRow rowTitle="Address 1">
                    <Grid container>
                      <Grid item xs={6} className={classes.input}>
                        <InputField
                          name="address"
                          placeholder={intl.formatMessage({
                            id: `Global.form.placeholders.streetName`,
                          })}
                          label={
                            <FormattedMessage
                              id={`Global.form.labels.streetName`}
                            />
                          }
                          size="small"
                          required
                        />
                      </Grid>

                      <Grid item xs={3} className={classes.input}>
                        <SelectField
                          name="postalCode"
                          components={{ MenuList }}
                          options={postCodesOptions}
                          defaultValue={initialValues.postalCode}
                          placeholder={intl.formatMessage({
                            id: `Global.form.placeholders.postalCode`,
                          })}
                          label={
                            <FormattedMessage
                              id={`Global.form.labels.postalCode`}
                            />
                          }
                          isClearable
                          isRequired
                        />
                      </Grid>
                      <Grid item xs={3} className={classes.input}>
                        <SelectField
                          name="communeNumber"
                          components={{ MenuList }}
                          options={communeNumbersOptions}
                          // defaultValue={initialValues.communeNumber}
                          placeholder={intl.formatMessage({
                            id: `Global.form.placeholders.communeNumber`,
                          })}
                          label={
                            <FormattedMessage
                              id={`Global.form.labels.communeNumber`}
                            />
                          }
                          isClearable
                          isRequired
                        />
                      </Grid>

                      {/*<Grid item xs={3} className={classes.input}>*/}
                      {/*  <InputField*/}
                      {/*    name="communeNumber"*/}
                      {/*    placeholder={intl.formatMessage({*/}
                      {/*      id: `Global.form.placeholders.communeNumber`,*/}
                      {/*    })}*/}
                      {/*    label={*/}
                      {/*      <FormattedMessage*/}
                      {/*        id={`Global.form.labels.communeNumber`}*/}
                      {/*      />*/}
                      {/*    }*/}
                      {/*    size="small"*/}
                      {/*    required*/}
                      {/*  />*/}
                      {/*</Grid>*/}
                      <Grid item xs={3} className={classes.input}>
                        <InputField
                          name="bnr"
                          placeholder={intl.formatMessage({
                            id: `Global.form.placeholders.bnr`,
                          })}
                          label={
                            <FormattedMessage id={`Global.form.labels.bnr`} />
                          }
                          size="small"
                          required
                        />
                      </Grid>
                      <Grid item xs={3} className={classes.input}>
                        <InputField
                          name="gnr"
                          placeholder={intl.formatMessage({
                            id: `Global.form.placeholders.gnr`,
                          })}
                          label={
                            <FormattedMessage id={`Global.form.labels.gnr`} />
                          }
                          size="small"
                          required
                        />
                      </Grid>
                      <Grid item xs={3} className={classes.input}>
                        <InputField
                          name="snr"
                          placeholder={intl.formatMessage({
                            id: `Global.form.placeholders.snr`,
                          })}
                          label={
                            <FormattedMessage id={`Global.form.labels.snr`} />
                          }
                          size="small"
                          required
                        />
                      </Grid>
                      <Grid item xs={3} className={classes.input}>
                        <InputField
                          name="festeNr"
                          placeholder={intl.formatMessage({
                            id: `Global.form.placeholders.festeNr`,
                          })}
                          label={
                            <FormattedMessage
                              id={`Global.form.labels.festeNr`}
                            />
                          }
                          size="small"
                          required
                        />
                      </Grid>
                    </Grid>
                  </ModalRow>

                  <ModalRow rowTitle="Invoice">
                    <Grid container className={classes.sectionStart}>
                      <Grid item xs={3} className={classes.input}>
                        <SelectField
                          name="cardPayment"
                          options={cardPaymentOptions}
                          defaultValue={initialValues.cardPayment}
                          placeholder={intl.formatMessage({
                            id: `Global.form.placeholders.creditApproved`,
                          })}
                          label={
                            <FormattedMessage
                              id={`Global.form.labels.creditApproved`}
                            />
                          }
                          isRequired
                        />
                      </Grid>
                      <Grid item xs={3} className={classes.input}>
                        <SelectField
                          name="priceTable"
                          options={priceTableOptions}
                          defaultValue={initialValues.priceTable}
                          placeholder={intl.formatMessage({
                            id: `Global.form.placeholders.priceTable`,
                          })}
                          label={
                            <FormattedMessage
                              id={`Global.form.labels.priceTable`}
                            />
                          }
                          isRequired
                        />
                      </Grid>
                      <Grid item xs={3} className={classes.input}>
                        <InputField
                          name="invoiceReceiver"
                          placeholder={intl.formatMessage({
                            id: `Global.form.placeholders.invoiceReceiver`,
                          })}
                          label={
                            <FormattedMessage
                              id={`Global.form.labels.invoiceReceiver`}
                            />
                          }
                          size="small"
                          required
                        />
                      </Grid>
                      <Grid item xs={3} className={classes.input}>
                        <InputField
                          name="invoiceReference"
                          placeholder={intl.formatMessage({
                            id: `Global.form.placeholders.invoiceReference`,
                          })}
                          label={
                            <FormattedMessage
                              id={`Global.form.labels.invoiceReference`}
                            />
                          }
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  </ModalRow>

                  <ButtonLoading
                    type="submit"
                    value={currentCustomer ? 'Global.save' : 'Global.create'}
                    state={isSubmitting}
                    disabled={isSubmitting}
                    className={classes.button}
                  />
                </>
              </Form>
            </>
          );
        }}
      </Formik>
    </CustomModal>
  );
};

export default injectIntl(observer(CustomerModal));
