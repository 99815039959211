import React from 'react';
import Chart from 'react-apexcharts';
import { Paper } from '@material-ui/core';

// COMPONENTS:
import ChartFilter from '../ChartFilter';

// STYLES:
import { useStyles } from './index.styles';
import { DataUsage } from '@material-ui/icons';

const TypeOfCustomers = () => {
  const classes = useStyles();

  const options = {
    chart: {
      type: 'donut',
    },
    colors: ['#ee4266', '#3bceac', '#d3d3d3'],
    labels: ['Private', 'Companies', 'Undefined (cash only)'],
    legend: {
      show: true,
      position: 'right',
      height: '100%',
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
            },
          },
        },
      },
    },
  };

  const series = [892, 400, 382];

  const filterOptions = [
    {
      value: 'overall',
      label: 'Overall',
    },
    {
      value: 'someotheroption',
      label: 'Some other option',
    },
  ];

  return (
    <Paper className={classes.paper}>
      <div className={classes.chartContainer}>
        <div className={classes.toolbarContainer}>
          <div className={classes.title}>
            <DataUsage color="primary" />
            <span className={classes.titleText}>Type of customers</span>
          </div>
          <ChartFilter
            options={filterOptions}
            onChangeCallback={o => console.log(o)}
          />
        </div>
        <Chart options={options} series={series} type="donut" />
      </div>
    </Paper>
  );
};

export default TypeOfCustomers;
