import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { Grid, Paper } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

// COMPONENTS:
import Header from 'common/components/Header';
import PricetablesTable from './components/Table';
import PricetableInformations from './components/Preview';

// STYLES:
import { useStyles } from './index.styles';

// STORE
import { IPricetablesStore, PricetablesStoreContext } from 'stores/pricetables';

const Pricetables = () => {
  const classes = useStyles();

  const breadcrumbs = [
    {
      name: 'Pricetables List',
      path: '/pricetables',
    },
  ];

  const { currentPricetable }: IPricetablesStore = useContext(
    PricetablesStoreContext
  );

  return (
    <div className={classes.container}>
      <Header
        breadcrumbs={breadcrumbs}
        title={<FormattedMessage id="Company.Pricetables.pricetables" />}
      />
      <Grid container className={classes.contentContainer}>
        <Grid item xs={12} sm={12} md={6} className={classes.column}>
          <PricetablesTable />
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={classes.column}>
          <Paper className={classes.employeesDetailsPaper}>
            <PricetableInformations currentPricetable={currentPricetable!} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default observer(Pricetables);
